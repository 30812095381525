import { Component, OnInit, Injector } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { first, mergeMap } from 'rxjs/operators';
import { ImageService } from '../../../_services/imageCarousel.service';
import { LanguageService } from '../../../_services/language.service';
import { LanguageReadDto } from '../../../_models/languageReadDto';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { Observable, pipe } from 'rxjs';
import { ImageCarousel } from 'src/app/_models/imageCarousel';

@Component({
  selector: 'app-image-carousel-addnew',
  templateUrl: './image-carousel.add.component.html',
})

export class ImageCarouselAddComponent implements OnInit {
  imageAddNew: any;
  languageList: LanguageReadDto[];

  constructor(
    private formbulider: FormBuilder,
    private imageService: ImageService,
    private languageService: LanguageService,
    private global: Global,
    private toastr: ToastrService,
    private fileUpload: FileUploadService
  ) {
    this.fetchLanguageAll();
  }

  ngOnInit() {
    this.imageAddNew = this.formbulider.group({
      Order: ['', [Validators.required]],
      ImageUrl: ['', [Validators.required]],
      IsActive: [false, [Validators.required]],
      file: ['', [Validators.required]],
      fileSource: ['', [Validators.required]],
      LanguageCode: ['', [Validators.required]],
    });
  }
  fetchLanguageAll() {
    this.languageService.apiLanguageGet()
      .pipe(first())
      .subscribe(
        data => {
          this.languageList = data.filter(t => t.isActive).sort(t => t.order);
        });
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.imageAddNew.patchValue({
        fileSource: file
      });
    }
  }
  onImageFormSubmit() {
    this.uploadImage().subscribe(imageInfo=> {
      const Imgnew = new ImageCarousel({
        imgUrl: imageInfo,
        isActive: this.imageAddNew.value.IsActive,
        order: this.imageAddNew.value.Order,
        languageCode: this.imageAddNew.value.LanguageCode,
      })
      this.imageService.addNew(Imgnew).subscribe(data => {
            this.modalClose();
            this.global.appEvent.next({ msg: 'reload.image-carousel', para: null });
            this.toastr.success('Data Created Successfully...!', 'Success');
          });
    })
  }

  uploadImage(){
    return new Observable(subscriber => {
      this.fileUpload.postFile([{file: this.imageAddNew.value.fileSource, name: this.imageAddNew.value.fileSource.name}]).subscribe(res=>{
        subscriber.next(res[0].origin);
        subscriber.complete();
      })
    })
  }


  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });
  }
}
