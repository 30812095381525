import { AfterViewInit, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { MapTab, StageNavList } from 'src/app/common/constants';
import { MapDataSourceService } from './service/map-data-source.service';
import { PublicMapService } from './service/public-map.service';
import { StageGroupMode } from './component/stage-group-item/stage-group-item.component';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends BaseComponent implements OnInit, AfterViewInit {
  currentFocusOnInfo: any;
  showIconUrl: boolean = true
  readonly MapTab = MapTab
  readonly StageGroupMode = StageGroupMode
  readonly StageNavList = StageNavList
  tabInfo = {
    currentTab: MapTab.Stage,
    activeStageType: undefined,
    tabList: [
        { name: MapTab.Stage, active: true },
        { name: MapTab.Marker, active: false },
        { name: MapTab.Portal, active: false },
        { name: MapTab.Escape, active: false },
        { name: MapTab.StageGroup, active: false }
    ]
  }
  activeStateList = [
    { label: 'All', value: null, active: true},
    { label: 'Active', value: {IsInactive: false}, active: false },
    { label: 'Inactive', value: {IsInactive: true} , active: false}
  ]
  canvasMode: 'grid'|'point' = 'grid'
  dataSource: any
  currentStageGroup: Array<string> = []
  isVisible = false;
  searchID: string

  get currentActiveSateLable(){
    return this.activeStateList.find(item => item.active)?.label
  }

  get selectedTabIndex(){
    let idx
    this.tabInfo.tabList.forEach((item,index)=>{
      if(item.name === this.tabInfo.currentTab){
        idx = index
      }
    })
    return idx
  }

  get data(){
    return this.publicMapService.loadedPoints
  }

  get totalCount(){
    return this.publicMapService.totalCount
  }


  constructor(
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    public global: Global,
    private dataSourceService: MapDataSourceService,
    private publicMapService: PublicMapService,
  ) {
    super(global)
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'show-detail':
          this.showDetail(para)
          break;
        case 'show-grid':
          this.showGrid()
          break
        case 'set.currentStageGroup':
          this.currentStageGroup = para
          this.ref.detectChanges()
          break
        case 'reload-map':
          this.changeTab(this.tabInfo.currentTab, this.tabInfo.activeStageType)
          break
      }
    });

    if(sessionStorage.getItem('initialMapTabName') && sessionStorage.getItem('initialMapTabName')!== MapTab.Stage){
      const tabItem = this.tabInfo.tabList.find(item=> item.name === sessionStorage.getItem('initialMapTabName') )
      this.setTabInfo(tabItem.name)
    } else {
      this.setTabInfo(MapTab.Stage)
    }

    this.publicMapService.documentEventHandler()
  };



  ngOnInit() {
    this.publicMapService.clearMarker()
    this.dataSourceService.getSourceNewVersion().subscribe((res: any)=>{
      this.dataSource = res
    })
    
  }



  ngAfterViewInit(): void {
    this.publicMapService.loadMap().subscribe({
      next:()=>{
        this.getData(true)
        this.showGrid()
      },
      error:()=>{
        this.toastr.error('Map loading failed')
      }
    })
  }


  setTabInfo(name: MapTab, stageType?){
    if(this.tabInfo.currentTab === name && this.tabInfo.activeStageType === stageType){
      return
    }
    this.tabInfo.tabList.forEach(item =>{
      if(item.name === name){
        item.active = true
        this.tabInfo.currentTab = name;
        sessionStorage.setItem('initialMapTabName', this.tabInfo.currentTab)
      } else {
        item.active = false
      }
      this.tabInfo.activeStageType = stageType
    })
  }

  changeTab(name, stageType?){
    this.currentFocusOnInfo = null;
    this.setTabInfo(name, stageType)
    this.showGrid()
    this.getData(true)
    // reset search field
    const input = document.getElementById('autoCompleteField') as HTMLInputElement;
    input.value = ''
  }

  openDetailPanel(ev){
    this.publicMapService.setCenter(ev.latitude, ev.longitude);
    this.showDetail(ev)
    this.tabInfo.currentTab === MapTab.Portal && this.publicMapService.checkPortalMaker(ev)
  }

  showDetail(para){
    this.canvasMode = 'point'
    this.currentFocusOnInfo = {...para, type: para?.type || this.tabInfo.currentTab}
    this.ref.detectChanges()  // to detect currentFocusOnInfo changed
  }

  showGrid(){
    this.canvasMode = 'grid'
  }

  ngOnDestroy(){
    // For switch tab to another page to trigger
    this.publicMapService.setCurrentStageGroup([])
    this.eventSubscription.unsubscribe()
  }
  getData(resetMode, prop?){
    const para = this.tabInfo.activeStageType ? {...prop, stageType: this.tabInfo.activeStageType} : prop
    const activePara = this.checkActiveState()
    this.publicMapService.getMorePoint(this.tabInfo.currentTab, resetMode, {...para, ...activePara})
    this.searchID = ''
  }

  checkActiveState(){
    let activeState;
    if(this.tabInfo.currentTab === MapTab.Stage){
      activeState = this.activeStateList.find(item=> item.active)
    } else {
      // reset to get all when tab is not stage
      this.activeStateList.forEach((item, index)=>{
        item.active = index === 0
      })
    }
    return activeState?.value
  }

  updateActiveAndGetData(triggerIndex, para?){
    this.activeStateList.forEach((item, index)=>{
      item.active = index === triggerIndex
    })
    this.getData(true, para)
  }

}
