<div class="card">
  <div class="card-body">
    <h5>
      Near By Place
    </h5>
    <div style="overflow:auto;">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              <div class="form-check">
                <input class="header-check form-check-input" type="checkbox" [(ngModel)]="selectAll" (ngModelChange)="onSelectAllChange($event)" />
              </div>
            </th>
            <th scope="col">Name</th>
            <th scope="col">Reward
              <select class="form-select" (change)="onSetRewards($event)">
                <option selected></option>
                <!-- <option *ngFor="let reward of rewards" value="{{reward.id}}">Des: {{reward.description}} Id: {{reward.id}}</option> -->
                <option *ngFor="let reward of rewards" value="{{reward.id}}">{{reward.description}}</option>
              
              </select>
            </th>
            <th scope="col">
              <button class="btn btn-primary btn-sm" type="button" [disabled]="!addAll" (click)="addBatchPoint()"><i class="fas fa-fw fa-plus"></i></button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let listItem of markersArray; index as i">
            <ng-container [formGroup]="listItem">
              <td>
                <div class="form-check">
                  <input class="header-check form-check-input" type="checkbox" formControlName="checked" *ngIf="!listItem.value.exist" (change)="checkBoxUpdate(listItem)"/>
                </div>
              </td>
              <td [ngClass]="{isExist: listItem.value.exist}">#{{i+1}}: {{listItem.value.name}}</td>
              <td>
                <select class="form-select" formControlName="mmitaRewardId">
                  <!-- <option *ngFor="let reward of rewards" value="{{reward.id}}">Des: {{reward.description}} Id: {{reward.id}}</option> -->
                  <option *ngFor="let reward of rewards" value="{{reward.id}}">{{reward.description}}</option>

                </select>
              </td>
              <td>
                <!-- by design: not allow to update -->
                <!-- <button class="btn btn-success btn-sm setMargin" type="button" (click)="editPoint(listItem)" *ngIf="listItem.value.exist && listItem.disabled"><i class="fas fa-fw fa-edit"></i></button>
                <button class="btn btn-info btn-sm setMargin" type="button" (click)="updatePoint(listItem)"
                  *ngIf="listItem.value.exist && listItem.enabled"><i class="fas fa-fw fa-save"></i></button> -->
                <button class="btn btn-primary btn-sm" type="button" (click)="addPoint(listItem)"
                *ngIf="!listItem.value.exist"><i class="fas fa-fw fa-plus"></i></button>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>