import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MitFieldInfo } from 'src/app/_models/body';

@Component({
  selector: 'app-mit-dialog',
  templateUrl: './mit-dialog.component.html',
  styleUrls: ['./mit-dialog.component.css']
})
export class MitDialogComponent implements OnInit {
  @Input() formGroup: FormGroup
  @Input() dialogFields: Array<MitFieldInfo> 
  @Input() callback
  constructor(
    private nzModalRef: NzModalRef,

  ) { }

  ngOnInit(): void {
  }

  closeEvent(){
    this.nzModalRef.close()
  }

  cancelEvent(){
    this.nzModalRef.triggerCancel()
  }

}
