import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { ReportService } from 'src/app/_services/report.service';
import { DatePipe } from '@angular/common';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  providers: [DatePipe]
})
export class ReportComponent extends BaseComponent{
  report: Array<any> = [];
  constructor(
    private reportService: ReportService,
    private toastr: ToastrService,
    public global: Global,
    private datePipe: DatePipe
  ) {
    super(global)
  }


  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'User ID', field: 'fromUserId'},
        { headerName: 'Module Type', field: 'moduleType'},
        { headerName: 'Report Id', field: 'theReportedItemId'},
        { headerName: 'Description', field: 'description'},
        { headerName: 'Created At', field: 'createdAt', sort: 'desc',
          valueFormatter: (params) =>{
           return this.datePipe.transform(params.value, 'short') 
          }},
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getReport();
  }

  ngOnInit() {
    this.setColumnDefs();
  }
  getReport() {
    this.reportService.getAll()
      .subscribe(
        data => {
          this.report = data;
        });
  }

}
