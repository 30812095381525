import { MitFieldInfo } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const CouponBaseField : Array<MitFieldInfo> =  [
      {
          key: 'type',
          label: 'Type',
          type: InputFieldType.Selector,
          size: 12,
          required: true,
          options: ['Stripe', 'Mmita']
      },
      {
        key: 'subName',
        label: 'Sub Name',
        type: InputFieldType.Text,
        size: 12,
        required: true,
      },
      {
        key: 'couponName',
        label: 'Coupon Name',
        type: InputFieldType.Text,
        size: 12,
        required: true,
      },
      {
        key: 'expiresAt',
        label: 'Expires At',
        type: InputFieldType.DateTimePicker,
        size: 12,
      },
      {
        key: 'merchantName',
        label: 'Merchant Name',
        type: InputFieldType.Text,
        size: 12,
        required: true,
      },
      {
        key: 'description',
        label: 'Description',
        type: InputFieldType.Text,
        size: 24,
        required: true
      },
      {
        key: 'profileUrl',
        label: 'Profile File',
        type: InputFieldType.Uploader,
        size: 12,
        required: true,
      },
    ]
  
export const StripeTypeFields =  (stripeAccount, stripeCoupon):Array<MitFieldInfo> => [
    {
      key: 'stripeAccountId',
      label: 'Stripe Account',
      type: InputFieldType.Selector,
      size: 12,
      options: stripeAccount
    },
    {
      key: 'stripeCouponId',
      label: 'Stripe Coupon',
      type: InputFieldType.Selector,
      size: 12,
      options: stripeCoupon
    },
  ]
  
export const MmitaTypeFields : Array<MitFieldInfo> = [
    {
      key: 'promotionCodes',
      label: 'Promotion Codes',
      type: InputFieldType.InputArray,
      size: 24,
    }
]

export const MmitaTypeCouponFields: Array<MitFieldInfo> = [
  {
    key: 'excel',
    label: 'Upload Coupon By Excel',
    type: InputFieldType.Uploader,
    size: 12,
    accept: '.xlsx, .xls, .csv'
  },
  {
    key: 'promotionCodes',
    label: 'Promotion Codes',
    type: InputFieldType.InputArray,
    size: 24,
  }
]

  