import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BaseComponent } from "src/app/ui-library/component/base.component";
import { Global } from "src/app/_services/global.service";
import { ModalService } from "src/app/_services/modal.service";
import { TaskService } from "src/app/_services/task.service";
import { RewardService } from "src/app/_services/reward.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TaskTemplate } from "./task-item.constant";
import { Observable } from "rxjs";

@Component({
    selector: 'app-task',
    templateUrl: './task.component.html',
  })
  export class TaskComponent extends BaseComponent{
    tasks: Array<any> = [];
    rewards: Array<any> = []
    formGroup: FormGroup

    constructor(
      private taskService: TaskService,
      private toastr: ToastrService,
      public global: Global,
      private modalService: ModalService,
      private rewardService: RewardService,
    ) {
        super(global);
    }

    setColumnDefs(){
      this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Name', field: 'name'},
        { headerName: 'Description', field: 'description'},
        { headerName: 'Asset ID', field: 'mmitaReward.rewardAssetIds'},
        { headerName: 'Number Of Tokens', field: 'mmitaReward.numberOfTokens'},
        { headerName: 'Event', field: 'taskEvent'},
        { headerName: 'Active', field: 'isActive'},
        { headerName: 'Repetition',field: 'repeatingIntervalType'},
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 120,
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="edit" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Task Detail')
                break;
              case 'edit':
                this.showItem(params.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
            }
          },
        },
      ];

    }
  
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.getAll();
    }

    getReward(){
      this.rewardService.getAll().subscribe(res=>{
        this.rewards = res.data.map(item=>{
          return {...item, name: item.description}
        })
      })
    }
  
    ngOnInit() {
      this.setColumnDefs();
      this.getReward()
    }

    getAll() {
      this.taskService.getAll()
        .subscribe(
          data => {
            this.tasks = data;
          });
    }
    
    showItem(data?){
      this.genFG(data)
      const dialogTemplate = TaskTemplate(
        this.global.section.IntervalTypeSelections, 
        this.rewards,
        this.global.section.TaskEventSelections)
      this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
    }

    delete(id){
      this.taskService.delete(id).subscribe(res=>{
          this.getAll();
          this.toastr.success('Data Delete Successfully...!', 'Success');
        })
    }

    genFG(data?){
      this.formGroup = new FormGroup({
        name: new FormControl(data?.name, Validators.required),
        description: new FormControl(data?.description, Validators.required),
        isActive: new FormControl(data?.isActive || false),
        repeatingIntervalType: new FormControl(data?.repeatingIntervalType, Validators.required),
        prerequisiteTaskId: new FormControl(data?.prerequisiteTaskId),
        intervalAfterPrerequisiteTask: new FormControl(data?.intervalAfterPrerequisiteTask, Validators.required),
        mmitaRewardId: new FormControl(data?.mmitaReward?.id, Validators.required),
        taskEvent: new FormControl(data?.taskEvent, Validators.required),
      });
    }

    onSubmit(id){
      return new Observable(sub=>{
        const callBack = () =>{
          this.getAll()
          this.toastr.success(`Saved Successfully...!`, 'Success');
          sub.next()
          sub.complete()
        }
        id ? this.taskService.updateById(this.formGroup.value, id).subscribe(()=>{
          callBack()
        }) : this.taskService.insertNew(this.formGroup.value).subscribe(()=>{
          callBack()
        })
      })

    }
}