import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PublicMapService } from '../../../service/public-map.service';
import { MapTab } from 'src/app/common/constants';

@Component({
  selector: 'app-add-stage-group',
  templateUrl: './add-stage-group.component.html',
  styleUrls: ['./add-stage-group.component.css'],
})
export class AddStageGroupComponent implements OnInit {
  name
  @Output() closeDialog = new EventEmitter<any>();

  get group(){
    return this.publicMapService.currentStageGroup
  }

  constructor(
    private publicMapService: PublicMapService,
  ) { }

  ngOnInit(): void {
  }

  remove(id){
    this.publicMapService.setCurrentStageGroup(this.group.filter((x) => x.id !== id))
  }

  submit(){
    this.publicMapService.addItem({data: {name: this.name, stageIds: this.group.map(item=> item.id)}, type: MapTab.StageGroup}).subscribe(_=>{
      this.closeDialog.emit()
    })
  }
}
