import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StreamService } from 'src/app/_services/streamer.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Observable, forkJoin } from 'rxjs';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { StageService } from 'src/app/_services/stage.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StreamingTemplate } from './streaming-item.constant';

@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.css']
})
export class StreamingComponent extends BaseComponent {
  data: Array<any>
  streamerSource: Array<any>
  assetSource: Array<any>
  stageSource: Array<any>
  streamerId: string
  formGroup: FormGroup
  constructor(
    private streamService: StreamService,
    public global: Global,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private assetService: MmitaAssetService,
    private stageService: StageService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
      super(global)
      this.streamerId = this.route.snapshot.paramMap.get('streamerId');
  }


  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Theme', field: 'theme'},
        { headerName: 'Description', field: 'description'},
        { headerName: 'Streamer', field: 'streamer.fullName'},
        { headerName: 'Event Status', field: 'status'},
        { headerName: 'Record Status', field: 'channel.recordStatus'},
        { headerName: 'Channel Status', field: 'channel.status'},
        { headerName: 'Created At', field: 'createdAt',  valueFormatter: (params) =>{
          return this.datePipe.transform(params.value, 'short') }
        },
        { headerName: 'Started At', field: 'startedAt',  valueFormatter: (params) =>{
          return this.datePipe.transform(params.value, 'short') }
        },
        { headerName: 'Channel Started At', field: 'channel.startedAt',  valueFormatter: (params) =>{
          return this.datePipe.transform(params.value, 'short') }
        },        
        { headerName: 'Channel Finished At', field: 'channel.finishedAt',  valueFormatter: (params) =>{
          return this.datePipe.transform(params.value, 'short') }
        },
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 150,
          cellRenderer: params => {
            return `
                      <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-copy text-success" id="copy" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="edit" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Streaming Event Detail')
                break;
              case 'edit':
                this.showItem(params?.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
              case 'copy':
                const data = this.createCopyEventDto(params.data)
                this.streamService.createStreamingEvent(data).subscribe(res=>{
                  this.getAll()
                  this.toastr.success('copy successfully')
                })
                break
            }
          },
        },
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }

  ngOnInit() {
    this.setColumnDefs();
    this.getSource()
  }

  getSource(){

    forkJoin({
      streamerSource: this.streamService.getAll(),
      assetSource: this.assetService.getAll({BusinessType: 'Streamer', PageSize: -1}),
      stageSource: this.stageService.getStage({
        StageType: 'Streamer',
        PageSize: '-1'
      })
    }).subscribe(res=>{
        this.streamerSource = res.streamerSource.data.map(item=>{
          return {...item, name: `Name:${item.fullName}, ID:${item.id}`}
        })
        this.assetSource = res.assetSource.data
        this.stageSource = res.stageSource.data.map(item=>{
          return {...item, name: `ID:${item.id}, Description:${item.description}`}
        })
      })
  }


  getAll() {
    this.streamService.getStreamingEvents(this.streamerId).subscribe((res:InfiniteResult)=>{
      this.data = res.data
    })
  }

  showItem(data?){
    this.genFG(data)
    const dialogTemplate = StreamingTemplate(data,
       this.assetSource,
       this.streamerSource,
       this.stageSource,
       this.global.section.StreamChannelStatusSelections,
       this.global.section.StreamEventStatusSelections)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }

  onSubmit(id?){
    return new Observable(sub=>{
      const callBack = () =>{
        this.toastr.success(`Saved Successfully...!`, 'Success');
        this.getAll()
        sub.next()
        sub.complete()
      }
      id ? this.streamService.updateStreamingEvent(this.formGroup.value, id).subscribe(()=>{
        callBack()
      }) : this.streamService.createStreamingEvent(this.formGroup.value).subscribe(()=>{
        callBack()
      })
    })
  }

  delete(id){
    this.streamService.deleteEvent(id)
    .subscribe(data => {
        this.getAll();
        this.toastr.success('Data Deleted Successfully...!', 'Success');
    });
  }

  createCopyEventDto(ev){
    return {
      description: ev.description,
      streamerId: ev.streamer.id,
      assetId: ev.asset.id,
      theme: ev.theme,
      stageIds: ev.stages.map(item=> item.id),
      startedAt: ev.startedAt
    }
  }

  genFG(orgData){
    this.formGroup = new FormGroup({
      description: new FormControl(orgData?.description, Validators.required),
      streamerId: new FormControl(orgData?.streamer?.id, Validators.required),
      assetId: new FormControl(orgData?.asset?.id, Validators.required),
      stageIds: new FormControl(orgData?.stages.map(item=> item?.id)),
      startedAt: new FormControl(orgData?.startedAt, Validators.required),
      theme: new FormControl(orgData?.theme, Validators.required),
    });
    if(orgData){
      this.formGroup.addControl('status', new FormControl(orgData?.channel?.status))
      this.formGroup.addControl('eventStatus', new FormControl(orgData?.status))
    }
  }




}