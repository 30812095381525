import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImageTypeTemplate, Model3dTypeTemplate, VideoTypeTemplate } from './asset-item.constant';
import { AssetType } from 'src/app/_models/AssetType';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { Observable } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-ant-asset-item',
  templateUrl: './ant-asset-item.component.html',
  styleUrls: ['./ant-asset-item.component.css']
})
export class AntAssetItemComponent implements OnInit {
  readonly source
  readonly copyMode: boolean
  readonly data: any
  readonly isBatch: boolean
  formGroup: FormGroup
  dialogFields
  callback
  constructor(
    private assetService: MmitaAssetService,
    private nzModalRef: NzModalRef
  ) { }

  ngOnInit(): void {
    this.genFG()
    this.setFormStatus(this.formGroup.value.assetType)
    this.callback = ()=>this.onSubmit.call(this)
  }

  genDialogField(field){
    if(this.isBatch){
      return field.filter(item=>{
        return item.key !== 'name' && item.key !== 'description'
      })
    }
    return field
  }

  setFormStatus(assetType, requiredVisionOS?){
    if(this.isBatch){
      this.formGroup.get('name').disable()
      this.formGroup.get('description').disable()
    }
    switch(assetType){
      case AssetType.Image:
        this.dialogFields = this.genDialogField(ImageTypeTemplate(this.source, this.isBatch))
        this.formGroup.get('thumbnailUrl').disable()
        this.formGroup.get('androidUrl').disable()
        this.formGroup.get('visionOSUrl').disable()
        this.formGroup.get('macOSUrl').disable()
        break;
      case AssetType.Video:
        this.dialogFields = this.genDialogField(VideoTypeTemplate(this.source, this.isBatch))
        this.formGroup.get('thumbnailUrl').enable()
        this.formGroup.get('androidUrl').disable()
        this.formGroup.get('visionOSUrl').disable()
        this.formGroup.get('macOSUrl').disable()
        break;
      case AssetType.Model3d:
        this.dialogFields = this.genDialogField(Model3dTypeTemplate(this.source, this.isBatch, requiredVisionOS))
        this.formGroup.get('thumbnailUrl').enable()
        this.formGroup.get('androidUrl').enable()
        this.formGroup.get('visionOSUrl').enable()
        this.formGroup.get('macOSUrl').enable()
        break;
    }
  }


  genFG(){
    this.formGroup = new FormGroup({
      assetType: new FormControl(this.data?.assetType || AssetType.Image, Validators.required),
      categoryId: new FormControl(this.data?.category?.id, Validators.required),
      scaleInMap: new FormControl(this.data?.scaleInMap || 1, [Validators.required, Validators.min(0)]),
      abilitiyIds: new FormControl(this.data?.abilitiyIds|| []),
      name: new FormControl(this.data?.name, Validators.required),
      description: new FormControl(this.data?.description),
      numberOfTokensUnlocked: new FormControl(this.data?.numberOfTokensUnlocked || 0, [Validators.required, Validators.min(0)]),
      adUrlLinked: new FormControl(this.data?.adUrlLinked),
      assetStatus: new FormControl(this.data?.assetStatus || 'Inactive', [Validators.required]),
      businessType: new FormControl(this.data?.businessType, Validators.required),
      url: new FormControl(this.data?.file?.url, Validators.required),
      thumbnailUrl: new FormControl(this.data?.thumbnail?.url, Validators.required),
      androidUrl: new FormControl(this.data?.androidFile?.url),
      visionOSUrl: new FormControl(this.data?.visionOSFile?.url),
      macOSUrl: new FormControl(this.data?.macOSFile?.url),
      tags: new FormControl(this.data?.tags.map(item=> item.name)),
    })
    this.formGroup.get('assetType').valueChanges.subscribe(res=>{
      this.setFormStatus(res)
      this.resetUrlField()
    })
    this.formGroup.get('categoryId').valueChanges.subscribe(res=>{
      const selectedCat = this.source.categoryFilterByType[AssetType.Model3d].find(item=>{
        return item.id === res
      })
      this.setFormStatus(this.formGroup.value.assetType, selectedCat?.assetCategoryOptions?.indexOf('VisionOS') >=0)
      this.resetUrlField()
      selectedCat?.assetCategoryOptions?.indexOf('VisionOS') >=0 ? this.formGroup.get('visionOSUrl').setValidators(Validators.required) : this.formGroup.get('visionOSUrl').setValidators(null)
    })
    !this.isBatch && !this.data && this.autoApplyFileSource()
  }

  resetUrlField(){
    this.formGroup.patchValue({url: this.data?.file?.url})
    this.formGroup.patchValue({thumbnailUrl: this.data?.thumbnail?.url})
    this.formGroup.patchValue({androidUrl: this.data?.androidFile?.url})
    this.formGroup.patchValue({visionOSUrl: this.data?.visionOSFile?.url})
    this.formGroup.patchValue({macOSUrl: this.data?.macOSFile?.url})
  }

  autoApplyFileSource(){
    const watchList = ['url', 'androidUrl', 'visionOSUrl', 'macOSUrl']
    const toAutoFillInCtrl = (item)=>{
      const checkList = watchList.filter((watchItem) => watchItem!== item);
      let nullFlag = true
      checkList.forEach(watchItem=>{
        if(this.formGroup.value[watchItem] && this.formGroup.value[watchItem] instanceof Array && this.formGroup.value[watchItem].length>0){
          nullFlag = false
        }
      })
      return nullFlag ? checkList: null
    }
    watchList.forEach(item=>{
      this.formGroup.get(item).valueChanges.subscribe(uploaded=>{
        if(this.formGroup.value.assetType !== AssetType.Model3d || !uploaded || !uploaded?.length || uploaded[0]?.file?.name.indexOf('.glb')<0){
          return
        }
        const fillInCtrlName = toAutoFillInCtrl(item)
        fillInCtrlName && fillInCtrlName.forEach(fillItem=>{
          setTimeout(()=>{
            this.formGroup.patchValue({[fillItem]: uploaded})
          }, 400)
        })
      })
    })
  }

  onSubmit(){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.nzModalRef.triggerOk()
        subscriber.next()
        subscriber.complete()
      }

      if(this.isBatch){
        const data = this.genBatchAsset()
        this.assetService.batchaddAsset(data).subscribe({
          next: x => saveSuccess(),
          error: x => subscriber.error(x)
        }) 
      } else {
        const data =  {...this.formGroup.value, ...this.imageUrlMapping(this.formGroup.value.assetType)}
        this.data?.id && !this.copyMode ? this.assetService.updateAsset(data, this.data.id).subscribe({
          next: x => saveSuccess(),
          error: x => subscriber.error(x)
        }) :  this.assetService.addAsset(data).subscribe({
          next: x => saveSuccess(),
          error: x => subscriber.error(x)
        })
      }
      })
    }


    private genBatchAsset(){
      const urlData = this.formGroup.value.url
      if(Array.isArray(urlData) && urlData.length>0){
        return urlData.map((item, index)=>{
          return {...this.formGroup.value, ...this.imageUrlMapping(this.formGroup.value.assetType, index)}
        })
      }
      return
    }

    private imageUrlMapping(assetType, index = 0){
      const urlData = this.formGroup.value.url
      const urlItem = Array.isArray(urlData) ? urlData[index] : urlData
      const thumData = this.formGroup.value.thumbnailUrl
      const thumItem = Array.isArray(thumData) ? thumData[index] : thumData

      switch(assetType){
        case AssetType.Image:
          if(urlItem.format === 'gif'){
            const gifThumData = this.formGroup.get('thumbnailUrl').value
            const gifThumItem = Array.isArray(gifThumData) ? gifThumData[index] : gifThumData
            return {
              url: urlItem?.origin?.url || this.data?.file?.url,
              thumbnailUrl: gifThumItem?.medium?.url || this.data?.thumbnail?.url,
              smallThumbnailUrl: gifThumItem?.small?.url || this.data?.smallThumbnail?.url,
              iconUrl: gifThumItem?.icon?.url || this.data?.icon?.url,
            }
          } else {
            return {
              url: urlItem?.medium?.url || this.data?.file?.url,
              thumbnailUrl: urlItem?.medium?.url || this.data?.thumbnail?.url,
              smallThumbnailUrl: urlItem?.small?.url || this.data?.smallThumbnail?.url,
              iconUrl: urlItem?.icon?.url || this.data?.icon?.url,
            }
          }

        case AssetType.Video:
          return {
            url: urlItem?.origin?.url || this.data?.file?.url,
            thumbnailUrl: thumItem?.medium?.url || this.data?.thumbnail?.url,
            smallThumbnailUrl: thumItem?.small?.url || this.data?.smallThumbnail?.url,
            iconUrl: thumItem?.icon?.url || this.data?.icon?.url,
          }
          case AssetType.Model3d:
            const androidItem = Array.isArray(this.formGroup.value.androidUrl) ? this.formGroup.value.androidUrl[index] : this.formGroup.value.androidUrl
            const visionItem = Array.isArray(this.formGroup.value.visionOSUrl) ? this.formGroup.value.visionOSUrl[index] : this.formGroup.value.visionOSUrl
            const macItem = Array.isArray(this.formGroup.value.macOSUrl) ? this.formGroup.value.macOSUrl[index] : this.formGroup.value.macOSUrl
            return {
              url: urlItem?.origin?.url || this.data?.file?.url,
              thumbnailUrl: thumItem?.medium?.url || this.data?.thumbnail?.url,
              smallThumbnailUrl: thumItem?.small?.url || this.data?.smallThumbnail?.url,
              iconUrl: thumItem?.icon?.url || this.data?.icon?.url,
              androidUrl: androidItem?.origin?.url || this.data?.androidFile?.url,
              visionOSUrl: visionItem?.origin?.url || this.data?.visionOSFile?.url,
              macOSUrl: macItem?.origin?.url || this.data?.macOSFile?.url,
            }
      }

    }





}
