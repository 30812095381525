import { Component, Input, OnInit } from '@angular/core';
import { NzUploadFile, NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { UploadFileDto } from 'src/app/_models/uploadFileDto';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'mit-nz-uploader',
  templateUrl: './mit-nz-uploader.component.html',
  styleUrls: ['./mit-nz-uploader.component.css']
})
export class MitNzUploaderComponent implements OnInit {
    @Input() fileList: NzUploadFile[] = []
    @Input() prefix: string
    readonly iconsSetting = { showPreviewIcon: false, showRemoveIcon: true, showDownloadIcon: false }
    constructor(
    ) { }

    ngOnInit(): void {
    }

    handleChange(info: NzUploadChangeParam): void {
        let fileList = [...info.fileList];
        fileList = fileList.map(item => { 
            return {
                ...item, status: 'done'
            }
        })
        this.fileList = fileList;
      }

    
    customRequest = ({ file, onSuccess, onError, action }) => {
        return
    };
    
    beforeUpload = (file: File): boolean => {
        return true;
    };

    getFileList(): Array<UploadFileDto> { 
        return this.fileList.map((file, index) => {
            return {file: file.originFileObj, name: `${this.prefix}-${index}`}
        })
    }

}
