<ul class="nav nav-pills">
    <li *ngFor="let nav of navList" class="nav-item" (click)="changeStageType(nav)">
        <a class="nav-link" [ngClass]="{'active': nav === activeNav}">{{nav}}</a>
    </li>
  </ul>

<ag-grid-angular
  #agGrid
  domLayout='autoHeight'
  class="ag-theme-alpine stage-grid"
  [rowData]="stages"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [paginationPageSize]="10"
  [suppressRowClickSelection]="true"
  [enableCellTextSelection]="true"
  (rowClicked)="onRowClicked($event)"
  >
</ag-grid-angular>