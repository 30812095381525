<div class="row row-cols-sm-auto date-time-picker">
    <div class="input-group" [ngClass]="{'error-field': showError}">
        <input
            class="form-control ant-input date-picker-input"
            placeholder="yyyy-mm-dd"
            name="dp"
            [(ngModel)]="date"
            ngbDatepicker
            #d="ngbDatepicker"
            (click)="d.toggle()"
            (dateSelect)="updateDateTimePicker()"
            [disabled]="formCtrl.disabled"
        />
        <ngb-timepicker [disabled]="formCtrl.disabled" [(ngModel)]="time"  [spinners]="false" (ngModelChange)="updateDateTimePicker()" [ngClass]="{'error-field': showError}" class="time-picker-wrapper"></ngb-timepicker>
    </div>
    <div class="current-time-zone">
        {{currentTZVal}}
    </div> 
    <div *ngIf="showError" class="error-msg">
        Please input field
    </div>
</div>
