import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RewardDto } from '../_models/rewardDto';
import { InfiniteResult } from '../_models/body';

@Injectable({ providedIn: 'root' })
export class RewardService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll(params?) {
    const par = {
      PageSize: '-1'
    }
    if(params){
      for(let prop in params){
        Object.assign(par, {[prop]: params[prop]});
      }
    }
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/MmitaReward/Query`,{
      params: par
    });
  }

  addReward(data: RewardDto) {
    return this.http.post<RewardDto>(`${environment.apiEndpoint}api/MmitaReward`, data);
  }

  delete(id: string){
    return this.http.delete<RewardDto>(`${environment.apiEndpoint}api/MmitaReward/${id}`);
  }

  update(data: RewardDto, id: string){
    return this.http.put<RewardDto>(`${environment.apiEndpoint}api/MmitaReward/${id}`, data);

  }
}