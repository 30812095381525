import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError, switchMap, interval, takeWhile, finalize, Observable, map, Subscription } from 'rxjs';
import { AssetType } from 'src/app/_models/AssetType';
import { Global } from 'src/app/_services/global.service';
import { MeshyService } from 'src/app/_services/meshy.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-glb-generator-text',
  templateUrl: './glb-generator-text.component.html',
  styleUrls: ['./glb-generator-text.component.css']
})
export class GlbGeneratorTextComponent implements OnInit {

    readonly Topology = this.global.section.MeshyTopologySelections
    readonly ArtStyle = this.global.section.MeshyArtStyleSelections
    isSpinning: boolean = false
    progress: string
    previewResult: any
    finalResult: any
    formGroup = new FormGroup({
        textTo3dPrompt: new FormControl('', Validators.required),
        topology: new FormControl(this.Topology[0], Validators.required),
        targetPolycount: new FormControl(10000, Validators.required),
        artStyle: new FormControl(this.ArtStyle[0], Validators.required)
    });
    private subscription: Subscription; 
    constructor(
        private global: Global,
        private meshyService: MeshyService,
        private modalService: ModalService,
        private nzModalRef: NzModalRef,
        private toast: ToastrService
  ) { }

    ngOnInit(): void {
      
    }
    
    private handleGlbGeneration(serviceMethod: (id?: string) => Observable<any>, id?: string, isPreview?: boolean): void {
        this.isSpinning = true;
        this.subscription = serviceMethod(id).pipe(
            map((res: any) => {
                if (!res.id) {
                    throw new Error('Response does not contain an ID');
                }
                return res;
            }),
            catchError(error => {
                console.error('Error generating GLB:', error);
                return throwError(error); // Re-throw the error to trigger retry
            }),
            switchMap((res: any) => {
                const id = res.id;
                return interval(5000).pipe(
                    switchMap(() => this.meshyService.getMeshyById(id)),
                    takeWhile((result: any) => result.progress !== 100, true)
                );
            }),
            finalize(() => {
                this.isSpinning = false; // Ensure loading state is stopped after completion or failure
            })
        ).subscribe(result => {
            this.progress = `${result.progress}%`;
            if (result.progress === 100) {
                if (isPreview) {
                    this.previewResult = result
                } else {
                    this.finalResult = result;
                }
            }
        }, error => {
            this.toast.error('Final error after retries:', error);
        });
    }
    
    

    // 调用通用方法来生成预览GLB
    genPreviewGlb() {
        this.previewResult = null
        this.progress = null
        const data = this.formGroup.value;
        this.handleGlbGeneration(() =>this.meshyService.genGlbByTextPreview(data), data, true);
    }

    // 调用通用方法来生成最终GLB
    genGlb() {
        this.finalResult = null;
        this.progress = null
        if (this.previewResult && this.previewResult.id) {
            this.handleGlbGeneration(() =>this.meshyService.genGlbByTextRefine(this.previewResult.id), this.previewResult.id, false);
        } else {
            this.toast.error('No preview result available to generate final GLB');
        }
    }

    showDetail() { 
        this.modalService.showDetail(this.finalResult, 'Detail')
    }

    showCreateAsset() {
        const glbFile = {
            url: this.finalResult.glbUrl
        }
        const data = {
            assetType: AssetType.Model3d,
            file: glbFile,
            androidFile: glbFile,
            visionOSFile: glbFile,
            macOSFile: glbFile,
            thumbnail: {
                url: this.finalResult.thumbnailUrl
            },
            smallThumbnail: {
                url: this.finalResult.smallThumbnailUrl
            },
            icon: {
                url: this.finalResult.iconUrl
            },
        }
        this.nzModalRef.close(data)
    }

    ngOnDestroy() { 
        this.subscription.unsubscribe()
    }

}
