import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SettingService } from './setting.service';



@Injectable({
  providedIn: 'root'
})
export class Global {
  public pageSize = 10;
  public appEvent = new Subject();
  public isPC: boolean
  public section: any
  constructor(
    private settingService: SettingService
  ) {
    this.isPC = this.checkIsPC();
    this.getSection()
  }

  private checkIsPC() {
    return !(
      /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  }

  getSection(){
    this.settingService.getSection().subscribe(res=>{
      this.section = res
    })
  }


}
