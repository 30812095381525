  <section class="header-btn-wrapper">
    <button nz-button nzType="primary" (click)="showItem()"><i class="fas fa-fw fa-plus"></i> Add New Asset</button>
    <button nz-button nzType="primary" (click)="showItem(null, false, true)"><i class="fas fa-fw fa-plus"></i> Add Batch Assets</button>
    <button nz-button (click)="updateBatchAssets()"><i class="fas fa-fw fa-edit"></i> Update Asset Abilities</button>
    <button nz-button nzType="primary" (click)="deleteBatchAssets()" nzDanger><i class="fas fa-minus"></i> Delete Batch Assets</button>
    <button nz-button nzType="primary" (click)="getAccessToken()"><i class="fas fa-random"></i>Sketchfab Access Token</button>
  </section>
  <app-search-form *ngIf="rendered" #SearchForm [searchSection]="searchSection" (searchCriteria)="setSearchCriteria($event)"></app-search-form>
  <ag-grid-angular
    #agGrid
    class="ag-theme-alpine"
    [rowHeight]="50"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true"
    [gridOptions]="gridOptions"
    style="height: 80vh"
    (sortChanged)="sortChanged($event)"
  >
  </ag-grid-angular>
