import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const VCTemplate = (): DialogTemplate =>  {
    return {
        dialogTitle: 'Set Vertical Carousel',
        dialogFields: [
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 24,
                required: true
            },
            {
                key: 'speed',
                label: 'Speed',
                type: InputFieldType.Selector,
                size: 12,
                options: [1,2,3],
                mode: 'default',
                required: true
            },
            {
                key: 'viusalArea',
                label: 'Viusal Area',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'images',
                label: 'Images',
                type: InputFieldType.Uploader,
                size: 24,
                mode: 'multiple',
                required: true,
                showPreviewImage: true
            },
        ]
    }
}