export class AssetWriteDto { 
    assetType?: string;
    name: string;
    description?: string;
    abilitiyIds?: Array<string>;
    // new property
    url?: string;
    androidUrl?: string;
    thumbnailUrl?: string;
    smallThumbnailUrl?: string;
    iconUrl?: string;
    visionOSUrl?: string;
    macOSUrl?: string;

    categoryId?: string;
    adUrlLinked?: string;
    numberOfTokensUnlocked?: number;
    isFramed?: boolean;
    assetStatus?: string;
    businessType: string
    scaleInMap: number
}