import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReviewStatus } from 'src/app/common/constants';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PartnerService } from 'src/app/_services/partner.service';
import { UpdateVersionComponent } from './update-version/update-version.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-product-version',
  templateUrl: './product-version.component.html',
  styleUrls: ['./product-version.component.scss'],
  providers: [DatePipe]
})
export class ProductVersionComponent extends BaseComponent implements OnInit{
  data
  initialProduct: string

  readonly TabList = ['Product', '3DModel']
  currentFocusOn: string = 'Product'

  constructor(
    private partnerService: PartnerService,
    public global: Global,
    private datePipe: DatePipe,
    private modalService: ModalService,
    private route:ActivatedRoute,
    private nzModalService: NzModalService

  ) { 
    super(global);
    this.initialProduct = this.route.snapshot.paramMap.get('productId');
    this.currentFocusOn = this.route.snapshot.paramMap.get('type') || 'Product';

  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchAll();
  }

  ngOnInit(){
    this.setColumnDefs();
  }

  formatDateTime(params){
    return this.datePipe.transform(params.value, 'short')
  }


  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id'},
      { headerName: 'Review Status', field: 'reviewStatus'},
      { headerName: `${this.currentFocusOn === 'Product'?'Product Id': 'Model Id'}`, field: `${this.currentFocusOn === 'Product'?'productId': 'modelId'}`},
      { headerName: `${this.currentFocusOn === 'Product'?'Product Name': 'Model Name'}`, field: `${this.currentFocusOn === 'Product'?'product.name': 'model.name'}`}, 
      { headerName: 'Status', field: 'status'},
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
          ${params.data.reviewStatus !== ReviewStatus.PrepareForSubmission && params.data.status !== 'TakeDown'? `<i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>`: ``}

                    `
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Asset Detail')
              break;
              case 'editDetail':
                this.update(params.data)
                break;
            }
          },
        },
    ];
    }


    update(data){
      this.nzModalService.create({
        nzContent: UpdateVersionComponent,
        nzTitle: 'Update Product Version State',
        nzFooter: null,
        nzOnOk: ()=> this.fetchAll(),
        nzComponentParams:{
          id: data?.id,
          currentReviewStatus: data?.reviewStatus,
          type: this.currentFocusOn
        }
      })
    }


  fetchAll(){
    switch(this.currentFocusOn){
      case 'Product':
        this.partnerService.getProductsVersion(this.initialProduct).subscribe((res: InfiniteResult)=>{
          this.data = res.data
        })
        break;
        case '3DModel':
          this.partnerService.getModelsVersion(this.initialProduct).subscribe((res: InfiniteResult)=>{
            this.data = res.data
          })
        break; 
    }
  }

  changeTab(tab){
    this.currentFocusOn = tab
    this.setColumnDefs();
    this.fetchAll()
  }
}
