import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BaseComponent } from "src/app/ui-library/component/base.component";
import { Global } from "src/app/_services/global.service";
import { ModalService } from "src/app/_services/modal.service";
import { SettingService } from "src/app/_services/setting.service";
import { SettingTemplate } from "./setting-item.constant";
import { FormControl, FormGroup } from "@angular/forms";
import { setNewOriginUrl } from "src/app/_helpers/utility";
import { Observable } from "rxjs";
import { SearchField } from "src/app/_models/body";
import { SearchFieldType } from "src/app/common/constants";
import { DatePipe } from "@angular/common";

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss']
  })
export class SettingComponent extends BaseComponent{
    searchSection: Array<SearchField> = [
        { name: 'Include Deleted', key: 'IsIncludeDeleted', type: SearchFieldType.Selector, options: [true, false] },
        { name: `Modify Date Range (${'UTC+' + (0 - new Date().getTimezoneOffset() / 60)})`, key:'_ModifyDateRange', type: SearchFieldType.DateRangePicker },]
    
    settings: any;
    tagList: Array<string>
    tagType: any
    formGroup: FormGroup
    constructor(
        private settingService: SettingService,
        private toastr: ToastrService,
        public global: Global,
        private modalService: ModalService,
        private datePipe: DatePipe
    ) {
        super(global)
    }

    setColumnDefs(){
      this.columnDefs = [
          { headerName: 'Tag', field: 'tag'},
          { headerName: 'ID', field: 'id'},
          { headerName: 'UniCode', field: 'uniCode'},
          { headerName: 'ClassName', field: 'className'},
          { headerName: 'Value', field: 'value', valueFormatter: (params) =>{
            return params?.value?.toString()
          }},
          { headerName: 'Description', field: 'description' },
          { headerName: 'Last Updated At', field: 'lastUpdatedAt', valueFormatter: (params) =>{
            return this.datePipe.transform(params.value, 'short')
          }},         
          {
            headerName: 'Action',
            pinned: 'right',
            minWidth: 120,
            cellRenderer: params => {
              return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                        <i class="fas fa-fw fa-edit text-primary" id="edit" style="margin-right:3px; cursor: pointer;"></i>
                        <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
            },
            onCellClicked: params => {
              switch(params.event.target.id){
                case 'moreDetail':
                  this.modalService.showDetail(params.data, 'Setting Detail')
                  break;
                case 'edit':
                  this.onEdit(params?.data)
                  break;
                case 'delete':
                  this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                  break;
              }
            },
          },
      ];
    }
  
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.getAll();
    }
  
    ngOnInit() {
      this.setColumnDefs();
      this.settingService.getSettingTag().subscribe((res:any)=>{
        this.tagList = res
      })
    }
    setFilterModel(ev){
      if(!ev){
        return this.gridApi.setFilterModel(null);
      }
      const model = {
        tag: {
            filterType: 'text',
            type: 'equals',
            filter: ev
        },
      }
      // Sets the filter model via the grid API
      this.gridApi.setFilterModel(model);
    }

    getAll(params?) {
      this.settingService.getAll(params)
        .subscribe(
          data => {
            this.settings = data;
          });
    }


  delete(id){
    this.settingService.delete(id).subscribe(res=>{
        this.getAll();
        this.toastr.success('Data Delete Successfully...!', 'Success');
      })
  }


  reset(){
    this.settingService.resetAll().subscribe(res=>{
      this.getAll();
      this.toastr.success('Data Delete Successfully...!', 'Success');
    })
  }

  onEdit(data){
    this.genFG(data)
    const dialogTemplate = SettingTemplate(data, this.tagList)
    this.modalService.createNzModal(()=>this.update.call(this, data?.id), this.formGroup, dialogTemplate)
  }

  resetAll(){
    this.modalService.createNzDeleteModal(()=>this.reset.call(this), 'Are you sure you want to reset all records')
  }

  update(id){
    return new Observable(subscriber => {
      setNewOriginUrl(this.formGroup, 'value')
      this.settingService.updateById(this.formGroup.value, id).subscribe(res=>{
          this.getAll();
          this.toastr.success('Data Update Successfully...!', 'Success');
          subscriber.next()
          subscriber.complete()
      })
    })
  }

  wallPostCalculation(){
    this.settingService.wallPostCalculation().subscribe()
  }

  genFG(orgData){
    this.formGroup = new FormGroup({
        value: new FormControl(orgData?.value),
        description: new FormControl(orgData?.description),
        tag: new FormControl(orgData?.tag),
    });
  }
    
    setSearchCriteria(ev) { 
        const params = { }
        if (ev?._ModifyDateRange?.length) { 
            params['StartAt'] = new Date(ev._ModifyDateRange[0]).toISOString()
            params['EndAt'] = new Date(ev._ModifyDateRange[1]).toISOString()
        }
        if (typeof ev?.IsIncludeDeleted === 'boolean') { 
            params['IsIncludeDeleted'] = ev.IsIncludeDeleted
        }

       this.getAll(Object.keys(params).length > 0 ? params : null)
    }
  
}