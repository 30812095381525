<form nz-form (ngSubmit)="checkValidation()" [formGroup]="formGroup" [nzLayout]="'vertical'">
    <div nz-row [nzGutter]="16">
        <ng-container *ngFor="let field of mitForm">
            <div nz-col [nzSpan]="field.size">
                <nz-form-item>
                    <nz-form-label [nzFor]="field.label"
                        [nzRequired]="getRequired(field)">{{field.label}}</nz-form-label>
                    <nz-form-control nzErrorTip="Please input field">
                        <ng-container [ngSwitch]="field.type">
                            <nz-select *ngSwitchCase="InputFieldType.Selector" [nzAllowClear]="!field.notAllowClear"
                                [nzMode]="field.mode || 'default'" [formControl]="field.formCtrl" [nzMaxTagCount]="3"
                                (nzOnSearch)="onSearch($event, field)" nzShowSearch
                                [nzMaxMultipleCount]="field?.maxCount || Infinity">
                                <nz-option *ngFor="let item of field.options" [nzValue]="item.id ? item.id: item"
                                    [nzLabel]="field?.selectorLabel ? item[field.selectorLabel] : (item.name? item.name: item)"></nz-option>
                            </nz-select>
                            <nz-input-number *ngSwitchCase="InputFieldType.Number" [formControl]="field.formCtrl"
                                class="mit-number-field"></nz-input-number>
                            <app-mit-uploader *ngSwitchCase="InputFieldType.Uploader" [formCtrl]="field.formCtrl"
                                [multiple]="field?.mode ==='multiple'" [showPreviewImage]="field?.showPreviewImage"
                                [accept]="field?.accept"></app-mit-uploader>
                            <nz-switch *ngSwitchCase="InputFieldType.Switch" [formControl]="field.formCtrl"></nz-switch>
                            <textarea nz-input *ngSwitchCase="InputFieldType.TextArea" [nzAutosize]="{ minRows: 3}"
                                [formControl]="field.formCtrl"></textarea>
                            <app-date-picker *ngSwitchCase="InputFieldType.DateTimePicker"
                                [formCtrl]="field.formCtrl"></app-date-picker>
                            <app-mit-input-array *ngSwitchCase="InputFieldType.InputArray" [formCtrl]="field.formCtrl"
                                nz-row [nzGutter]="16"></app-mit-input-array>
                            <app-mit-tag *ngSwitchCase="InputFieldType.Tag" [formCtrl]="field.formCtrl"></app-mit-tag>
                            <input *ngSwitchDefault nz-input type="text" [formControl]="field.formCtrl">
                        </ng-container>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div *ngIf="field?.offset" nz-col [nzOffset]="field?.offset">
            </div>
        </ng-container>

    </div>
    <div style="text-align: center;">
        <button type="button" nz-button nzType="default" (click)="cancel()" style="margin-right: 10px;">Cancel</button>
        <button type="submit" nz-button nzType="primary">Save</button>
    </div>
</form>