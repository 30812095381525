/**
 * Mmita.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AssetType = 'Image' | 'Video' | 'Model3d';

export const AssetType = {
    Image: 'Image' as AssetType,
    Video: 'Video' as AssetType,
    Model3d: 'Model3d' as AssetType
};