<button class="send-coupon" type="button" nz-button nzType="primary" (click)="sendCoupon(tplContent)" [disabled]="selectedUsers?.length<1">
    Send Coupon
</button>
<app-customer [customerPageMode]="false" (selectedUsersOnChanged)="setSelectedUsers($event)"></app-customer>


<ng-template #tplContent let-params>
    <nz-descriptions [nzTitle]="couponInfo?.couponName" [nzColumn]="2">
        <nz-descriptions-item nzTitle="ID">{{couponInfo?.id}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Expires At">{{couponInfo?.expiresAt| date:'medium'}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Description">{{couponInfo?.description}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="ProfileUrl">
            <img
            nz-image
            [nzSrc]="couponInfo?.profileUrl"
            alt=""
            width="50px"
            height="50px"
          />
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-divider [nzText]="selectedUsers.length > 1 ? 'Send to the following ' + selectedUsers.length + ' users': 'Send to the following user'"></nz-divider>
      <nz-table #basicTable [nzData]="selectedUsers">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{data.id}}</td>
            <td>{{data.firstName}} {{data.lastName}}</td>
            <td>{{data.email}}</td>
          </tr>
        </tbody>
      </nz-table>
</ng-template>