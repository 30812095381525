<ng-container [ngSwitch]="tabInfo.currentTab">
    <app-stage-group-item 
        *ngSwitchCase="MapTab.StageGroup" 
        [currentFocusOnInfo]="currentFocusOnInfo"
    >
    <a nz-button nzType="link" (click)="backToGrid()" class="back-btn" content>Back</a>
    </app-stage-group-item>

    <app-point-info
        *ngSwitchDefault
        [tabInfo]="tabInfo"
        [currentFocusOnInfo]="currentFocusOnInfo"
        [rewards]="dataSource?.allRewards"
        [assetCategory]="dataSource?.assetCategory"
        [vcSourceList]="dataSource?.vcSourceList"
        [portalSource]="dataSource?.portalSource"
        [streamingEventSource]="dataSource?.streamingEventSource"
        [streamerSubTypeAssetSource]="dataSource?.streamerSubTypeAssetSource"
        [campaignSource]="dataSource?.campaignSource"
        [occlusionMeshAssetCategory]="dataSource?.occlusionMeshAssetCategory"
        (onRemoveEvent)="delete($event)"
        (onUpdateEvent)="update($event)"
        (onAddPointEvent)="add($event)"
    >
        <a nz-button nzType="link" (click)="backToGrid()" class="back-btn" content>Back</a>
    </app-point-info> 

</ng-container>


