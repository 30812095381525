import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CouponBaseField, MmitaTypeFields, StripeTypeFields } from './coupon-item-constant';
import { StripeService } from 'src/app/_services/stripe.service';
import { Observable } from 'rxjs/internal/Observable';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CouponService } from 'src/app/_services/coupon.service';
import { setNewOriginUrl } from 'src/app/_helpers/utility';

@Component({
  selector: 'app-ant-coupon-item',
  templateUrl: './ant-coupon-item.component.html',
  styleUrls: ['./ant-coupon-item.component.css']
})
export class AntCouponItemComponent implements OnInit {
  readonly orgData: any
  readonly stripeAccount
  stripeCoupon: Array<any> = []
  formGroup: FormGroup
  dialogFields
  callback
  constructor(
    private stripeService: StripeService,
    private nzModalRef: NzModalRef,
    private couponSrvice: CouponService
  ) { }

  ngOnInit(): void {
    this.genFG()
    this.setFormStatus()
    this.callback = ()=>this.onSubmit.call(this)
  }

  setFormStatus(){
    const type = this.formGroup.get('type').value
    if(type === Type.Mmita && !this.orgData){
      this.dialogFields = CouponBaseField.concat(MmitaTypeFields)
    } else if(type === Type.Stripe){
      if(this.formGroup.value?.stripeAccountId){
        this.stripeService.getStripeCoupon(this.formGroup.value?.stripeAccountId).subscribe(res=>{
          this.stripeCoupon = res.map(item=>{
            return {...item, id: item.stripeCouponId, name: item.stripCoupon.name}
          })
          this.dialogFields = CouponBaseField.concat(StripeTypeFields(this.stripeAccount, this.stripeCoupon))
        })
      } else {
        this.dialogFields = CouponBaseField.concat(StripeTypeFields(this.stripeAccount, this.stripeCoupon))
      }
    } else {
      this.dialogFields = CouponBaseField
    }
  }


  genFG(){
    this.formGroup = new FormGroup({
      profileUrl: new FormControl(this.orgData?.profileUrl, Validators.required),
      subName: new FormControl(this.orgData?.subName, Validators.required),
      description: new FormControl(this.orgData?.description, Validators.required),
      couponName: new FormControl(this.orgData?.couponName, Validators.required),
      expiresAt: new FormControl(this.orgData?.expiresAt),
      stripeAccountId: new FormControl(this.orgData?.stripeAccountId),
      stripeCouponId: new FormControl(this.orgData?.stripeCouponId),
      type: new FormControl({value: this.orgData?.type, disabled: !!this.orgData}),
      merchantName: new FormControl(this.orgData?.merchantName, Validators.required),
    })

    if(!this.orgData){
      this.formGroup.addControl('promotionCodes', new FormControl([]))
    }

    const watchList = ['stripeAccountId', 'type']
    watchList.forEach(item=>{
      this.formGroup.get(item).valueChanges.subscribe(res=>{
        setTimeout(()=>{
          this.setFormStatus()
        })
      })
    })

  }

  onSubmit(){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.nzModalRef.triggerOk()
        subscriber.next()
        subscriber.complete()
      }
      setNewOriginUrl(this.formGroup, 'profileUrl')
      this.orgData ? this.couponSrvice.updateCoupon(this.orgData.id, this.formGroup.value).subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      })  : this.couponSrvice.createCoupon(this.formGroup.value).subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      }) 
      })
  }
}

export enum Type{
  Stripe = 'Stripe',
  Mmita = 'Mmita'
}

