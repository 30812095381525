import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RewordConstant } from 'src/app/common/constants';
import { RewardFields } from '../reward-item.constant';
import { RewardService } from 'src/app/_services/reward.service';
import { ToastrService } from 'ngx-toastr';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ant-reward-item',
  templateUrl: './ant-reward-item.component.html',
  styleUrls: ['./ant-reward-item.component.css']
})
export class AntRewardItemComponent implements OnInit {
  formGroup: FormGroup
  dialogFields
  callback
  readonly data: any
  readonly allAssets
  readonly galleryAssets
  readonly preivewAssets
  readonly stripeCoupon
  readonly constantOptionDatas = {
    mapRewardType:  RewordConstant.mapRewardType,
    coolDownIntervalType: RewordConstant.coolDownIntervalType,
    hosterType: RewordConstant.hosterType
  }
  unshowFieldLastTerm = []

  constructor(
    private rewardService: RewardService,
    private toastr: ToastrService,
    private nzModalRef: NzModalRef
  ) { }

  ngOnInit(): void {
    this.genFG(this.data)
    this.setFormStatus(this.data)
    this.callback = ()=>this.onSubmit.call(this)
  }
  onSubmit(){
    return new Observable(sub=>{
      const saveSuccess = () =>{
        this.nzModalRef.triggerOk()
        sub.next()
        sub.complete()
      }
      this.data ? this.rewardService.update(this.formGroup.value, this.data.id).subscribe(res=>{
        saveSuccess()
      }) : this.rewardService.addReward(this.formGroup.value).subscribe(res=>{
        saveSuccess()
      })
    })

  }

  setFormStatus(fgData){
    let assetList
    let unshowField = []
    if(fgData?.hosterType === 'MapPlace'){
      assetList = this.galleryAssets
    } else {
      assetList = this.allAssets
      unshowField = unshowField.concat(['ratioOnMap', 'isRefreshable', 'isRandomPoint'])
    }
    if(fgData?.mapRewardType !== 'Coupon'){
      unshowField.push('mmitaCouponIds')
    }
    if(!fgData?.isLimitMaxCollectedCount){
      unshowField = unshowField.concat(['totalCollectableCount', 'alreadyCollectedCount'])
    }
    if(!fgData?.isRandomReward){
      unshowField = unshowField.concat(['tokensRatio', 'mmitaCouponRatio'])
    }

    fgData?.mapRewardType === 'Asset' ? this.formGroup.get('rewardAssetIds').setValidators([Validators.required]) : this.formGroup.get('rewardAssetIds').setValidators(null)

    this.unshowFieldLastTerm.forEach(item=>{
      this.formGroup.get(item).enable()
    })
    unshowField.forEach(item=>{
      this.formGroup.get(item).disable()
    })
    this.unshowFieldLastTerm = unshowField
    this.dialogFields = RewardFields(this.constantOptionDatas, assetList, this.preivewAssets, this.stripeCoupon, unshowField, fgData?.mapRewardType === 'Asset')
  }

  genFG(orgData?){
    this.formGroup = new FormGroup({
      description: new FormControl(orgData?.description),
      mapRewardType: new FormControl(orgData?.mapRewardType, Validators.required),
      ratioOnMap: new FormControl(orgData?.ratioOnMap),
      coolDownIntervalType: new FormControl(orgData?.coolDownIntervalType,Validators.required),
      hosterType: new FormControl(orgData?.hosterType, Validators.required),
      previewAssetId: new FormControl(orgData?.previewAsset?.id,Validators.required),
      rewardAssetIds: new FormControl(orgData?.rewardAssetIds || []),
      numberOfTokens: new FormControl(orgData?.numberOfTokens,Validators.required),
      isRefreshable: new FormControl(orgData?.isRefreshable || false),
      isRandomPoint: new FormControl(orgData?.isRandomPoint || false),
      mmitaCouponIds: new FormControl(orgData?.mmitaCouponIds,Validators.required),
      isLimitMaxCollectedCount: new FormControl(orgData?.isLimitMaxCollectedCount ||false),
      totalCollectableCount: new FormControl(orgData?.totalCollectableCount|| 0, Validators.required),
      alreadyCollectedCount: new FormControl(orgData?.alreadyCollectedCount || 0, Validators.required),
      isRandomReward: new FormControl(orgData?.isRandomReward || false),
      tokensRatio: new FormControl(orgData?.tokensRatio, Validators.required),
      mmitaCouponRatio: new FormControl(orgData?.mmitaCouponRatio, Validators.required),
    })
    orgData && this.formGroup.get('mapRewardType').disable()
    const watchField = ['hosterType', 'mapRewardType', 'isLimitMaxCollectedCount', 'isRandomReward']
    watchField.forEach(item=>{
      this.formGroup.get(item).valueChanges.subscribe(res=>{
        setTimeout(()=>{
          this.setFormStatus(this.formGroup.value)
        })
      })
    })
  }
}
