import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  constructor(
    private http: HttpClient
  ) { }

  getGoogleRestaurantType(){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Google/RestaurantType/Query`,{
      params: {
        PageSize: '-1'
      }
    })
  }

  createRestaurantType(data){
    return this.http.post<any>(`${environment.apiEndpoint}api/Google/RestaurantType`, data)
  }

  updateRestaurantType(data, id){
    return this.http.put<any>(`${environment.apiEndpoint}api/Google/RestaurantType/${id}`, data)
  }

  deletaRestaurantType(id){
    return this.http.delete<any>(`${environment.apiEndpoint}api/Google/RestaurantType/${id}`)
  }

  getUniqueCode(){
    return this.http.get<Array<string>>(`${environment.apiEndpoint}api/Google/UniqueCode`)
  }


}
