<section class="header-btn-wrapper">
    <button nz-button nzType="primary" (click)="showItem()">Create Streaming Event</button>
</section>
<ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="data"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true">
</ag-grid-angular>