import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const CampaignTemplate = (coupons, rewardSource): DialogTemplate => {
    return {
        dialogTitle: 'Set Campaign',
        dialogFields: [
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'mmitaRewardId',
                label: 'MMITA Reward ID',
                type: InputFieldType.Selector,
                size: 12,
                options: coupons,
                required: true,
                selectorLabel: 'description'
            },
            {
                key: 'subMmitaRewardId',
                label: 'Sub MMITA Reward ID',
                type: InputFieldType.Selector,
                size: 12,
                options: rewardSource,
                required: true,
                selectorLabel: 'description'
            },
            {
                key: 'startAt',
                label: 'Start At',
                type: InputFieldType.DateTimePicker,
                size: 12,
                required: true
            },
            {
                key: 'endAt',
                label: 'End At',
                type: InputFieldType.DateTimePicker,
                size: 12,
                required: true
            },
            {
                key: 'numberOfTokensToBuy',
                label: 'Number Of Tokens To Buy',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'coverProfileUrl',
                label: 'Cover Profile Url',
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true
            },
            {
                key: 'rewardPreviewUrl',
                label: 'Reward Profile Url',
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true
            },
            {
                key: 'rewardIntroductionUrl',
                label: 'Reward Introduction Url',
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true
            },
            {
                key: 'rewardDetailUrl',
                label: 'Reward Detail Url',
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true
            },
            {
                key: 'menuIconUrl',
                label: 'Menu Icon Url',
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true
            },
        ]
    }
}
