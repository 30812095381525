import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PartnerService } from 'src/app/_services/partner.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent  extends BaseComponent implements OnInit{
  data
  initialProduct: string
  constructor(
    private partnerService: PartnerService,
    public global: Global,
    private modalService: ModalService,

  ) { 
    super(global);

  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchAll();
  }

  ngOnInit(){
    this.setColumnDefs();
  }



  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id'},
      { headerName: 'Description', field: 'description'},
      { headerName: 'Email', field: 'email'},
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>`},
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Info Detail')
              break;
            }
          },
        },
    ];
    }
  


  fetchAll(){
    this.partnerService.getContactUs().subscribe((res: InfiniteResult)=>{
      this.data = res.data
    })
  }
}