<div class="md-popover login-popover" style="margin:30px">
  <section class="my-popover-content">
    <div class="input-group mb-3">
      <h4>Are you sure you want to delete this record?</h4>
    </div>
    <div class="row">
      <div class="col-md-2">
        <button type="submit" (click)="imageCarouselDelete()" class="btn btn-block btn-primary">Delete</button>
      </div>
      <div class="col-md-2">
        <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
        <!--<a href="/asset" class="btn btn-block btn-danger"> Back</a>-->
      </div>
    </div>
  </section>
</div>

