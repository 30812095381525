<div *ngIf="!allowCopy" class="button-wrapper">
    <button nz-button nzType="primary" nzSize="small" (click)="updateTabelCollapse(true)" style="margin-right: 5px;">Expand All</button>
    <button nz-button nzType="primary" nzSize="small" (click)="updateTabelCollapse(false)">Collapse All</button>
</div>

<nz-table #expandTable [nzData]="listOfMapData" nzTableLayout="fixed" nzSize="small">
    <thead>
        <tr>
        <th>label</th>
        <th>value</th>
        <th *ngIf="allowCopy" nzWidth="70px">Action</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let data of expandTable.data">
        <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
            <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
            <td
                [nzIndentSize]="item.level! * 20"
                [nzShowExpand]="!!item.children"
                [(nzExpand)]="item.expand"
                (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)"
            >
                {{ item.label }}
            </td>
            <td>
                <ng-container *ngIf="item.isURL; else normalVal">
                    <img nz-image [nzSrc]="item.value" width="80px" [nzFallback]="fallback" alt=""/>
                    <a [href]="item.value" target="_blank" style="display:  block;">  {{ item.value }}</a>
                </ng-container>
                <ng-template #normalVal>
                    {{ item.value }}
                </ng-template>
            </td>
            <td *ngIf="allowCopy">
                    <button type="button" nz-button nzType="primary" nzSize="small" (click)="copyValue(item.value)">Copy</button>
            </td>
            </tr>
        </ng-container>
        </ng-container>
    </tbody>
</nz-table>



