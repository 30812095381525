import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getVirtualUserMsg(){
    return this.httpClient.get(`${environment.apiEndpoint}api/VirtualUserMsg`)
  }

  postVirtualUserMsg(data){
    return this.httpClient.post(`${environment.apiEndpoint}api/VirtualUserMsg`,data )
  }

  deleteVirtualUserMsg(id){
    return this.httpClient.delete(`${environment.apiEndpoint}api/VirtualUserMsg/${id}`)
  }

  putVirtualUserMsg(data, id){
    return this.httpClient.put(`${environment.apiEndpoint}api/VirtualUserMsg/${id}`,data )
  }
}
