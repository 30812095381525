import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({ providedIn: 'root' })
export class StageService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getStage(params){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Stage/Query`,{
      params: params
    });
  }

  // 【New Api】only return key information
  getStagesForKeyInfo(params){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Map/Stage/Query`,{
      params: params
    });
  }

  delete(id){
    return this.http.delete<any[]>(`${environment.apiEndpoint}api/Stage/${id}`);
  }

  update(data, id){
    return this.http.put<any[]>(`${environment.apiEndpoint}api/Stage/${id}`, data);
  }

  add(data){
    return this.http.post<any[]>(`${environment.apiEndpoint}api/Stage`, data);
  }

  createGroup(data){
    return this.http.post<any[]>(`${environment.apiEndpoint}api/Stage/Group`, data);
  }

  getStageGroup(params){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Stage/Group/Query`,{
      params: params
    });
  }

  deleteStageGroup(id){
    return this.http.delete<any[]>(`${environment.apiEndpoint}api/Stage/Group/${id}`);
  }

  updateStageGroup(data, id){
    return this.http.put<any[]>(`${environment.apiEndpoint}api/Stage/Group/${id}`, data);
  }

  copyGroup(data){
    return this.http.post<any[]>(`${environment.apiEndpoint}api/Stage/Group/Copy`, data);
  }

  syncGroup(id){
    return this.http.put<any[]>(`${environment.apiEndpoint}api/Stage/Group/${id}/Sync`, null, { responseType: 'text' as any });
  }

  previewCopyGroup(data){
    return this.http.post<any[]>(`${environment.apiEndpoint}api/Stage/Group/Copy/Preview`, data);
  }
  
  getStageById(id){
    return this.http.get<any>(`${environment.apiEndpoint}api/Stage/${id}`);
  }

  batchDelete(ids){
    return this.http.delete(`${environment.apiEndpoint}api/Stages`,{ body: {
      ids: ids
    }})
  }

  batchDeleteGroups(ids){
    return this.http.delete(`${environment.apiEndpoint}api/Stage/Groups`,{ body: {
      ids: ids
    }})
  }

}
