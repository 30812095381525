/**
 * Admin.API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AssetType } from './AssetType';

export class AssetReadDto { 
    id?: string;
    assetType?: AssetType;
    name: string;
    description?: string;
    url?: string;
    thumbnailUrl?: string;
    smallThumbnailUrl?: string;
    category?: any;
    abilitiyIds?: Array<string>;
    adUrlLinked?: string;
    isFramed?: boolean;
    numberOfTokensUnlocked?: number
    ownerUserId?: string;
    androidUrl?: string;
    iconUrl?: string;
    assetStatus?: string;
    createdAt?: string;
    businessType: string;
    visionOSUrl?: string;
    macOSUrl?: string;

}