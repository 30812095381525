import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BaseComponent } from "src/app/ui-library/component/base.component";
import { Global } from "src/app/_services/global.service";
import { ModalService } from "src/app/_services/modal.service";
import { WhitelistService } from "src/app/_services/whitelist.service";
import { CategoryService } from "src/app/_services/category.service";
import { InfiniteResult } from "src/app/_models/body";
import { NzModalService } from "ng-zorro-antd/modal";
import { WhiteListDialogComponent } from "./white-list-dialog/white-list-dialog.component";

@Component({
    selector: 'app-whitelist',
    templateUrl: './whitelist.component.html',
  })
  export class WhiteListComponent extends BaseComponent{
    whiteListData: Array<any> = [];
    assetSourceArray: Array<any>
    activeCustomer: Array<any>
    constructor(
      private whitelistService: WhitelistService,
      private toastr: ToastrService,
      public global: Global,
      private modalService: ModalService,
      private datePipe: DatePipe,
      private categoryService: CategoryService,
      private nzModalService: NzModalService
    ) {
        super(global)
    }

    setColumnDefs(){
      this.columnDefs = [
          { headerName: 'ID', field: 'id'},
          { headerName: 'Email', field: 'email'},
          { headerName: 'User Id', field: 'userId'},
          { headerName: 'Module Type', field: 'moduleType'},
          { headerName: 'Module Data Id', field: 'moduleDataId'},
          { headerName: 'Created At', field: 'createdAt',  valueFormatter: 
            params => {
              return this.datePipe.transform(params.value, 'short');
            }},
          { headerName: 'Action', 
            pinned: 'right',
            minWidth: 50,
            cellRenderer: params => {
              return ` <i class="fas fa-fw fa-trash grid-btn-icon text-danger"></i>`;
            },
            cellStyle: params => {
              return {cursor: 'pointer'};
            },
            onCellClicked: (params)=> this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
          },
      ];
    }
  
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.getAll();
    }
  
    ngOnInit() {
      this.setColumnDefs();
      this.getSource()
    }

    getAll() {
      this.whitelistService.getAll().subscribe(data => {
        this.whiteListData = data;
      });
    }

    

  delete(id){
    this.whitelistService.delete(id).subscribe(res=>{
        this.getAll();
        this.toastr.success('Data Delete Successfully...!', 'Success');
      })
  }

  addWhiteList(){
    this.nzModalService.create({
      nzContent: WhiteListDialogComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: 'Create White List',
      nzComponentParams: {
        assetSourceArray: this.assetSourceArray,
      },
      nzOnOk: ()=> this.getAll()
    })
  }

  getSource(){
    this.categoryService.getAssetCategory({AssetCategoryOption: 'WhitelistOnly'}).subscribe((res: InfiniteResult)=>{
      this.assetSourceArray = res.data.map(item=>{
        return {...item, name: item.multilingualNames['en-US']}
      })
    })
  }
  
}