import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BaseComponent } from "src/app/ui-library/component/base.component";
import { MarkerDto } from "src/app/_models/mapDto";
import { RewardDto } from "src/app/_models/rewardDto";
import { Global } from "src/app/_services/global.service";
@Component({
  selector: 'app-recommended-list',
  templateUrl: './recommended-list.component.html',
  styleUrls: ['./recommended-list.component.scss'],
})
export class RecommendedListComponent extends BaseComponent {
  @Input() recommendedList: Array<any>;
  @Input() rewards: Array<RewardDto>;
  markersArray: Array<FormGroup>;
  selectAll: boolean = false;
  addAll: boolean = false;
  @Output() onAddPointEvent = new EventEmitter<MarkerDto>();
  @Output() onAddBatchPointsEvent = new EventEmitter<Array<MarkerDto>>();
  @Output() onUpdatePointEvent = new EventEmitter<MarkerDto>();
  currentFocusOnAsset;
  constructor(
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    public global: Global
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'update.recommended-list':
          this.updateMarkersArray(para);
          break;
        case 'delete.recommended-list':
          this.deleteMarkerInArray(para);
          break;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    changes && changes.recommendedList && changes.recommendedList.currentValue && this.regenerateRecommend();
  }

  regenerateRecommend() {
    this.selectAll = false;
    this.addAll = false;
    this.markersArray = this.recommendedList.map(item => {
      let formGroup = new FormGroup({
        googlePlaceId: new FormControl(item.googlePlaceId? item.googlePlaceId: item.id),
        name: new FormControl(item.name),
        latitude: new FormControl(item.latitude ? item.latitude : item.location? item.location.lat : null),
        longitude: new FormControl(item.longitude ? item.longitude : item.location? item.location.lng : null),
        checked: new FormControl(false),
        exist: new FormControl(false),
        mmitaRewardId: new FormControl(item.mmitaRewardId),
      })
      item.id && formGroup.addControl('id', new FormControl(item.id));
      return formGroup
    })
    this.ref.detectChanges();
    this.markersArray.forEach(item => {
      if (item.value.mmitaRewardId) {
        item.disable({ emitEvent: false });
        item.patchValue({ exist: true })
      }
      item.controls['checked'].valueChanges.subscribe(value => {
        this.markersValueChanges(value)
      });
    })
  }

  markersValueChanges(ev) {
    setTimeout(() => {
      let _selectAllFlag = true;
      let _addAllFlag = true;
      for (let i = 0; i < this.markersArray.length; i++) {
        if (!this.markersArray[i].value.checked) {
          _selectAllFlag = false;
          break;
        }
      }
      for (let i = 0; i < this.markersArray.length; i++) {
        if (this.markersArray[i].value.checked) {
          _addAllFlag = true;
          break;
        }
        _addAllFlag = false;
      }
      this.selectAll = _selectAllFlag;
      this.addAll = _addAllFlag;
      this.ref.detectChanges()
    }, 0)
  }

  onSelectAllChange(selectAll) {
    this.markersArray.forEach(fg => {
      fg.patchValue({ checked: fg.value.exist ? false : selectAll })
    })
  }

  onSetRewards(ev){
    this.currentFocusOnAsset = ev.target.value
    this.markersArray.forEach(fg => {
      if (!fg.value.exist && fg.value.checked) {
        fg.patchValue({ mmitaRewardId: ev.target.value });
      }
    })
  }

  editPoint(formGroup) {
    formGroup.enable();
  }
  addPoint(formGroup) {
    if (formGroup.invalid) {
      this.toastr.error('Please Fill in Coin Number and Asset', 'Error', {
        timeOut: 3000
      });
      this.ref.detectChanges()
      return;
    }
    this.onAddPointEvent.emit(new MarkerDto(formGroup.value))
  }
  updatePoint(formGroup) {
    if (formGroup.invalid) {
      this.toastr.error('Please Fill in Coin Number and Asset', 'Error');
      this.ref.detectChanges()
      return;
    }
    const markerInfo = new MarkerDto(formGroup.value);
    const updatePoint: any = { info: markerInfo, id: formGroup.value.id };
    this.onUpdatePointEvent.emit(updatePoint);
  }

  addBatchPoint() {
    let newMarkersArray = this.markersArray.filter(item => { return item.controls['checked'].value == true });
    for (let i = 0; i < newMarkersArray.length; i++) {
      if (newMarkersArray[i].invalid) {
        this.toastr.error('Field Invalid', 'Error', {
          timeOut: 3000
        });
        return;
      }
    }
    const batchMarkers = newMarkersArray.map(item => {
      return new MarkerDto(item.value)
    })
    this.onAddBatchPointsEvent.emit(batchMarkers)
  }

  
  updateMarkersArray(data){
    const updateMarkerItem = (item) => {
      const updateItem = this.markersArray.find(maker=>{
        return maker.value.googlePlaceId === item.googlePlaceId
      });
      if(!updateItem){
        return;
      }
      !updateItem.get('id') && updateItem.addControl('id', new FormControl(item.id));
      updateItem.patchValue({
        googlePlaceId: item.googlePlaceId,
        name: item.name,
        latitude: item.latitude,
        longitude: item.longitude,
        mmitaRewardId:item.mmitaRewardId ? item.mmitaRewardId : item.mmitaReward ? item.mmitaReward.id : null,
        checked: false,
        exist: true });
      updateItem.disable();
    }
    if(Array.isArray(data) && data.length>0){
      data.forEach(item=>{
        updateMarkerItem(item);
      })
    } else {
      updateMarkerItem(data);
    }
  }

  deleteMarkerInArray(id) {
    const deleteMarkerItem = (id) => {
      const deleteItem = this.markersArray.find(maker => {
        return maker.value.id === id
      });
      if(!deleteItem){
        return;
      }
      deleteItem.patchValue({
        id: null,
        mmitaRewardId: null,
        checked: false,
        exist: false
      });
      deleteItem.enable();
    }
    deleteMarkerItem(id)
  }

  checkBoxUpdate(fg: FormGroup){
    const isChecked =  fg.get('checked').value
    fg.patchValue({mmitaRewardId: isChecked ? this.currentFocusOnAsset : null})
  }

}