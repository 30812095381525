import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MarkerDto } from '../_models/mapDto';


@Injectable({ providedIn: 'root' })
export class MapService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  deleteMarker(id: string){
    return this.http.request<any>('delete',`${environment.apiEndpoint}api/MapPlace/${id}`)
  }

  updateMarker(marker: MarkerDto, id: string){
    return this.http.put<MarkerDto>(`${environment.apiEndpoint}api/MapPlace/${id}`, marker)
  }

  addMarker(marker: MarkerDto){
    return this.http.post<MarkerDto>(`${environment.apiEndpoint}api/MapPlace`, marker)
  }

  batchAddMarker(markers: Array<any>){
    return this.http.post<Array<any>>(`${environment.apiEndpoint}api/MapPlaces`, markers)
  }

  LoadMapPlaceData(data){
    return this.http.get<any>(`${environment.apiEndpoint}api/MapPlaces/Query`, {
      params: data
    });
  }

  batchDelete(ids){
    return this.http.delete(`${environment.apiEndpoint}api/MapPlaces`,{ body: {
      ids: ids
    }})
  }

}
