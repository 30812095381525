import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastr: ToastrService
  ) { }

  showFormInvalid(formGroup){
    for (const name in formGroup.controls) {
      if (formGroup.controls[name].invalid) {
        this.toastr.error(JSON.stringify(formGroup.controls[name].errors) ,`"${name}" Field`, {
          timeOut: 3000
        });
      }
    }
  }
}
