import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const CategoryTemplate = (itemId, section): DialogTemplate =>  {
    return {
        dialogTitle: itemId ? 'Edit Asset Category' :'Add Asset Category',
        dialogFields: [
            {
                key: 'order',
                label: 'Order',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'maxNumberOfUploadsForCustomer',
                label: 'Max Number Of Uploads For Customer',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'us',
                label: 'en-US',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'cn',
                label: 'zh-CN',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'hk',
                label: 'zh-HK',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'jp',
                label: 'Japanese',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'es',
                label: 'Spanish',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'de',
                label: 'German',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'pt',
                label: 'Portuguese',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'ko',
                label: 'Korean',
                type: InputFieldType.Text,
                size: 6,
                required: true
            },
            {
                key: 'fr',
                label: 'French',
                type: InputFieldType.Text,
                size: 6,
                required: true,
                offset: 18,
            },
            {
                key: 'assetCategoryType',
                label: 'Asset Category Type',
                type: InputFieldType.Selector,
                size: 12,
                required: true,
                options: section.AssetCategoryTypeSelections,
                mode: 'default',
            },
            {
                key: 'assetType',
                label: 'Asset Type',
                type: InputFieldType.Selector,
                size: 12,
                required: true,
                options: section.AssetTypeSelections,
                mode: 'default',
            },
            {
                key: 'assetCategoryOptions',
                label: 'Asset Category Options',
                type: InputFieldType.Selector,
                size: 24,
                options: section.AssetCategoryOptionsSelections,
                mode: 'multiple'
            }
        ]
    }
 
}