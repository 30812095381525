import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class EscapeGameService {
    httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) { }

    getPortal(data) {
      return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal/Query`, {
        params: data
      });
    }

    createPortal(data) { 
        return this.http.post<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal`, data);
    }

    updatePortal(data, portalId) { 
        return this.http.put<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal/${portalId}`, data);
    }

    deletePortal(portalId) {
        return this.http.delete<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal/${portalId}`);
    }

    batchDeletePortal(ids) {
        return this.http.delete<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portals`, {
            body: {
                ids: ids
            }
        })
    }

    getSetting() { 
      return this.http.get<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Setting`)  
    }

    postSetting(data) { 
        return this.http.post<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Setting`, data)  
      }
}
