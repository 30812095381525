import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { LanguageReadDto } from 'src/app/_models/languageReadDto';
import { FakeDataService } from 'src/app/_services/fake-data.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FakeDataTemplate } from './fake-data-item.constant';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fake-data',
  templateUrl: './fake-data.component.html',
  styleUrls: ['./fake-data.component.css']
})
export class FakeDataComponent extends BaseComponent{
  data: LanguageReadDto[];
  formGroup: FormGroup
  constructor(
    private fakeDataService: FakeDataService,
    private modalService: ModalService,
    public global: Global,
    private toastr: ToastrService,
  ) {
    super(global);
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchAll();
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id'},
      { headerName: 'Path', field: 'path'},
      { headerName: 'Api Method Type', field: 'apiMethodType' },
      { headerName: 'Response', field: 'response' },
      {
        headerName: 'Action',
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Fake Data Detail')
              break;
            case 'editDetail':
              this.showItem(params?.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
          }
        },
      }
  ];
  }
  fetchAll() {
    this.fakeDataService.getAll().subscribe(
      res => {
        this.data = res.data;
      });
  }

  delete(id){
    this.fakeDataService.delete(id).subscribe(res=>{
      this.fetchAll();
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    })
  }
  showItem(data?) {
    this.genFG(data)
    const dialogTemplate = FakeDataTemplate()
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }

  onSubmit(id?){
    return new Observable(subscriber => {
      const api = id ? this.fakeDataService.update(this.formGroup.value, id) : this.fakeDataService.create(this.formGroup.value)
      api.subscribe(res=>{
        this.fetchAll()
        this.toastr.success(`${id ? `Update`: `Cteate`} Successfully...!`, 'Success');
        subscriber.next()
        subscriber.complete()
      })
    })
  }

  genFG(orgData?){
    this.formGroup = new FormGroup({
      path: new FormControl(orgData?.path , Validators.required),
      apiMethodType: new FormControl(orgData?.apiMethodType, Validators.required),
      response: new FormControl(orgData?.response, Validators.required),
    })
    if(orgData){
      this.formGroup.get('path').disable()
      this.formGroup.get('apiMethodType').disable()
    }
  }

}
