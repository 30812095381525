<app-topbar class="app-topbar" (toggleCollapsed)="updateCollapsedStatus($event)" [isCollapsed]="isCollapsed"> </app-topbar>

<div class="main-layout">
  <app-sidebar *ngIf="router.url !== '/login'" class="side-bar" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" [horizontal]="true" [animation]="true"></app-sidebar>
   <!-- <div class="router-container" [ngClass]="{'map-wrapper': isMap}"> -->
    <div class="card-body router-container" [ngClass]="{'map-wrapper': isMap}">
    <router-outlet ></router-outlet>
  </div>
</div>

<ngx-spinner bdColor ="rgba(0, 0, 0, 0.6)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"><p style="color: white"> Loading... </p></ngx-spinner>
