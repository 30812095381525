import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Faq } from '../_models/faq';

@Injectable({ providedIn: 'root' })
export class FaqService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Faq[]>(`${environment.apiEndpoint}api/Faq`);
  }

  getById(id: string) {
    return this.http.get<Faq>(`${environment.apiEndpoint}api/Faq/${id}`);
  }

  addNew(faq: Faq) {
    return this.http.post<string>(`${environment.apiEndpoint}api/Faq`, faq, this.httpOptions)
  }

  updateById(faq: Faq, id: string) {
    return this.http.put<Faq>(`${environment.apiEndpoint}api/Faq/${id}`, faq, this.httpOptions)
  }

  delete(id: string) {
    return this.http.delete<Faq>(`${environment.apiEndpoint}api/Faq/${id}`);
  }
}
