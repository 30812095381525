import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Global } from 'src/app/_services/global.service';

@Component({
  selector: 'app-localization-category',
  templateUrl: './localization-category.component.html',
  styleUrls: ['./localization-category.component.scss']
})
export class LocalizationCategoryComponent implements OnInit {
  allCategoryData: Array<any> = [];
  editableCategoryData: Array<any> = [];
  formGroup: FormGroup;
  currentData;
  constructor(
    private global: Global
  ) { }

  ngOnInit() {
    this.editableCategoryData = this.allCategoryData.filter(item=> item.name !== 'Default')
    const currentData = this.editableCategoryData[0]
    this.formGroup = new FormGroup({
      categoryId: new FormControl(currentData.id),
      description: new FormControl(currentData.description),
      name: new FormControl(currentData.name)
    })
  }

  updateForm(){
    const currentCategoryInfo = this.editableCategoryData.find(item=> item.id === this.formGroup.value.categoryId)
    this.formGroup.patchValue({description: currentCategoryInfo.description})
    this.formGroup.patchValue({name: currentCategoryInfo.name})
  }

  onSubmit(){
    this.global.appEvent.next({ msg: 'update.localization-category', 
    para: {
        id:this.formGroup.value.categoryId,
        data: {
          description: this.formGroup.value.description,
          name: this.formGroup.value.name,
        }
      } 
    })
  }

  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });
  }


}
