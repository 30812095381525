import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const StripeSettingTemplate = (partners): DialogTemplate => {
    return {
        dialogTitle: 'Set Stripe Setting',
        dialogFields: [
            {
                key: 'developerId',
                label: 'Developer Id',
                type: InputFieldType.Selector,
                options: partners,
                size: 24,
                required: true,
                selectorLabel: 'des'
            },
            {
                key: 'apiKey',
                label: 'API Key',
                type: InputFieldType.Text,
                size: 24,
                required: true
            },
            {
                key: 'active',
                label: 'Active',
                type: InputFieldType.Switch,
                size: 12
            },
        ]
    }
}
