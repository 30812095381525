<ag-grid-angular
  #agGrid
  domLayout='autoHeight'
  class="ag-theme-alpine"
  [rowData]="data"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [suppressRowClickSelection]="true"
  [enableCellTextSelection]="true"
  (gridReady)="onGridReady($event)"
  (rowClicked)="onRowClicked($event)"
>
</ag-grid-angular>
