export enum StageType {
    Normal = 'Normal',
    VerticalCarousel = 'VerticalCarousel',
    ReviewBoard = 'ReviewBoard',
    Streamer = 'Streamer',
    OcclusionMesh = 'OcclusionMesh'
}




export class StageDto {
    description: string;
    scale: number;
    previewAssetId: string;
    longitude: number;
    latitude: number;
    altitude: number;
    height: number;
    heading: number;
    stageType: string;
    verticalCarouselId: string|undefined
    reviewBoardPlaceId: string|undefined
    visibleRadius: number
    isGeospatial: boolean
    rotationX: number
    rotationY: number
    rotationZ: number
    isInactive: boolean
    environmentPreviewImageUrl: string
    streamEventId: string
    withOcclusionMesh: boolean
    lookAtUser: boolean
    constructor(data, environmentImg, altitude){
        this.description = data.description;
        this.scale = data.scale;
        this.previewAssetId = data.previewAssetId;
        this.height = data.height
        this.altitude = altitude
        this.heading = data.heading;
        this.stageType = data.stageType
        this.visibleRadius = data.visibleRadius
        this.isGeospatial = data.isGeospatial
        this.rotationX = data.rotationX
        this.rotationX = data.rotationX
        this.rotationY = data.rotationY
        this.rotationZ = data.rotationZ
        this.isInactive = data.isInactive
        this.environmentPreviewImageUrl = environmentImg || data.environmentPreviewImageUrl
        this.streamEventId = data.streamEventId
        this.withOcclusionMesh = data.withOcclusionMesh
        this.lookAtUser = data.lookAtUser
        if(this.stageType === StageType.VerticalCarousel){
            this.verticalCarouselId = data.verticalCarouselId
        } else if ( this.stageType === StageType.ReviewBoard){
            this.reviewBoardPlaceId = data.reviewBoardPlaceId
        }
        if(!data.id){
            this.longitude = data.longitude
            this.latitude = data.latitude
        }
    }
}