    <section class="header-btn-wrapper">
        <button type="button" nz-button nzType="primary" (click)="showItem()">
          <i class="fas fa-fw fa-plus"></i> Add Coupon
        </button>
    </section>
    <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="data"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    [enableCellTextSelection]="true"
    (gridReady)="onGridReady($event)"
    [rowHeight]="80"
    >
    </ag-grid-angular>

