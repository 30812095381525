import { Component, OnInit } from '@angular/core';
import { MetricService } from 'src/app/_services/metric.service';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.css']
})
export class DailyComponent implements OnInit {
  dailyInfo: Array<any> = []
  randered: boolean
  constructor(
    private metricService: MetricService
  ) { }

  ngOnInit(): void {
    this.metricService.getDailyLogin().subscribe(res=>{
      for(let prop in res){
        const item = {key: prop, value: res[prop]}
        this.dailyInfo.push(item)
      }
      this.randered = true
    })
  }
}
