import { FileUploadService } from "src/app/_services/file-upload.service";
import {cloneDeep} from 'lodash';
import { Injectable } from "@angular/core";
import { AssetWriteDto } from "src/app/_models/models";
import { UploadFileDto, FileReadDto } from "src/app/_models/uploadFileDto";
import { Observable } from "rxjs";

@Injectable()
export class AssetDataBuilder {
    private assetData = new AssetWriteDto()
    private blob = {
        fileSource: undefined,
        thumbnailSource: undefined,
        androidFileSource: undefined,
        visionOSFileSource: undefined,
        macOSFileSource: undefined,
    }

    constructor(
        private fileUploadService: FileUploadService
    ){

    }

    private initData(){
        this.assetData = new AssetWriteDto()
        this.blob = {
            fileSource: undefined,
            thumbnailSource: undefined,
            androidFileSource: undefined,
            visionOSFileSource: undefined,
            macOSFileSource: undefined,
        }
    
    }

    genAsset(asset, isSingle: boolean, batchInfo: Array<any>, copyMode: boolean, mergeData:AssetWriteDto, gifThumSource: Array<File>){
        this.initData();
        return new Observable(subscriber => {
            if(asset){
                const assetnew = this.mapAbilityId(asset)
                this.assetData.assetType = assetnew.assetType;
                this.assetData.categoryId = assetnew.categoryType;
                this.assetData.abilitiyIds = assetnew.ability;
                this.assetData.name = assetnew.name;
                this.assetData.description = assetnew.description;
                this.assetData.adUrlLinked = assetnew.adUrlLinked;
                this.assetData.numberOfTokensUnlocked = assetnew.numberOfTokensUnlocked;
                this.assetData.isFramed = false; // by design
                this.assetData.assetStatus = assetnew.assetStatus;
                this.assetData.businessType = assetnew.businessType
                this.assetData.scaleInMap = assetnew.scaleInMap
                
                if(copyMode){
                    this.assetData.url = mergeData.url;
                    this.assetData.androidUrl = mergeData.androidUrl;
                    this.assetData.thumbnailUrl = mergeData.thumbnailUrl;
                    this.assetData.smallThumbnailUrl = mergeData.smallThumbnailUrl;
                    this.assetData.iconUrl = mergeData.iconUrl;
                    this.assetData.visionOSUrl = mergeData.visionOSUrl;
                    this.assetData.macOSUrl = mergeData.macOSUrl;
                }

                // udpate batch Asset (only model3d)
                if(batchInfo){
                    const batchUpdateData = {
                        assetIds: batchInfo.map(item=> item.id),
                        abilityIds: this.assetData.abilitiyIds
                    }
                    subscriber.next(batchUpdateData);
                    subscriber.complete()
                    return
                }
                this.blob = this.buildBlob(assetnew, isSingle);
                const uploadList = this.buildFile(this.blob, isSingle, gifThumSource)
                if(!uploadList || !uploadList.length){
                    subscriber.next(this.assetData);
                    subscriber.complete()
                } else {
                    const validUploadList = this.checkUploadListValidation(this.assetData.assetType, uploadList, isSingle)
                    if(!validUploadList){
                        subscriber.error('upload wrong file type.')
                        return;
                    }
                    this.genFinalAsset(uploadList, isSingle).subscribe(res=>{
                        subscriber.next(res);
                        subscriber.complete()
                    })
                }
            } else {
                subscriber.next();
                subscriber.complete()
            }
            
        })
    }

    checkUploadListValidation(assetType: string, uploadList: Array<UploadFileDto>, isSingle){
        if(isSingle){
            const type = assetType.toLocaleLowerCase()
            const checkFileType=(type: string, list:Array<UploadFileDto>)=>{
                for(let i =0; i< list.length; i++){
                    if(list[i].name === 'fileSource' && list[i].file.type.indexOf(type)<0){
                        return false
                    }
                }
                return true
            }
            switch(type){
                case 'image':
                case 'video':
                    return checkFileType(type, uploadList)
                default:
                    return true;
            }
        }
        return true
    }

    genBatchUpdate(batchInfo, asset){
        const batchIds = batchInfo.map(item=>{
            return item.id
          });
        const abilityIds = this.mapAbilityId(asset).ability;
        return {
            assetIds: batchIds,
            abilityIds: abilityIds
        }
    }

    
  private buildBlob(asset, isSingle){
    const source = {
        fileSource: undefined,
        thumbnailSource: undefined,
        androidFileSource: undefined,
        visionOSFileSource: undefined,
        macOSFileSource: undefined,
    }
    if(isSingle){
        for(let prop in source){
            if(asset[prop]){
                source[prop] = asset[prop];
            }
        }
    } else {
        for(let prop in source){
            if(asset[prop]){
                source[prop] = Array.from(asset[prop]).map((f: File) =>{
                    return f
                });
            }
        }
    }
    return source
  }

    

    private genFinalAsset(uploadList, isSingle){
        return new Observable(subscriber => {
            if(isSingle){
                this.fileUploadService.postFile(uploadList).subscribe((res: Array<FileReadDto>)=>{
                    const fileUrlObj = res.find(item=> item.name === 'fileSource');
                    const thumbnailSourceObj = res.find(item=> item.name === 'thumbnailSource');
                    const androidSourceObj = res.find(item=> item.name === 'androidFileSource');
                    const visionOSSourceObj = res.find(item=> item.name === 'visionOSFileSource');
                    const macOSSourceObj = res.find(item=> item.name === 'macOSFileSource');
                    switch(this.assetData.assetType){
                        case 'Image':
                            if(fileUrlObj.format === 'gif'){
                                this.assetData.url=  fileUrlObj && (fileUrlObj.medium ||fileUrlObj.origin);
                                this.assetData.thumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.medium
                                this.assetData.smallThumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.small
                                this.assetData.iconUrl = thumbnailSourceObj && thumbnailSourceObj.icon
                            } else {
                                this.assetData.url= this.assetData.thumbnailUrl = fileUrlObj && (fileUrlObj.medium ||fileUrlObj.origin);
                                this.assetData.smallThumbnailUrl = fileUrlObj && (fileUrlObj.small||fileUrlObj.origin);
                                this.assetData.iconUrl = fileUrlObj && (fileUrlObj.icon||fileUrlObj.origin);
                            }

                        break;
                        case 'Video':
                            this.assetData.url = fileUrlObj && fileUrlObj.origin;
                            this.assetData.thumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.medium;
                            this.assetData.smallThumbnailUrl = thumbnailSourceObj &&  thumbnailSourceObj.small;
                            this.assetData.iconUrl = thumbnailSourceObj && thumbnailSourceObj.icon;
                        break;
                        case 'Model3d':
                            this.assetData.url = fileUrlObj && fileUrlObj.origin;
                            this.assetData.thumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.medium;
                            this.assetData.smallThumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.small;
                            this.assetData.iconUrl = thumbnailSourceObj && thumbnailSourceObj.icon;
                            this.assetData.androidUrl = androidSourceObj && androidSourceObj.origin;
                            this.assetData.visionOSUrl = visionOSSourceObj && visionOSSourceObj.origin;
                            this.assetData.macOSUrl = macOSSourceObj?.origin;
                        break;
                        case 'Game':
                            this.assetData.url = fileUrlObj && fileUrlObj.origin;
                            this.assetData.thumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.medium;
                            this.assetData.smallThumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.small;
                            this.assetData.iconUrl = thumbnailSourceObj && thumbnailSourceObj.icon;
                            this.assetData.androidUrl = androidSourceObj && androidSourceObj.origin;
                            this.assetData.visionOSUrl = visionOSSourceObj && visionOSSourceObj.origin;
                            this.assetData.macOSUrl = macOSSourceObj?.origin;
                        break;
                    }
                    subscriber.next(this.assetData);
                    subscriber.complete()
                })
            } else {
                this.fileUploadService.postFile(uploadList).subscribe((res: Array<FileReadDto>)=>{
                    subscriber.next(this.genBatchAssetData(res));
                    subscriber.complete()
                })
            }
            
        })

    }


    private genBatchAssetData(imageData: Array<FileReadDto>){
        let finalData = [];
        switch(this.assetData.assetType){
            case 'Image':
                const _imageData = imageData.filter(item => item.name.indexOf('_thumbnailSource')< 0)
                finalData = _imageData.map(fileUrlObj=>{
                    const assetData = cloneDeep(this.assetData);
                    assetData.name = this.getFileName(fileUrlObj)
                    assetData.url= fileUrlObj.medium ||fileUrlObj.origin;
                    if(fileUrlObj.format === 'gif'){
                        const thum = imageData.find(item=> item.name === `_thumbnailSource-${fileUrlObj.name}`)
                        assetData.thumbnailUrl = thum.medium ? thum.medium: fileUrlObj.origin;
                        assetData.smallThumbnailUrl = thum ? thum.small:fileUrlObj.origin
                        assetData.iconUrl = thum ? thum.icon: fileUrlObj.origin;
                    } else {
                        assetData.url = assetData.thumbnailUrl = fileUrlObj.medium ||fileUrlObj.origin;
                        assetData.smallThumbnailUrl = fileUrlObj.small||fileUrlObj.origin;
                        assetData.iconUrl = fileUrlObj.icon||fileUrlObj.origin;
                    }
            
                    return assetData
                })
            break;
            case 'Video':
                const videoUrl = this.getSourceFile(imageData, 'Video');
                finalData = videoUrl.map(fileUrlObj=>{
                    const assetData = cloneDeep(this.assetData);
                    const thumbnailSourceObj = this.mapFile(imageData, this.getFileName(fileUrlObj), 'Image')
                    assetData.name = this.getFileName(fileUrlObj)
                    assetData.url = fileUrlObj.origin;
                    assetData.thumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.medium;
                    assetData.smallThumbnailUrl = thumbnailSourceObj &&  thumbnailSourceObj.small;
                    assetData.iconUrl = thumbnailSourceObj &&  thumbnailSourceObj.icon;
                    return assetData
                })
            break;
            case 'Model3d':
                const model = this.getSourceFile(imageData, 'Model', 'fileSource');
                finalData = model.map(fileUrlObj=>{
                    const iosFileName = this.getFileName(fileUrlObj, 'fileSource')
                    const assetData = cloneDeep(this.assetData);
                    const thumbnailSourceObj = this.mapFile(imageData, iosFileName, 'Image', 'thumbnailSource')
                    const androidSourceObj = this.mapFile(imageData, iosFileName, 'Model', 'androidFileSource')
                    const visionOSSourceObj = this.mapFile(imageData, iosFileName, 'Model', 'visionOSFileSource')
                    const macOSSourceObj = this.mapFile(imageData, iosFileName, 'Model', 'macOSFileSource')
                    assetData.name = iosFileName
                    assetData.url = fileUrlObj.origin;
                    assetData.thumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.medium;
                    assetData.smallThumbnailUrl = thumbnailSourceObj && thumbnailSourceObj.small;
                    assetData.iconUrl = thumbnailSourceObj && thumbnailSourceObj.icon;
                    assetData.androidUrl = androidSourceObj && androidSourceObj.origin;
                    assetData.visionOSUrl = visionOSSourceObj && visionOSSourceObj.origin;
                    assetData.macOSUrl = macOSSourceObj?.origin;
                    return assetData
                })
            break;

        }
        return finalData
    }

    private getFileName(file, namePrefix?){
        const firstName = file.name.substring(0, file.name.lastIndexOf("."))
        if(namePrefix){
            const prefix = `${namePrefix}-`
            return firstName.indexOf(prefix) === 0 && firstName.substring(prefix.length, firstName.length)
        } else {
            return firstName
        }
        
    }

    private getSourceFile(fileArray, fileType, namePrefix?){
        if(namePrefix){
            const arr = fileArray.filter(item => item.type === fileType);
            return arr.filter(item => item.name.split('-')[0] === namePrefix)
        } else {
            return fileArray.filter(item => item.type === fileType);
        }
    }

    private mapFile(fileArray, fileName, fileType, namePrefix?){
        return fileArray.find(item=>{
            const firstName = this.getFileName(item, namePrefix)
            return firstName === fileName && item.type === fileType
        })
    }

    private buildFile(blob, isSingle, gifThumSource: Array<File>){
        const uploadList = [];
        for(let prop in blob){
            if(isSingle && blob[prop]){
                uploadList.push(new UploadFileDto({name: prop, file: blob[prop]}))
                if(blob[prop].type.indexOf('gif')>=0 && gifThumSource.length == 1){
                    uploadList.push(new UploadFileDto({name: `thumbnailSource`, file: gifThumSource[0]}))
                }
            } else if(blob[prop] && blob[prop].length){
                blob[prop].forEach(item=>{
                    if(this.assetData.assetType === 'Model3d'){
                        // to distinguish ios file and android file
                        uploadList.push(new UploadFileDto({name: `${prop}-${item.name}`, file: item}))
                    } else {
                        uploadList.push(new UploadFileDto({name: item.name, file: item}))

                    }
                    if(item.type.indexOf('gif')>=0){
                        const _thum = gifThumSource.find(gifSource=> gifSource.name.indexOf(item.name)>=0 )
                        uploadList.push(new UploadFileDto({name: `_thumbnailSource-${item.name}`, file: _thum}))
                    }
                })
            }


        }
        return uploadList
    }

        
    private mapAbilityId(assetnew){
        const assetData = cloneDeep(assetnew);
        if(!assetData.ability){
        assetData.ability = [];
        } else {
        assetData.ability = assetData.ability.map(item=>{
            return item.id
        })
        }
        return assetData;
    }


}