<app-search-place (onSearchEvent)="searchByText($event)"></app-search-place>
<div class="table-wrapper">
    <table *ngIf="searchResultArray" class="table table-striped">
        <thead>
            <tr>
                <th>Name</th>
                <th>Lat</th>
                <th>Lng</th>
                <th>Address</th>
            </tr>
        </thead>
        <tbody>
            <tr class="info-row" *ngFor="let item of searchResultArray; index as i" (click)="selectItem(item)">
                <td>
                    <img src="item.icon" alt="">
                    {{item.name}}
                </td>
                <td>
                    {{item.latitude}}
                </td>
                <td>
                    {{item.longitude}}
                </td>
                <td [title]="item.formatted_address">
                    {{item.address}}
                </td>
            </tr>
        </tbody>
    
    </table>
</div>

