import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { MetricService } from 'src/app/_services/metric.service';
import { ModalService } from 'src/app/_services/modal.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';

@Component({
  selector: 'app-visitor-record',
  templateUrl: './visitor-record.component.html',
  styleUrls: ['./visitor-record.component.css']
})
export class VisitorRecordComponent extends BaseComponent {
  headerList = []
  data: Array<any>

  constructor(
    private metricService: MetricService,
    public global: Global,
    private modalService: ModalService,
    private datePipe: DatePipe
  ) { 
    super(global);

  }

  ngOnInit(): void {
    this.setColumnDefs()
  }
  
  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Inviter User ID', field: 'inviterUserId'},
      { headerName: 'Inviter User Email', field: "inviterUserEmail"},
      { headerName: 'Click Count', field: 'clickCount'},
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return ` <i class="fas fa-fw fa-calendar-days" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.metricService.getViewDate(params?.data?.inviterUserId).subscribe((res: Array<string>)=>{
                const dateArray = res.map(item=>{
                  return this.datePipe.transform(item, 'medium')
                })
                this.modalService.showDetail(dateArray, 'Time of clicked the link')
              })
              break;
          }
        },
      },
    ];

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }

  getAll(){
    this.metricService.getVisitorRecord().subscribe((res: Array<any>)=>{
      this.data = res
    })
  }



}
