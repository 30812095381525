<section class="header-btn-wrapper">
    <button nz-button nzType="primary" (click)="sendEmailToReadyToExpire()" style="margin-right: 10px;">
        Send Email To Ready To Expire 
    </button>
    <button nz-button nzType="primary" (click)="inactiveStageOfPartnerExpired()">
        Inactive Stage Of Partner Expired
    </button>
  </section>

<ag-grid-angular
#agGrid
domLayout='autoHeight'
class="ag-theme-alpine"
[rowData]="data"
[columnDefs]="columnDefs"
rowSelection="multiple"
[defaultColDef]="defaultColDef"
[pagination]="true"
[suppressRowClickSelection]="true"
(gridReady)="onGridReady($event)"
[enableCellTextSelection]="true"

>
</ag-grid-angular>