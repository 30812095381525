<div class="md-popover login-popover" style="margin:30px">
  <h3 style="text-align:center">Image Edit</h3>
  <section class="my-popover-content">
    <form *ngIf="getdata" class="mb-4" ngNativeValidate [formGroup]="imageUpdate" (ngSubmit)="onImageUpdateFormSubmit(imageUpdate.value)">
      <div class="form-group mb-3">
        <label for="Order">Order:</label>
        <input type="number" class="form-control" [(ngModel)]="getdata.order" placeholder="Order" min="1"  step="0.1" formControlName="Order" required>
      </div>
      <div class="form-group mb-3">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" [(ngModel)]="getdata.isActive" placeholder="Descrption" formControlName="IsActive">
          <label  class="form-check-label" for="Active">Active</label>
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="LanguageCode">Language:</label>
        <select class="form-select" formControlName="LanguageCode" [(ngModel)]="getdata.languageCode" required>
          <option *ngFor="let item of languageList" value="{{item.languageCode}}">{{item.displayName}} ({{item.languageName}})</option>
        </select>
      </div>
      <div class="form-group mb-4">
        <label for="ImageUrl">Carousel Image:</label>
        <input formControlName="file"
               id="file"
               type="file"
               class="form-control"
               accept="image/*"
               (change)="onFileChange($event)">
        <input type="hidden" class="form-control" placeholder="ImgUrl" [(ngModel)]="getdata.imgUrl" formControlName="ImgUrl" required>
      </div>
      <div class="row">
        <div class="col-md-2">
          <button type="submit" class="btn btn-block btn-primary">Update</button>
        </div>
        <div class="col-md-2">
          <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
          <!--<a href="/asset" class="btn btn-block btn-danger"> Back</a>-->
        </div>
      </div>
    </form>
  </section>
</div>
