import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { UploadFileDto } from '../_models/uploadFileDto';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  path = environment.FILE_PATH;
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    @Optional() config: Configuration
  ) {
    if (config) {
      this.configuration = config;
    }
  }

  public postFile(fileArray: Array<UploadFileDto>){
    let header = new HttpHeaders()
    .set('Content-Disposition', 'form-data')
    const formParams = new FormData();
    fileArray.forEach(item=>{
      formParams.set(item.name, item.file);
    })
    return this.httpClient.request<any>('post', `${this.path}FileUpload/Multiple/Query`,
      {
        headers: header,
        body: formParams
      }
    );
  }

  getFileDetail(fileArray: Array<UploadFileDto>, guidMode = false){
    let header = new HttpHeaders()
    .set('Content-Disposition', 'form-data')
    const formParams = new FormData();
    fileArray.forEach(item=>{
      formParams.set(guidMode? item.guid: item.name, item.file);
    })
    return this.httpClient.request<any>('post', `${this.path}FileUpload/Multiple/Detail/Query`,
      {
        headers: header,
        body: formParams
      }
    ); 
  }
}
