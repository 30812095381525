
    <div style="margin:30px">
        <section>
          <h3 style="text-align:center">Create Marker</h3>
        </section>
        <label for="id" class="form-label">Random Level(The larger the number, the closer to the portal) </label>
        <div class="input-group mb-3">
          <input type="number" class="form-control"  [(ngModel)]="randomLevel">
          <button class="btn btn-primary" type="button"  (click)="setRandom()">Set</button>
        </div>


        <table class="table table-striped" style="margin-top: 10px;">
            <thead>
              <tr>
                <th scope="col">Longitude</th>
                <th scope="col">Latitude</th>
                <th scope="col">Reward</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let listItem of formArray; index as i">
                <ng-container [formGroup]="listItem">
                <td>
                    <input type="text" class="form-control" formControlName="longitude" >
    
                </td>
                <td>
                    <input type="text" class="form-control" formControlName="latitude">
    
                </td>
                <td>
                    <img [src]="rewardSource[i].previewAsset.iconUrl" alt="">
                </td>
    
    
                </ng-container>
            </tr>
                </tbody>
                </table>
                <button type="button" class="btn btn-primary" (click)="save()">Save</button>

    </div>


            