
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { CategoryDto } from '../../_models/categoryDto';
import { CategoryService } from '../../_services/category.service';
import { Global } from '../../_services/global.service';
import { ModalService } from '../../_services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryTemplate } from './asset-category-item.constant';
import { Observable } from 'rxjs';
import { GridOptions, RowHeightParams } from 'ag-grid-community';
@Component({
  selector: 'app-asset-category',
  templateUrl: './asset-category.component.html',
  styleUrls: ['./asset-category.component.scss']

})
export class AssetCategoryComponent extends BaseComponent implements OnInit {
    category: Array<CategoryDto>;
    formGroup: FormGroup
    gridOptions: GridOptions = {
      getRowHeight: this.getRowHeight,
    }
    constructor(
        private categoryService: CategoryService,
        public global: Global,
        public modalService: ModalService,
        private toastr: ToastrService,
        private router: Router,
    ){
      super(global);
    }

    getRowHeight(params: RowHeightParams): number | undefined | null {
      const height = params?.data?.assetCategoryOptions?.length * 20
      return height< 40 ? 40: height;
    }

    setColumnDefs(){
      this.columnDefs = [
        { headerName: 'Order', field: 'order', minWidth: 80},
        { headerName: 'ID', field: 'id'},
        { headerName: 'en-US', field: 'multilingualNames.en-US'},
        { headerName: 'zh-CN', field: 'multilingualNames.zh-CN' },
        { headerName: 'Asset Type', field: 'assetType' },
        { headerName: 'Category Type', field: 'assetCategoryType' },
        { headerName: 'Category Options', field: 'assetCategoryOptions',
          cellRenderer: params => {
              let innerHtml = ''
              params.value.forEach(item=>{
                innerHtml += `<li style="list-style: none;">${item}</li>`
              });
              return innerHtml
          }
      },
        { headerName: 'Max Number Of Uploads For Customer', field: 'maxNumberOfUploadsForCustomer' },

        {
          headerName: 'Action',
          pinned: 'right',
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-arrow-right" id="navigate" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'navigate':
                this.naviageToAsset(params)
                break;
              case 'editDetail':
                this.showItem(params.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id), 'If you delete this category, all files under that category will also be deleted. Are you sure you want to delete?')
                break;
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Asset Category Detail')
                break;
            }
          },
           minWidth: 140
        }
    ];
    }


    showItem(data?){
      this.genFG(data)
      const dialogTemplate = CategoryTemplate(!!data, this.global.section)
      this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.getAll();
    }
    
    ngOnInit(){
       this.setColumnDefs();
    }

    getAll(){
        this.categoryService.getAssetCategory().subscribe(res=>{
            this.category = res.data;
        }  )
    }


    delete(id){
        this.categoryService.deleteCategory(id).subscribe(data => {
            this.getAll();
            this.toastr.success('Data Deleted Successfully...!', 'Success');
        });
    }
    naviageToAsset(params){
      this.router.navigateByUrl(`/asset/${params.data.id}`)
    }

    genFG(res?){
      this.formGroup = new FormGroup({
          order: new FormControl(res?.order,Validators.required),
          us: new FormControl(res?.multilingualNames['en-US'],Validators.required),
          cn: new FormControl(res?.multilingualNames['zh-CN'],Validators.required),
          hk: new FormControl(res?.multilingualNames['zh-HK'],Validators.required),

          jp: new FormControl(res?.multilingualNames['jp'],Validators.required),
          es: new FormControl(res?.multilingualNames['es'],Validators.required),
          de: new FormControl(res?.multilingualNames['de'],Validators.required),
          pt: new FormControl(res?.multilingualNames['pt'],Validators.required),
          ko: new FormControl(res?.multilingualNames['ko'],Validators.required),
          fr: new FormControl(res?.multilingualNames['fr'],Validators.required),
          assetCategoryOptions: new FormControl(res?.assetCategoryOptions),
          maxNumberOfUploadsForCustomer: new FormControl(res?.maxNumberOfUploadsForCustomer,Validators.required),
          // only support add item
          assetType: new FormControl(res?.assetType,Validators.required),
          assetCategoryType: new FormControl(res?.assetCategoryType,Validators.required),
        });

      if(res){
        this.formGroup.get('assetType').disable()
        this.formGroup.get('assetCategoryType').disable()
      }
  }

    onSubmit(itemId){
      return new Observable(subscriber => {
        const saveSuccess = () => {
          this.getAll()
          this.toastr.success('Data Saved Successfully...!', 'Success');
          subscriber.next()
          subscriber.complete()
      }
      const category = this.buildCategory(this.formGroup.value, itemId);
      itemId ? this.categoryService.updateCategory(itemId,category).subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      }) : this.categoryService.addCategory(category).subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      })
    })
  }

  buildCategory(formVal, itemId){
    const category: CategoryDto = {
        maxNumberOfUploadsForCustomer: formVal.maxNumberOfUploadsForCustomer,
        multilingualNames: {
          "en-US": formVal.us,
          "zh-CN": formVal.cn,
          "zh-HK": formVal.hk,
          'jp': formVal.jp,
          'es': formVal.es,
          'de': formVal.de,
          'pt': formVal.pt,
          'ko': formVal.ko,
          'fr': formVal.fr,
        },
        order: formVal.order,
        assetCategoryOptions: formVal.assetCategoryOptions,
        // 如果不需要修改的字段均可以不传或者传null(注意是null不是'')，接口只会对传入不为null的字段进行更新
        assetType: itemId ? null : formVal.assetType,
        assetCategoryType: itemId ? null : formVal.assetCategoryType,
    }
    return category;
  }
}
