  <section class="header-btn-wrapper">
    <button nz-button nzType="primary" (click)="showItem()"><i class="fas fa-fw fa-plus"></i>&nbsp;Add New Asset</button>
    <button nz-button nzType="primary" (click)="showItem(null, false, true)"><i class="fas fa-fw fa-plus"></i>&nbsp;Add Batch Assets</button>
    <button nz-button (click)="updateBatchAssets()"><i class="fas fa-fw fa-edit"></i>&nbsp;Update Asset Abilities</button>
    <button nz-button nzType="primary" (click)="deleteBatchAssets()" nzDanger><i class="fas fa-minus"></i>&nbsp;Delete Batch Assets</button>
    <button nz-button nzType="primary" (click)="getAccessToken()"><i class="fas fa-random"></i>&nbsp;Sketchfab Access Token</button>
    <button nz-button nzType="primary" (click)="open3DGenerator('Image')" class="generator-btn"><i class="fas fa-image"></i>&nbsp;Create Asset By Image</button>
    <button nz-button nzType="primary" (click)="open3DGenerator('Text')" class="generator-btn"><i class="fas fa-file-lines"></i>&nbsp;Create Asset By Text</button>

    
  </section>
  <app-search-form *ngIf="rendered" #SearchForm [searchSection]="searchSection" (searchCriteria)="setSearchCriteria($event)"></app-search-form>
  <ag-grid-angular
    #agGrid
    class="ag-theme-alpine"
    [rowHeight]="50"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true"
    [gridOptions]="gridOptions"
    style="height: 80vh"
    (sortChanged)="sortChanged($event)"
  >
  </ag-grid-angular>
