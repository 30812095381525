    <nz-form-item>
        <nz-form-label nzFor="excel" class="coupon-label-warpper">Upload Coupon By Excel</nz-form-label>
        <nz-form-control>
            <app-mit-uploader [accept]="'.xlsx, .xls, .csv'" (onFileChange)="upload($event)"></app-mit-uploader>
        </nz-form-control>
    </nz-form-item>
    
    <nz-divider></nz-divider>
    <nz-form-item>
        <nz-form-label class="coupon-label-warpper" nzFor="coupon">Promotion Codes</nz-form-label>
        <nz-form-control>
            <div nz-row [nzGutter]="16">
                <ng-container *ngFor="let item of promotionCodes; let i = index; trackBy:trackById">
                    <div class="input-array-wrapper" nz-col [nzSpan]="12">
                        <input
                        class="passenger-input"
                        nz-input
                        [attr.id]="i"
                        [(ngModel)]="item.promotionCode"
                        />
                        <ng-container *ngIf="i < orginalPromotionCodes.length">
                            <!-- <span nz-icon nzType="save" nzTheme="outline" (click)="save(i)" class="save-icon"></span> -->
                            <i class="fas fa-solid fa-floppy-disk save-icon ani" (click)="save(item)"></i>
                            <i *ngIf="item.active; else checkUserInfo" class="fas fa-trash-can remove-code ani" (click)="removeCode(item.id)"></i>
                            <ng-template #checkUserInfo>
                                <i class="fas fa-bars detail-icon ani" (click)="getUserInfo(item.id)"></i>
                            </ng-template>
                        </ng-container>
                    </div>
                </ng-container>
                <div nz-col [nzSpan]="12">
                    <button nz-button nzType="dashed" type="button" class="add-button" (click)="addField()">
                        Add Item
                    </button>
                </div>

            </div>
            <div *ngIf="promotionCodes.length > orginalPromotionCodes.length" style="padding: 10px; text-align: center;">
                <button nz-button nzType="primary" type="button" (click)="addFields()" nzBlock>
                    Push New Coupons
                </button>
            </div>
        </nz-form-control>
    </nz-form-item>

