import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { TaskDto } from '../_models/taskDto';

@Injectable({ providedIn: 'root' })
export class TaskService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<TaskDto[]>(`${environment.apiEndpoint}api/Tasks`);
  }

  getById(id: string) {
    return this.http.get<TaskDto>(`${environment.apiEndpoint}api/Task/${id}`);
  }

  insertNew(task) {
    return this.http.post<TaskDto>(`${environment.apiEndpoint}api/Task`, task, this.httpOptions)
  }

  updateById(task: TaskDto, id: string) {
    return this.http.put<TaskDto>(`${environment.apiEndpoint}api/Task/${id}`, task, this.httpOptions)
  }

  delete(id: string) {
    return this.http.delete<TaskDto>(`${environment.apiEndpoint}api/Task/${id}`);
  }

}
