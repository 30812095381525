<nz-upload 
[nzFileList]="fileList" [nzMultiple]="true"
[nzCustomRequest]="customRequest"
[nzBeforeUpload]="beforeUpload"
(nzChange)="handleChange($event)"
[nzShowUploadList]="iconsSetting"
>
    <button nz-button>
      <span nz-icon nzType="upload"></span>
      Upload
    </button>
  </nz-upload>