export class UploadFileDto { 
    name?: string;
    file: File;
    guid?: string
    constructor(data?){
        if(!data){
            return
        }
        this.name = data.name;
        this.file = data.file
    }
}


export class FileReadDto {
    icon: string;
    medium: string
    name: string
    origin: string
    small: string
    type: string
    format: string;
}