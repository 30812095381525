import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { LogService } from 'src/app/_services/log.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent extends BaseComponent{
  data: Array<any> = [];
  path: Array<string> = []
  constructor(
    private logService: LogService,
    public global: Global
  ) {
    super(global)
  }


  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'Path', field: 'path'},
        { headerName: 'Is Blob', field: 'isBlob'},
        { headerName: 'Blob Url', field: 'blobUrl'},
        { headerName: 'Display Name', field: 'displayName'},
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 50,
          cellRenderer: params => {
            return  `<i class="fas fa-fw ${params.data.blobUrl ?  'fa-file':'fa-arrow-right'}" style="margin-right:3px; cursor: pointer;"></i>`
          },
          onCellClicked: params => {
            if(params.data.isBlob){
              window.open(params.data.blobUrl);
            } else {
              this.getReport(params.data.path)
            }
          },
        },
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getReport();
  }

  ngOnInit() {
    this.setColumnDefs();
  }
  getReport(path?) {
    this.logService.getAll(path)
      .subscribe(
        data => {
          this.data = data;
          this.path = path ? path.split('/') : null
        });
  }

  getPathInfo(pathIndex){
    const pathArray = this.path.slice(0,pathIndex+1)
    const callPath = pathArray.join('/')
    this.getReport(callPath)
  }

}
