import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-mit-tag',
  templateUrl: './mit-tag.component.html',
  styleUrls: ['./mit-tag.component.scss']
})
export class MitTagComponent implements OnInit {
  @Input() formCtrl: FormControl
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  tags: Array<string>;
  inputVisible = false;
  inputValue = '';
  constructor() { }

  get allowAddTag(){
    return this.tags.length< 20
  }

  ngOnInit(): void {
    this.tags = this.formCtrl.value || []
  }
  handleClose(removedTag: {}): void {
    this.tags = this.tags.filter(tag => tag !== removedTag);
    this.formCtrl.setValue(this.tags)

  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  handleInputConfirm(): void {
    if (this.inputValue && this.tags.indexOf(this.inputValue) === -1) {
      this.tags = [...this.tags, this.inputValue];
    }
    this.inputValue = '';
    this.inputVisible = false;
    this.formCtrl.setValue(this.tags)

  }
}
