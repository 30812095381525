import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll(path?) {
    return this.http.get<any[]>(`${environment.apiEndpoint}api/Log`, {
      params: {
        path: path
      }
    });
  }



}