  <section class="header-btn-wrapper">
    <button nz-button nzType="primary" (click)="showItem()" class="btn">
      <i class="fas fa-fw fa-plus"></i> Add New Language
    </button>

  </section>
  <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="languageReadDto"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    [enableCellTextSelection]="true"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>


