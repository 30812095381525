import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { GoogleService } from 'src/app/_services/google.service';
import { ModalService } from 'src/app/_services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RestaurantTemplate } from './restaurant-type-item.constant';
import { Observable } from 'rxjs';
import { setNewOriginUrl } from 'src/app/_helpers/utility';

@Component({
  selector: 'app-restaurant-type',
  templateUrl: './restaurant-type.component.html',
  styleUrls: ['./restaurant-type.component.css']
})
export class RestaurantTypeComponent extends BaseComponent implements OnInit {
  data: Array<any> = [];
  uniqueCode: Array<string>
  formGroup: FormGroup
  constructor(
    private googleService: GoogleService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private modalService: ModalService,
    public global: Global,
  ) {
    super(global);
   }

  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Description', field: 'description'},
        { headerName: 'Google Unique Code', field: 'googleUniqueCode'},
        { headerName: 'Created At', field: 'createdAt',
          valueFormatter: (params) =>{
           return this.datePipe.transform(params.value, 'medium') 
        }},
        { headerName: 'Url', field: 'url',        
          cellClass: 'img-cell',
          cellRenderer: params => {
            return ` <img height="80" src="${params.value}?m=${Math.random()}" alt="without thumbnail"/>`;
          },
          cellStyle: {'background-color': 'rgba(33, 150, 243, 0.36)'}
        },

        {
          headerName: 'Action',
          minWidth: 120,
          pinned: 'right',
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Restaurant Type Detail')
                break;
              case 'editDetail':
                this.showItem(params?.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
            }
          },
        }
    ];
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }

  ngOnInit() {
    this.setColumnDefs();
    this.getUniqueCode()
  }

  getUniqueCode(){
    this.googleService.getUniqueCode().subscribe(res=>{
      this.uniqueCode = res
    })
  }

  getAll() {
    this.googleService.getGoogleRestaurantType().subscribe(res=>{
      this.data = res.data
    })
  }

  showItem(data?){
    this.genFG(data)
    const dialogTemplate = RestaurantTemplate(!!data, this.uniqueCode)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }


  delete(id){
    this.googleService.deletaRestaurantType(id).subscribe(res=>{
      this.getAll();
      this.toastr.success('Data Delete Successfully...!', 'Success');
    })
  }

  genFG(orgData?){
    if(orgData){
      this.formGroup = new FormGroup({
        description: new FormControl(orgData?.description, Validators.required),
        url: new FormControl(orgData?.url, Validators.required)
      })
    } else {
      this.formGroup = new FormGroup({
        description: new FormControl(undefined, Validators.required),
        url: new FormControl(undefined, Validators.required),
        googleUniqueCode: new FormControl(undefined, Validators.required),
      })
    }
  }

  onSubmit(id){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.getAll()
        this.toastr.success('Data Saved Successfully...!', 'Success');
        subscriber.next()
        subscriber.complete()
      }
      setNewOriginUrl(this.formGroup, 'url')
      id ? this.googleService.updateRestaurantType(this.formGroup.value, id).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          }) : this.googleService.createRestaurantType(this.formGroup.value).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          })
      })
  }


}
