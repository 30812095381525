import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MeshyService {
    httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) { }

    genGlbByImage(data) { 
        return this.http.post(`${environment.apiEndpoint}api/Meshy/ImageTo3d`, data)
    }

    genGlbByTextPreview(data) { 
        return this.http.post(`${environment.apiEndpoint}api/Meshy/TextTo3d/Preview`, data)
    }
    genGlbByTextRefine(id: string) { 
        return this.http.post(`${environment.apiEndpoint}api/Meshy/TextTo3d/Refine`, {textTo3dPreviewTaskId: id})
    }

    getMeshyById(id) { 
        return this.http.get(`${environment.apiEndpoint}api/Meshy/${id}`)
    }

    getMeshy() { 
        return this.http.get(`${environment.apiEndpoint}api/Meshy`)
    }
}
