import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) { }

  getCategory(){
    return this.httpClient.get(`${environment.apiEndpoint}api/Language/Localization/Category`)
  }

  getDetailByCategoryId(categoryId: string){
    return this.httpClient.get(`${environment.apiEndpoint}api/Language/Localization/Get/Category/${categoryId}`)
  }

  deleteLocalizationPhrase(idArray: any){
    return this.httpClient.request<any>('delete',`${environment.apiEndpoint}api/Language/Localization`, {
      body: idArray,
      observe: 'body'
    })
  }

  updateLocalizationPhrase(id, data){
    return this.httpClient.put(`${environment.apiEndpoint}api/Language/Localization/${id}`, data)
  }

  addLocalizationPhrase(data){
    return this.httpClient.post(`${environment.apiEndpoint}api/Language/Localization`, data)
  }

  updateLocalizationCategory(id, data){
    return this.httpClient.put(`${environment.apiEndpoint}api/Language/Localization/Category/${id}`, data)
  }

  getRelease(){
    return this.httpClient.get(`${environment.apiEndpoint}api/Language/Localization/Release`)
  }
  pubilsh(){
    return this.httpClient.post(`${environment.apiEndpoint}api/Language/Localization/Publish`, null)
  }
}
