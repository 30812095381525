import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CampaignService } from 'src/app/_services/campaign.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';

@Component({
  selector: 'app-wave-portal',
  templateUrl: './wave-portal.component.html',
  styleUrls: ['./wave-portal.component.css']
})
export class WavePortalComponent extends BaseComponent implements OnInit {
  data: Array<any>
  waveId: string
  @Output() onFocusEvent = new EventEmitter<any>(); // can remove?
  @Output() onUpdateEvent = new EventEmitter<any>(); // can remove?

  constructor(
      private campaignService: CampaignService,
      public global: Global,
      public modalService: ModalService,
      private route: ActivatedRoute,
      private datePipe: DatePipe,
      private toastr: ToastrService,
  ){
    super(global);
    this.waveId = this.route.snapshot.paramMap.get('id');
    
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id',minWidth: 150},
      { headerName: 'Wave Id', field: 'campaignWaveId',minWidth: 150},
      { headerName: 'Longitude', field: 'longitude',minWidth: 150},
      { headerName: 'Latitude', field: 'latitude',minWidth: 150},
      { headerName: 'Created At', field: 'createdAt',minWidth: 150, valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'short')
      }},
      { headerName: 'Address', field: 'address',minWidth: 150},
      { headerName: 'Icon Url', field: 'iconUrl',minWidth: 150,
        cellClass: 'img-cell',
        cellRenderer: params => {
            return ` <img height="100%" src="${params.value}" alt="without backgroundUrl"/>`;
        },
      },
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return ` 
                    <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>
                    `;
        },

        onCellClicked: params => {
          switch(params.event.target.id){
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Portal Detail')
              break;
          }
        },

      }
  ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }
  
  ngOnInit(){
    this.setColumnDefs();
  }

  getAll(prop?){
    const param = prop? prop : this.waveId? {PageSize: '-1',CampaignWaveId: this.waveId}: {PageSize: '-1'}
    this.campaignService.getAllPortal(param).subscribe(res=>{
        if(res.count === 0){
          return
        }
        this.data = res.data;
        this.onUpdateEvent.emit(res)
    })
  }


  delete(id){
      this.campaignService.deletePortal(id)
      .subscribe(data => {
          this.global.appEvent.next({ msg: 'modalService.close', para: null });
          this.getAll();
          this.toastr.success('Data Deleted Successfully...!', 'Success');
      });
  }

  onRowClicked(ev){
    this.onFocusEvent.emit(ev.data)
  }




}
