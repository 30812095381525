import { Component,Input } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { BaseComponent } from '../base.component';


@Component({
  selector: 'app-infinite-grid',
  templateUrl: './infinite-grid.component.html',
  styleUrls: ['./infinite-grid.component.css']
})
export class InfiniteGridComponent extends BaseComponent {
  @Input() searchSection
  searchCriteria = {}
  sortCriteria
  defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true,
    wrapText: true,
    cellStyle : {'white-space':'nowrap'},
    filterParams: {
      newRowsAction: 'keep'
    },
    headerCheckboxSelectionFilteredOnly: true,
    minWidth: 100
  };
  gridOptions = {
    rowBuffer: 0,
    rowSelection: 'multiple',
    // tell grid we want virtual row model type
    rowModelType: 'infinite',
    // how big each page in our page cache will be, default is 100
    cacheBlockSize: 100,
    // how many extra blank rows to display to the user at the end of the dataset,
    // which sets the vertical scroll and then allows the grid to request viewing more rows of data.
    // default is 1, ie show 1 row.
    cacheOverflowSize: 2,
    // how many server side requests to send at a time. if user is scrolling lots, then the requests
    // are throttled down
    maxConcurrentDatasourceRequests: 1,
    // how many rows to initially show in the grid. having 1 shows a blank row, so it looks like
    // the grid is loading from the users perspective (as we have a spinner in the first col)
    // infiniteInitialRowCount: 1000,
    // how many pages to store in cache. default is undefined, which allows an infinite sized cache,
    // pages are never purged. this should be set for large data to stop your browser from getting
    // full of data
    maxBlocksInCache: 10,
    // debug: true,
  };
  constructor(
    public global: Global
  ) {
    super(global);
  }

  setDatasource(event){
    const dataSource = {
      getRows: (params) => {
        console.log('asking for ' + params.startRow + ' to ' + params.endRow);
        // start with index = 1
        const currentPageIndex = this.agGrid.api.paginationGetCurrentPage()+1
        event.apply(this, [{PageIndex: currentPageIndex, pageSize: 100, ...this.searchCriteria, ...this.sortCriteria}])
        .subscribe((res)=> {
          const rowsThisPage = res.data
          res.totalCount > 0 ? this.gridApi?.hideOverlay() : this.gridApi?.showNoRowsOverlay();
          // to disabeld the last page navigate button, but will support when backend api updated.
          params.successCallback(rowsThisPage, res.totalCount);
        })
      },
    };
    this.agGrid.api.setDatasource(dataSource);
  }

  setSearchCriteria(ev){
    this.searchCriteria = ev
    if(!this.gridApi){
      return
    }
    this.gridApi.paginationGoToFirstPage()
    this.gridApi.purgeInfiniteCache();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  sortChanged(event){
    const shortCol = event.columnApi.getColumnState().find(item=>{
      return !!item.sort
    })
    if(shortCol){
      if(shortCol.sort === "asc"){
        this.sortCriteria =  {Sorts: shortCol.colId}
      } else if(shortCol.sort === "desc"){
        this.sortCriteria =  {Sorts: `${shortCol.colId}_DESC`}
      }
    } else {
      this.sortCriteria = null
    }
  }


}
