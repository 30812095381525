import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mit-nz-form-item',
  templateUrl: './mit-nz-form-item.component.html',
  styleUrls: ['./mit-nz-form-item.component.css']
})
export class MitNzFormItemComponent implements OnInit {
    @Input() label!: string;
    @Input() span!: number;
    @Input() required: boolean
  constructor() { }

  ngOnInit(): void {
  }

}
