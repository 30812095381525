/**
 * Mmita.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders }                           from '@angular/common/http';
import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../environments/environment';
import { buildFormData } from '../_helpers/utility';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }


    apiLanguageGet(){
        return this.httpClient.get<any>(`${environment.apiEndpoint}api/Language`);
    }

    apiLanguageIdDelete(id:string){
        return this.httpClient.delete<any>(`${environment.apiEndpoint}api/Language/${id}`);
    }

    apiLanguageIdPut(id:string, data){
        return this.httpClient.put(`${environment.apiEndpoint}api/Language/${id}`, data)
    }

    apiLanguagePost(data){
        return this.httpClient.post(`${environment.apiEndpoint}api/Language`, data)

    }  

    // language version 
    getLanguageVersion(){
        return this.httpClient.get(`${environment.apiEndpoint}api/Language/Localization/Versions`)
    }

    postLanguageVersion(data){
        const formData = buildFormData(data)
        return this.httpClient.post(`${environment.apiEndpoint}api/Language/Localization/Version`, formData)
    }

    putLanguageVersion(id,data){
        const formData = buildFormData(data)
        return this.httpClient.put(`${environment.apiEndpoint}api/Language/Localization/Version/${id}`, formData)
    }


    deleteLanguageVersion(id){
        return this.httpClient.delete(`${environment.apiEndpoint}api/Language/Localization/Version/${id}`)
    }

    getTranslation(text: string){
        return this.httpClient.get(`${environment.apiEndpoint}api/Language/Translator`,{
            params: {
                text: text
            }
        })
    }
}
