<div class="card">
    <form [formGroup]="searchForm" class="card-body" (ngSubmit)="onSubmit()" ngNativeValidate>
        <div class="point-header-wrapper">
            <h5>
                Search Place
            </h5>
            <div class="search-btn-wrapper">
                <button class="btn btn-info btn-sm" type="button" (click)="clearForm()">Clear</button>
                <button class="btn btn-primary btn-sm" type="submit">Search</button>
            </div>
        </div>
      
   
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <label for="name" class="form-label">Name*</label>
                <input type="text" class="form-control" formControlName="name" required>
            </div>
            <div class="col-lg-3 col-md-6">
                <label for="lat" class="form-label">Lat</label>
                <input type="double" class="form-control" formControlName="lat"  min="-90" max="90">
            </div>
            <div class="col-lg-3 col-md-6">
                <label for="lng" class="form-label">Lng</label>
                <input type="double" class="form-control" formControlName="lng"  min="-180" max="180">
            </div>
            <div class="col-lg-3 col-md-6">
                <label for="radius" class="form-label">Radius</label>
                <input type="number" class="form-control" formControlName="radius" >
            </div>
        </div>
    </form>
</div>
    
