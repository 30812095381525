import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-search-place',
    templateUrl: './search-place.component.html',
    styleUrls: ['./search-place.component.scss'],
  })
  export class SearchPlaceComponent {
    @Output() onSearchEvent = new EventEmitter<any>();
    searchForm = this.formBuilder.group({
        name: '',
        lat: null,
        lng: null,
        radius: null
    });
    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService
    ){}

    onSubmit(){
        if(this.searchForm.value.name == '' || !this.searchForm.value.name){
            this.toastr.error('Please Input search value', 'Error');
            return;
        }
        this.onSearchEvent.emit(this.searchForm.value)
    }

    clearForm(){
        this.searchForm.reset();
    }
  }