import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Global } from 'src/app/_services/global.service';
import { LocalizationService } from 'src/app/_services/localization.service';
import { LocalizationItemBuilder } from '../localization-data.builder';

@Component({
  selector: 'app-localization-item',
  templateUrl: './localization-item.component.html',
  styleUrls: ['./localization-item.component.scss'],
})
export class LocalizationItemComponent implements OnInit {
  orgData: any;
  formGroup: FormGroup;
  categoryList: Array<any> = [];
  rendered: boolean = false;
  updateMode: boolean = false;
  constructor(
    private localizationService: LocalizationService,
    private global: Global
  ) { }

  ngOnInit() {
    this.updateMode = !!this.orgData;
    this.localizationService.getCategory().subscribe((res: Array<any>) =>{
      this.categoryList = res;
      this.formGroup = new FormGroup({
        description: new FormControl(this.orgData? this.orgData.description: undefined),
        us: new FormControl(this.orgData? this.orgData.values['en-US']: undefined),
        cn: new FormControl(this.orgData? this.orgData.values['zh-CN']: undefined),
        hk: new FormControl(this.orgData? this.orgData.values['zh-HK']: undefined),
        categoryId: new FormControl(this.orgData? this.orgData.categoryId: undefined,Validators.required),
      });
      !this.updateMode && this.formGroup.addControl('key',  new FormControl())
      this.rendered = true;
    })
  }

  onSubmit(){
    const localItemData = new LocalizationItemBuilder(this.formGroup.value, this.updateMode);
    if(this.updateMode){
      this.global.appEvent.next({ msg: 'update.localization', para: {id: this.orgData.id, data: localItemData} })
    } else {
      this.global.appEvent.next({ msg: 'add.localization', para: {data: localItemData} })
    }

  }
  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });
  }

}
