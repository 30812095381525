<div style="margin:30px">
    <section>
      <h3 style="text-align:center">Update Partner Asset</h3>
    </section>
    <form ngNativeValidate [formGroup]="formGroup" class="row"  (ngSubmit)="submit()">
        <div class="form-group col-12">
          <label for="applePayProductId">applePayProductId:</label>
          <input type="text" class="form-control" formControlName="applePayProductId" />
        </div>
        <div class="form-group col-12">
            <label for="googlePayProductId">googlePayProductId:</label>
            <input type="text" class="form-control" formControlName="googlePayProductId" />
        </div>
        <div class="col-4">
            <button type="submit" class="btn btn-block btn-primary">Update</button>
          </div>
          <div class="col-4">
            <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
          </div>
        </form>

</div>
