import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StageType } from 'src/app/_models/stageWriteDto';
import { Global } from 'src/app/_services/global.service';
import { StageService } from 'src/app/_services/stage.service';
import { MapTab, PointInfoMode } from 'src/app/common/constants';


@Component({
  selector: 'app-cesium-panel-content',
  templateUrl: './cesium-panel-content.component.html',
  styleUrls: ['./cesium-panel-content.component.css']
})
export class CesiumPanelContentComponent {
  @Input() assetSource: Array<any>
  @Input() assetCategory: Array<any>
  @Input() streamerSubTypeAssetSource: Array<any>
  @Input() vcSourceList: Array<any>
  @Input() streamingEventSource: Array<any>
  @Input() currentFocusOnInfo: any
  @Output() updateLoadedVPSData = new EventEmitter<any>();

  readonly stageTypeList = [ StageType.Normal, StageType.VerticalCarousel,StageType.ReviewBoard, StageType.Streamer];
  readonly MapTab = MapTab
  readonly PointInfoMode = PointInfoMode
  constructor(
    private stageService: StageService,
    private toastrService: ToastrService,
    private global: Global
  ) {
    
   }

  add(info:{data: any, type: string}) {
    this.stageService.add(info.data).subscribe((res: any) => {
      this.currentFocusOnInfo = {...res, type: MapTab.Stage};
      this.updateLoadedVPSData.emit()
      this.toastrService.success(`Add Geospatial Drop Object Successfully...!`, 'Success');
    })
  }

  update(ev: {data: any, type: string}) {
    this.stageService.update(ev.data.info, ev.data.id).subscribe((res:any) => {
      this.currentFocusOnInfo = {...res, type: MapTab.Stage};
      this.updateLoadedVPSData.emit()

      this.toastrService.success(`Update Successfully...!`, 'Success');
    })
  }

  delete(data: {id: string, type:string}){
    this.stageService.delete(data.id).subscribe(res=>{
      this.currentFocusOnInfo = {};
      this.updateLoadedVPSData.emit()
      this.emitGlobalEvent('removeInCesium')
      this.toastrService.success(`Remove Successfully...!`, 'Success');
    })
  }

  emitGlobalEvent(msg){
    this.global.appEvent.next({msg: msg})
  }


}
