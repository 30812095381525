import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CampaignService } from 'src/app/_services/campaign.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { MitTableComponent } from 'src/app/ui-library/dialog/mit-table/mit-table.component';
import { CategoryService } from 'src/app/_services/category.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AntCampaignWaveItemComponent } from './ant-campaign-wave-item/ant-campaign-wave-item.component';

@Component({
  selector: 'app-campaign-wave',
  templateUrl: './campaign-wave.component.html',
  styleUrls: ['./campaign-wave.component.css']
})
export class CampaignWaveComponent extends BaseComponent implements OnInit {
  data: Array<any> = []
  category: Array<any>
  campaignId: string
  campaignInfo: any
  rendered: boolean
  assetSource: Array<any>
  isReleased: string


  constructor(
      private campaignService: CampaignService,
      public global: Global,
      public modalService: ModalService,
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private mmitaAssetService: MmitaAssetService,
      private router: Router,
      private categoryService: CategoryService,
      private datePipe: DatePipe,
      private nzModalService: NzModalService,
  ){
    super(global);
    this.campaignId = this.route.snapshot.paramMap.get('id');
    this.isReleased = this.route.snapshot.paramMap.get('isReleased');
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id'},
      { headerName: 'Campaign Id', field: 'campaignId'},
      { headerName: 'Asset Category Id', field: 'assetCategoryId'},
      { headerName: 'Participants Number', field: 'numberOfUnlockedUser'},
      { headerName: 'Completed User Number', field: 'numberOfCompletedUser'},
      { headerName: 'Voucher Collected Number', field: 'numberOfCouponCollectedUser'},
      { headerName: 'Start At', field: 'startAt', valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'short')
      }},
      { headerName: 'End At', field: 'endAt', valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'short')
      }},

      { headerName: 'AssetIds', field: 'assetIds', autoHeight: true,
        cellRenderer: params => {
          let innerHtml = ''
          params.value.forEach(item=>{
            innerHtml += `<li style="list-style: none;">${item}</li>`
          });
          return innerHtml
      }},
      { headerName: 'Cover', field: 'coverProfileUrl',
        cellClass: 'img-cell',
        cellRenderer: params => {
            return ` <img height="80" src="${params.value}" alt="without backgroundUrl"/>`;
        },
      },
      {
        headerName: 'Action',
        pinned: 'right',
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-arrow-right" id="navigate" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    ${params.data.numberOfUnlockedUser<= 0 ? '': `<i class="fas fa-fw fa-unlock" id="showUnlock" style="margin-right:3px; cursor: pointer;"></i>`}
                    ${params.data.isReleased ? '': `
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`}
                    `;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'navigate':
              this.router.navigateByUrl(`/portal/${params.data.id}`)
              break;
            case 'editDetail':
              this.showItem(params?.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data))
              break;
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Wave Detail')
              break;
            case 'showUnlock':
              this.campaignService.getWaveUnlockUserInfo(params.data.id).subscribe((res: Array<any>)=>{

                this.nzModalService.create({
                  nzContent: MitTableComponent,
                  nzTitle: 'Unlocked Users',
                  nzWidth: '800px',
                  nzFooter: null,
                  nzComponentParams: {
                    data: res.map(item=>{
                      return {...item,
                        id: item.userInfo.id,
                        name: `${item.userInfo.firstName} ${item.userInfo.lastName}`,
                        action: (info)=>{
                          this.modalService.showDetail(item, 'Detail Information')
                      }
                    }
                    }),
                    shownTitle: ['name','numberOfCollectedAssetOfWave', 'isCollectCompleted'],
                    action: true
                  },
                })
              })
              break
          }
        },
         minWidth: 150
      }
  ];
  }

  showItem(data?){
    this.nzModalService.create({
      nzContent: AntCampaignWaveItemComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: 'Set Campaign Wave',
      nzOnOk: ()=>{
        this.getAll()
      },
      nzComponentParams: {
        orgData: data,
        assetSource: this.assetSource,
        category: this.category,
        campaignId: this.campaignId || data?.campaignId
      }
  })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }
  
  ngOnInit(){
    this.setColumnDefs();
    this.campaignId && this.getCampaignInfo()
    this.getCategory()
    this.getAssetSource()
  }

  getAll(){
    this.campaignService.getAllWave(this.campaignId).subscribe(res=>{
        this.data = res.data;
    })
  }


  delete(prop){
      this.campaignService.deleteWave(prop.campaignId, prop.id)
      .subscribe(data => {
          this.getAll();
          this.toastr.success('Data Deleted Successfully...!', 'Success');
      });
  }

  getCampaignInfo(){
    this.campaignService.getCampaignItem(this.campaignId).subscribe(res=>{
      this.campaignInfo = res
      this.rendered = true
    })
  }

  getCategory(){
    this.categoryService.getAssetCategory({AssetCategoryOption: 'CollectableOnMap'}).subscribe(res=>{
      this.category = res.data.map(item=>{
        return {...item, name: item.multilingualNames['en-US'] }
      })
    })
  }

  getAssetSource(){
    this.mmitaAssetService.getAll({AssetType: 'Model3d', AssetCategoryType: 'SystemItems', PageSize: '-1'}).subscribe(res=>{
      this.assetSource = res.data
    })
  }


}

