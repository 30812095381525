import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { CampaignService } from 'src/app/_services/campaign.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { RewardService } from 'src/app/_services/reward.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { ConfirmComponent } from 'src/app/ui-library/dialog/confirm/confirm.component';
import { DailyComponent } from '../daily/daily.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignTemplate } from './campaign-item.constant';
import { setNewOriginUrl } from 'src/app/_helpers/utility';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent extends BaseComponent implements OnInit {
  data: Array<any>
  rewards: Array<any> = []
  coupons: Array<any>
  rendered: boolean = false
  formGroup: FormGroup
  constructor(
      private campaignService: CampaignService,
      public global: Global,
      public modalService: ModalService,
      private router: Router,
      private ngbModal: NgbModal,
      private rewardService: RewardService,
      private datePipe: DatePipe,
      private toastr: ToastrService
  ){
    super(global);
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id'},
      { headerName: 'Name', field: 'name'},
      { headerName: 'Tokens', field: 'numberOfTokensToBuy'},
      { headerName: 'Released', field: 'isReleased'},
      { headerName: 'Released At', field: 'releasedAt', valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'short')
      }},
      { headerName: 'Start At', field: 'startAt', valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'short')
      }},
      { headerName: 'End At', field: 'endAt', valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'short')
      }},
      { headerName: 'Reward', field: 'mmitaRewardId' ,
       valueFormatter: (params) =>{
        const reward = this.rewards.find(item=> item.id === params.value)
        return reward ? reward.description : params.value
     } 
    },
      { headerName: 'Cover', field: 'coverProfileUrl',
        cellClass: 'img-cell',
        cellRenderer: params => {
            return ` <img height="80" src="${params.value}" alt="without backgroundUrl"/>`;
        },
      },
      {
        headerName: 'Action',
        pinned: 'right',
        cellRenderer: params => {
          return `   <i class="fas fa-fw fa-arrow-right" id="navigate" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i> 
                    ${params.data.isReleased? 
                      ` <i class="fas fa-fw fa-cloud-arrow-down text-secondary" id="takeDown" style="margin-right:3px; cursor: pointer;"></i>`
                      : 
                    `
                    <i class="fas fa-fw fa-cloud-arrow-up text-success" id="release" style="margin-right:3px; cursor: pointer;"></i>`}
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>
                    `
                    
                    ;
        },

        onCellClicked: params => {
          switch(params.event.target.id){
            case 'navigate':
              this.router.navigateByUrl(`/wave/${params.data.id}`)
              break;
            case 'editDetail':
              this.showItem(params.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Campaign Detail')
              break;
            case 'release':
            case 'takeDown':
              this.showDialog(params.event.target.id, params)
              break;
          }
        },
         minWidth: 160

      }
  ];
  }

  showDialog(action: string,params){
    let api;
    const modal = this.ngbModal.open(ConfirmComponent, {size: 'sm'})
    modal.componentInstance.confirmMessage = 'Confirm to '+ action
    modal.result.then(res=>{
    if(!res){
      return
    }
    switch(action){
      case 'release':
        api = (id)=>this.campaignService.releaseCampaign(id)
        break;
      case 'takeDown':
        api = (id)=>this.campaignService.takeDownCampaign(id)
        break;
    }
    
    api(params.data.id).subscribe(res=>{
              this.toastr.success(`${action} successfully`);
              this.getAll()
            })
    }).catch()
  }
  showItem(data?){
    this.genFG(data)
    const dialogTemplate = CampaignTemplate(this.coupons,this.rewards)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }
  onSubmit(itemId?){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.getAll()
        this.toastr.success('Data Saved Successfully...!', 'Success');
        subscriber.next()
        subscriber.complete()
      }
      const fileArray = ['coverProfileUrl', 'rewardPreviewUrl', 'rewardIntroductionUrl',
      'rewardDetailUrl', 'menuIconUrl']
      fileArray.forEach(item=>{
        setNewOriginUrl(this.formGroup, item)
      })
      itemId ? this.campaignService.updateCampaign(itemId,this.formGroup.value).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          }) :  this.campaignService.postCampaign(this.formGroup.value).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          })
      })
    }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }
  
  ngOnInit(){
      this.getRewardSource().subscribe(()=>{
        this.setColumnDefs();
        this.rendered = true
      })

  }

  getAll(){
    this.campaignService.getAll().subscribe(res=>{
        this.data = res.data;
    })
  }


  delete(id){
      this.campaignService.deleteCampaign(id)
      .subscribe(data => {
          this.getAll();
          this.toastr.success('Data Deleted Successfully...!', 'Success');
      });
  }

  getRewardSource(){
    return new Observable(subscriber => {
      forkJoin({
        rewards: this.rewardService.getAll(),
        coupons: this.rewardService.getAll({MapRewardType: 'Coupon'})}).subscribe(res=>{
          this.rewards = res.rewards.data
          this.coupons = res.coupons.data
          subscriber.next()
          subscriber.complete()
        })
    })
  }

  showDaily(){
    this.ngbModal.open(DailyComponent, {
      modalDialogClass: 'dialog-wrapper'
    })
  }

  genFG(orgData?){
    this.formGroup = new FormGroup({
      coverProfileUrl: new FormControl(orgData?.coverProfileUrl, Validators.required),
      rewardPreviewUrl: new FormControl(orgData?.rewardPreviewUrl, Validators.required),
      rewardIntroductionUrl: new FormControl(orgData?.rewardIntroductionUrl, Validators.required),
      rewardDetailUrl: new FormControl(orgData?.rewardDetailUrl, Validators.required),
      menuIconUrl: new FormControl(orgData?.menuIconUrl, Validators.required),
      name: new FormControl(orgData?.name, Validators.required),
      description: new FormControl(orgData?.description, Validators.required),
      startAt: new FormControl(orgData?.startAt, Validators.required),
      endAt: new FormControl(orgData?.endAt, Validators.required),
      numberOfTokensToBuy: new FormControl(orgData?.numberOfTokensToBuy || 0, [Validators.min(0), Validators.required]),
      mmitaRewardId: new FormControl(orgData?.mmitaRewardId, Validators.required),
      subMmitaRewardId: new FormControl(orgData?.subMmitaRewardId, Validators.required),
    })
    if(orgData?.isReleased){
      this.formGroup.get('startAt').disable()
      this.formGroup.get('endAt').disable()
      this.formGroup.get('numberOfTokensToBuy').disable()
      this.formGroup.get('mmitaRewardId').disable()
      this.formGroup.get('subMmitaRewardId').disable()
    }
  }

}

