  <section  class="header-btn-wrapper">
    <button type="button" (click)="showLocalizationItemDialog()" class="btn btn-primary add-btn">
      <i class="fas fa-fw fa-plus"></i>
        Add Phrase
    </button>
    <button type="button" (click)="showUpdateCategoryDialog()" class="btn btn-primary">
      <i class="fas fa-fw  fa-edit" ></i>
        Update Category
    </button>
    <button type="button" (click)="publish()" class="btn btn-primary" [ngClass]="{'disabled': !needToPublish}">
      <i class="fas fa-fw  fa-upload" ></i>
        Publish
    </button>
  </section>

    <ul class="nav nav-tabs">
      <li *ngFor="let tab of tabList" class="nav-item">
        <a class="nav-link tab-link" [ngClass]="{'active': currentTabId === tab.id}" (click)="showTabDetail(tab.id)" >
          {{tab.name}}
        </a>
      </li>
  </ul>
  <ag-grid-angular
  #agGrid
  domLayout='autoHeight'
  class="ag-theme-alpine"
  [rowData]="localizationData"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [suppressRowClickSelection]="true"
  [enableCellTextSelection]="true"
  (gridReady)="onGridReady($event)"
  >
</ag-grid-angular>


