import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InfiniteGridComponent } from 'src/app/ui-library/component/infinite-grid/infinite-grid.component';
import { batchDeleteResult } from 'src/app/_helpers/utility';
import { AnchorService } from 'src/app/_services/anchor.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
@Component({
  selector: 'app-anchor',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.scss'],
  providers: [DatePipe]
})
export class AnchorComponent extends InfiniteGridComponent implements OnInit {
  searchSection = [{name: 'Owner UserId', key: 'OwnerUserId'}, {name: 'Id', key: 'Id'}, {name: 'Address Condition', key: 'AddressCondition'}]

  constructor(
      private anchorService: AnchorService,
      private modalService: ModalService,
      public global: Global,
      private toastr: ToastrService,
      private datePipe: DatePipe
  ) {
    super(global)
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'delete.anchor':
          this.delete(para.id);
          break;
        case 'delete.batchAnchors':
          this.deleteBatch(para.id);
          break;
      }
    });
   }

  ngOnInit(){
    this.setColumnDefs();

  }


  ngAfterViewInit(): void {
    this.setDatasource((prop)=>this.anchorService.getAll(prop))
  }

  formatDateTime(params){
    return this.datePipe.transform(params.value, 'short')
  }

  
  setColumnDefs(){
    this.columnDefs = [
      { headerCheckboxSelection: true, checkboxSelection: true, width: 40, sortable:false},
      { headerName: 'ID', field: 'id'},
      { headerName: 'Asset Thumbnail', field: 'asset.smallThumbnail.url', sortable:false,
        cellClass: 'img-cell',
        cellRenderer: params => {
            return params.value ? `<img height="80" src="${params.value}" alt="without backgroundUrl"/>` : `-`;
        },
      },
      { headerName: 'Placement Photo ', field: 'thumbnailOnMapUrl', sortable:false,
        cellClass: 'img-cell',
        cellRenderer: params => {
            return params.value ? `<img height="80" src="${params.value}" alt="without backgroundUrl"/>` : `-`;
        },
      },
      { headerName: 'Address', field: 'multilingualAddresses.en-US', sortable:false},

      { headerName: 'Created Time', field: 'createdAt',
        valueFormatter: this.formatDateTime.bind(this)
      },
      { headerName: 'User E-mail', field: 'owner.email', sortable:false},
      { headerName: 'Asset Type', field: 'asset.assetType', sortable:false},
      { headerName: 'Asset ID', field: 'asset.id', sortable:false},
      { headerName: 'Category', field: 'asset.category.multilingualNames.en-US', sortable:false},
      { headerName: 'Action', sortable:false, pinned: 'right',
        minWidth: 90,
        cellRenderer: params => {
          return ` 
          <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
          <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Anchor Detail')
              break;
          }
        },
    },
  ];
  }

  delete(id){
    this.anchorService.delete(id).subscribe(res=>{
      this.global.appEvent.next({ msg: 'modalService.close', para: null });
      this.gridApi.purgeInfiniteCache();
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    } )
  }

  deleteBatch(ids){
    this.anchorService.batchDelete({ids: ids}).subscribe(res=>{
      this.global.appEvent.next({ msg: 'modalService.close', para: null });
      this.gridApi.deselectAll();
      this.gridApi.purgeInfiniteCache();
      batchDeleteResult(res,this.toastr)
    } )
  }

  onDeleteBatch(){
    const deleteAnchors = this.gridApi.getSelectedRows().map(item=>{
      return item.id
    });
    if(deleteAnchors.length < 1){
      this.toastr.info('Please select anchors', 'Info')
      return;
    } else {
      const msg =  `Are you sure you want to delete ${deleteAnchors.length>1 ? `${deleteAnchors.length} records`: `${deleteAnchors.length} record`}`
      this.modalService.createNzDeleteModal(()=>this.deleteBatch.call(this, deleteAnchors), msg)
    }
  }
}
