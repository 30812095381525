<ng-container *ngFor="let item of formCtrl.value; let i = index; trackBy:trackById">
    <div class="input-array-wrapper" nz-col [nzSpan]="12">
        <input
        class="passenger-input"
        nz-input
        [attr.id]="i"
        [(ngModel)]="formCtrl.value[i]"
        [disabled]="formCtrl.disabled"
        />
        <span
        nz-icon
        nzType="minus-circle-o"
        class="dynamic-delete-button"
        (click)="removeField(i)"
    ></span>
    </div>
</ng-container>
<button nz-col [nzSpan]="12" *ngIf="!formCtrl.disabled" nz-button nzType="dashed" type="button" class="add-button" (click)="addField()">
    <span nz-icon nzType="plus" class="plus-icon"></span>
    Add Item
</button>