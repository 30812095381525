import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const TaskTemplate = (taskIntervalType, rewards, TaskEventSelections): DialogTemplate => {
    return {
        dialogTitle: 'Set Task',
        dialogFields: [
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'isActive',
                label: 'Active',
                type: InputFieldType.Switch,
                size: 12,
            },
            {
                key: 'repeatingIntervalType',
                label: 'Repeating Interval Type',
                type: InputFieldType.Selector,
                size: 12,
                options: taskIntervalType,
                required: true
            },
            {
                key: 'prerequisiteTaskId',
                label: 'Prerequisite Task Id',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'intervalAfterPrerequisiteTask',
                label: 'Interval After Prerequisite Task',
                type: InputFieldType.Selector,
                size: 12,
                options: taskIntervalType,
                required: true
            },
            {
                key: 'mmitaRewardId',
                label: 'Reward ID',
                type: InputFieldType.Selector,
                size: 12,
                options: rewards,
                required: true
            },
            {
                key: 'taskEvent',
                label: 'Task Event',
                type: InputFieldType.Selector,
                size: 12,
                options: TaskEventSelections,
                required: true
            },
        ]
    }
}
