    <section class="header-btn-wrapper">
        <button nz-button nzType="primary" (click)="onDeleteBatch()" nzDanger><i class="fa fa-minus"></i> Delete Batch Anchor</button>
    </section>
    <app-search-form [searchSection]="searchSection" (searchCriteria)="setSearchCriteria($event)"></app-search-form>
    <ag-grid-angular
      #agGrid
      class="ag-theme-alpine"
      [rowHeight]="80"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [suppressRowClickSelection]="true"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
      style="height: 80vh"
      [pagination]="true"
      (sortChanged)="sortChanged($event)"
    >
  </ag-grid-angular>
