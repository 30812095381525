import { Component, ViewChild } from '@angular/core';
import { MmitaAssetService } from '../../_services/mmitaAsset.service'
import { ModalService } from '../../_services/modal.service';
import { CategoryService } from 'src/app/_services/category.service';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';
import { AbilityService } from 'src/app/_services/ability.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SketchfebComponent } from './sketchfeb/sketchfeb.component';
import { InfiniteGridComponent } from 'src/app/ui-library/component/infinite-grid/infinite-grid.component';
import { SearchFieldType } from 'src/app/common/constants';
import { SearchFormComponent } from 'src/app/ui-library/search-form/search-form.component';
import { Observable, forkJoin } from 'rxjs';
import { AssetAbilityTemplate } from './ant-asset-item/asset-item.constant';
import { FormControl, FormGroup } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AntAssetItemComponent } from './ant-asset-item/ant-asset-item.component';
import { GlbGeneratorImageComponent } from './glb-generator-image/glb-generator-image.component';
import { GlbGeneratorTextComponent } from './glb-generator-text/glb-generator-text.component';
@Component({
  selector: 'app-asset',
  templateUrl: './mmitaAsset.component.html',
  styleUrls: ['./mmitaAsset.component.scss'],
})
export class MmitaAssetComponent extends InfiniteGridComponent {
  @ViewChild('SearchForm', { static: false }) SearchForm: SearchFormComponent

  searchSection: Array<any>
  abilityList: Array<any> = [];
  initialCategory: string;
  rendered: boolean
  categoryList
  source
  constructor(
    private assetService: MmitaAssetService,
    private modalService: ModalService,
    private categoryService: CategoryService,
    public global: Global,
    private toastr: ToastrService,
    private abilityService: AbilityService,
    private route: ActivatedRoute,
    private ngbModal: NgbModal,
    private router: Router,
    private nzModalService: NzModalService
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: { msg: string, para: any }) => {
      const { msg, para } = event;
      switch (msg) {
        case 'reload.asset':
          this.loadData()
          break;
      }
    });
    this.initialCategory = this.route.snapshot.paramMap.get('categoryId');
  }
  setColumnDefs() {
    this.columnDefs = [
      { headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 150, sortable: false },
      { headerName: 'ID', field: 'id', },
      {
        headerName: 'Asset Thumbnail', field: 'iconUrl',
        cellClass: 'img-cell', minWidth: 130,
        cellRenderer: params => {
          if (!params.data) return 'not loaded yet';
          return ` <img height="49" src="${params.value}?m=${Math.random()}" alt="without thumbnail"/>`;
        },
      },
      { headerName: 'Name', field: 'name' },
      { headerName: 'Asset Type', field: 'assetType' },
      { headerName: 'Category', field: 'category.multilingualNames.en-US', sortable: false },
      { headerName: 'Status', field: 'assetStatus' },
      {
        headerName: 'File Size(Android)', field: 'androidFile.size', valueFormatter: (params) => {
          return params.value ? `${(params.value / (1024 * 1024)).toFixed(2)} M` : '-'
        }
      },
      {
        headerName: 'File Size', field: 'file.size', valueFormatter: (params) => {
          return params.value ? `${(params.value / (1024 * 1024)).toFixed(2)} M` : '-'
        }
      },
      {
        headerName: 'File Size(Vision Pro)', field: 'visionOSFile.size', valueFormatter: (params) => {
          return params.value ? `${(params.value / (1024 * 1024)).toFixed(2)} M` : '-'
        }
      },
      { headerName: 'Business Type', field: 'businessType' },
      {
        headerName: 'Ability', field: 'abilitiyIds',
        cellRenderer: params => {
          if (!params.data) return 'not loaded yet';
          let abilityHtml = '';
          const abiListLength = params.value.length;
          const abNameArray = this.mapAbilityName(params.value);
          abNameArray.forEach((abi, index) => {
            abilityHtml += `<span>${abi} ${index < abiListLength - 1 ? ',' : ''} </span>`
          })
          return abilityHtml;
        }
      },
      { headerName: 'Category ID', field: 'category.id', sortable: false },
      { headerName: 'Unlock Token Amount', field: 'numberOfTokensUnlocked' },
      { headerName: 'Scale in Map', field: 'scaleInMap' },
      {
        headerName: 'Action',
        sortable: false,
        pinned: 'right',
        minWidth: 160,
        cellRenderer: params => {
          if (!params.data) return 'not loaded yet';
          return `  ${params.data.businessType === 'GameInMarket' ? `<i class="fas fa-fw fa-arrow-right" id="navigate" style="margin-right:3px; cursor: pointer;"></i>` : ``}
                    <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-copy text-primary" id="copyDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="deleteAsset" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch (params.event.target.id) {
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Asset Detail')
              break;
            case 'editDetail':
              this.showItem(params?.data)
              break;
            case 'copyDetail':
              this.showItem(params?.data, true)
              break;
            case 'deleteAsset':
              this.modalService.createNzDeleteModal(() => this.delete.call(this, params?.data?.id))
              break;
            case 'navigate':
              if (!params.data.gameInfo) {
                this.toastr.info('Did not link game info')
                return
              }
              this.router.navigateByUrl(`/product/${params.data.gameInfo.developerId}`)
              break;
          }
        },
      },
    ];
  }

  loadData() {
    this.gridApi.deselectAll();
    this.gridApi.purgeInfiniteCache();
  }


  ngOnInit() {
    this.setColumnDefs();
  }

  ngAfterViewInit() {
    this.setDatasource((prop) => this.assetService.getAll(prop))
    this.getSource().subscribe(source => {
      this.source = source
      this.searchSection = [
        { name: 'Id', key: 'Id' },
        { name: 'Category Name', key: 'CategoryId', type: SearchFieldType.Selector, options: this.categoryList },
        { name: 'Asset Type', key: 'AssetType', type: SearchFieldType.Selector, options: this.global.section?.AssetTypeSelections },
        { name: 'Business Type', key: 'BusinessType', type: SearchFieldType.Selector, options: this.global.section?.AssetBusinessTypeSelections },
        { name: 'Asset Status', key: 'AssetStatus', type: SearchFieldType.Selector, options: ['Active', 'Inactive'] },
        { name: 'Category Type', key: 'AssetCategoryType', type: SearchFieldType.Selector, options: this.global.section?.AssetCategoryTypeSelections },
        { name: 'Category Option', key: 'AssetCategoryOption', type: SearchFieldType.Selector, options: this.global.section?.AssetCategoryOptionsSelections },
        { name: 'File Format', key: 'FileFormat', type: SearchFieldType.Selector, options: this.global.section?.FileFormatSelections },
        { name: 'Name', key: 'Name_Contains', type: SearchFieldType.Text },
      ]
      this.rendered = true
      if (this.initialCategory) {
        setTimeout(() => {
          this.SearchForm.setFormCtrl('CategoryId', this.initialCategory)
        }, 400)
      }
    })
  }


  deleteBatchAssets() {
    const deleteAssets = this.gridApi.getSelectedRows().map(item => {
      return item.id
    });
    if (deleteAssets.length < 1) {
      this.toastr.info('Please select assets', 'Info')
      return;
    } else {
      const msg = `Are you sure you want to delete ${deleteAssets.length > 1 ? `${deleteAssets.length} records` : `${deleteAssets.length} record`}`
      this.modalService.createNzDeleteModal(() => {
        this.assetService.batchDelete({ ids: deleteAssets }).subscribe(res => {
          this.loadData()
          this.toastr.success('Data Deleted Successfully...!', 'Success');
        });
      }, msg)
    }
  }

  updateBatchAssets() {
    const updateAssets = this.gridApi.getSelectedRows().filter(row => {
      return row.assetType === 'Model3d'
    }).map(item => {
      return item.id;
    });
    if (updateAssets.length < 1) {
      this.toastr.info('Please select assets', 'Only Supports Model3d Asset')
      return;
    } else {
      const formGroup = new FormGroup({
        abilityIds: new FormControl([])
      })
      this.modalService.createNzModal(() => this.updateAbility.call(this, updateAssets, formGroup), formGroup, AssetAbilityTemplate(this.abilityList))
    }
  }

  updateAbility(updateAssets, formGroup) {
    return new Observable(sub => {
      this.assetService.batchUpdateAbility({ assetIds: updateAssets, ...formGroup.value }).subscribe(res => {
        this.loadData()
        this.toastr.success(`Update Successfully...!`, 'Success');
        sub.next()
        sub.complete()
      })
    })
  }

  mapAbilityName(idArray: Array<string>) {
    return idArray.map(item => {
      const mapAb = this.abilityList.find(ab => ab.id === item)
      return mapAb ? mapAb.multilingualNames['en-US'] : null
    })
  }


  getAccessToken() {
    this.ngbModal.open(SketchfebComponent, { size: 'lg', centered: true });
  }

  delete(itemId) {
    this.assetService.delete(itemId).subscribe(data => {
      this.loadData()
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    });
  }

  showItem(orgData?, copyMode?, isBatch?) {
    this.nzModalService.create({
      nzContent: AntAssetItemComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: 'Set Asset',
      nzOnOk: () => {
        this.toastr.success('Saved Successfully...!', 'Success');
        this.loadData()
      },
      nzComponentParams: {
        source: this.source,
        data: orgData,
        copyMode: copyMode,
        isBatch: isBatch
      }
    })
  }



  getSource() {
    return new Observable(subscriber => {
      forkJoin({
        categoryList: this.categoryService.getAssetCategory(),
        abilityList: this.abilityService.getAll()
      }).subscribe(res => {
        this.categoryList = res.categoryList.data.map(item => {
          return { ...item, name: item.multilingualNames['en-US'] }
        })
        this.abilityList = res.abilityList.map(item => {
          return { ...item, name: item.multilingualNames['en-US'] }
        });
        const source = {
          abilityList: this.abilityList,
          assetTypeSelections: this.global.section.AssetTypeSelections,
          assetBusinessTypeSelections: this.global.section.AssetBusinessTypeSelections,
          categoryFilterByType: this.mapCategorySource(this.categoryList)
        }
        subscriber.next(source)
        subscriber.complete()
      })
    })
  }

  private mapCategorySource(categoryList) {
    const catForAsset = {}
    this.global.section.AssetTypeSelections.forEach(item => {
      catForAsset[item] = []
    })
    categoryList.forEach(cat => {
      this.global.section.AssetTypeSelections.forEach(item => {
        // End-users allow to upload: maxNumberOfUploadsForCustomer>0
        // Admin allow to upload: maxNumberOfUploadsForCustomer=0
        if (item === cat.assetType && cat.maxNumberOfUploadsForCustomer <= 0) {
          catForAsset[item].push(cat)
        }
      })
    })
    return catForAsset
  }

  open3DGenerator(type: 'Image' | 'Text') {
    const contect = (type === 'Image' ? GlbGeneratorImageComponent : GlbGeneratorTextComponent) as any
    const modalRef = this.nzModalService.create({
      nzContent: contect,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: `Create Asset by ${type}`,
      nzOnCancel: () => {
        this.showConfirmDialog(() => {
          modalRef.close();
        });
        return false; // Prevent the modal from closing immediately
      }
    })

    modalRef.afterClose.subscribe((data) => {
      if (data) {
        this.showItem(data, true, false)
      } else {
        console.log('模态框被取消');
      }
    });
  }

  showConfirmDialog(onOk: () => void) {
    this.nzModalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure you want to leave?',
      nzOkText: 'OK',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        onOk();
      },
      nzOnCancel: () => {
        // Do nothing or handle cancel action if needed
      }
    });
  }


}
