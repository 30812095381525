import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Global } from './_services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isMap
  constructor(
    public router: Router,
    private global: Global,
    ) { 
      this.router.events
          .subscribe(
            (event: any) => {
              if(event instanceof NavigationStart) {
                this.isMap = event.url === '/map'
              }
            });
    }
  isCollapsed = false

  updateCollapsedStatus(ev){
    this.isCollapsed = ev
    this.global.appEvent.next({msg: 'resize.grid'})
  }
  ngOnInit(){
  }
}
