import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { InfiniteResult } from "../_models/body";

@Injectable({ providedIn: 'root' })
export class AnchorService {
    formOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };

    constructor(
        private http: HttpClient
    ){

    }

    getAll(params?){
        return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/ARs/Query`,{
            params: params
        });
    }

    batchDelete(assetsIdList: any){
        return this.http.request<any>('delete',`${environment.apiEndpoint}api/ARs`, {
          body: assetsIdList,
          headers: this.formOptions,
          observe: 'body',
        })
    }

    delete(id: string) {
        return this.http.delete<any>(`${environment.apiEndpoint}api/AR/${id}`);
    }
    

}