import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AstralEscapeDto, MarkerDto, PortalDto } from "src/app/_models/mapDto";
import { ToastrService } from "ngx-toastr";
import { RewardDto } from "src/app/_models/rewardDto";
import { StageDto, StageType } from "src/app/_models/stageWriteDto";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReviewBoardComponent } from "../review-board/review-board.component";
import { CommonService } from "src/app/common/common.service";
import { AssetDialogComponent } from "../asset-dialog/asset-dialog.component";
import { RewardService } from "src/app/_services/reward.service";
import { PortalMarkerComponent } from "../portal-marker/portal-marker.component";
import { IntervalType, MapTab, PointInfoMode } from "src/app/common/constants";
import { uploadImage } from "src/app/_helpers/utility";
import { FileUploadService } from "src/app/_services/file-upload.service";
import { DomSanitizer } from "@angular/platform-browser";
import { isEqual } from 'lodash'
import { Global } from "src/app/_services/global.service";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/_services/modal.service";
import { MmitaAssetService } from "src/app/_services/mmitaAsset.service";
import { CampaignService } from "src/app/_services/campaign.service";
import { PublicMapService } from "../../service/public-map.service";
import { AssetType } from "src/app/_models/AssetType";

  enum MapType {
    Marker = 'Map Objects',
    Stage = 'Geospatial Drop Objects',
      Portal = 'Portal',
      Escape = 'Astral Escape'
  }
@Component({
  selector: 'app-point-info',
  templateUrl: './point-info.component.html',
  styleUrls: ['./point-info.component.scss'],
})
export class PointInfoComponent implements OnInit, OnChanges {
  @Input() currentFocusOnInfo: any;
  @Input() rewards: Array<RewardDto>;
  @Input() tabInfo: any
  @Input() assetCategory: Array<any> = []
  @Input() campaignSource: Array<any> = []

  @Output() onGetNearEvent = new EventEmitter<any>();
  @Output() onRemoveEvent = new EventEmitter<any>();
  @Output() onUpdateEvent = new EventEmitter<any>();
  @Output() onAddPointEvent = new EventEmitter<any>();
  @Output() showMarkers = new EventEmitter<any>();

  @Input() vcSourceList: Array<any>
  @Input() portalSource: Array<any>
  @Input() streamingEventSource: Array<any>
  @Input() streamerSubTypeAssetSource: Array<any>
  @Input() occlusionMeshAssetCategory: Array<any>

  // for cesium
  @Input() pointInfoMode = PointInfoMode.MapMode

  formGroup: FormGroup;
  editMode: boolean = false;
  readonly PointInfoMode = PointInfoMode
  stageTypeList : Array<string>;
  pointType: string;
  waveSource: Array<any> = []
  supportOcclusion: boolean
  readonly SupportPreviewAsset = [StageType.Normal, StageType.Streamer, StageType.OcclusionMesh]

  // for view 
  fileSource: any // for preview image
  previewMediaSource: any
    subscription = new Subscription()
    readonly IntervalType = IntervalType
  constructor(
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private ngbModal: NgbModal,
    private commonService: CommonService,
    private rewardService: RewardService,
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private global: Global,
    private modalService: ModalService,
    private assetService: MmitaAssetService,
    private campaignService: CampaignService,
    private publicMapService: PublicMapService,
    private zone: NgZone
  ) {
    this.subscription.add(
      this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
        const {msg , para} = event;
        switch (msg) {
          case 'setFormCtrl':
            this.setFormCtrl(para)
            break;
          case 'delete.point':
            this.delete(para.id);
            break;
        }
      })
    )
  }


  ngOnInit() {
    this.stageTypeList = this.pointInfoMode == PointInfoMode.MapMode ? Object.values(StageType) : [StageType.Normal];
    this.genFormGroup();
  }

  getPointType(){
    // marker type also will show in portal page
    if(this.pointInfoMode === PointInfoMode.MapMode){
      if(this.editMode){
        return this.currentFocusOnInfo?.type || this.tabInfo?.currentTab
      } else {
        return this.tabInfo?.currentTab
      }
    } else {
      return MapType.Stage
    }
  }

  resetViewData(){
    this.fileSource = null
    this.previewMediaSource = null
  }


  genFormGroup() {
    if (!this.currentFocusOnInfo) {
      return
    }
    this.editMode = !!this.currentFocusOnInfo.id;
    this.pointType = this.getPointType()
    this.resetViewData()
    switch(this.pointType){
      case MapType.Marker:
        this.formGroup = new FormGroup({
          mmitaRewardId: new FormControl(this.currentFocusOnInfo?.mmitaReward?.id, Validators.required),
          latitude: new FormControl(this.currentFocusOnInfo.latitude, Validators.required),
          longitude: new FormControl(this.currentFocusOnInfo.longitude, Validators.required),
          name: new FormControl(this.currentFocusOnInfo.name),
        });
        if(this.editMode){
          this.formGroup.addControl('id', new FormControl({value: this.currentFocusOnInfo.id, disabled: true}, Validators.required));
          this.currentFocusOnInfo.campaignWavePortalId && this.formGroup.addControl('campaignWavePortalId', new FormControl(this.currentFocusOnInfo.campaignWavePortalId))
          this.currentFocusOnInfo.address && this.formGroup.addControl('address', new FormControl(this.currentFocusOnInfo.address))

        }
        break;
      case MapType.Stage:
        this.formGroup = new FormGroup({
          description: new FormControl(this.currentFocusOnInfo.description),
          latitude: new FormControl(this.currentFocusOnInfo.latitude, Validators.required),
          longitude: new FormControl(this.currentFocusOnInfo.longitude, Validators.required),
          scale: new FormControl(isNaN(this.currentFocusOnInfo.scale)? 1 : this.currentFocusOnInfo.scale, [Validators.required, Validators.min(0)]),
          previewAssetId: new FormControl(this.currentFocusOnInfo.previewAssetId),
          altitude: new FormControl(this.currentFocusOnInfo.altitude, Validators.required),
          height: new FormControl(this.currentFocusOnInfo?.height || 0, Validators.required),
          heading: new FormControl(isNaN(this.currentFocusOnInfo.heading) ? -1 :this.currentFocusOnInfo.heading, [Validators.required, Validators.min(-1), Validators.max(360)]),
          stageType: new FormControl(this.currentFocusOnInfo?.stageType || this.tabInfo?.activeStageType || StageType.Normal , Validators.required),
          verticalCarouselId: new FormControl(this.currentFocusOnInfo?.verticalCarouselId),
          reviewBoardPlaceId: new FormControl(this.currentFocusOnInfo?.reviewBoardPlaceId),
          visibleRadius: new FormControl(isNaN(this.currentFocusOnInfo.visibleRadius) ? 50 :this.currentFocusOnInfo.visibleRadius,[Validators.required, Validators.min(0)]),
          withOcclusionMesh: new FormControl(this.currentFocusOnInfo?.withOcclusionMesh === undefined ? true : this.currentFocusOnInfo?.withOcclusionMesh),
          lookAtUser: new FormControl(this.currentFocusOnInfo?.lookAtUser === undefined ? true : this.currentFocusOnInfo?.lookAtUser),

          isGeospatial: new FormControl(this.currentFocusOnInfo?.isGeospatial || false),
          rotationX: new FormControl(this.currentFocusOnInfo?.rotationX || 0),
          rotationY: new FormControl(this.currentFocusOnInfo?.rotationY || 0),
          rotationZ: new FormControl(this.currentFocusOnInfo?.rotationZ || 0),
          isInactive: new FormControl(this.currentFocusOnInfo?.isInactive || false),
          environmentPreviewImageUrl: new FormControl(this.currentFocusOnInfo?.environmentPreviewImageUrl),
          streamEventId: new FormControl(this.currentFocusOnInfo?.streamEventId),
          // for view
          previewAssetName: new FormControl(this.currentFocusOnInfo?.previewAsset?.name || this.currentFocusOnInfo?.previewAssetName ),
          androidUrl: new FormControl(this.currentFocusOnInfo?.previewAsset?.androidUrl || this.currentFocusOnInfo?.androidUrl),
          iconUrl: new FormControl(this.currentFocusOnInfo?.previewAsset?.iconUrl || this.currentFocusOnInfo?.iconUrl ),
        });
          if(this.editMode){
              this.formGroup.addControl('id', new FormControl({value: this.currentFocusOnInfo.id, disabled: true}, Validators.required));
              if(!this.currentFocusOnInfo.isGeospatial){
                this.formGroup.get('height').disable();
                this.formGroup.get('altitude').disable()
              }
            }
          this.supportOcclusion = this.currentFocusOnInfo?.previewAsset?.assetType === AssetType.Image || this.currentFocusOnInfo?.previewAsset?.assetType === AssetType.Video

          setTimeout(()=>{
            if((document.getElementById('file')as any)){
              (document.getElementById('file')as any).value = '';
            }
            this.changeGeospatial(this.currentFocusOnInfo.isGeospatial)
            this.currentFocusOnInfo.isCreatedByDeveloper ? this.formGroup.get('stageType').disable() : this.formGroup.get('stageType').enable();
          }, 400)
          break
        case MapType.Portal:
          this.formGroup = new FormGroup({
            campaignId: new FormControl(this.currentFocusOnInfo.campaignId, Validators.required),
            campaignWaveId: new FormControl(this.currentFocusOnInfo.campaignWaveId, Validators.required),
            latitude: new FormControl(this.currentFocusOnInfo.latitude, Validators.required),
            longitude: new FormControl(this.currentFocusOnInfo.longitude, Validators.required),
          })
          this.onChangeCampaign(true)
            break;
        case MapTab.Escape:
            this.formGroup = new FormGroup({
                interval: new FormControl(this.currentFocusOnInfo.interval || 'Day', Validators.required),
                latitude: new FormControl(this.currentFocusOnInfo.latitude, Validators.required),
                longitude: new FormControl(this.currentFocusOnInfo.longitude, Validators.required),
            }) 
            break

    }
    // for cesium
    this.formGroup.valueChanges.subscribe(res=>{
      this.ref.detectChanges(); // to fix ant validation issue
      this.global.appEvent.next({msg: 'alignMmitaFormValue',para: {...res, id: this.currentFocusOnInfo.id}})
    })
    this.ref.detectChanges(); // to fix show form issue
  }


  
  getVCtName(){
    if(!this.vcSourceList){
      return null
    }
    const item = this.vcSourceList.find(item=> item.id === this.formGroup.controls.verticalCarouselId.value)
    return item? item.description :null
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.currentFocusOnInfo && !changes.currentFocusOnInfo.isFirstChange()){
      this.formGroup = null; // to set formGroup null so that reload ui in html
      setTimeout(()=>{  // to fix ant validation issue
        this.genFormGroup()
      })
    }
  }

  save() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.commonService.showFormInvalid(this.formGroup)
      return;
    }
    switch(this.pointType){
      case MapType.Marker:
        const markerInfo = new MarkerDto(this.formGroup.value);
        if(this.editMode){
          const updateMarker = { info: markerInfo, id: this.currentFocusOnInfo.id};
          this.onUpdateEvent.emit({data: updateMarker, type: MapTab.Marker}) 
        } else {
          this.onAddPointEvent.emit({data: markerInfo, type: MapTab.Marker});
        }
      break;
      case MapType.Stage:
        uploadImage(this.fileUploadService,this.fileSource).subscribe(environmentImg=>{
          const stageInfo = new StageDto(this.formGroup.value, environmentImg, this.formGroup.get('altitude').value);
          if(this.editMode){
            const updateStage = { info: stageInfo, id: this.currentFocusOnInfo.id};
            this.onUpdateEvent.emit({data: updateStage, type: MapTab.Stage}) 
          }else {
            this.onAddPointEvent.emit({data: stageInfo, type: MapTab.Stage});
          }
        })
        break;
        case MapType.Portal:
            const portalInfo = new PortalDto(this.formGroup.value);
            if (this.editMode) {
                const updateStage = { info: portalInfo, id: this.currentFocusOnInfo.id };
                this.onUpdateEvent.emit({ data: updateStage, type: MapTab.Portal })
            } else {
                this.onAddPointEvent.emit({ data: this.formGroup.value, type: MapTab.Portal });
            }
            break;
        case MapTab.Escape:
            const escapeInfo = new AstralEscapeDto(this.formGroup.value)
            if (this.editMode) {
                const updateEscape = { info: escapeInfo, id: this.currentFocusOnInfo.id };
                this.onUpdateEvent.emit({ data: updateEscape, type: MapTab.Escape })
            } else {
                this.onAddPointEvent.emit({ data: this.formGroup.value, type: MapTab.Escape });
            }
    }
  }

  delete(id){
    this.onRemoveEvent.emit({id: id, type: this.pointType})
  }

  showDeleteDialog() {
    this.modalService.commonDelete( this.currentFocusOnInfo.id, 'delete.point');
  }

  checkCoin(){
    this.formGroup.value.numberOfTokens < 0 && this.formGroup.patchValue({numberOfTokens: 0})
  }

  showSearchDialog(){
    this.zone.run(()=>{
      const modal = this.ngbModal.open(ReviewBoardComponent, {size: 'xl'}) 
      modal.result.then(res=>{
        this.formGroup.patchValue({reviewBoardPlaceId: res.googlePlaceId, latitude: res.latitude, longitude:res.longitude})
      }).catch(error=>{
      })
    })
  }

  changeStageType(){
    const currentStageType = this.formGroup.value.stageType
    // only one item in vc list /rb list
    switch(currentStageType){
      case StageType.VerticalCarousel:
      case StageType.ReviewBoard:
        this.assetService.getAll({BusinessType: currentStageType}).subscribe(res=>{
          const data = res.data[0]
          if(data){
            this.formGroup.patchValue({previewAssetId: data.id, iconUrl: data.iconUrl})
          } else{
            this.toastr.warning('no asset in the list')
          }
        })
        break
      default:
        this.formGroup.patchValue({
            previewAssetId: this.currentFocusOnInfo ? this.currentFocusOnInfo.previewAssetId : null,
            iconUrl: this.currentFocusOnInfo.previewAsset ? this.currentFocusOnInfo.previewAsset.iconUrl : null,
            previewAssetName: this.currentFocusOnInfo.previewAsset ? this.currentFocusOnInfo.previewAsset.name : null,
            streamEventId: this.currentFocusOnInfo.streamEventId,})
        break
    }
  }
  showAssetDialog(stageType){
    this.zone.run(()=>{
      const modal = this.ngbModal.open(AssetDialogComponent, {size: 'lg'}) 
      switch(stageType){
        case StageType.Normal:
          modal.componentInstance.assetCategory = this.assetCategory
          break;
        case StageType.Streamer:
          modal.componentInstance.assetList = this.streamerSubTypeAssetSource
          modal.componentInstance.rendered = true
          break;
        case StageType.OcclusionMesh:
          modal.componentInstance.assetCategory = this.occlusionMeshAssetCategory
          break;
        default:
          // when tabInfo is all stage type
          modal.componentInstance.assetCategory = this.assetCategory
          break;
      }
      modal.componentInstance.orgAssetId = this.formGroup.value.previewAssetId
      modal.componentInstance.pointInfoMode = this.pointInfoMode
      modal.result.then((res: any)=>{
        if(res){
          this.formGroup.patchValue({previewAssetId: res.id, previewAssetName: res.name, androidUrl: res.androidUrl, iconUrl:res.iconUrl})
          this.supportOcclusion = res?.assetType !== AssetType.Model3d
        }
      }).catch(error=>{
  
      })
    })
  }

  createPortalMarker(){
    this.rewardService.getAll({CampaignWaveId: this.currentFocusOnInfo.campaignWaveId}).subscribe(res=>{
      const rewardSource = res.data
      if(!rewardSource || !rewardSource.length){
        this.toastr.info('No Reward')
        return;
      }
      const modal = this.ngbModal.open(PortalMarkerComponent, {size: 'lg'}) 
      modal.componentInstance.currentFocusOnInfo = this.currentFocusOnInfo
      modal.componentInstance.orgAssetId = this.formGroup.value.previewAssetId
      modal.componentInstance.rewardSource = rewardSource
      modal.result.then(res=>{
        if(!res || !res.length){
          return;
        }
        this.publicMapService.createMockMarkers(res)
      }).catch(()=>{
        
      })
    })

  }

  changeGeospatial(isGeospatial: boolean){
    if(isGeospatial){
      this.formGroup.get('height').disable();
      this.formGroup.get('altitude').enable()

    } else {
      this.formGroup.get('height').enable();
      this.formGroup.get('altitude').disable()

    }
  }

  onFileChange(e){
    this.fileSource = e.target.files[0]
    this.previewMediaSource = this.fileSource ? this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(this.fileSource)) : null
  }

  removeEnvImg(){
    this.formGroup.patchValue({environmentPreviewImageUrl: ''})
  }

  changeStreamingEvent(){
    const selected =  this.streamingEventSource.find(item=>{
      return item.id === this.formGroup.value.streamEventId
    })
    if(!selected){
      return
    }
    this.formGroup.patchValue({previewAssetId: selected.asset.id, previewAssetName: selected.asset.name, iconUrl: selected.asset.iconUrl})
  }
  
  // for cesium
  setFormCtrl(ev){
    for(let prop in ev){
      if(this.formGroup.get(prop) && !isEqual(this.formGroup.get(prop).value, ev[prop])){
        this.formGroup.get(prop).patchValue(ev[prop])
        if(prop === 'isGeospatial'){
          this.changeGeospatial(ev[prop])
        }
      }
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }

  onChangeCampaign(init?){
    const campaignId = this.formGroup.get('campaignId').value
    if(campaignId){
      this.campaignService.getAllWave(campaignId).subscribe(res=>{
        this.waveSource = res.data
      })
    } else {
      this.waveSource = []
    }
    !init && this.formGroup.patchValue({campaignWaveId: null})
  }


}