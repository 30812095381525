<div class="drawer-header">
    <div class="header point-type-header">
        {{currentTab}}
    </div>
    <ng-content select="[stageTabType]"></ng-content>
</div>

<ng-content select="[search]"></ng-content>
<div class="page-count-wrapper">
    {{data?.length}} Loaded on map / {{totalCount}} Placed
    <button nz-button nzType="primary" nzSize="large" nzDanger nzSize="small" (click)="batchDelete()" [disabled]="gridApi?.getSelectedRows()?.length<1">Batch Delete</button>
</div>
<ag-grid-angular
  #agGrid
  domLayout='autoHeight'
  class="ag-theme-alpine"
  [rowData]="data"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [suppressRowClickSelection]="true"
  [enableCellTextSelection]="true"
  (gridReady)="onGridReady($event)"
  (rowClicked)="onRowClicked($event)"
>
</ag-grid-angular>
  