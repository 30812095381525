import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const SettingTemplate = (settingData, tagList): DialogTemplate =>  {
    return {
        dialogTitle: 'Edit Setting',
        dialogFields: [
            {
                key: 'value',
                label: 'Value',
                type: settingData?.uniCode === 'GameSdkFileUrl' ? InputFieldType.Uploader :InputFieldType.Text,
                size: 24,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 24,
                required: true
            },
            {
                key: 'tag',
                label: 'Tag',
                type: InputFieldType.Selector,
                size: 24,
                required: true,
                options: tagList
            },
        ]
    }
}