import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LanguageCode } from 'src/app/common/constants';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { TemplateService } from 'src/app/_services/template.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Template } from './template-item.constant';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent extends BaseComponent {
  data: Array<any> = [];
  formGroup: FormGroup
  constructor(
    private templateService: TemplateService,
    public global: Global,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
      super(global);
  }
  formatDateTime(params){
    return this.datePipe.transform(params.value, 'short')
  }
  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'ID', field: 'id'},
      { headerName: 'Name', field: 'name'},
      { headerName: 'Type', field: 'type'},
      { headerName: 'languageCode', field: 'languageCode'},
      { headerName: 'url', field: 'url'},
      { headerName: 'createdAt', field: 'createdAt', valueFormatter: this.formatDateTime.bind(this), sort: 'desc'},

      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="edit" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Template Detail')
              break;
            case 'edit':
              this.openItem(params?.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
          }
        },
      },
    ];

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  getAll() {
    this.templateService.getAll()
      .subscribe(res => {
          this.data = res.data;
        });
  }
  
  openItem(data?){
    this.genFG(data)
    const dialogTemplate = Template(!!data, this.global.section.TemplateTypeSelections, LanguageCode)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }

  onSubmit(id){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.getAll()
        this.toastr.success('Data Saved Successfully...!', 'Success');
        subscriber.next()
        subscriber.complete()
      }

      const newUploadedUrl = this.formGroup.get('url').value?.[0]?.origin?.url
      newUploadedUrl && this.formGroup.patchValue({url: newUploadedUrl})
      const api = !!id ? 
      this.templateService.updateById(id, this.formGroup.value) : 
      this.templateService.create(this.formGroup.value)
      api.subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      })
    }) 
  }


  delete(id){
    this.templateService.delete(id).subscribe(res=>{
        this.getAll();
        this.toastr.success('Data Delete Successfully...!', 'Success');
      })
  }

  genFG(orgData){
    this.formGroup = new FormGroup({
      name: new FormControl(orgData?.name, Validators.required),
      type: new FormControl(orgData?.type, Validators.required),
      languageCode: new FormControl(orgData?.languageCode, Validators.required),
      url: new FormControl(orgData?.url, Validators.required)
    })
    orgData && this.formGroup.get('languageCode').disable()
    orgData && this.formGroup.get('type').disable()
  }
}
