    <section class="header-btn-wrapper">
        <button nz-button nzType="primary" (click)="openItem()">
          <i class="fas fa-fw fa-plus"></i> Add Template
        </button>
      </section>
      
    <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowHeight]="80"
    [rowData]="data"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true"

  >
  </ag-grid-angular>
  