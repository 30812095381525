import { Component, ViewChild } from '@angular/core';
import { FaqService } from '../../_services/faq.service'
import { ModalService } from '../../_services/modal.service';
import { Faq } from '../../_models/faq';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent extends BaseComponent{
  faqs: Faq[];
  anchors = [];
  constructor(
    private faqService: FaqService,
    private modalService: ModalService,
    private toastr: ToastrService,
    public global: Global
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'reload.faq':
          this.fetchFaqAll();
          break;
      }
    });
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Order', field: 'order',width: 40},
      { headerName: 'Question', field: 'question',width: 200, wrapText: true},
      { headerName: 'Answer', field: 'answer' ,width: 300, wrapText: true},
      { headerName: 'Language', field: 'languageCode',width: 60 },
      { headerName: 'Type', field: 'type',width: 70 },

      {
        headerName: 'Action',width: 80,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="edit" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'FAQ Detail')
              break;
            case 'edit':
              this.EditFaq(params)
              break;
            case 'delete':
              this.DeleteFaq(params)
              break;
          }
        },
      },
  ];
  }

  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchFaqAll();
  }

  ngOnInit(){
    this.setColumnDefs();
  }
  fetchFaqAll() {
    this.faqService.getAll()
      .subscribe(
        data => {
          this.faqs = data;
        });
  }
  AddFaq() {
    this.modalService.AddFaq();
  }
  DeleteFaq(params) {
    const id = params.data.id
    this.modalService.DeleteFaq(id);
  }
  EditFaq(params) {
    const id = params.data.id
    this.modalService.UpdateFaq(id);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
}
