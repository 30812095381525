import { Component, OnInit, ViewChild } from '@angular/core';
import { MapDataSourceService } from '../map/service/map-data-source.service';
import { MapTab, PointInfoMode } from 'src/app/common/constants';
import { CesiumPanelContentComponent } from './cesium-panel-content/cesium-panel-content.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetDialogComponent } from '../map/component/asset-dialog/asset-dialog.component';
import { StageService } from 'src/app/_services/stage.service';
import { chunk } from 'lodash';
import { Subscription, timer } from 'rxjs'
import { take } from 'rxjs/operators';
import { Global } from 'src/app/_services/global.service';
@Component({
  selector: 'app-cesium',
  templateUrl: './cesium.component.html',
  styleUrls: ['./cesium.component.scss']
})
export class CesiumComponent implements OnInit {
  assetSource: Array<any>
  assetCategory: Array<any>
  streamerSubTypeAssetSource: Array<any>
  vcSourceList: Array<any>
  streamingEventSource: Array<any>
  currentFocusOnInfo = null
  readonly MapTab = MapTab
  @ViewChild('cesiumPanel',{static: false})cesiumPanel: CesiumPanelContentComponent
  lastestUpdateplacementKey: string
  subscription = new Subscription()
  defaultAsset: any
  isPC: boolean
  vpsChunkArray = []
  displayingChunkIdx: number
  updateFlag: boolean = false;
  constructor(
    private dataSourceService: MapDataSourceService,
    private ngbModal: NgbModal,
    private stageService: StageService,
    private global: Global
  ) { 
    this.isPC = this.global.isPC
    this.subscription.add(
      // 监听跨域请求的返回    
      window.addEventListener("message", (event)=>{        
        console.log(event.data)
        switch(event.data.eventName){
          case 'focusOn':
            if(this.lastestUpdateplacementKey && this.lastestUpdateplacementKey === event.data.placementKey){
                this.global.appEvent.next({msg: 'setFormCtrl', para: event.data.prop.data})
            } else {
              this.currentFocusOnInfo = event.data.prop.data
              this.lastestUpdateplacementKey = event.data.placementKey
            }
            break;
          case 'getStage':
            this.getStage(event.data.prop)
            break
          case 'blur':
            this.currentFocusOnInfo = null
            break
        }
      }, false))

      this.subscription.add(
        this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
          const {msg , para} = event;
          switch (msg) {
            case 'alignMmitaFormValue':
            case 'removeInCesium':
              this.postMessage({eventName: msg, value: para})
              break;
          }
        })
      )
  }


  
  getStage(prop){
    // must use pagesize -1 to get data
    this.stageService.getStage({...prop, PreviewAssetSubType: 'Glb', PageSize: -1}).subscribe(res=>{
      this.updateFlag = false
      if(res.count == 0){
        return
      }
      this.vpsChunkArray = chunk(res.data, 10)
      this.changePage(this.displayingChunkIdx)
    })
  }

  changePage(index){
    this.postMessage({eventName: 'removeAll'}) 
    this.displayingChunkIdx = index
    if(this.updateFlag){
      this.postMessage({eventName: 'getBoundary'})
    } else {
      this.postMessage({eventName: 'renderAssets', value: this.vpsChunkArray[index]})
    }
  }

  ngOnInit() {
    this.dataSourceService.getSource({IsStageTypeNormal: true, AssetType: 'Model3d'}).subscribe((res: any)=>{
      this.assetCategory = res.assetCategory.data
      this.vcSourceList = res.vcSourceList.data
      this.streamingEventSource = res.streamingEventSource.data,
      this.streamerSubTypeAssetSource = res.streamerSubTypeAssetSource.data
    })
  }

  postMessage(ev: {eventName: string, value?: any}){
    const iFrame: any = document.getElementById('cesium'); 
    iFrame.contentWindow.postMessage({eventName: ev.eventName, prop: ev.value}, "*");
  }

  loadNearbyVPS(){
    this.postMessage({eventName: 'getBoundary'})
    this.displayingChunkIdx = 0
  }


  showAssetDialog(){
    const modal = this.ngbModal.open(AssetDialogComponent, {size: 'lg'}) 
    modal.componentInstance.assetCategory = this.assetCategory
    modal.componentInstance.pointInfoMode = PointInfoMode.CesiumMode
    modal.result.then((res: any)=>{
      if(res){
        this.postMessage({eventName: 'setPreviewAsset', value: res})
        this.postMessage({eventName: 'placeInCenter'})
        this.defaultAsset = res
      }
    }).catch(error=>{
    })
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }


}


