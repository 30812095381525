import { DialogTemplate, MitFieldInfo } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const StreamingTemplate = (editMode, assetSource, streamerSource,stageSource, StreamChannelStatus, StreamEventStatusSelections): DialogTemplate => {
    return {
        dialogTitle: 'Set Streaming Event',
        dialogFields: editMode ? 
            baseFieldArray(assetSource, streamerSource,stageSource).concat(editModeFields(StreamChannelStatus, StreamEventStatusSelections)):
            baseFieldArray(assetSource, streamerSource,stageSource)
    }
}

const baseFieldArray = (assetSource, streamerSource,stageSource) : Array<MitFieldInfo> =>{
    return [
        {
            key: 'theme',
            label: 'Theme',
            type: InputFieldType.Text,
            size: 12,
            required: true
        },
        {
            key: 'description',
            label: 'Description',
            type: InputFieldType.Text,
            size: 12,
            required: true
        },
        {
            key: 'assetId',
            label: 'Asset',
            type: InputFieldType.Selector,
            size: 12,
            options: assetSource,
            required: true
        },
        {
            key: 'startedAt',
            label: 'Start At: (UTC)',
            type: InputFieldType.DateTimePicker,
            size: 12,
            required: true
        },
        {
            key: 'streamerId',
            label: 'Streamer',
            type: InputFieldType.Selector,
            size: 24,
            options: streamerSource,
            required: true
        },
        {
            key: 'stageIds',
            label: 'Stage IDs',
            type: InputFieldType.Selector,
            size: 24,
            options: stageSource,
            mode: 'multiple'
        },
    ]
}

const editModeFields = (StreamChannelStatus, StreamEventStatusSelections): Array<MitFieldInfo> =>{
    return [
        {
            key: 'status',
            label: 'Channel Status',
            type: InputFieldType.Selector,
            size: 12,
            options: StreamChannelStatus
        },
        {
            key: 'eventStatus',
            label: 'Event Status',
            type: InputFieldType.Selector,
            size: 12,
            options: StreamEventStatusSelections
        },
    ]
}