import { Component, OnInit } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { LocalizationService } from 'src/app/_services/localization.service';
import { ModalService } from 'src/app/_services/modal.service';
import { LocalizationDataBuilder } from './localization-data.builder';

@Component({
  selector: 'app-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss'],
  providers: [LocalizationDataBuilder]
})
export class LocalizationComponent extends BaseComponent implements OnInit {
  tabList: Array<any> = [];
  currentTabId: string;
  localizationData;
  eventSubscription: any;
  needToPublish: boolean = false
  constructor(
    private localizationService: LocalizationService,
    private localizationDataBuilder: LocalizationDataBuilder,
    private modalService: ModalService,
    public global: Global,
    private toastr: ToastrService
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
          case 'delete.localization':
              this.delete(para.id);
              break;
          case 'update.localization':
              this.update(para.id, para.data)
              break;
          case 'add.localization':
              this.add(para.data);
              break;
          case 'update.localization-category':
            this.updateCategory(para.id, para.data)

      }
    });
  }

  ngOnInit() {
    this.setColumnDefs();
    this.checkPubilshState()
  }

  checkPubilshState(){
    this.localizationService.getRelease().subscribe((res: any)=>{
      this.needToPublish = res.needToPublish
    })
  }

  showTabDetail(id){
    this.localizationService.getDetailByCategoryId(id).subscribe(res=>{
      this.currentTabId = id;
      this.localizationData = this.localizationDataBuilder.genData(res);
      this.gridApi.setRowData(this.localizationData)
      this.gridApi && this.gridApi.redrawRows();
    })

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.initPage()
  }

  initPage(){
    this.localizationService.getCategory().subscribe((res: Array<any>) =>{
      this.tabList = res;
      this.showTabDetail(this.tabList[0].id)
    })
  }

  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Key', field: 'key'},
        { headerName: 'Status', field: 'status',cellStyle: this.colTextStyle.bind(this), width:100},
        { headerName: 'en-US', field: 'values.en-US'},
        { headerName: 'Delete', 
        cellRenderer: params => {
          return ` <i class="fas fa-fw fa-trash grid-btn-icon text-danger"></i>`;
        },
        cellStyle: params => {
          return {cursor: 'pointer', display: params.data.id ? 'block': 'none'};
        },
        maxWidth: 100,
        onCellClicked: this.onDelete.bind(this)
    },
    ];
  }

  showLocalizationItemDialog(params?){
    this.modalService.localizationItem(params && params.data);
  }

  onDelete(param){
    this.modalService.commonDelete(param.data.id, 'delete.localization')
  }

  colTextStyle(params){
    switch(params.data.status){
      case 'Released':
        return {color: 'green'};
      case 'Updating':
        return {color: 'orange'};
      case 'Deleting':
        return {color: 'red'};
      case 'JustAdd':
        return {color: '#007bff'};
    }
  }
  delete(id){
    this.localizationService.deleteLocalizationPhrase([id]).subscribe(res=>{
      this.global.appEvent.next({ msg: 'modalService.close', para: null });
      this.showTabDetail(this.currentTabId);
      this.toastr.success('Data Delete Successfully...!', 'Success');
    })
  }

  update(id, data){
    this.localizationService.updateLocalizationPhrase(id, data).subscribe(res=>{
      this.global.appEvent.next({ msg: 'modalService.close', para: null });
      this.showTabDetail(this.currentTabId);
      this.toastr.success('Data Update Successfully...!', 'Success');
    })
  }

  add(data){
    this.localizationService.addLocalizationPhrase(data).subscribe(res=>{
      this.global.appEvent.next({ msg: 'modalService.close', para: null });
      this.showTabDetail(this.currentTabId);
      this.toastr.success('Data Add Successfully...!', 'Success');
    })
  }

  showUpdateCategoryDialog(){
    this.modalService.localizationCategory(this.tabList)
  }

  updateCategory(id, data){
    this.localizationService.updateLocalizationCategory(id, data).subscribe(res=>{
      this.global.appEvent.next({ msg: 'modalService.close', para: null });
      this.initPage();
      this.toastr.success('Data Add Successfully...!', 'Success');
    })
  }

  publish(){
    if(!this.needToPublish){
      return
    }
    this.localizationService.pubilsh().subscribe(res=>{
      this.toastr.success('Pubilsh Successfully...!', 'Success');
      this.checkPubilshState()
      this.showTabDetail(this.currentTabId);
    })
  }

  

}

