import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const RestaurantTemplate = (editMode, uniqueCode): DialogTemplate =>  {
    return {
        dialogTitle: 'Set Review Board Type',
        dialogFields: buildDialogFields(editMode, uniqueCode)
    }
}

const buildDialogFields = (editMode, uniqueCode) =>{
    if(editMode){
        return _dialogFields
    } else {
        return googleUniqueCode(uniqueCode).concat(_dialogFields)
    }
}

const googleUniqueCode = (uniqueCode) => {
    return [
        {
            key: 'googleUniqueCode',
            label: 'Google Unique Code',
            type: InputFieldType.Selector,
            size: 24,
            options: uniqueCode,
            required: true
        }
    ]
}


const _dialogFields: Array<any> = [
    {
        key: 'description',
        label: 'Description',
        type: InputFieldType.Text,
        size: 24,
        required: true
    },
    {
        key: 'url',
        label: 'Image',
        type: InputFieldType.Uploader,
        size: 24,
        required: true
    },
]