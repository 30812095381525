import { AssetType } from "src/app/_models/AssetType";
import { DialogTemplate, MitFieldInfo } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const AssetAbilityTemplate = (abilityList) : DialogTemplate => {
    return{
        dialogTitle: 'Update Assets Abilities',
        dialogFields: [
            {
                key: 'abilityIds',
                label: 'Asset Ability',
                type: InputFieldType.Selector,
                size: 24,
                mode: 'multiple',
                options: abilityList
            },
        ]
    }
}

export const ImageTypeTemplate = (source, isBatch) : Array<MitFieldInfo>  => {
    return [
            {
                key: 'assetType',
                label: 'Asset Type',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.assetTypeSelections,
                required: true
            },
            {
                key: 'categoryId',
                label: 'Category Name',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.categoryFilterByType[AssetType.Image],
                required: true
            },
            {
                key: 'scaleInMap',
                label: 'Scale In Map',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'numberOfTokensUnlocked',
                label: 'Number Of Tokens Unlocked',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'adUrlLinked',
                label: 'Ad Url Linked',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'assetStatus',
                label: 'Asset Status',
                type: InputFieldType.Selector,
                size: 12,
                options: ['Active', 'Inactive'],
                required: true,
                notAllowClear: true
            },
            {
                key: 'businessType',
                label: 'Business Type',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.assetBusinessTypeSelections,
                required: true,
                notAllowClear: true
                
            },
            {
                key: 'tags',
                label: 'Tags',
                type: InputFieldType.Tag,
                size: 24,
            },
            {
                key: 'url',
                label: 'Image File',
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true,
                accept: isBatch? 'image/png, image/jpeg':'image/*',
                mode: isBatch? 'multiple': 'default'
            },
        ]
}

export const VideoTypeTemplate = (source, isBatch) : Array<MitFieldInfo>  => {
    return [
            {
                key: 'assetType',
                label: 'Asset Type',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.assetTypeSelections,
                required: true
            },
            {
                key: 'categoryId',
                label: 'Category Name',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.categoryFilterByType[AssetType.Video],
                required: true
            },
            {
                key: 'scaleInMap',
                label: 'Scale In Map',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'numberOfTokensUnlocked',
                label: 'Number Of Tokens Unlocked',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'adUrlLinked',
                label: 'Ad Url Linked',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'assetStatus',
                label: 'Asset Status',
                type: InputFieldType.Selector,
                size: 12,
                options: ['Active', 'Inactive'],
                required: true,
                notAllowClear: true
            },
            {
                key: 'businessType',
                label: 'Business Type',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.assetBusinessTypeSelections,
                required: true,
                notAllowClear: true
                
            },
            {
                key: 'tags',
                label: 'Tags',
                type: InputFieldType.Tag,
                size: 24,
            },
            {
                key: 'url',
                label: `Video File ${isBatch ? '(files will be matched in order)': ''}`,
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                accept: 'video/*',
                mode: isBatch? 'multiple': 'default'
            },
            {
                key: 'thumbnailUrl',
                label: `Thumbnail File ${isBatch ? '(files will be matched in order)': ''}`,
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true,
                accept: 'image/png, image/jpeg',
                mode: isBatch? 'multiple': 'default'
            },
        ]
}

export const Model3dTypeTemplate = (source, isBatch, requiredVisionOS) : Array<MitFieldInfo>  => {
    return [
            {
                key: 'assetType',
                label: 'Asset Type',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.assetTypeSelections,
                required: true
            },
            {
                key: 'categoryId',
                label: 'Category Name',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.categoryFilterByType[AssetType.Model3d],
                required: true
            },
            {
                key: 'scaleInMap',
                label: 'Scale In Map',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'abilitiyIds',
                label: 'Asset Ability',
                type: InputFieldType.Selector,
                size: 24,
                options: source?.abilityList,
                mode: 'multiple'
            },
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'numberOfTokensUnlocked',
                label: 'Number Of Tokens Unlocked',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'adUrlLinked',
                label: 'Ad Url Linked',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'assetStatus',
                label: 'Asset Status',
                type: InputFieldType.Selector,
                size: 12,
                options: ['Active', 'Inactive'],
                required: true,
                notAllowClear: true
            },
            {
                key: 'businessType',
                label: 'Business Type',
                type: InputFieldType.Selector,
                size: 12,
                options: source?.assetBusinessTypeSelections,
                required: true,
                notAllowClear: true
                
            },
            {
                key: 'tags',
                label: 'Tags',
                type: InputFieldType.Tag,
                size: 24,
            },
            {
                key: 'thumbnailUrl',
                label: `Thumbnail File ${isBatch ? '(files will be matched in order)': ''}`,
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                showPreviewImage: true,
                accept: 'image/png, image/jpeg',
                mode: isBatch? 'multiple': 'default'

            },
            {
                key: 'url',
                label: `IOS File ${isBatch ? '(files will be matched in order)': ''}`,
                type: InputFieldType.Uploader,
                size: 12,
                required: true,
                mode: isBatch? 'multiple': 'default'
            },
            {
                key: 'androidUrl',
                label: `Android File ${isBatch ? '(files will be matched in order)': ''}`,
                type: InputFieldType.Uploader,
                size: 12,
                mode: isBatch? 'multiple': 'default'
            },
            {
                key: 'visionOSUrl',
                label: `VisionOS File ${isBatch ? '(files will be matched in order)': ''}`,
                type: InputFieldType.Uploader,
                size: 12,
                mode: isBatch? 'multiple': 'default',
                required: requiredVisionOS
            },
            {
                key: 'macOSUrl',
                label: `MacOS File ${isBatch ? '(files will be matched in order)': ''}`,
                type: InputFieldType.Uploader,
                size: 12,
                mode: isBatch? 'multiple': 'default'
            },
        ]
}