import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PartnerService } from 'src/app/_services/partner.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PartnerTemplate } from './partner-item.constant';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
  providers: [DatePipe]
})
export class PartnerComponent  extends BaseComponent implements OnInit{
  data
  formGroup: FormGroup
  constructor(
    private partnerService: PartnerService,
    public global: Global,
    private datePipe: DatePipe,
    private modalService: ModalService,
    private router: Router,
    private toastrService: ToastrService
  ) { 
    super(global);
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchAll();
  }

  ngOnInit(){
    this.setColumnDefs();
  }

  formatDateTime(params){
    return this.datePipe.transform(params.value, 'short')
  }


  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id', cellClass: 'id-cell',minWidth: 190},
      { headerName: 'First Name', field: 'firstName'},
      { headerName: 'Last Name', field: 'lastName'},
      { headerName: 'Email', field: 'email'},
      { headerName: 'Created At', field: 'createdAt',valueFormatter: this.formatDateTime.bind(this), minWidth:170},
      { headerName: 'Last Logined At', field: 'lastLoginedAt',valueFormatter: this.formatDateTime.bind(this), minWidth:170},
      { headerName: 'Status', field: 'status'},
      { headerName: 'connectedToStripe', field: 'connectedToStripe'},
      { headerName: 'stripeAccount', field: 'stripeAccount'},
      {
        headerName: 'Action',
        pinned: 'right',
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-cubes" id="navigate" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-dollar-sign" id="checkBill" style="margin-right:3px; cursor: pointer;"></i>
                    `
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Developer Detail')
              break;
            case 'editDetail':
              this.showItem(params?.data)
              break;
              case 'navigate':
                this.router.navigateByUrl(`/product/${params.data.id}`)
                break;
              case 'checkBill':
                this.router.navigateByUrl(`/income/${params.data.id}`)
                break;
          }
        },
        minWidth:120,
      },
  ];
  }

  showItem(data){
    this.genFG(data)
    const dialogTemplate = PartnerTemplate(this.global.section.DeveloperStatusSelections)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }

  onSubmit(id){
    return new Observable(subscriber => {
      const onSuccess = ()=>{
        this.fetchAll();
        this.toastrService.success(`Update Successfully...!`, 'Success');
        subscriber.next()
        subscriber.complete()
      }
      this.partnerService.updateDeveloper(id, this.formGroup.value).subscribe(()=>{
          onSuccess()
      })
  }) 
  }

  fetchAll(){
    this.partnerService.getDevelopers().subscribe((res: InfiniteResult)=>{
      this.data = res.data
    })
  }

  sendEmailToReadyToExpire(){
    this.partnerService.sendEmailToReadyToExpire().subscribe((res)=>{
      this.toastrService.success('set')
    })
  }

  inactiveStageOfPartnerExpired(){
    this.partnerService.inactiveStageOfPartnerExpired().subscribe((res)=>{
      this.toastrService.success('set')
    })
  }

  genFG(data){
    this.formGroup = new FormGroup({
      lastName: new FormControl(data?.lastName,Validators.required),
      firstName: new FormControl(data?.firstName,Validators.required),
      status: new FormControl(data?.status,Validators.required),
      subscriptionExpiredAt: new FormControl(data?.subscriptionExpiredAt),
    });
  }
}
