import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { captureGifThumbnail } from 'src/app/_helpers/utility';
import { MitField, MitFieldInfo } from 'src/app/_models/body';
import { UploadFileDto } from 'src/app/_models/uploadFileDto';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { InputFieldType } from 'src/app/common/constants';
import { uniqBy } from 'lodash'
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
@Component({
  selector: 'app-mit-form',
  templateUrl: './mit-form.component.html',
  styleUrls: ['./mit-form.component.css']
})
export class MitFormComponent implements OnInit {
  readonly InputFieldType = InputFieldType
  readonly Infinity = Infinity
  @Input() formGroup: FormGroup
  @Input() dialogFields: Array<MitFieldInfo>
  @Input() callback
  @Output() closeEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  mitForm: Array<MitField>
  gifThumFlag = false
  private searchSubject = new Subject<{ value: string, field: MitFieldInfo }>();
  constructor(
    private fileUploadService: FileUploadService
  ) {

  }

  ngOnInit(): void {
    this.fieldMapping()
    this.searchSubject.pipe(debounceTime(500)).subscribe(({ value, field }) => {
      this.triggerSelectorSearch(value, field);
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dialogFields?.firstChange) {
      return
    }
    this.fieldMapping()
  }

  fieldMapping() {
    this.mitForm = this.dialogFields.map(item => {
      return {
        ...item,
        formCtrl: this.formGroup.get(item.key)
      } as MitField
    })
  }


  private mapUrlToCtrlName(uploadedArray) {
    const uploaderFields = this.dialogFields.filter(item => item.type === InputFieldType.Uploader)
    if (this.gifThumFlag) {
      uploaderFields.push({ key: 'thumbnailUrl', label: null, size: null, type: InputFieldType.Uploader })
    }
    uploaderFields.forEach(item => {
      const uploadCtrlVal = this.formGroup.get(item.key).value
      if (uploadCtrlVal instanceof Array) {
        const toSetFGCtrlVal = uploadCtrlVal.map(ctrlVal => {
          return uploadedArray.find(uploadedItem => {
            return uploadedItem.name === ctrlVal.guid
          })
        })
        this.formGroup.patchValue({ [item.key]: toSetFGCtrlVal })
      }
    })
  }

  checkValidation() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    const toUpload = this.buildToUploadArray()
    if (toUpload.length > 0) {
      this.fileUploadService.getFileDetail(toUpload, true).subscribe((uploadedArray: Array<any>) => {
        this.mapUrlToCtrlName(uploadedArray)
        this.callback.apply(this).subscribe({
          next: x => this.closeEvent.emit()
        })
      })
    } else {
      this.callback.apply(this).subscribe({
        next: x => this.closeEvent.emit()
      })
    }
  }

  private buildToUploadArray() {
    const uploaderFields = this.dialogFields.filter(item => item.type === InputFieldType.Uploader)
    let uploadArray: Array<UploadFileDto> = []
    uploaderFields.forEach(item => {
      const fgCtrlVal = this.formGroup.value[item.key]
      if (fgCtrlVal instanceof Array) {
        uploadArray = uploadArray.concat(fgCtrlVal)
      }
    })
    const gifThumb = this.genGifThumbnail(uploadArray)
    gifThumb.length > 0 && uploadArray.push(...gifThumb)

    return uniqBy(uploadArray, 'guid')
  }


  genGifThumbnail(uploadArray) {
    const thumb = captureGifThumbnail(uploadArray)
    if (thumb?.length > 0) {
      // only need to support AssetType === Image
      this.gifThumFlag = true
      this.formGroup.get('thumbnailUrl').patchValue(thumb)
    }
    return thumb
  }

  cancel() {
    this.cancelEvent.emit()
  }


  onSearch(value: string, field: MitFieldInfo): void {
    this.searchSubject.next({ value, field });
  }


  triggerSelectorSearch(ev, field: MitFieldInfo) {
    if (!field.selectorSearchEvent) {
      return
    }
    field.selectorSearchEvent.apply(this, [ev]).subscribe(res => {
      field.options = res
    })
  }

  // onEnter(ev: Event, field: MitFieldInfo): void {
  //   ev.preventDefault();
  //   console.log('enter')
  //   this.triggerSelectorSearch(this.searchValue, field);
  // }


  getRequired(field: MitFieldInfo) {
    if (field.requiredEvent) {
      return field.requiredEvent.apply(this)
    }
    return field.required
  }

}
