import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const LanguageTemplate = (editMode): DialogTemplate =>  {
    return {
        dialogTitle: editMode ? 'Update Language' :'Create Language',        
        dialogFields: [
            {
                key: 'order',
                label: 'Order',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'languageCode',
                label: 'Language Code',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'languageName',
                label: 'Language Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'displayName',
                label: 'Display Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'mobileLanguageKey',
                label: 'Mobile Language Key',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'isActive',
                label: 'Active',
                type: InputFieldType.Switch,
                size: 12,
                required: true
            },
            {
                key: 'iosFontAssetUrl',
                label: 'ios Font Asset Url',
                type: InputFieldType.Uploader,
                size: 12,
                showPreviewImage: false
            },
            {
                key: 'androidFontAssetUrl',
                label: 'Android Font Asset Url',
                type: InputFieldType.Uploader,
                size: 12,
                showPreviewImage: false
            },
        ]
    }
}