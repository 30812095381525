import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { CouponService } from 'src/app/_services/coupon.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { RewardService } from 'src/app/_services/reward.service';
import { forkJoin } from 'rxjs';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AntRewardItemComponent } from './ant-reward-item/ant-reward-item.component';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss'],
})
export class RewardComponent extends BaseComponent implements OnInit {
  rewards: Array<any> = [];
  stripeCoupon: Array<any>
  allAssets
  galleryAssets
  preivewAssets
  constructor(
    private rewardService: RewardService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private modalService: ModalService,
    public global: Global,
    private couponService: CouponService,
    private assetService: MmitaAssetService,
    private nzModalService: NzModalService
  ) {
    super(global);
   }
  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Description', field: 'description'},
        { headerName: 'Interval Type', field: 'coolDownIntervalType'},
        { headerName: 'Hoster Type', field: 'hosterType'},
        { headerName: 'Reward Type', field: 'mapRewardType'},
        { headerName: 'Tokens', field: 'numberOfTokens'},
        { headerName: 'Percentage', field: 'percentageOnMap'},
        { headerName: 'Created At', field: 'createdAt',
          valueFormatter: (params) =>{
           return this.datePipe.transform(params.value, 'medium') 
        }},
        { headerName: 'Ratio', field: 'ratioOnMap'},
        { headerName: 'Thumbnail', field: 'previewAsset.smallThumbnailUrl',        
          cellClass: 'img-cell',
          cellRenderer: params => {
            return params.value ? ` <img height="80" src="${params.value}?m=${Math.random()}" alt="without thumbnail"/>` : `-`;
          }
        },
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 120,
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Reward Detail')
                break;
              case 'editDetail':
                this.showItem(params?.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
            }
          },
        }
    ];
  }

  getCoupon(){
    this.couponService.getAll().subscribe(res=>{
      this.stripeCoupon = res.data.map(item=>{
        return {...item, name: item.couponName}
      })
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getReport();
  }

  ngOnInit() {
    this.setColumnDefs();
    this.getCoupon()
    this.getAsset()
  }
  getReport() {
    this.rewardService.getAll().subscribe(res=>{
      this.rewards = res.data
    })
  }

  delete(id){
    this.rewardService.delete(id).subscribe(res=>{
      this.getReport();
      this.toastr.success('Data Delete Successfully...!', 'Success');
    })
  }

  showItem(orgData?){
    this.nzModalService.create({
      nzContent: AntRewardItemComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: 'Set Reward',
      nzOnOk: ()=>{
        this.toastr.success('Saved Successfully...!', 'Success');
        this.getReport()
      },
      nzComponentParams: {
        allAssets: this.allAssets,
        data: orgData,
        galleryAssets: this.galleryAssets,
        preivewAssets: this.preivewAssets,
        stripeCoupon: this.stripeCoupon
      }
    })
  }
 

  getAsset(){
    forkJoin({
      allAssets: this.assetService.getAll({PageSize: '-1'}),
      galleryAssets: this.assetService.getMapPlaceAsset('AssetCategoryType=Gallery&AssetCategoryOption=CollectableOnMap&AssetStatus=Active'),
      systemAssets: this.assetService.getMapPlaceAsset('AssetCategoryType=SystemItems&AssetStatus=Active')}).subscribe(res=>{
        this.allAssets = res.allAssets.data
        this.galleryAssets = res.galleryAssets.data
        this.preivewAssets = [...res.galleryAssets.data, ...res.systemAssets.data]
    })
  }

}
