<div class="dialog-wrapper container">
    <h5 class="header">
        Select Asset
    </h5>

    <select *ngIf="assetCategory" class="form-select" [(ngModel)]="activeCatId" (change)="getCategoryAssets(activeCatId)">
        <option *ngFor="let nav of assetCategory" [value]="nav.id">{{nav.multilingualNames['en-US']}}</option>
    </select>

    <section *ngIf="rendered" class="asset-wrapper container">
        <ng-container *ngIf="assetList && assetList.length;else noAsset">
            <div class="row">
                <div *ngFor="let item of assetList" class="col-lg-3 col-6 asset-item" (click)="focusOnAsset(item)">
                    <img [src]="item.smallThumbnailUrl" alt="" class="asset-img" [ngClass]="{'focus-on': focusOnAssetInfo && item.id === focusOnAssetInfo.id}">
                </div>
            </div>
        </ng-container>
        <ng-template #noAsset>
            <div class="notice">
                No Exist Model
            </div>
        </ng-template>
    </section>
    <div style="text-align: center; padding: 10px 0;">
        <button type="button" nz-button nzType="primary" (click)="modalClose()" nzDanger style="margin-right: 5px;">Close</button>
        <button type="button" nz-button nzType="primary" (click)="setAsset()">Set</button>
    </div>
        
</div>


