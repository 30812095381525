import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { mapRandom } from 'src/app/_helpers/utility';
import { MapService } from 'src/app/_services/map.service';

@Component({
  selector: 'app-portal-marker',
  templateUrl: './portal-marker.component.html',
  styleUrls: ['./portal-marker.component.css']
})
export class PortalMarkerComponent implements OnInit {
  currentFocusOnInfo: any
  randomLevel: number = 3
  readonly rewardSource: Array<any>

  formArray: Array<FormGroup> = []
  constructor(
    private mapService: MapService,
    private toastrService: ToastrService,
    private ngbActiveModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.genFormArray()
  }

  genFormArray(){
    this.rewardSource.forEach((item,index)=>{
      this.formArray.push(new FormGroup({
        googlePlaceId: new FormControl(),
        name: new FormControl(),
        address: new FormControl(),
        longitude: new FormControl(this.currentFocusOnInfo.longitude + mapRandom(this.randomLevel)),
        latitude: new FormControl(this.currentFocusOnInfo.latitude + mapRandom(this.randomLevel)),
        campaignWavePortalId: new FormControl(this.currentFocusOnInfo.id),
        mmitaRewardId: new FormControl(item.id),
      }))
    })
  }

  setRandom(){
    this.formArray = []
    this.genFormArray()
  }

  save(){
    const data = this.formArray.map(item => item.value)
    this.mapService.batchAddMarker(data).subscribe(res=>{
      this.toastrService.success(`Add ${res.length} markers`)
      this.ngbActiveModal.close(res)
    })
  }
}
