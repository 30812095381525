    <section class="header-btn-wrapper">
        <button nz-button nzType="primary" nzDanger (click)="resetAll()" class="btn-item">Reset All</button>
        <button nz-button (click)="wallPostCalculation()" class="btn-item">Refresh The Wall Sorting</button>
        <div class="filter-wrapper">
          <nz-select style="width: 100%;" nzPlaceHolder="Filter by Tag" (ngModelChange)="setFilterModel($event)" [(ngModel)]="tagType" nzAllowClear>
            <nz-option *ngFor="let item of tagList" [nzLabel]="item" [nzValue]="item"></nz-option>
          </nz-select>
        </div>
    <nz-divider></nz-divider>
    <app-search-form [searchSection]="searchSection" (searchCriteria)="setSearchCriteria($event)"></app-search-form>

    </section>
    <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="settings"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true"

  >
  </ag-grid-angular>
  
  