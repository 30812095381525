import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GridAction } from 'src/app/common/constants';
import { PartnerService } from 'src/app/_services/partner.service';

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.css']
})
export class LinkAccountComponent implements OnInit {
  orgData: any
  formGroup: FormGroup;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private partnerService: PartnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    const info = this.orgData.gameInfo ? this.orgData.gameInfo: this.orgData.modelInfo
    this.formGroup = new FormGroup({
      applePayProductId: new FormControl(info.applePayProductId),
      googlePayProductId: new FormControl(info.googlePayProductId)
    })
  }

  modalClose(){
    this.ngbActiveModal.close()
  }

  submit(){
    this.partnerService.updateAssetPayId(this.orgData.id,this.formGroup.value).subscribe(()=>{
      this.toastr.success('Update Successfully...!', 'Success');
      this.ngbActiveModal.close(GridAction.REFRESH)
    })
  }


  

}
