import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PartnerService } from 'src/app/_services/partner.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarkDownTemplate } from './markdown-item.constant';
import { Observable } from 'rxjs/internal/Observable';
import { setNewOriginUrl } from 'src/app/_helpers/utility';

@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss']
})
export class MarkdownComponent extends BaseComponent implements OnInit{
  data
  initialProduct: string
  formGroup: FormGroup
  constructor(
    private partnerService: PartnerService,
    public global: Global,
    private modalService: ModalService,
    private toastr: ToastrService
  ) { 
    super(global);
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchAll();
  }

  ngOnInit(){
    this.setColumnDefs();
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id'},
      { headerName: 'Name', field: 'name'},
      { headerName: 'Unique Key', field: 'uniqueKey'},
      { headerName: 'Order', field: 'order'},
      { headerName: 'Description', field: 'description'},
      { headerName: 'MD Type', field: 'mdType'},
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Mark Down Detail')
              break;
            case 'editDetail':
              this.showMarkDownItem(params.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
          }
        },
      }
    ];
    }

  fetchAll(){
    this.partnerService.getMarkDown().subscribe((res: InfiniteResult)=>{
      this.data = res.data
    })
  }

  delete(id){
    this.partnerService.deleteMarkDown(id).subscribe(()=>{
      this.fetchAll()
    }, )
  }

  showMarkDownItem(data?){
    this.genFG(data)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, MarkDownTemplate)
  }

  onSubmit(id){
    return new Observable(subscriber => {
      setNewOriginUrl(this.formGroup, 'url')
      if(id){
        this.partnerService.updateMd(id, this.formGroup.value).subscribe(()=>{
          this.toastr.success('Update Successfully...!', 'Success');
          this.fetchAll()
          subscriber.next()
          subscriber.complete()
        })
      } else {
        this.partnerService.addMd(this.formGroup.value).subscribe(()=>{
          this.toastr.success('Add Successfully...!', 'Success');
          this.fetchAll()
          subscriber.next()
          subscriber.complete()
        })
      }
    })
  }

  genFG(data?){
    this.formGroup = new FormGroup({
      mdType: new FormControl(data?.mdType, Validators.required),
      url: new FormControl(data?.url, Validators.required),
      description: new FormControl(data?.description, Validators.required),
      name: new FormControl(data?.name, Validators.required),
      order: new FormControl(data?.order, Validators.required),
      uniqueKey: new FormControl(data?.uniqueKey, Validators.required)
    });
    data && this.formGroup.get('uniqueKey').disable()
  }
}
