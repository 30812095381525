import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { SettingDto } from '../_models/settingDto';

@Injectable({ providedIn: 'root' })
export class SettingService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll(params?) {
    return this.http.get<SettingDto[]>(`${environment.apiEndpoint}api/Setting`, { params: params });
  }

  getById(id: string) {
    return this.http.get<SettingDto>(`${environment.apiEndpoint}api/Setting/${id}`);
  }

  insertNew(setting) {
    return this.http.post<SettingDto>(`${environment.apiEndpoint}api/Setting`, setting, this.httpOptions)
  }

  updateById(setting: SettingDto, id: number) {
    return this.http.put<SettingDto>(`${environment.apiEndpoint}api/Setting/${id}`, setting, this.httpOptions)
  }

  delete(id: string) {
    return this.http.delete<SettingDto>(`${environment.apiEndpoint}api/Setting/${id}`);
  }

  resetAll() {
    return this.http.delete<any>(`${environment.apiEndpoint}api/Setting/ResetAll`);
  }

  getSettingTag(){
    return this.http.get<SettingDto>(`${environment.apiEndpoint}api/Setting/Tags`);
  }

  getByGroup(){
    return this.http.get<SettingDto>(`${environment.apiEndpoint}api/Setting/GroupByTags`);
  }

  wallPostCalculation(){
    return this.http.post<SettingDto>(`${environment.apiEndpoint}api/Setting/WallPostCalculation`, null);
  }

  getSection(){
    return this.http.get<SettingDto>(`${environment.apiEndpoint}api/Selection`);
  }
}
