import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAllStripeSetting(){
    return this.httpClient.get(`${environment.apiEndpoint}api/Stripe/Setting/Query`,{
      params:{
        'PageSize': '-1'
      }
    })
  }

  addSetting(body){
    return this.httpClient.post(`${environment.apiEndpoint}api/Stripe/Setting`, body)
  }

  updateSetting(body, id){
    return this.httpClient.put(`${environment.apiEndpoint}api/Stripe/Setting/${id}`, body)
  }

  deleteSetting(id){
    return this.httpClient.delete(`${environment.apiEndpoint}api/Stripe/Setting/${id}`)
  }

  getStripeAccount(){
    return this.httpClient.get<Array<any>>(`${environment.apiEndpoint}api/Stripe/Accouts`)
  }

  getStripeCoupon(accountId){
    return this.httpClient.get<Array<any>>(`${environment.apiEndpoint}api/Stripe/Accout/${accountId}/Coupons`)
  }
}
