import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteResult } from 'src/app/_models/body';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { PointInfoMode } from 'src/app/common/constants';

@Component({
  selector: 'app-asset-dialog',
  templateUrl: './asset-dialog.component.html',
  styleUrls: ['./asset-dialog.component.scss']
})
export class AssetDialogComponent implements OnInit {
  readonly assetCategory: Array<any>
  readonly orgAssetId: string
  focusOnAssetInfo: any
  assetList: Array<any>
  rendered: boolean  = false
  pointInfoMode: PointInfoMode
  activeCatId: string

  constructor(
    private mmitaAssetService: MmitaAssetService,
    private ngbActiveModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    if(this.assetCategory){
      if(this.orgAssetId){
        this.mmitaAssetService.getById(this.orgAssetId).subscribe(res=>{
          this.focusOnAssetInfo = res
          this.activeCatId = res.category.id
          this.getCategoryAssets(this.activeCatId)
        })
      } else {
        this.activeCatId = this.assetCategory[0].id
        this.getCategoryAssets(this.activeCatId)
      }
    } else {
      this.focusOnAssetInfo = this.assetList.find(item=>{
        return item.id === this.orgAssetId
      })
    }
  }

  getCategoryAssets(categoryId){
    this.rendered = false
    const param = this.pointInfoMode === PointInfoMode.CesiumMode ? {CategoryId: categoryId, PageSize: '-1', FileFormat: 'glb'} : {CategoryId: categoryId, PageSize: '-1'} 
    this.mmitaAssetService.getAll(param).subscribe((res: InfiniteResult)=>{
        this.assetList = res.data
        this.rendered = true
    })
  }

  focusOnAsset(info){
    this.focusOnAssetInfo = info
  }

  setAsset(){
    this.ngbActiveModal.close(this.focusOnAssetInfo)
  }

  modalClose(){
    this.ngbActiveModal.close()
  }

}
