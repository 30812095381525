import { Component, Injector, OnInit, Input, OnDestroy, ViewChild, Directive } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { Subscription } from 'rxjs';
import { Global } from 'src/app/_services/global.service';
@Directive()
export class BaseComponent implements OnDestroy{
    public eventSubscription: Subscription;
    columnDefs = [];
    defaultColDef = {
       sortable: true,
       filter: true,
       resizable: true,
       wrapText: true,
       cellStyle : {'white-space':'nowrap'},
        //  autoHeight: true,
        //  flex: 1,
        filterParams: {
         newRowsAction: 'keep'
        },
        //  autoHeight: true,  // for asset category
        headerCheckboxSelectionFilteredOnly: true,
        minWidth: 100
     };
    gridApi;
    pageSize: number = this.global.pageSize
  @ViewChild('agGrid', {static: false}) agGrid: AgGridAngular;

  constructor(
    public global: Global
  ){
    this.global.appEvent.subscribe((res: any)=>{
      if(res && res.pagesize){
        this.pageSize = this.global.pageSize = res.pagesize
        this.agGrid.api.paginationSetPageSize(this.pageSize)
      } else if(res && res.msg === 'resize.grid'){
        this.resize()
      }
    })

  }

  ngAfterViewInit(){
    this.agGrid && this.agGrid.api.paginationSetPageSize(this.pageSize)

  }

  resize(){
    setTimeout(()=>{
      this.agGrid && this.agGrid.api.sizeColumnsToFit()
    }, 200)
  }





  ngOnDestroy(){
    this.eventSubscription && this.eventSubscription.unsubscribe();
  }
}
