export class ImageCarousel {
  imgUrl: string;
  isActive: boolean;
  order: number;
  languageCode?: string;
  constructor(data){
    if(!data){
      return;
    }
    this.imgUrl = data.imgUrl;
    this.isActive = data.isActive;
    this.order = data.order;
    this.languageCode = data.languageCode
  }
}
