<section class="button-wrapper">
    <div class="btn-group" role="group">
        <button type="button" class="btn btn-primary btn-sm" (click)="showAssetDialog()">
            <ng-container *ngIf="defaultAsset; else defaultText">
                Default Asset:
                <img [src]="defaultAsset.iconUrl" class="thumbnail"/>
            </ng-container>
            <ng-template #defaultText>Set Default Asset</ng-template>
        </button>
        <button *ngIf="defaultAsset" type="button" class="btn btn-primary btn-sm" (click)="postMessage({eventName: 'placeInCenter'})"><i class="fas fa-fw fa-plus"></i></button>
      </div>
      <div class="btn-group" role="group">
        <button type="button" class="btn btn-primary btn-sm" (click)="loadNearbyVPS()">Load Nearby VPS</button>
        <ng-container *ngIf="vpsChunkArray.length" >
          <button type="button" class="btn btn-sm btn-primary" nz-dropdown [nzDropdownMenu]="menu">
            Displaying Batch[{{displayingChunkIdx}}]
          </button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item *ngFor="let item of vpsChunkArray;index as i" (click)="changePage(i)">{{i}}</li>
            </ul>
          </nz-dropdown-menu>
        </ng-container>
        </div>
</section>


<iframe id="cesium" src="/assets/cesium/index.html" frameborder="0" style="width: 100%; height: calc(100vh - 120px)"></iframe>
<app-mit-off-canvas 
    *ngIf="currentFocusOnInfo"
    >
    <app-cesium-panel-content content
    #cesiumPanel 
    [assetCategory]="assetCategory"
    [vcSourceList]="vcSourceList"
    [streamingEventSource]="streamingEventSource"
    [streamerSubTypeAssetSource]="streamerSubTypeAssetSource"
    [currentFocusOnInfo]="currentFocusOnInfo"
    (updateLoadedVPSData)="updateFlag = true"
    ></app-cesium-panel-content>
</app-mit-off-canvas>