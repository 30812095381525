import { Injectable } from '@angular/core';
import { LoaderOptions, Loader } from 'google-maps';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Global } from 'src/app/_services/global.service';
import { MarkerList } from './map.data';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  map: any
  googlePlaceService: any
  elevationService: any
  google
  infoWindow
  readonly options: LoaderOptions = {libraries: ['geometry','places']}
  readonly GoogleMaploader = new Loader('AIzaSyCDfjSu4kzFtYvXrP1Mwl1TLrWYve1xmqc', this.options);
  markerArray: Array<any> = [];

  constructor(
    private toastr: ToastrService,
    public global: Global,
    private spinner: NgxSpinnerService
    ) { 
  }

  loadMap(lastPosition){
    return new Observable(subscriber => {
      this.GoogleMaploader.load().then((google)=>{
        this.google = google
        this.map = new google.maps.Map(document.getElementById('MapEl'), {
          center: lastPosition ? lastPosition : new google.maps.LatLng(22.33609, 114.175887),
          zoom: localStorage.getItem('mapZoom') ? parseInt(localStorage.getItem('mapZoom')) : 14,
          mapTypeControl: false
        });
        this.googlePlaceService = new google.maps.places.PlacesService(this.map);
        this.elevationService = new google.maps.ElevationService();
        this.infoWindow = new google.maps.InfoWindow();
        this.mapClickListenHandler()
        this.detectSearchPlaceField()
        this.storeLastLocation()
        subscriber.next(this.map)
        subscriber.complete()
      }).catch(error=>{
        subscriber.error(error)
      })
    })
  }


  mapClickListenHandler() {
    // Configure the click listener.
    this.map.addListener("click", (mapsMouseEvent) => {
      // Close the current InfoWindow.
      this.mapClick(mapsMouseEvent).subscribe(res=>{
        this.global.appEvent.next({msg: 'show-detail', para: res})
      })
    });
  }


  private getElevation(latLng){
    return new Observable(subscriber => {
      this.elevationService.getElevationForLocations({
        locations: [latLng],
      }).then((res) => {
        if(res?.results){
          subscriber.next(res?.results[0].elevation)
        } else {
          subscriber.next(0)
        }
        subscriber.complete();
      }, error =>{
        subscriber.next(0)
        subscriber.complete();
      })
    }) 
  }

  private storeLastLocation() {
    this.map.addListener("center_changed", () => {
      localStorage.setItem('lastLocation', JSON.stringify(this.map.getCenter()));
      localStorage.setItem('mapZoom', this.map.getZoom());
    })
  }


  mapClick(mapsMouseEvent){
    return new Observable(subscriber => {
      const latLng = mapsMouseEvent.latLng.toJSON();
      this.getElevation(latLng).subscribe(altitude=>{
        this.infoWindow.close();
        this.infoWindow.setPosition(mapsMouseEvent.latLng);
        this.infoWindow.setContent(
          `${JSON.stringify(latLng)},"Alt: ${altitude}"`)
        this.infoWindow.open(this.map);
        subscriber.next({
          latitude: latLng.lat,
          longitude: latLng.lng,
          altitude: altitude
        })
        subscriber.complete()        
      })
    })
  }

  genAssetMarkerItem(item, showIconUrl){
    const marker = new this.google.maps.Marker({
      position: { lat: item.latitude, lng: item.longitude },
      map: this.map,
      title: item?.name,
      label: item?.group?.displayName,
      icon: showIconUrl && item?.iconUrl && {
        // url: showIconUrl ? item.iconUrl : null, 
        url: item?.iconUrl || null,
        // iconUrl 50x50
        size: new google.maps.Size(50, 50),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole.
        anchor: new google.maps.Point(25, 25),
    },
      id: item?.id
    });
    marker.addListener("click", (ev: {keepCanvasMode: boolean}) => {
      this.global.appEvent.next({msg: 'trigger.infoWindow', para: { marker: marker, item: item, infoWindow: this.infoWindow, infoWindowLinkTo:marker , keepCanvasMode: ev.keepCanvasMode}})
    });
    this.markerArray.push(marker)
    return marker
  }

  private createMarker(place: any, markerIndex: number, setCenter = true) {
    if (!place.geometry || !place.geometry.location) return;
    const marker = new this.google.maps.Marker({
      map: this.map,
      position: place.geometry.location,
    });
    setCenter && this.map.setCenter(place.geometry.location);
    this.google.maps.event.addListener(marker, "click", () => {
      this.infoWindow.close()
      this.infoWindow.setContent(`#${markerIndex + 1}: ` + (place.name || ""));
      this.infoWindow.setPosition(marker.position);
      this.infoWindow.open(this.map);
    });
    return marker
  }


  searchByText(ev) {
    this.spinner.show()
    return new Observable(subscriber => {
      const location = ev.lat || ev.lng? new this.google.maps.LatLng(ev.lat, ev.lng) : this.map.getCenter()
      const request = {
        location: location,
        radius: ev.radius ? ev.radius: '5000',
        query: ev.name
      };
      const callback = (results, status) => {
        if(status == google.maps.places.PlacesServiceStatus.OK){
          const res: SearchResult = this.genMarkerListRecommendedList(results, true)
          this.toastr.success('Search succeeded')
          this.markerArray.push(res.markerArray);
          this.spinner.hide()
          subscriber.next(res)
          subscriber.complete()
        } else {
          this.spinner.hide()
          this.toastr.error('search failed')
          subscriber.error()
        }
      }
      this.googlePlaceService.textSearch(request, callback);
    })

  }


  
  getNearBy(currentFocusOnInfo) {
    this.spinner.show()
    return new Observable(subscriber => {
      const location = currentFocusOnInfo? new this.google.maps.LatLng(currentFocusOnInfo.latitude, currentFocusOnInfo.longitude) : this.map.getCenter()
      const request = {
        location: location,
        radius: 500,
        elevator: this.google.maps.ElevationService(),
      };
      const callback = (results, status) => {
        if(status == google.maps.places.PlacesServiceStatus.OK){
          const res:SearchResult= this.genMarkerListRecommendedList(results, false)
          this.toastr.success(`return ${results.length} record${results.length>1 ?`s`:``}`,'Search succeeded')
          this.markerArray.push(res.markerArray);
          this.spinner.hide()
          subscriber.next(res)
          subscriber.complete()
        } else{
          this.spinner.hide()
          this.toastr.error('search failed')
          subscriber.error()
        }
      }
      this.googlePlaceService.nearbySearch(request, callback);
    })

  }



  private genMarkerListRecommendedList(results, setCenter){
    let markerArray = []
    let recommendedList: MarkerList
    for (var i = 0; i < results.length; i++) {
      const marker = this.createMarker(results[i], i, setCenter);
      markerArray.push(marker);
    }
    recommendedList = new MarkerList(results);
    return new SearchResult(markerArray, recommendedList)
  }

  clearMarker(){
    this.markerArray.forEach(item => {
      typeof(item.setMap)=="function" && item.setMap(null)
    })
    this.markerArray = [];
  }

  clearMarkerItem(id){
    this.markerArray.forEach((item, index)=>{
      if(item.id === id){
        item && typeof(item.setMap)=="function" && item.setMap(null)
        this.markerArray.splice(index, 1);
      } 
    })
  }

  private detectSearchPlaceField(){
    const input = document.getElementById('autoCompleteField') as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input);  
    google.maps.event.addListener(autocomplete, 'place_changed', ()=>{
        const place = autocomplete.getPlace();
        const location = place.geometry.location
        const _palce = {...place, latitude: location.lat(), longitude: location.lng()}
        this.map.setCenter(location);
        this.map.setZoom(12)
        this.genAssetMarkerItem(_palce,false)
    });
  }  

  triggerClickMarkerEvent(id){
    const marker = this.markerArray.find(item=>{
      return item.id === id
    })
   new google.maps.event.trigger( marker, 'click', {keepCanvasMode: true} );
  }

}

export class SearchResult {
  markerArray: Array<any> = []
  recommendedList: MarkerList = []
  constructor(markerArray, recommendedList){
    this.markerArray = markerArray
    this.recommendedList = recommendedList
  }
}
