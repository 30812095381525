import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const MessageTemplate : DialogTemplate = {
        dialogTitle: 'Set Message',
        dialogFields: [
            {
                key: 'title',
                label: 'Title',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'isPersonalised',
                label: 'Personalised',
                type: InputFieldType.Switch,
                size: 12,
            },
            {
                key: 'assignedSentAt',
                label: 'Schedule Sent Date (UTC)',
                type: InputFieldType.DateTimePicker,
                size: 24,
                required: true
            },
            {
                key: 'message',
                label: 'Message',
                type: InputFieldType.TextArea,
                size: 24,
                required: true
            },
            {
                key: 'imagesDetail',
                label: 'Images',
                type: InputFieldType.Uploader,
                size: 24,
                showPreviewImage: true
            },
            {
                key: 'links',
                label: 'Links',
                type: InputFieldType.InputArray,
                size: 24,
            }
        ]
    }