import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { StageService } from 'src/app/_services/stage.service';
import { PublicMapService } from '../../service/public-map.service';
import { MapTab } from 'src/app/common/constants';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-stage-group-item',
  templateUrl: './stage-group-item.component.html',
  styleUrls: ['./stage-group-item.component.css']
})
export class StageGroupItemComponent implements OnInit {
  @Input() mode: StageGroupMode
  @Input() currentFocusOnInfo: any
  name: string
  readonly StageGroupMode = StageGroupMode
  rootGroupArray: Array<any> = []
  formGroup: FormGroup
  rendered: boolean = false
  scaleTypeList: Array<any>
	constructor(
    private stageService: StageService,
    private toastr: ToastrService,
    private global: Global,
    private publicMapService: PublicMapService,
    private modalService: ModalService,
    private ref: ChangeDetectorRef
    ) {}


  ngOnInit() {
    this.scaleTypeList = this.global.section.StageGroupScaleTypeSelections.map(item=>{
      return {value: item, label: item ==='Object' ? 'Sizes only': 'Sizes and Distances'}
    })
    this.stageService.getStageGroup({IsRoot: true, PageSize: -1}).subscribe(res=>{
      this.rootGroupArray = res.data
      this.rendered = true
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.currentFocusOnInfo){
      if(this.currentFocusOnInfo.fake){
        return;
      }
      this.resetData()
      if(this.currentFocusOnInfo.id){
        this.mode = StageGroupMode.EditMode
        setTimeout(()=>{
          this.name = this.currentFocusOnInfo.name
        })
      } else {
        this.mode = StageGroupMode.PreviewMode
        this.formGroup = null
        setTimeout(()=>{
          this.setFG()
        })

      }
    }
  }

  setFG(){
    this.formGroup = new FormGroup({
      rootId: new FormControl(undefined, Validators.required),
      scaleMultiple: new FormControl(undefined, Validators.required),
      heading: new FormControl(undefined, Validators.required),
      longitude: new FormControl(this.currentFocusOnInfo?.longitude, Validators.required),
      latitude: new FormControl(this.currentFocusOnInfo?.latitude, Validators.required),
      altitude: new FormControl(this.currentFocusOnInfo?.altitude, Validators.required),
      scaleType: new FormControl(this.currentFocusOnInfo?.scaleType || 'ObjectAndDistance', Validators.required)
    })
    this.formGroup.valueChanges.subscribe(res=>{
      this.ref.detectChanges(); // to fix ant validation issue
    })
    this.ref.detectChanges(); // to fix ant validation issue

  }

  resetData(){
    this.name = null
  }

  checkValidation(mode){
    switch(mode){
      case StageGroupMode.EditMode:
        return !!this.name
      default:
        if (this.formGroup.valid) {
          return true
        } else {
          Object.values(this.formGroup.controls).forEach(control => {
            if (control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({ onlySelf: true });
            }
          });
          return false
        }
    }

  }

  submit(mode){
    if(!this.checkValidation(mode)){
      return
    }
    switch(mode){
      case StageGroupMode.EditMode:
        this.stageService.updateStageGroup({name: this.name},this.currentFocusOnInfo.id).subscribe(res=>{
          this.toastr.success('Update successfully.')
          this.global.appEvent.next({msg: 'reload-map', para: {resetMode: true}})
        })
        break
      case StageGroupMode.CopyMode:
        this.stageService.copyGroup(this.formGroup.value).subscribe(res=>{
          this.toastr.success('save successfully')
          this.global.appEvent.next({msg: 'reload-map', para: {resetMode: true}})
        })
        break
      case StageGroupMode.PreviewMode:
        this.stageService.previewCopyGroup(this.formGroup.value).subscribe(res=>{
          this.toastr.success('The preview data is loaded successfully, you can adjust the parameters and save.')
          this.mode = StageGroupMode.CopyMode
          this.publicMapService.createMockMarkers(res)
        })
      break
    }
  }

  delete(id){
    this.modalService.createNzDeleteModal(()=>{
      this.publicMapService.deleteItem(id, MapTab.StageGroup).subscribe(_=>{
        this.global.appEvent.next({msg: 'reload-map'})
      })
    })
  }
}

export enum StageGroupMode{
  EditMode,
  CopyMode,
  PreviewMode
}
