import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FaqAddComponent } from '../component/faq/add/faq.add.component';
import { FaqUpdateComponent } from '../component/faq/update/faq.update.component';
import { FaqDeleteComponent } from '../component/faq/delete/faq.delete.component';
import { ImageCarouselAddComponent } from '../component/image-carousel/add/image-carousel.add.component';
import { ImageCarouselUpdateComponent } from '../component/image-carousel/update/image-carousel.update.component';
import { ImageCarouselDeleteComponent } from '../component/image-carousel/delete/image-carousel.delete.component';
import { Global } from './global.service';
import { DeleteComponent } from '../ui-library/dialog/delete/delete.component';
import { LocalizationItemComponent } from '../component/localization/localization-item/localization-item.component';
import { LocalizationCategoryComponent } from '../component/localization/localization-category/localization-category.component';
import { DetailComponent } from '../ui-library/dialog/common/detail/detail.component';
import { LanguageVersionItemComponent } from '../component/language-version/language-version-item/language-version-item.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DialogTemplate } from '../_models/body';
import { FormGroup } from '@angular/forms';
import { MitDialogComponent } from '../ui-library/mit-dialog/mit-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private global: Global,
    private nzModalService: NzModalService
    ) {
    this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'modalService.close':
          this.close();
          break;
      }
    });
   }

  showDetail(detailData, dialogName: string, allowCopy = false) {
    this.nzModalService.create({
      nzContent: DetailComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: dialogName,
      nzClassName: 'detail-dialog',
      nzComponentParams: {
        allowCopy: allowCopy,
        detailData: detailData
      },
    })
  }

  // Faq
  AddFaq() {
    this.modalRef = this.modalService.open(FaqAddComponent, { size: 'lg', centered: true });
  }
  UpdateFaq(id: string) {
    this.modalRef = this.modalService.open(FaqUpdateComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.itemId = id;
  }
  DeleteFaq(id: string) {
    this.modalRef = this.modalService.open(FaqDeleteComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.itemId = id;
  }

  // Carousel
  AddImageCarousel() {
    this.modalRef = this.modalService.open(ImageCarouselAddComponent, { size: 'lg', centered: true });
  }
  UpdateImageCarousel(id: string) {
    this.modalRef = this.modalService.open(ImageCarouselUpdateComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.itemId = id;
  }
  DeleteImageCarousel(id: string) {
    this.modalRef = this.modalService.open(ImageCarouselDeleteComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.itemId = id;
  }

  // language version
  showLanguageVersionItem(isCreateMode: boolean, languageData?: any){
    this.modalRef = this.modalService.open(LanguageVersionItemComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.isCreateMode = isCreateMode
    this.modalRef.componentInstance.orgData = languageData
  }
  // localization
  localizationItem(data){
    this.modalRef = this.modalService.open(LocalizationItemComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.orgData = data;
  }

  localizationCategory(data){
    this.modalRef = this.modalService.open(LocalizationCategoryComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.allCategoryData = data;
  }

  

  commonDelete(id: string, itemType: string, size: string = 'sm', prop?){
    this.modalRef = this.modalService.open(DeleteComponent, { size: size, centered: true});
    this.modalRef.componentInstance.itemId = id;
    this.modalRef.componentInstance.itemType = itemType;
    if(prop){
      this.modalRef.componentInstance.prop = prop
    }
  }


  close() {
    this.modalService.dismissAll();
  }


  createNzModal(callback, formGroup: FormGroup, dialogTemplate: DialogTemplate){
    this.nzModalService.create({
      nzContent: MitDialogComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: dialogTemplate.dialogTitle,
      nzComponentParams: {
        formGroup: formGroup,
        dialogFields: dialogTemplate.dialogFields,
        callback: callback
      },
    })
  }

  createNzDeleteModal(callback, content?){
    this.nzModalService.create({
      nzContent: content || 'Are you sure you want to delete this record?',
      nzWidth: '400px',
      nzOnOk: callback,
      nzOkText: 'Delete',
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzBodyStyle: {
        'font-size': '20px'
      }
    })
  }

}
