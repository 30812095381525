
<nz-form-item>
    <nz-form-label [nzFor]="field.name">{{field.name}}</nz-form-label>
    <nz-form-control>
        <ng-container [ngSwitch]="field.type">
            <nz-select *ngSwitchCase="SearchFieldType.Selector" nzAllowClear nzShowSearch [formControl]="formCtrl">
                <!-- item may String or {id: String, name: String} -->
                <nz-option *ngFor="let item of field.options" [nzValue]="item.id ? item.id: item" [nzLabel]="item.name? item.name: item"></nz-option>
            </nz-select>
            <nz-range-picker *ngSwitchCase="SearchFieldType.DateRangePicker" [nzShowTime]="true" [formControl]="formCtrl"></nz-range-picker>
            <input *ngSwitchDefault nz-input type="text" [formControl]="formCtrl">
        </ng-container>
    </nz-form-control>
</nz-form-item>