import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { LanguageService } from 'src/app/_services/language.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-language-version',
  templateUrl: './language-version.component.html',
  styleUrls: ['./language-version.component.scss'],
  providers: [DatePipe]
})
export class LanguageVersionComponent extends BaseComponent{
  languageReadDto: any;
  @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;
  text: string

  constructor(
    private languageService: LanguageService,
    private modalService: ModalService,
    public global: Global,
    private toastr: ToastrService,
    private datePipe: DatePipe

  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'reload.languageVersion':
          this.fetchLanguageAll();
          break;
      }
    });
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchLanguageAll();
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'ID', field: 'id'},
      { headerName: 'Version', field: 'updateVersion'},
      { headerName: 'Client', field: 'client'},
      { headerName: 'Update At', field: 'updatedAt',
        valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'medium') 
      }},
      { headerName: 'Minimum', field: 'minimumClientVersion' },

      { headerName: 'Maximum(Not include)', field: 'maximumClientVersion' },
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Language Version Detail')
              break;
            case 'editDetail':
              this.editLanguage(params)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.onDelete.call(this, params?.data?.id))
              break;
          }
        },
      }
  ];
  }
  fetchLanguageAll() {
    this.languageService.getLanguageVersion()
      .subscribe(
        data => {
          this.languageReadDto = data;
        });
  }
  addLanguage() {
    this.modalService.showLanguageVersionItem(true);
  }

  onDelete(id){
    this.languageService.deleteLanguageVersion(id).subscribe(res=>{
      this.fetchLanguageAll()
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    })
  }

  editLanguage(params) {
    const id = params.data.id;
    this.modalService.showLanguageVersionItem(false, params.data);

  }

  getTranslation(text){
    this.languageService.getTranslation(text).subscribe(res=>{
      this.modalService.showDetail(res, 'Translation',true)
    })
  }
}

