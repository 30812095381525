import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  confirmMessage: string;

  constructor(
    private ngbActiveModal: NgbActiveModal
  ) { }

  ngOnInit() {

  }


  modalClose(ev){
    this.ngbActiveModal.close(ev)
  }

}
