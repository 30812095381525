import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const FakeDataTemplate = (): DialogTemplate =>  {
    return {
        dialogTitle: 'Set Fake Data',
        dialogFields: [
            {
                key: 'path',
                label: 'Path',
                type: InputFieldType.Text,
                size: 16,
                required: true
            },
            {
                key: 'apiMethodType',
                label: 'Api Method Type',
                type: InputFieldType.Selector,
                size: 8,
                required: true,
                options: ['ALL', 'GET', 'POST', 'PUT', 'DELETE']
            },
            {
                key: 'response',
                label: 'Response',
                type: InputFieldType.TextArea,
                size: 24,
                required: true,
            },
        ]
    }
}