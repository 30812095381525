import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const PartnerTemplate = (StatusOptions): DialogTemplate => {
    return {
        dialogTitle: 'Edit Developer Information',
        dialogFields: [
            {
                key: 'firstName',
                label: 'First Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'lastName',
                label: 'Last Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'status',
                label: 'Status',
                type: InputFieldType.Selector,
                size: 12,
                options: StatusOptions,
                required: true
            },
            {
                key: 'subscriptionExpiredAt',
                label: 'Subscription Expired At',
                type: InputFieldType.DateTimePicker,
                size: 12,
            },
        ]
    }
}
