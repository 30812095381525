import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Template/Query`,{
      params: {
        'PageSize': '-1'
      }
    });
  }

  create(info) {
    return this.http.post<any>(`${environment.apiEndpoint}api/Template`, info)
  }

  updateById(id: string, info: any) {
    return this.http.put<any>(`${environment.apiEndpoint}api/Template/${id}`, info)
  }

  delete(id: string) {
    return this.http.delete<any>(`${environment.apiEndpoint}api/Template/${id}`);
  }

}
