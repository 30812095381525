import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerService } from '../../_services/customer.service';
import { ModalService } from '../../_services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { SearchFieldType } from 'src/app/common/constants';
import { DatePipe } from '@angular/common';
import { InfiniteGridComponent } from 'src/app/ui-library/component/infinite-grid/infinite-grid.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { CustomerTemplate } from './customer-item.constant';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AddCoinComponent } from './add-coin/add-coin.component';
import { setNewOriginUrl } from 'src/app/_helpers/utility';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
})
export class CustomerComponent extends InfiniteGridComponent{
  @Input() customerPageMode: boolean = true
  @Output() selectedUsersOnChanged = new EventEmitter<any>()
  searchSection = [
    {name: 'Email', key: 'Email_Contains'}, 
    {name: 'Id', key: 'Id'}, 
    {name: 'Full Name', key: 'FullName_Contains'},
    {name: 'Tester Account', key: 'IsTester', type: SearchFieldType.Selector, options: ['true', 'false']}
  ]
  formGroup: FormGroup
  constructor(
    private customerService: CustomerService,
    private modalService: ModalService,
    private toastr: ToastrService,
    public global: Global,
    private datePipe: DatePipe,
    private nzModalService: NzModalService
  ) {
    super(global);
  }

  getUserName(params){
    if(!params.data) return 'not loaded yet';
    return params.data.firstName + ' ' +  params.data.lastName
  }

  checkEmailConfirmed(params){
    if(!params.data) return 'not loaded yet'
    return params.data.emailConfirmed ? 'Email Confirmed':'Email Unconfirmed'
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 50, sortable:false},
      { headerName: 'ID', field: 'id', width: 300 },
      { headerName: 'Name',  width:200, valueGetter: this.getUserName.bind(this), sortable:false},
      { headerName: 'Email', field: 'email', width: 200},
      { headerName: 'Country Or Region', field: 'countryOrRegion', width: 200},
      { headerName: 'Gender', field: 'gender', width: 150},
      { headerName: 'Tester', field: 'isTester', width: 150},
      { headerName: 'Confirmed At', field: 'emailConfirmedAt', width: 150,  valueFormatter:(params)=>{
        return this.datePipe.transform(params.value, 'short')
      }},
      { headerName: 'Status', field: 'emailConfirmed', valueGetter: this.checkEmailConfirmed.bind(this), width: 200}];

      if(this.customerPageMode){
        this.columnDefs.push({
          headerName: 'Action',
          pinned: 'right',
          minWidth: 160,
          sortable:false,
          cellRenderer: params => {
            return `  <i class="fas fa-coins" id="exchange" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Customer Detail')
                break;
              case 'editDetail':
                this.showItem(params?.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
              case 'exchange':
                this.addCoin(params?.data?.id)
                break;
            }
          },
        })
      }
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  ngAfterViewInit(): void {
    this.setDatasource((prop)=>this.customerService.getAll(prop))
  }

  delete(id){
    this.customerService.delete(id).subscribe(res=>{
      this.gridApi.purgeInfiniteCache();
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    } )
  }
  
  deleteBatchCustomer(){
    const customers = this.gridApi.getSelectedRows().map(item=>{
      return item.id
    });
    if(customers.length < 1){
      this.toastr.info('Please select customers', 'Info')
      return;
    } else {
      const msg =  `Are you sure you want to delete ${customers.length>1 ? `${customers.length} records`: `${customers.length} record`}`
      this.modalService.createNzDeleteModal(()=>{
        customers.forEach(id=>{
          this.delete(id)
        })
        this.gridApi.deselectAll();
      }, msg)
    }
  }

  addCoin(id){
    this.nzModalService.create({
      nzContent: AddCoinComponent,
      nzWidth: '400px',
      nzFooter: null,
      nzTitle: 'Exchange Coins',
      nzComponentParams: {
        id: id,
        callback: ()=> this.gridApi.purgeInfiniteCache()
      },
    })
  }

  genFG(res){
    this.formGroup = new FormGroup({
      email: new FormControl(res?.email,Validators.required),
      firstName: new FormControl(res?.firstName,Validators.required),
      lastName: new FormControl(res?.lastName,Validators.required),
      gender: new FormControl(res?.gender),
      isTester: new FormControl(res?.isTester),
      languageCode: new FormControl(res?.languageCode),
      profileUrl: new FormControl(res?.profileUrl),
    })
  }

  showItem(data){
    this.genFG(data)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, CustomerTemplate())
  }

  onSubmit(itemId?){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.gridApi.purgeInfiniteCache()
        this.toastr.success('Data Saved Successfully...!', 'Success');
        subscriber.next()
        subscriber.complete()
      }
      setNewOriginUrl(this.formGroup, 'profileUrl')
      this.customerService.updateById(this.formGroup.value, itemId).subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      })
    })
  }

  onSelectionChanged(){
    const customers = this.gridApi.getSelectedRows();
    this.selectedUsersOnChanged.emit(customers)
  }
}
