import { Component } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { TagService } from 'src/app/_services/tag.service';
import { SearchFieldType } from 'src/app/common/constants';
import { InfiniteGridComponent } from 'src/app/ui-library/component/infinite-grid/infinite-grid.component';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent extends InfiniteGridComponent{
    searchSection = [  
        {name: 'Type', key: 'Type', type: SearchFieldType.Selector, options: ['CommonTag', 'HashTag']}
    ]
    constructor(
        public global: Global,
        private tagService: TagService,
        private modalService: ModalService
    ) {
        super(global);

    }

    ngOnInit(): void {
        this.setColumnDefs();
    }

    ngAfterViewInit(): void {
        this.setDatasource((prop)=>this.tagService.getAll(prop))
    }
    
  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Name', field: 'name' },
        { headerName: 'Search Count', field: 'searchCount' },
        { headerName: 'Number Of Posts', field: 'numberOfPosts' },
        { headerName: 'Number Of Views', field: 'numberOfViews' },
        { headerName: 'Number Of Clicks', field: 'numberOfClicks' },
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Template Detail')
              break;
          }
        },
      },
    ]
  }

}
