<!-- <div class="mr-3" *ngIf="isPC">
  <label for="pagesize">Page Size:</label>
  <input type="number" class="form-control page-size-field" [(ngModel)]="pageSize"/>
  <button type="button" (click)="setPageSize()" class="btn btn-success">
       Set
  </button>
</div> -->


<nav *ngIf="router.url !== '/login'" class="topbar-wrapper" [ngClass]="{'collapsed-brand': isCollapsed}">
  <app-sidebar-brand class="brand-wrapper">
    <span content class="fas fa-fw fa-bars menu-icon"
    (click)="updateCollapsed()"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseExample"
    ></span>  
  </app-sidebar-brand>
  <app-topbar-navbar class="shadow right-top-bar"></app-topbar-navbar>

</nav>