import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Global } from '../../_services/global.service'
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
  @Input('isCollapsed') isCollapsed: boolean
  @Output() toggleCollapsed = new EventEmitter<boolean>();

  constructor(
     public router: Router,
      public global: Global) { 
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
          if(document.body.clientWidth <= 767){
            this.updateCollapsed()
          }
      });
    }

  // setPageSize(){
  //   this.global.appEvent.next({'pagesize': this.pageSize})
  //   this.global.pageSize = this.pageSize
  // }

  updateCollapsed(){
    this.toggleCollapsed.emit(!this.isCollapsed)
  }

}
