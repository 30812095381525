import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-send-coupon',
  templateUrl: './send-coupon.component.html',
  styleUrls: ['./send-coupon.component.css']
})
export class SendCouponComponent implements OnInit {
  readonly couponInfo: any
  selectedUsers: Array<any> = []
  sendCouponEvent: any
  constructor(
    private nzModalService: NzModalService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
  }

  sendCoupon(tplContent){
    this.nzModalService.create({
      nzTitle: 'Confirm',
      nzContent: tplContent,
      nzWidth: 800,
      nzOkText: 'Send',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.sendCouponEvent.apply(this, [this.selectedUsers.map((user) => user.id)])
      }
    })
  }

  setSelectedUsers(ev){
    this.selectedUsers = ev
    if(this.selectedUsers.length > this.couponInfo.maxRedemptions){
      this.toast.info(`This coupon's max redemptions is ${this.couponInfo.maxRedemptions}`, 'Max redemptions exceeded')
    } 
  }
}
