import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbCalendar, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls:['./date-picker.component.scss'],

})
export class DatePickerComponent implements OnInit {
  @Input('formCtrl') formCtrl: FormControl
  date: NgbDateStruct = { year: 0, month: 0, day: 0 };
  time: NgbTimeStruct = {hour: 0, minute: 0, second: 0};
  showError = false
  currentTZ: string

  get currentTZVal(){
    if(this.formCtrl.value){
      return new Date(this.formCtrl.value)
    }
    return null
  }
  constructor() {}
  
  ngOnInit(): void {
    if(this.formCtrl?.value){
      const initDate = new Date(this.formCtrl.value)
      this.date.day = initDate.getUTCDate()
      this.date.month = initDate.getUTCMonth()+1
      this.date.year = initDate.getUTCFullYear()
      this.time.hour = initDate.getUTCHours()
      this.time.minute = initDate.getUTCMinutes()
    }
    this.formCtrl.statusChanges.subscribe(feedBack=>{
      this.showError = feedBack === 'INVALID'
    })
  }

  updateDateTimePicker(){
    if(!this.time || !this.date){
      !this.date && !this.time && this.formCtrl.setValue(null)
      return
    }
    const currentDateTime = Date.UTC(this.date?.year, this.date?.month-1, this.date?.day, this.time?.hour, this.time?.minute, this.time?.second)
    this.formCtrl.setValue(new Date(currentDateTime).toISOString())
  }

}