import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private httpClient: HttpClient

  ) { }
    getAll(params?) {
      let para = {}
      if(params){
        for(let prop in params){
          Object.assign(para, {[prop]: params[prop]});
        }
      }
      return this.httpClient.get(`${environment.apiEndpoint}api/Tags/Query`,{
        params: para
      });
    }
}
