<app-point-info  content 
  [pointInfoMode]="PointInfoMode.CesiumMode"
  [type]="MapTab.Stage"
  [currentFocusOnInfo]="currentFocusOnInfo"
  [assetCategory]="assetCategory"
  [vcSourceList]="vcSourceList"
  [streamingEventSource]="streamingEventSource"
  [streamerSubTypeAssetSource]="streamerSubTypeAssetSource"
  (onRemoveEvent)="delete($event)"
  (onUpdateEvent)="update($event)"
  (onAddPointEvent)="add($event)"
  >
  <ng-container content>
    <button type="button" class="btn btn-warning" (click)="emitGlobalEvent('removeInCesium')">Remove in Cesium</button>
    <!-- <div class="btn-wrapper">
      <button type="button" class="btn btn-success full-btn" (click)="saveAll()">Save all models</button>
    </div> -->
  </ng-container>
</app-point-info>

