<nz-table #basicTable [nzData]="group">
    <thead>
      <tr>
        <th>#</th>
        <th>Icon</th>
        <th>ID</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of basicTable.data; index as i">
        <td>{{i+1}}</td>
        <td>
            <img class="icon-url" [src]="item?.icon?.url" alt="">
        </td>
        <td>{{ item?.id }}</td>
        <td>
            <button nz-button nzType="primary" nzDanger nzSize="small" (click)="remove(item.id)">Remove</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
<h5  style="margin: 10px 0">Create AR Group</h5>
<nz-form-item>
    <nz-form-label nzLabelAlign="left" nzRequired nzFor="name">Group Name</nz-form-label>
    <nz-form-control>
      <input nz-input required [(ngModel)]="name" />
    </nz-form-control>
</nz-form-item>
<button nz-button nzType="primary" (click)="submit()" [disabled]="!name || !group?.length">Save</button>