<nz-tag
    class="tag-wrapper"
    *ngFor="let tag of tags; let i = index"
    nzMode="closeable"
    (nzOnClose)="handleClose(tag)"
    [title]="tag"
    >
    {{sliceTagName(tag)}}
</nz-tag>
<nz-tag *ngIf="!inputVisible && allowAddTag" class="editable-tag" nzNoAnimation (click)="showInput()">
    <span nz-icon nzType="plus"></span>
    New Tag
</nz-tag>

<input
    #inputElement
    maxlength="40"
    nz-input
    nzSize="small"
    *ngIf="inputVisible"
    type="text"
    [(ngModel)]="inputValue"
    style="width: 78px;"
    (blur)="handleInputConfirm()"
    (keydown.enter)="handleInputConfirm()"
/>