import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PartnerService } from 'src/app/_services/partner.service';
import { FeeTableComponent } from './fee-table/fee-table.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent extends BaseComponent {
  initialPartnerId: string
  data
  constructor(
    private route: ActivatedRoute,
    private partnerService: PartnerService,
    public global: Global,
    private datePipe: DatePipe,
    private ngbModal: NgbModal,
    private modalService: ModalService,
    private nzModalService: NzModalService
  ) { 
    super(global);
    this.initialPartnerId = this.route.snapshot.paramMap.get('id');

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchAll();
  }


  fetchAll(){
    this.partnerService.getPartnerIncome(this.initialPartnerId).subscribe(res=>{
      this.data = res.data
    })
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  formatDateTime(params){
    return this.datePipe.transform(params.value, 'short')
  }

  formatBenefit(params){
    return `${params.value} ${params.data.income.currency.toUpperCase()}`
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'ID', field: 'id'},
      { headerName: 'Project Name', field: 'asset.name'},
      { headerName: 'Partner Email', field: 'developer.email'},
      { headerName: 'Buyer Email', field: 'user.email'},
      { headerName: 'Order Amount', field: 'orderAmount'},
      { headerName: 'Created At', field: 'createdAt',valueFormatter: this.formatDateTime.bind(this), minWidth:170},
      { headerName: 'Benefit', field: 'income.money',valueFormatter: this.formatBenefit.bind(this)},
      { headerName: 'Settled At', field: 'settledAt',valueFormatter: this.formatDateTime.bind(this), minWidth:170},
      { headerName: 'Status', field: 'status'},
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 100,
        cellRenderer: params => {
          return `
              <i class="fas fa-fw fa-file-invoice-dollar" id="fees" style="margin-right:3px; cursor: pointer;"></i>
              <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
              `
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'fees':
              this.nzModalService.create({
                nzContent: FeeTableComponent,
                nzTitle: 'Fee Detail',
                nzWidth: 800,
                nzFooter: null,
                nzComponentParams:{
                  data: params?.data?.fees
                }
              })
              break;
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Detail')
              break;
          }
        },
      },
    ];
  }

}
