import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleMapService, SearchResult } from './google-map.service';
import { AmapService } from './amap.service';
import { MapTab } from 'src/app/common/constants';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';
import { MapPlacesLatLngBounds } from 'src/app/_models/mapDto';
import { environment } from 'src/environments/environment';
import { StageService } from 'src/app/_services/stage.service';
import { MapService } from 'src/app/_services/map.service';
import { InfiniteResult } from 'src/app/_models/body';
import { CampaignService } from 'src/app/_services/campaign.service';
import { isEqual } from 'lodash';
import { EscapeGameService } from 'src/app/_services/escape-game.service';

@Injectable({
  providedIn: 'root'
})
export class PublicMapService {
  private lastPosition
  readonly isCN: boolean = environment.isCN
  currentStageGroup = []
  mockMarkers:Array<any> = []
  map
  pageIndex:number = 1
  lastTimeLatLngBounds: any;

  // for grid
  loadedPoints: Array<any> = []
  totalCount: number

  constructor(
        private googleMapService: GoogleMapService,
        private amapService: AmapService,
        private global: Global,
        private toastr: ToastrService,
        private stageService: StageService,
        private mapService: MapService,
        private campaignService: CampaignService,
        private escapeGameService: EscapeGameService
  ) { 
   this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
          case 'trigger.infoWindow':
            switch(para.item.type){
              case MapTab.Stage:
                this.stageService.getStageById(para.item.id).subscribe(res=>{
                  this.attachMsgInInfoWindow(para.marker, {...para.item, ...res}, para.infoWindow, para.infoWindowLinkTo, para.keepCanvasMode);
                })
                break;
              case MapTab.Portal:
                this.checkPortalMaker(para.item)
                this.attachMsgInInfoWindow(para.marker, para.item, para.infoWindow, para.infoWindowLinkTo, para.keepCanvasMode);
                break;
                case MapTab.StageGroup:
                  this.stageService.getStage({GroupId: para?.item?.id, pageSize: -1}).subscribe((res: InfiniteResult)=>{
                    this.createMockMarkers(res.data)
                  })
                  this.attachMsgInInfoWindow(para.marker, para.item, para.infoWindow, para.infoWindowLinkTo, para.keepCanvasMode);
                  break;
              default:
                this.attachMsgInInfoWindow(para.marker, para.item, para.infoWindow, para.infoWindowLinkTo, para.keepCanvasMode);
                break;
            }
          break;
      }
    });
  }
    
  getCurrentPosition(){
    const lastLocation = localStorage.getItem('lastLocation');
    if (lastLocation && lastLocation !=='undefined') {
      const location = JSON.parse(lastLocation)
      if (location.lat !== "" && location.lng !== "") {
        this.lastPosition = location;
      }
    }
  }

  documentEventHandler(){
        // Invalidate the zoom event of the browser on the mobile site
        document.addEventListener('touchstart',function (event) {
          if(event.touches.length>1){
            event.preventDefault();
          }
        });
        var lastTouchEnd=0;
        document.addEventListener('touchend',function (event) {
          var now=(new Date()).getTime();
          if(now-lastTouchEnd<=300){
            event.preventDefault();
          }
          lastTouchEnd=now;
        },false);
        document.addEventListener('gesturestart', function (event) {
          event.preventDefault();
        });
  }

  loadMap(){
    this.getCurrentPosition()
    return new Observable(subscriber => {
      if(this.isCN){
        this.amapService.loadMap(this.lastPosition).subscribe(aMap=>{
          this.map = aMap
          subscriber.next()
          subscriber.complete()
        })
  
      } else {
        this.googleMapService.loadMap(this.lastPosition).subscribe(googleMap=>{
          this.map = googleMap
          subscriber.next()
          subscriber.complete()
        })
      }
    })
  }


  genAssetMarkerItem(item, showIconUrl){
    return this.isCN ? this.amapService.genAssetMarkerItem(item, showIconUrl) : this.googleMapService.genAssetMarkerItem(item, showIconUrl)
  }

  
  // Attaches an info window to a marker with the provided message. When the
  // marker is clicked, the info window will open with the secret message.
  private attachMsgInInfoWindow(marker, pointData, infoWindow, infoWindowLinkTo, keepCanvasMode) {
    const markerId = this.isCN ? marker._opts.id : marker.id
    const contentString = (currentStageGroup)=>{
      switch(pointData.type){
        case MapTab.Marker:
          return `${ pointData.name? `<div> Location Name: ${pointData.name} </div>`: ''} ${pointData.numberOfTokens ? `<div>Coin Number: ${pointData.numberOfTokens}</div>` : ``}`
        case MapTab.Stage:
          return  `${pointData.description ? `<div style="margin-bottom:5px">Description: ${pointData.description}</div>`: ``}
                  <button class="btn btn-primary btn-sm" id="${markerId}">${currentStageGroup.find(item=> item?.id === markerId) ? 'Remove from Watchlist': 'Add to Watchlist'}</button>`
        case MapTab.StageGroup:
          return `${ pointData.name? `<div style="margin-bottom:5px">Name: ${pointData.name}</div>`: ''}`
        default:
          return `${ pointData?.name || ''}`
      }
    }
    const infoWindowContent = contentString(this.currentStageGroup)

    infoWindow.close();
    infoWindow.setContent(infoWindowContent)
    infoWindowContent && infoWindow.open(this.map, infoWindowLinkTo);
    !keepCanvasMode && this.global.appEvent.next({msg: 'show-detail', para: { ...pointData, marker: marker}})
    setTimeout(()=>{
      document?.getElementById(markerId)?.addEventListener("click",()=>{
        switch(pointData.type){
          case MapTab.Stage:
            if(this.currentStageGroup.find(item=> item?.id === markerId)){
              this.currentStageGroup = this.currentStageGroup.filter((x) => x.id !== markerId)
            } else {
              this.currentStageGroup.push(marker)
            }
            infoWindow.close();
            break;
        }
        this.global.appEvent.next({msg: 'set.currentStageGroup', para: this.currentStageGroup})
      })
    },400)
  }

  private getMarkerParam(LatLngBounds){
    if(!LatLngBounds){
      return
    }
    return new MapPlacesLatLngBounds(LatLngBounds, this.isCN)
  }

  setCenter(lat: number, lng: number){
    const position = this.isCN ? new window['AMap'].LngLat(lng, lat) : new google.maps.LatLng(lat, lng)
    this.map.setCenter(position)
  }

  // have been move to center changed detection
  // recordMapInfo(){
  //   if(!this.map){
  //     return
  //   }
  //   localStorage.setItem('mapZoom', this.map.getZoom());
  //   if(this.isCN){
  //     const position = {lng: this.map.getCenter().lng, lat: this.map.getCenter().lat}
  //     localStorage.setItem('lastLocation', JSON.stringify(position));
  //   } else {
  //     localStorage.setItem('lastLocation', JSON.stringify(this.map.getCenter()));
  //   }
  // }

  searchByText(ev){
    this.clearMockMarker()
    const api = (isCN)=>{
      return isCN ? this.amapService.searchByText(ev) : this.googleMapService.searchByText(ev)
    }
    return new Observable(subscriber => {
      api(this.isCN).subscribe((res: SearchResult)=>{
        this.mockMarkers = res.markerArray
        subscriber.next(res.recommendedList)
        subscriber.complete()
      })
    })
  }

  getNearBy(currentFocusOnInfo){
    this.clearMockMarker()
    const api = (isCN)=>{
      return isCN ? this.amapService.getNearBy(currentFocusOnInfo) : this.googleMapService.getNearBy(currentFocusOnInfo)
    }
    return new Observable(subscriber => {
      api(this.isCN).subscribe((res: SearchResult)=>{
        this.mockMarkers = res.markerArray
        subscriber.next(res.recommendedList)
        subscriber.complete()
      })
    })
  }

  clearMarker(){
    this.clearMockMarker()
    this.isCN && this.map ? this.map.clearMap(): this.googleMapService.clearMarker()
  }

  clearMarkerItem(id){
    this.isCN ? this.amapService.clearMarkerItem(id): this.googleMapService.clearMarkerItem(id)
  }

  clearMockMarker(iconMarker?){
    this.mockMarkers && this.mockMarkers.forEach(item=>{
      this.isCN ? this.clearMarkerItem(iconMarker? item._opts.id: item._amap_id ) : item.setMap(null)
    })
    this.mockMarkers = []
  }

  getDataByTabType(currentTab, prop){
    let api
    return new Observable(subscriber=>{
      switch(currentTab){
        case MapTab.Marker:
          api = this.mapService.LoadMapPlaceData({...prop, PageSize: 100})
          break;
        case MapTab.Stage:
          api = this.stageService.getStagesForKeyInfo({...prop, PageSize: 300})
          break
        case MapTab.Portal:
          api = this.campaignService.getAllPortal({...prop, PageSize: -1})
          break
        case MapTab.StageGroup:
          api = this.stageService.getStageGroup({...prop, PageSize: 100})
              break
          case MapTab.Escape:
          api = this.escapeGameService.getPortal({...prop, PageSize: 100})
            break
      }
      api.subscribe((res:InfiniteResult)=>{
        subscriber.next(res)
        subscriber.complete()
      })
    })
  }

  deleteItem(id, currentType) {
    return new Observable(sub=>{
      switch(currentType){
        case MapTab.Marker:
          this.mapService.deleteMarker(id).subscribe(res => {
            onSuccess()
          })
          break;
        case MapTab.Stage:
          this.stageService.delete(id).subscribe(res=>{
            onSuccess()
          })
          break;
        case MapTab.Portal:
          this.campaignService.deletePortal(id).subscribe(res=>{
            onSuccess()
          })
          break;
        case MapTab.StageGroup:
          this.stageService.deleteStageGroup(id).subscribe(()=>{
            onSuccess()
          })
            break;
        case MapTab.Escape:
            this.escapeGameService.deletePortal(id).subscribe(()=>{
                onSuccess()
            })
        break;
              
      }
      const onSuccess = () =>{
        this.clearMarkerItem(id)
        this.clearMockMarker(true)
        this.global.appEvent.next({msg: 'reload-map'})
        this.toastr.success(`Remove Successfully...!`, 'Success');
        sub.next()
        sub.complete()
      }
    })

  }


  updateItem(ev: {data: any, type: MapTab}){
    return new Observable(sub=>{
      const marker = ev.data
      switch (ev.type){
        case MapTab.Marker:
          this.mapService.updateMarker(marker.info, marker.id).subscribe(res => {
            onSuccess(res)
          })
          break;
        case MapTab.Stage:
          this.stageService.update(marker.info, marker.id).subscribe(res => {
            onSuccess(res)
          })
          break
        case MapTab.Portal:
          this.campaignService.updateWavePortal(marker.info, marker.id).subscribe(res => {
            onSuccess(res)
          })
        break
        case MapTab.Escape:
            this.escapeGameService.updatePortal(marker.info, marker.id).subscribe(res => {
                onSuccess(res)
            })
        break  
      }
  
      const onSuccess = (res) =>{
        this.clearMarkerItem(marker.id)
        const currentFocusOnInfo = {...res, type: ev.type }
        this.genAssetMarkerItem(currentFocusOnInfo, true) //may need to fix to support showIconUrl
        this.global.appEvent.next({msg: 'show-detail', para: currentFocusOnInfo})
        this.setCenter(res.latitude, res.longitude);
        this.toastr.success(`Update Successfully...!`, 'Success');
        sub.next()
        sub.complete()
      }
    })

  }


  batchDelete(data: Array<string>, type: MapTab){
    return new Observable(sub=>{
      switch (type){
        case MapTab.Marker:
          this.mapService.batchDelete(data).subscribe(res => {
            onSuccess(res)
          })
        break;
        case MapTab.Stage:
          this.stageService.batchDelete(data).subscribe(res => {
            onSuccess(res)
          })
        break;
        case MapTab.StageGroup:
          this.stageService.batchDeleteGroups(data).subscribe(res => {
            onSuccess(res)
          })
        break;
        case MapTab.Portal:
          this.campaignService.batchDelete(data).subscribe(res => {
            onSuccess(res)
          })
        break;
        case MapTab.Escape:
            this.escapeGameService.batchDeletePortal(data).subscribe(res => {
                onSuccess(res)
            })
        break;
      }
  
      const onSuccess = (res) =>{
        this.global.appEvent.next({msg: 'reload-map'})
        this.toastr.success('remove successfully')
        sub.next()
        sub.complete()

      }
    })
  }

  addItem(ev: {data: any, type: MapTab}){
    return new Observable(sub=>{
      const marker = ev.data
      switch (ev.type){
        case MapTab.Marker:
          this.mapService.addMarker(ev.data).subscribe(res => {
            onSuccess(res)
          })
          break;
        case MapTab.Stage:
          this.stageService.add(ev.data).subscribe(res => {
            onSuccess(res)
          })
          break
        case MapTab.Portal:
          this.campaignService.createWavePortal(ev.data).subscribe(res => {
            onSuccess(res)
          })
          break
        case MapTab.StageGroup:
          this.stageService.createGroup(ev.data).subscribe(res=>{
            this.setCurrentStageGroup([])
            this.global.appEvent.next({msg: 'reload-map'})
            this.toastr.success(`Add ${ev.type} Successfully...!`, 'Success');
            sub.next()
            sub.complete()    
          })
              break
        case MapTab.Escape:
            this.escapeGameService.createPortal(ev.data).subscribe(res => {
                onSuccess(res)
            })
        break
      }
  
      const onSuccess = (res) =>{
        this.setCenter(res.latitude, res.longitude);
        const currentFocusOnInfo = {...res, type: ev.type};
        this.genAssetMarkerItem(currentFocusOnInfo, true)
        this.global.appEvent.next({msg: 'show-detail', para: currentFocusOnInfo})
        this.toastr.success(`Add ${ev.type} Successfully...!`, 'Success');
        sub.next()
        sub.complete()

      }
    })
  }

  createMockMarkers(data){
    this.clearMockMarker(true)
    this.mockMarkers = data.map((item) => {
      return this.genAssetMarkerItem(item, true)
    })
  }

  checkPortalMaker(ev){
    this.clearMockMarker(true)
    this.getDataByTabType(MapTab.Marker, {CampaignWavePortalId: ev.id,IgnoreGps: true}).subscribe((res: InfiniteResult)=>{
      const array = res.data.map(item=>{
        return {...item, type: MapTab.Marker}
      })
      this.createMockMarkers(array)
      res.totalCount ? this.toastr.success(`There are ${res.totalCount} markers in this portal`) : this.toastr.info(`There is no marker in this portal`)
    })
  }


  private getBounds(){
     // may need to retry when load map at the first time
    return new Observable(sub=>{
      if(!!this?.map?.getBounds()?.toJSON()){
        sub.next(this?.map?.getBounds()?.toJSON())
        sub.complete()
      } else {
        const bounds = setInterval(()=>{
          if(!!this?.map?.getBounds()?.toJSON()){
            clearInterval(bounds)
            sub.next(this?.map?.getBounds()?.toJSON())
            sub.complete()
          }
        }, 500);
      }
    })
  }

  private getRequestParam(){
    return new Observable(sub=>{
      this.getBounds().subscribe(res=>{
        const LatLngBounds = res
        const data = this.getMarkerParam(LatLngBounds)
        sub.next({...data, PageIndex: this.pageIndex})
        sub.complete()
      })
    })
  }

  resetMapMarker() {
    this.pageIndex = 1
    this.clearMarker()
    this.clearMockMarker(true)
  }

  private needToRest(resetMode){
    // once drag or zoom map, need to disabled continuationToken and clearMarker
    return resetMode || !isEqual(this.lastTimeLatLngBounds, this.map.getBounds().toJSON())
  }


  getMorePoint(currentTab: MapTab, resetMode: boolean = false, para?: any){
      const reset = this.needToRest(resetMode)
      reset && this.resetMapMarker()
      this.getRequestParam().subscribe((ev: any)=>{
        const parameters = {...para, ...ev}
        if(isNaN(parameters?.MinLongitude) || isNaN(parameters?.MaxLongitude) || isNaN(parameters?.MinLatitude) || isNaN(parameters?.MaxLatitude) ){
          return this.toastr.error('Failed to load map')
        }
        this.getDataByTabType(currentTab, parameters).subscribe((res: InfiniteResult)=>{
          // this.global.appEvent.next({msg: 'set.gridData' , para: {data: res, tabType: currentTab, resetGrid: reset , stageType: parameters?.stageType}})
          this.pushPlacesCallBack(res, currentTab)
          this.setGridData(res, reset)
        })
      })
  }


  private setGridData(res: InfiniteResult, resetGrid: boolean){
    if(resetGrid){
      this.loadedPoints = res.data
      this.totalCount = res?.totalCount
    } else if(res?.count){
      this.loadedPoints = this.loadedPoints.concat(res.data)
      this.totalCount = res?.totalCount
    }
  }


  pushPlacesCallBack(res: InfiniteResult, type:MapTab){
    if(res.count=== 0){
      this.toastr.info('No more records in this area')
      return
    }
    this.pageIndex++
    this.lastTimeLatLngBounds = this.map?.getBounds()?.toJSON()
    res.data.forEach(item=>{
      this.genAssetMarkerItem({...item, type: type}, true)
    })
  }

  setCurrentStageGroup(ev){
    this.currentStageGroup = ev
    this.global.appEvent.next({msg: 'set.currentStageGroup', para: this.currentStageGroup})
  }

  triggerClickMarkerEvent(id){
    if(!this.isCN){
      this.googleMapService.triggerClickMarkerEvent(id)
    }
  }

}
