<ag-grid-angular
#agGrid
class="ag-theme-alpine"
[gridOptions]="gridOptions"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[suppressRowClickSelection]="true"
[enableCellTextSelection]="true"
(gridReady)="onGridReady($event)"
style="height: 80vh"
[pagination]="true"
(sortChanged)="sortChanged($event)"
>
</ag-grid-angular>