import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';

@Component({
  selector: 'app-sketchfeb',
  templateUrl: './sketchfeb.component.html',
  styleUrls: ['./sketchfeb.component.scss']
})
export class SketchfebComponent implements OnInit {
  currentSketchfedInfo: any;
  rendered: boolean = false;
  constructor(
    private assetService: MmitaAssetService,
    private global: Global,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.assetService.getSketchfabAccessToken().subscribe((res: any)=>{
      
      this.currentSketchfedInfo = res
      const expireDate = new Date (res.expireTime* 1000 + new Date(res.createdAt).getTime()).toISOString()
      this.currentSketchfedInfo['expireDate'] = expireDate
      this.rendered = true
    })
  }

  formSubmit(){
    const saveSuccess = () =>{
      this.modalClose();
      this.toastr.success('Sketchfab AccessToken Refresh Successfully...!', 'Success', {
        timeOut: 3000
      });
    };
    this.assetService.postSketchfabAccessToken().subscribe(res=>{
      saveSuccess()
    })
  }

  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });
  }

}
