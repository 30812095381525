import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { AbilityService } from 'src/app/_services/ability.service';
import { Global } from '../../_services/global.service';
import { ModalService } from '../../_services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbilityTemplate } from './asset-ability-item.constant';
import { AbilityDto } from 'src/app/_models/abilityDto';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-asset-ability',
  templateUrl: './asset-ability.component.html',
  styleUrls: ['./asset-ability.component.scss']

})
export class AssetAbilityComponent extends BaseComponent implements OnInit {
    ability: Array<any>;
    columnDefs = [];
    formGroup: FormGroup
    constructor(
        private abilityService: AbilityService,
        public global: Global,
        public modalService: ModalService,
        private toastr: ToastrService
    ){
      super(global);
    }

    multiRowValHtml(params){
      let innerHtml = ''
      for (var prop in params.value) {
        innerHtml += `<li style="list-style: none;">${prop}: ${params.value[prop]}</li>`
      }
      return `<ul style="padding-inline-start: unset;">${innerHtml}<ul>`
    }

    setColumnDefs(){
      this.columnDefs = [
        { headerName: 'Order', field: 'order'},
        { headerName: 'Multilingual Names', field: 'multilingualNames',
          cellRenderer: params => {return this.multiRowValHtml(params)}},
        { headerName: 'Multilingual Descriptions', field: 'multilingualDescriptions',
          cellRenderer: params => {return this.multiRowValHtml(params)}},
          { headerName: 'Image', field: 'url',
          cellRenderer: params => {
            return params.value?` <img height="100%" style="max-height: 130px" src="${params.value}" alt="without image"/>`:`-`;
            },
          },      
        {
          headerName: 'Action',
          pinned: 'right',
          cellRenderer: params => {
            return ` 
                      <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'editDetail':
                this.showItem(params?.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Asset Ability Detail')
                break;
            }
          },
          minWidth: 120
        }
    ];
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.getAll();
    }
    
    ngOnInit(){
       this.setColumnDefs();
    }

    getAll(){
        this.abilityService.getAll().subscribe(res=>{
            this.ability = res;
        })
    }

    delete(id){
        this.abilityService.deleteAbility(id)
        .subscribe(data => {
            this.getAll();
            this.toastr.success('Data Deleted Successfully...!', 'Success');
        });
    }

    showItem(data?){
      this.genFG(data)
      const dialogTemplate = AbilityTemplate(!!data)
      this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
    }

    private buildAbility(formVal){
      const ability: AbilityDto = {
          multilingualNames: {'en-US': formVal.us, 'zh-CN':formVal.cn, 'zh-HK': formVal.hk},
          multilingualDescriptions: {'en-US': formVal.desUS, 'zh-CN':formVal.desCN, 'zh-HK':formVal.desHK},
          url: formVal?.url?.[0]?.origin?.url,
          order: formVal.order
      }
      return ability;
    }
    onSubmit(itemId?){
      return new Observable(subscriber => {
        const saveSuccess = () => {
          this.getAll()
          this.toastr.success('Data Saved Successfully...!', 'Success');
          subscriber.next()
          subscriber.complete()
        }
        const ability = this.buildAbility(this.formGroup.value);
        itemId ? this.abilityService.updateAbility(itemId,ability).subscribe({
              next: x => saveSuccess(),
              error: x => subscriber.error(x)
            }) :  this.abilityService.addAbility(ability).subscribe({
              next: x => saveSuccess(),
              error: x => subscriber.error(x)
            })
        })
      }

    genFG(res?){
      this.formGroup = new FormGroup({
        order: new FormControl(res?.order, Validators.required),
        us: new FormControl(res?.multilingualNames['en-US'],Validators.required),
        cn: new FormControl(res?.multilingualNames['zh-CN'],Validators.required),
        hk: new FormControl(res?.multilingualNames['zh-HK'],Validators.required),
        desUS: new FormControl(res?.multilingualDescriptions['en-US']),
        desCN: new FormControl(res?.multilingualDescriptions['zh-CN']),
        desHK: new FormControl(res?.multilingualDescriptions['zh-HK']),
        url: new FormControl(res?.url),
      });
    }
}
