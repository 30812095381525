import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { JwtInterceptor, ErrorInterceptor } from '../app/_helpers';
import { NgbActiveModal, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';
import { LoginComponent } from './component/user-login/user-login.component'
import { MmitaAssetComponent } from './component/mmitaAsset/mmitaAsset.component';
import { CustomerComponent } from './component/customer/customer.component';

import { SidebarComponent } from './ui-library/sidebar/sidebar.component';
import { SidebarBrandComponent } from './ui-library/sidebar-brand/sidebar-brand.component';
import { TopbarComponent } from './ui-library/topbar/topbar.component';
import { FooterComponent } from './ui-library/footer/footer.component';
import { TopbarNavbarComponent } from './ui-library/topbar-navbar/topbar-navbar.component';
import { DashboardComponent } from './ui-library/pages/dashboard/dashboard.component';
import { FaqComponent } from './component/faq/faq.component';
import { FaqAddComponent } from './component/faq/add/faq.add.component';
import { FaqUpdateComponent } from './component/faq/update/faq.update.component';
import { FaqDeleteComponent } from './component/faq/delete/faq.delete.component';
import { ImageCarouselComponent } from './component/image-carousel/image-carousel.component';
import { ImageCarouselAddComponent } from './component/image-carousel/add/image-carousel.add.component';
import { ImageCarouselUpdateComponent } from './component/image-carousel/update/image-carousel.update.component';
import { ImageCarouselDeleteComponent } from './component/image-carousel/delete/image-carousel.delete.component';

import { LanguageComponent } from './component/language/language.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AssetCategoryComponent } from './component/asset-category/asset-category.component';
import { DeleteComponent } from './ui-library/dialog/delete/delete.component';
import { AnchorComponent } from './component/anchor/anchor.component';
import { AssetAbilityComponent } from './component/asset-ability/asset-ability.component';
import { ReportComponent } from './component/report/report.component';
import { MapComponent } from './component/map/map.component'
import { PointInfoComponent } from './component/map/component/point-info/point-info.component';
import { SearchPlaceComponent } from './component/map/component/search-place/search-place.component';
import { RecommendedListComponent } from './component/map/component/recommended-list/recommended-list.component';
import { TaskComponent } from './component/task/task.component';
import { SettingComponent } from './component/setting/setting.component';
import { DatePickerComponent } from './ui-library/date-picker/date-picker.component';
import { AddCoinComponent } from './component/customer/add-coin/add-coin.component';
import { WhiteListComponent } from './component/whitelist/whitelist.component';
import { LocalizationComponent } from './component/localization/localization.component';
import { LocalizationItemComponent } from './component/localization/localization-item/localization-item.component';
import { LocalizationCategoryComponent } from './component/localization/localization-category/localization-category.component';
import { RewardComponent } from './component/reward/reward.component';
import { StageComponent } from './component/map/component/stage/stage.component';
import { DetailComponent } from './ui-library/dialog/common/detail/detail.component';
import { LanguageVersionComponent } from './component/language-version/language-version.component';
import { LanguageVersionItemComponent } from './component/language-version/language-version-item/language-version-item.component';
import { MessageComponent } from './component/message/message.component';
import { LogComponent } from './component/log-page/log.component';
import { SketchfebComponent } from './component/mmitaAsset/sketchfeb/sketchfeb.component';
import { ResourceFileComponent } from './component/resource-file/resource-file.component';
import { PartnerComponent } from './component/dev-partner/partner/partner.component';
import { ProductComponent } from './component/dev-partner/product/product.component';
import { ProductVersionComponent } from './component/dev-partner/product-version/product-version.component';
import { UpdateVersionComponent } from './component/dev-partner/product-version/update-version/update-version.component';
import { InfiniteGridComponent } from './ui-library/component/infinite-grid/infinite-grid.component';
import { ContactUsComponent } from './component/dev-partner/contact-us/contact-us.component';
import { MarkdownComponent } from './component/dev-partner/markdown/markdown.component';
import { VerticalCarouselComponent } from './component/vertical-carousel/vertical-carousel.component';
import { ReviewBoardComponent } from './component/map/component/review-board/review-board.component';
import { StripeSettingComponent } from './component/stripe/stripe-setting/stripe-setting.component';
import { MitOffCanvasComponent } from './ui-library/mit-off-canvas/mit-off-canvas.component';
import { AssetDialogComponent } from './component/map/component/asset-dialog/asset-dialog.component';
import { TemplateComponent } from './component/template/template.component';
import { CouponComponent } from './component/coupon/coupon.component';
import { StripeCouponInfoComponent } from './component/stripe/stripe-coupon-info/stripe-coupon-info.component';
import { LinkAccountComponent } from './component/dev-partner/product-version/link-account/link-account.component';
import { IncomeComponent } from './component/dev-partner/partner/income/income.component';
import { FeeTableComponent } from './component/dev-partner/partner/income/fee-table/fee-table.component';
import { SearchFieldComponent } from './ui-library/search-form/search-field/search-field.component';
import { SearchFormComponent } from './ui-library/search-form/search-form.component';
import { CampaignComponent } from './component/campaign/campaign.component';
import { CampaignWaveComponent } from './component/campaign/campaign-wave/campaign-wave.component';
import { ConfirmComponent } from './ui-library/dialog/confirm/confirm.component';
import { WavePortalComponent } from './component/campaign/wave-portal/wave-portal.component';
import { PortalMarkerComponent } from './component/map/component/portal-marker/portal-marker.component';
import { MitTableComponent } from './ui-library/dialog/mit-table/mit-table.component';
import { RestaurantTypeComponent } from './component/restaurant-type/restaurant-type.component';
import { FakeDataComponent } from './component/fake-data/fake-data.component';
import { StageGroupComponent } from './component/map/component/stage-group/stage-group.component';
import { StageGroupItemComponent } from './component/map/component/stage-group-item/stage-group-item.component';
import { StreamerComponent } from './component/streamer/streamer.component';
import { StreamingComponent } from './component/streaming/streaming.component';
import { CesiumComponent } from './component/cesium/cesium.component';
import { CesiumPanelContentComponent } from './component/cesium/cesium-panel-content/cesium-panel-content.component';
import { AppRoutingModule } from './app-routing.module';
import { ZorroUiModules } from './zorro-ui';
import { DailyComponent } from './component/daily/daily.component';
import { MitFormComponent } from './ui-library/mit-form/mit-form.component';
import { MitUploaderComponent } from './ui-library/mit-uploader/mit-uploader.component';
import { WhiteListDialogComponent } from './component/whitelist/white-list-dialog/white-list-dialog.component';
import { MapGridComponent } from './component/map/component/map-grid/map-grid.component';
import { MarkerItemComponent } from './component/map/component/marker-item/marker-item.component';
import { AddStageGroupComponent } from './component/map/component/stage-group-item/add-stage-group/add-stage-group.component';
import { VisitorRecordComponent } from './component/visitor-record/visitor-record.component';
import { MitInputArrayComponent } from './ui-library/mit-input-array/mit-input-array.component';
import { MitDialogComponent } from './ui-library/mit-dialog/mit-dialog.component';
import { AntAssetItemComponent } from './component/mmitaAsset/ant-asset-item/ant-asset-item.component';
import { MitTagComponent } from './ui-library/mit-tag/mit-tag.component';
import { TagComponent } from './component/tag/tag.component';
import { AntCouponItemComponent } from './component/coupon/ant-coupon-item/ant-coupon-item.component';
import { AntCampaignWaveItemComponent } from './component/campaign/campaign-wave/ant-campaign-wave-item/ant-campaign-wave-item.component';
import { AntRewardItemComponent } from './component/reward/ant-reward-item/ant-reward-item.component';
import { MmitaTypeCouponItemComponent } from './component/coupon/mmita-type-coupon-item/mmita-type-coupon-item.component';
import { VcPostedComponent } from './component/vc-posted/vc-posted.component';
import { SendCouponComponent } from './component/coupon/send-coupon/send-coupon.component';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { EscapeGameSettingComponent } from './component/escape-game-setting/escape-game-setting.component';
import { MitNzFormItemComponent } from './ui-library/mit-nz-form-item/mit-nz-form-item.component';
import { MitNzUploaderComponent } from './ui-library/mit-nz-uploader/mit-nz-uploader.component';
import { GlbGeneratorImageComponent } from './component/mmitaAsset/glb-generator-image/glb-generator-image.component';
import { GlbGeneratorTextComponent } from './component/mmitaAsset/glb-generator-text/glb-generator-text.component';
import { MeshyComponent } from './component/meshy/meshy.component';
import { EscapeGameListComponent } from './component/escape-game-list/escape-game-list.component';
import { GameWhiteListComponent } from './component/whitelist/game-white-list/game-white-list.component';
import { WhitelistWrapperComponent } from './component/whitelist/whitelist-wrapper/whitelist-wrapper.component';
import { GameWhiteListDialogComponent } from './component/whitelist/game-white-list/game-white-list-dialog.component';
registerLocaleData(en); // for ant date picker

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MmitaAssetComponent,
    FaqComponent,
    FaqAddComponent,
    FaqUpdateComponent,
    FaqDeleteComponent,
    ImageCarouselComponent,
    ImageCarouselAddComponent,
    ImageCarouselUpdateComponent,
    ImageCarouselDeleteComponent,
    SidebarComponent,
    SidebarBrandComponent,
    TopbarComponent,
    FooterComponent,
    TopbarNavbarComponent,
    DashboardComponent,
    LoginComponent,
    CustomerComponent,
    LanguageComponent,
    AssetCategoryComponent,
    DeleteComponent,
    AnchorComponent,
    AssetAbilityComponent,
    ReportComponent,
    MapComponent,
    PointInfoComponent,
    SearchPlaceComponent,
    RecommendedListComponent,
    TaskComponent,
    SettingComponent,
    DatePickerComponent,
    AddCoinComponent,
    WhiteListComponent,
    LocalizationComponent,
    LocalizationItemComponent,
    LocalizationCategoryComponent,
    RewardComponent,
    StageComponent,
    DetailComponent,
    LanguageVersionComponent,
    LanguageVersionItemComponent,
    MessageComponent,
    LogComponent,
    SketchfebComponent,
    ResourceFileComponent,
    PartnerComponent,
    ProductComponent,
    ProductVersionComponent,
    UpdateVersionComponent,
    InfiniteGridComponent,
    ContactUsComponent,
    MarkdownComponent,
    VerticalCarouselComponent,
    ReviewBoardComponent,
    StripeSettingComponent,
    MitOffCanvasComponent,
    AssetDialogComponent,
    TemplateComponent,
    CouponComponent,
    StripeCouponInfoComponent,
    LinkAccountComponent,
    IncomeComponent,
    FeeTableComponent,
    SearchFieldComponent,
    SearchFormComponent,
    CampaignComponent,
    CampaignWaveComponent,
    ConfirmComponent,
    WavePortalComponent,
    PortalMarkerComponent,
    MitTableComponent,
    RestaurantTypeComponent,
    FakeDataComponent,
    StageGroupItemComponent,
    StageGroupComponent,
    StreamerComponent,
    StreamingComponent,
    CesiumComponent,
    CesiumPanelContentComponent,
    DailyComponent,
    MitFormComponent,
    MitUploaderComponent,
    WhiteListDialogComponent,
    MapGridComponent,
    MarkerItemComponent,
    AddStageGroupComponent,
    VisitorRecordComponent,
    MitInputArrayComponent,
    MitDialogComponent,
    AntAssetItemComponent,
    MitTagComponent,
    TagComponent,
    AntCouponItemComponent,
    AntCampaignWaveItemComponent,
    AntRewardItemComponent,
    MmitaTypeCouponItemComponent,
    VcPostedComponent,
    SendCouponComponent,
    EscapeGameSettingComponent,
    MitNzFormItemComponent,
    MitNzUploaderComponent,
    GlbGeneratorImageComponent,
    GlbGeneratorTextComponent,
    MeshyComponent,
    EscapeGameListComponent,
    GameWhiteListComponent,
    WhitelistWrapperComponent,
    GameWhiteListDialogComponent
  ],
  entryComponents:[
    FaqAddComponent,
    FaqUpdateComponent,
    FaqDeleteComponent,
    ImageCarouselAddComponent,
    ImageCarouselUpdateComponent,
    ImageCarouselDeleteComponent,
    DeleteComponent,
    DatePickerComponent,
    AddCoinComponent,
    LocalizationItemComponent,
    LocalizationCategoryComponent,
    DetailComponent,
    LanguageVersionItemComponent,
    SketchfebComponent,
    UpdateVersionComponent,
    ReviewBoardComponent,
    AssetDialogComponent,
    LinkAccountComponent,
    FeeTableComponent,
    ConfirmComponent,
    PortalMarkerComponent,
    MitTableComponent,
    StageGroupItemComponent,
    CesiumPanelContentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AgGridModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbModalModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    AppRoutingModule,
    ...ZorroUiModules
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      NgbActiveModal, DatePipe, 
      { provide: NZ_I18N, useValue: en_US }
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
