import { Component, OnInit, Input, Injector } from '@angular/core';
import { ImageService } from '../../../_services/imageCarousel.service';
import { first } from 'rxjs/operators';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-carousel-delete',
  templateUrl: './image-carousel.delete.component.html',
})

export class ImageCarouselDeleteComponent implements OnInit {
  id: string;
  @Input() public itemId;

  constructor(
    private imageService: ImageService,
    private injector: Injector,
    private global: Global,
    private toastr: ToastrService
    )  {  }

  ngOnInit() {
    this.id = this.itemId; // this.route.snapshot.params['id'];
  }
  imageCarouselDelete() {
    this.imageService.delete(this.id)
      .pipe(first())
      .subscribe(
        data => {
          this.modalClose();
          this.global.appEvent.next({ msg: 'reload.image-carousel', para: null });
          this.toastr.success('Data Deleted Successfully...!', 'Success');
        });
  }
  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });

  }

}
