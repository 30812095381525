
<nz-spin [nzSpinning]="isSpinning" [nzTip]="progress">
    <div nz-row [nzGutter]="16">
        <div nz-col [nzSpan]="12">
            <form nz-row [nzGutter]="16" nz-form [formGroup]="formGroup" [nzLayout]="'vertical'" (ngSubmit)="genPreviewGlb()">
                <mit-nz-form-item nz-col  [nzSpan]="24" [label]="'Text To 3d Prompt'" [required]="true">
                    <textarea
                    nz-input
                    [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                    formControlName="textTo3dPrompt"
                  ></textarea>
                </mit-nz-form-item>
                <mit-nz-form-item nz-col [nzSpan]="24" [label]="'Topology'" [required]="true">
                    <nz-radio-group formControlName="topology">
                        <label *ngFor="let item of Topology" nz-radio [nzValue]="item">{{item}}</label>
                      </nz-radio-group>
                </mit-nz-form-item>
                <mit-nz-form-item nz-col [nzSpan]="24" [label]="'Art Style'" [required]="true">
                    <nz-radio-group formControlName="artStyle">
                        <label *ngFor="let item of ArtStyle" nz-radio [nzValue]="item">{{item}}</label>
                      </nz-radio-group>
                </mit-nz-form-item>
                <mit-nz-form-item nz-col [nzSpan]="24" [label]="'Target Poly Count'" [required]="true">
                    <nz-input-number formControlName="targetPolycount" [nzSize]="'large'" [nzMin]="10000" [nzMax]="30000" [nzPrecision]="0"></nz-input-number>
                </mit-nz-form-item>
                <button nz-button nzType="primary" type="submit">Generate 3D file</button>
            </form>
        </div>
        <div nz-col [nzSpan]="12">
            <ng-container *ngIf="previewResult">
                <img
                nz-image
                width="260px"
                height="260px"
                [nzSrc]="previewResult?.thumbnailUrl"
                alt=""
            />
            <div style="padding: 10px 0;">
                <button nz-button nzType="default" type="button" (click)="showDetail()" style="margin-right: 5px;">View Detail</button>
                <button nz-button nzType="primary" type="button" (click)="genGlb()">Refine</button>
            </div>
            </ng-container>

            <ng-container *ngIf="finalResult">
                <img
                nz-image
                width="260px"
                height="260px"
                [nzSrc]="finalResult?.thumbnailUrl"
                alt=""
            />
            <div style="padding-top: 10px;">
                <button nz-button nzType="default" type="button" (click)="showDetail()" style="margin-right: 5px;">View Detail</button>
                <button nz-button nzType="primary" type="button" (click)="showCreateAsset()">Create Asset By This Glb</button>
            </div>
            </ng-container>

        </div>
    </div>
</nz-spin>

