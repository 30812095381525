import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription, catchError, finalize, interval, retry, switchMap, takeWhile, throwError } from 'rxjs';
import { AssetType } from 'src/app/_models/AssetType';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { Global } from 'src/app/_services/global.service';
import { MeshyService } from 'src/app/_services/meshy.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-glb-generator-image',
  templateUrl: './glb-generator-image.component.html',
  styleUrls: ['./glb-generator-image.component.css']
})
export class GlbGeneratorImageComponent implements OnInit {

    readonly Topology = this.global.section.MeshyTopologySelections
    readonly ArtStyle = this.global.section.MeshyArtStyleSelections
    isSpinning: boolean = false
    progress: string
    finalResult: any
    formGroup = new FormGroup({
        imageTo3dUrl: new FormControl('', Validators.required),
        topology: new FormControl(this.Topology[0], Validators.required),
        targetPolycount: new FormControl(10000, Validators.required),
        artStyle: new FormControl(this.ArtStyle[0], Validators.required)
    });
    private subscription: Subscription; 
    constructor(
        private global: Global,
        private meshyService: MeshyService,
        private fileUploadService: FileUploadService,
        private modalService: ModalService,
        private nzModalRef: NzModalRef,
        private toast: ToastrService
  ) { }

    ngOnInit(): void {
      
    }
    
    onSubmit() { 
        if (this.formGroup.value.imageTo3dUrl && this.formGroup.value?.imageTo3dUrl[0].file?.type.indexOf('gif') >= 0) { 
            this.formGroup.get('imageTo3dUrl').patchValue(null)
            this.toast.info('Please Upload PNG Or JPG File')
            return
        }
        if (!this.formGroup.value.imageTo3dUrl) {
            this.toast.info('Please complete the form')
            return
        }
        this.fileUploadService.postFile(this.formGroup.value.imageTo3dUrl).subscribe(res => { 
            this.generateGlb(res[0])            
        })
    }

    generateGlb(imageObj: any) {
        this.progress = null
        const imageTo3dUrl = imageObj.origin;
        const data = { ...this.formGroup.value, imageTo3dUrl: imageTo3dUrl };
        this.isSpinning = true;
    
        this.subscription = this.meshyService.genGlbByImage(data).pipe(
            catchError(error => {
                console.error('Error generating GLB:', error);
                return throwError(error); // Re-throw the error to trigger retry
            }),
            switchMap((res: any) => {
                if (!res.id) {
                    throw new Error('Response does not contain an ID');
                }
                const id = res.id;
                return interval(5000).pipe(
                    switchMap(() => this.meshyService.getMeshyById(id)),
                    takeWhile((result: any) => result.progress !== 100, true)
                );
            }),
            finalize(() => {
                this.isSpinning = false; // Ensure loading state is stopped after completion or failure
            })
        ).subscribe(finalResult => {
            this.progress = `${finalResult.progress}%`;
            if (finalResult.progress === 100) {
                this.finalResult = finalResult;
            }
        }, error => {
            console.error('Final error after retries:', error);
        });
    }

    showDetail() { 
        this.modalService.showDetail(this.finalResult, 'Detail')
    }

    showCreateAsset() {
        const glbFile = {
            url: this.finalResult.glbUrl
        }
        const data = {
            assetType: AssetType.Model3d,
            file: glbFile,
            androidFile: glbFile,
            visionOSFile: glbFile,
            macOSFile: glbFile,
            thumbnail: {
                url: this.finalResult.thumbnailUrl
            },
            smallThumbnail: {
                url: this.finalResult.smallThumbnailUrl
            },
            icon: {
                url: this.finalResult.iconUrl
            },
        }
        this.nzModalRef.close(data)
    }

    ngOnDestroy() { 
        this.subscription.unsubscribe()
    }

}
