import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mit-table',
  templateUrl: './mit-table.component.html',
  styleUrls: ['./mit-table.component.css']
})
export class MitTableComponent implements OnInit {
  data: Array<any>
  shownTitle: Array<string>
  // tableTitle: string
  action = false
  ngOnInit() {
  }

  triggerEvent(ev){
    ev.action.apply(this,[ev])
  }

}
