import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {cloneDeep} from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'app-language-version-item',
  templateUrl: './language-version-item.component.html',
  styleUrls: ['./language-version-item.component.scss']
})
export class LanguageVersionItemComponent implements OnInit {
  readonly clientList = this.global.section.LanguageVersionClientSelections
  isCreateMode: boolean = false;
  orgData: any;
  formGroup: FormGroup;
  fileSource: any
  constructor(
    private service: LanguageService,
    private toastr: ToastrService,
    private global: Global
  ) { }

 
  ngOnInit() {
    if(this.isCreateMode){
      this.formGroup = new FormGroup({
        ExcelFile:  new FormControl(null, Validators.required),
        Client: new FormControl(null, Validators.required),
        MinimumClientVersion: new FormControl(null),
        MaximumClientVersion: new FormControl(null)
      })
    }else {
      
      this.formGroup = new FormGroup({
        ExcelFile:  new FormControl(null),
        Client: new FormControl(this.orgData.client, Validators.required),
        MinimumClientVersion: new FormControl(this.orgData.minimumClientVersion),
        MaximumClientVersion: new FormControl(this.orgData.maximumClientVersion)
      })
    }

  }

  onFileChange(e){
    this.fileSource = e.target.files[0]
  }

  onLanguageFormSubmit(){
    const languageData = cloneDeep(this.formGroup.value)
    languageData.ExcelFile = this.fileSource;
    if(this.formGroup.invalid){
      this.toastr.info('Please Complete the Form', 'Info');
      return;
    }
    if(this.isCreateMode){
      this.service.postLanguageVersion(languageData).subscribe(res=>{
        this.toastr.success('Data Created Successfully...!', 'Success');
        this.global.appEvent.next({ msg: 'reload.languageVersion', para: null });
        this.modalClose();
      })
    } else {
      this.service.putLanguageVersion(this.orgData.id,languageData).subscribe(res=>{
        this.toastr.success('Data Created Successfully...!', 'Success');
        this.global.appEvent.next({ msg: 'reload.languageVersion', para: null });
        this.modalClose();
      })
    }

  }

  modalClose(){
    this.global.appEvent.next({ msg: 'modalService.close', para: null });
}   


}
