import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./_helpers";
import { AnchorComponent } from "./component/anchor/anchor.component";
import { AssetAbilityComponent } from "./component/asset-ability/asset-ability.component";
import { AssetCategoryComponent } from "./component/asset-category/asset-category.component";
import { CampaignWaveComponent } from "./component/campaign/campaign-wave/campaign-wave.component";
import { CampaignComponent } from "./component/campaign/campaign.component";
import { WavePortalComponent } from "./component/campaign/wave-portal/wave-portal.component";
import { CesiumComponent } from "./component/cesium/cesium.component";
import { CouponComponent } from "./component/coupon/coupon.component";
import { CustomerComponent } from "./component/customer/customer.component";
import { ContactUsComponent } from "./component/dev-partner/contact-us/contact-us.component";
import { MarkdownComponent } from "./component/dev-partner/markdown/markdown.component";
import { IncomeComponent } from "./component/dev-partner/partner/income/income.component";
import { PartnerComponent } from "./component/dev-partner/partner/partner.component";
import { ProductVersionComponent } from "./component/dev-partner/product-version/product-version.component";
import { ProductComponent } from "./component/dev-partner/product/product.component";
import { FakeDataComponent } from "./component/fake-data/fake-data.component";
import { FaqComponent } from "./component/faq/faq.component";
import { ImageCarouselComponent } from "./component/image-carousel/image-carousel.component";
import { LanguageVersionComponent } from "./component/language-version/language-version.component";
import { LanguageComponent } from "./component/language/language.component";
import { LocalizationComponent } from "./component/localization/localization.component";
import { LogComponent } from "./component/log-page/log.component";
import { MapComponent } from "./component/map/map.component";
import { MessageComponent } from "./component/message/message.component";
import { MmitaAssetComponent } from "./component/mmitaAsset/mmitaAsset.component";
import { ReportComponent } from "./component/report/report.component";
import { ResourceFileComponent } from "./component/resource-file/resource-file.component";
import { RestaurantTypeComponent } from "./component/restaurant-type/restaurant-type.component";
import { RewardComponent } from "./component/reward/reward.component";
import { SettingComponent } from "./component/setting/setting.component";
import { StreamerComponent } from "./component/streamer/streamer.component";
import { StreamingComponent } from "./component/streaming/streaming.component";
import { StripeSettingComponent } from "./component/stripe/stripe-setting/stripe-setting.component";
import { TaskComponent } from "./component/task/task.component";
import { TemplateComponent } from "./component/template/template.component";
import { LoginComponent } from "./component/user-login/user-login.component";
import { VerticalCarouselComponent } from "./component/vertical-carousel/vertical-carousel.component";
import { WhiteListComponent } from "./component/whitelist/whitelist.component";
import { DashboardComponent } from "./ui-library/pages/dashboard/dashboard.component";
import { DailyComponent } from "./component/daily/daily.component";
import { VisitorRecordComponent } from "./component/visitor-record/visitor-record.component";
import { TagComponent } from "./component/tag/tag.component";
import { VcPostedComponent } from "./component/vc-posted/vc-posted.component";
import { EscapeGameSettingComponent } from "./component/escape-game-setting/escape-game-setting.component";
import { MeshyComponent } from "./component/meshy/meshy.component";

const routes: Routes = [
    { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'asset/:categoryId', component: MmitaAssetComponent, canActivate: [AuthGuard]},
    { path: 'asset', component: MmitaAssetComponent, canActivate: [AuthGuard]},
    { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard] },
    { path: 'faq', component: FaqComponent, canActivate: [AuthGuard] },
    { path: 'carousel', component: ImageCarouselComponent, canActivate: [AuthGuard] },
    { path: 'language', component: LanguageComponent, canActivate: [AuthGuard] },
    { path: 'language-version', component: LanguageVersionComponent, canActivate: [AuthGuard] },
    { path: 'localization', component: LocalizationComponent, canActivate: [AuthGuard] },
    { path: 'category', component: AssetCategoryComponent, canActivate: [AuthGuard] },
    { path: 'anchor', component: AnchorComponent, canActivate: [AuthGuard] },
    { path: 'asset-ability', component: AssetAbilityComponent, canActivate: [AuthGuard] },
    { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
    { path: 'map', component: MapComponent, canActivate: [AuthGuard] },
    { path: 'task', component: TaskComponent, canActivate: [AuthGuard] },
    { path: 'setting', component: SettingComponent, canActivate: [AuthGuard] },
    { path: 'whitelist', component: WhiteListComponent, canActivate: [AuthGuard] },
    { path: 'reward', component: RewardComponent, canActivate: [AuthGuard] },
    { path: 'message', component: MessageComponent, canActivate: [AuthGuard] },
    { path: 'log', component: LogComponent, canActivate: [AuthGuard] },
    { path: 'resource', component: ResourceFileComponent, canActivate: [AuthGuard] },
    { path: 'developer', component: PartnerComponent, canActivate: [AuthGuard] },
    { path: 'product/:partnerId', component: ProductComponent, canActivate: [AuthGuard] },
    { path: 'product', component: ProductComponent, canActivate: [AuthGuard] },
    { path: 'version', component: ProductVersionComponent, canActivate: [AuthGuard] },
    { path: 'version/:type/:productId', component: ProductVersionComponent, canActivate: [AuthGuard] },
    { path: 'contact-us', component: ContactUsComponent, canActivate: [AuthGuard] },
    { path: 'mark-down', component: MarkdownComponent, canActivate: [AuthGuard] },
    { path: 'vertical-carousel', component: VerticalCarouselComponent, canActivate: [AuthGuard] },
    { path: 'stripe-setting', component: StripeSettingComponent, canActivate: [AuthGuard] },
    { path: 'template', component: TemplateComponent, canActivate: [AuthGuard] },
    { path: 'coupon', component: CouponComponent, canActivate: [AuthGuard] },  
    { path: 'income/:id', component: IncomeComponent, canActivate: [AuthGuard] },  
    { path: 'income', component: IncomeComponent, canActivate: [AuthGuard] },  
    { path: 'campaign', component: CampaignComponent, canActivate: [AuthGuard] },  
    { path: 'wave', component: CampaignWaveComponent, canActivate: [AuthGuard] },  
    { path: 'wave/:id', component: CampaignWaveComponent, canActivate: [AuthGuard] },  
    { path: 'portal/:id', component: WavePortalComponent, canActivate: [AuthGuard] },  
    { path: 'portal', component: WavePortalComponent, canActivate: [AuthGuard] },  
    { path: 'restaurant-type', component: RestaurantTypeComponent, canActivate: [AuthGuard] },
    { path: 'fake-data', component: FakeDataComponent, canActivate: [AuthGuard] },
    { path: 'streamer', component: StreamerComponent, canActivate: [AuthGuard] },
    { path: 'streaming/:streamerId', component: StreamingComponent, canActivate: [AuthGuard] },
    { path: 'streaming', component: StreamingComponent, canActivate: [AuthGuard] },
    { path: 'cesium', component: CesiumComponent, canActivate: [AuthGuard] },
    { path: 'daily', component: DailyComponent, canActivate: [AuthGuard] },
    { path: 'visitor-record', component: VisitorRecordComponent, canActivate: [AuthGuard] },
    { path: 'tag', component: TagComponent, canActivate: [AuthGuard] },
    { path: 'vc-posted', component: VcPostedComponent, canActivate: [AuthGuard] },
    { path: 'escape-game-setting', component: EscapeGameSettingComponent, canActivate: [AuthGuard] },
    { path: 'meshy', component: MeshyComponent, canActivate: [AuthGuard] },
]
@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule],
  })
  export class AppRoutingModule {
    constructor( ){
  
    }
  }