  <form [formGroup]="formGroup" nz-form (ngSubmit)="onSubmit()" [nzLayout]="'vertical'">
    <div nz-row [nzGutter]="16">
      <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="latitude">Exchange Count</nz-form-label>
            <nz-form-control nzErrorTip="Please input field">
              <nz-input-number formControlName="exchangeCount" class="mit-number-field"></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
    </div>
    <div>
      <button type="submit" nz-button nzType="primary" style="margin-right: 10px;">Save</button>
      <button type="button" nz-button nzType="primary" (click)="modalClose()" nzDanger>Close</button>
    </div>
</form>
  