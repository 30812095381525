<div class="md-popover login-popover" style="margin:30px">
    <section class="my-popover-header">
      <h3 style="text-align:center">{{isCreateMode ? 'Create Language Item' : 'Update Language Item'}}</h3>
    </section>
    <section class="my-popover-content">
      <form [formGroup]="formGroup" ngNativeValidate (ngSubmit)="onLanguageFormSubmit()">
        <section class="row">
            <div class="form-group col-6">
                <label for="order">ExcelFile:</label>
                <input
                id="ExcelFile"
                type="file"
                class="form-control"
                [multiple]="false"
                formControlName="ExcelFile"
                (change)="onFileChange($event)"
                />
            </div>

            <div class="form-group col-6">
                <label for="order">Client:</label>
                <select class="form-select" formControlName="Client" required>
                    <option *ngFor="let item of clientList" value="{{item}}">{{item}}</option>                
                  </select>
            </div>
            </section>

            <section class="row">
                <div class="form-group col-6">
                    <label for="order">MinimumClientVersion:</label>
                    <input type="text" class="form-control"  formControlName="MinimumClientVersion">

                </div>
    
                <div class="form-group col-6">
                    <label for="order">MaximumClientVersion:</label>
                    <input type="text" class="form-control" formControlName="MaximumClientVersion">
                </div>
                </section>
                <div class="row">
                    <div class="col-md-2">
                        <button type="submit" class="btn btn-block btn-primary">Save</button>
                      </div>
                      <div class="col-md-2">
                        <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
                      </div>
                </div>
        </form>
    </section>
</div>
