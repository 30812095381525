import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class StreamService {
  constructor(
    private httpClient: HttpClient
  ) { }

  getAll(){
    return this.httpClient.get<InfiniteResult>(`${environment.apiEndpoint}api/Streamer/Query`,{
      params:{
        'PageSize': '-1'
      }
    })
  }

  delete(id){
    return this.httpClient.delete(`${environment.apiEndpoint}api/Streamer/${id}`)
  }

  create(data){
    return this.httpClient.post(`${environment.apiEndpoint}api/Streamer`,data)
  }

  udpate(data, id){
    return this.httpClient.put(`${environment.apiEndpoint}api/Streamer/${id}`,data)
  }


  getStreamingEvents(streamerId?: string){
    const _params = streamerId ? {
      StreamerId: streamerId,
      PageSize: '-1'
    }:  {PageSize: '-1'} 

    return this.httpClient.get<InfiniteResult>(`${environment.apiEndpoint}api/Streaming/Events/Query`,{
      params: _params
    })
  }

  createStreamingEvent(data){
    return this.httpClient.post(`${environment.apiEndpoint}api/Streaming/Event`,data)
  }

  updateStreamingEvent(data, id){
    return this.httpClient.put(`${environment.apiEndpoint}api/Streaming/Event/${id}`,data)
  }

  deleteEvent(id){
    return this.httpClient.delete(`${environment.apiEndpoint}api/Streaming/Event/${id}`)
  }

  updateChannel(id, data){
    return this.httpClient.put(`${environment.apiEndpoint}api/Streaming/Channel/${id}`,data)
  }

}
