import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { MessageService } from 'src/app/_services/message.service';
import { ModalService } from 'src/app/_services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageTemplate } from './message-item.constant';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent extends BaseComponent {
  columnDefs: Array<any> = [];
  gridData: any;
  formGroup: FormGroup

  constructor(
    private messageService: MessageService,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    public global: Global,
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'reload.message':
          this.getMessageList();
          break;
      }
    });
   }

   setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Assigned Sent Date', field: 'assignedSentAt', sort: 'desc',
        valueFormatter: (params) =>{
          return this.datePipe.transform(params.value, 'yyyy-MM-dd, HH:mm  z') 
        }},
      { headerName: 'ID', field: 'id'},
      { headerName: 'Title', field: 'title'},
      { headerName: 'Personalised', field: 'isPersonalised'},
      { headerName: 'Message', field: 'message'},
      { headerName: 'Links', field: 'links',           
          cellRenderer: params => {
            let innerHtml = ''
            params.value.forEach(item=>{
              innerHtml += `<li style="list-style: none;">${item}</li>`
            });
            return innerHtml
          },
          autoHeight: true,
        },
      { headerName: 'Sent To', field: 'sentTo'},
      { headerName: 'Status', field: 'status'},
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 160,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Message Detail')
              break;
            case 'editDetail':
              this.showMessageItem(params.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
          }
        },
      }

  ];
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.getMessageList();
    params.api.sizeColumnsToFit();
    // params.api.resetRowHeights()
  }

  getMessageList(){
    this.messageService.getVirtualUserMsg().subscribe(res=>{
      this.gridData = res
    })
  }

  showMessageItem(orgData?){
    this.genFG(orgData)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, orgData?.id), this.formGroup, MessageTemplate)
  }

  onSubmit(itemId?){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.getMessageList()
        this.toastr.success('Data Saved Successfully...!', 'Success');
        subscriber.next()
        subscriber.complete()
      }

      this.formatImagesDetail()
      itemId ? this.messageService.putVirtualUserMsg(this.formGroup.value,itemId).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          }) :  this.messageService.postVirtualUserMsg(this.formGroup.value).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          })
      })
    }

    formatImagesDetail(){
      if(!Array.isArray(this.formGroup.value?.imagesDetail) || !this.formGroup.value?.imagesDetail?.length){
        return
      }
      const imagesDetail = this.formGroup.value.imagesDetail.map(item=>{
        return {
          url: item?.url || item?.origin?.url,
          width: item?.width || item?.origin?.pixel?.width,
          height: item?.height || item?.origin?.pixel?.height,
        }
      })
      this.formGroup.patchValue({imagesDetail: imagesDetail})
    }

  delete(id){
    this.messageService.deleteVirtualUserMsg(id).subscribe(res=>{
      this.getMessageList();
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    })
  }

  genFG(data?){
    this.formGroup = new FormGroup({
      title: new FormControl(data?.title),
      isPersonalised: new FormControl(data?.isPersonalised || false),
      message: new FormControl(data?.message, Validators.required),
      assignedSentAt: new FormControl(data?.assignedSentAt, Validators.required),
      links: new FormControl(data?.links || []),
      imagesDetail: new FormControl(data?.imagesDetail),
    })
    if(data){
      this.formGroup.get('title').disable()
      this.formGroup.get('isPersonalised').disable()
      this.formGroup.get('message').disable()
      this.formGroup.get('assignedSentAt').disable()
      this.formGroup.get('links').disable()
      this.formGroup.get('imagesDetail').disable()
    }
  }

}
