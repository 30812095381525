import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { CouponService } from 'src/app/_services/coupon.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';;

@Component({
  selector: 'app-stripe-coupon-info',
  templateUrl: './stripe-coupon-info.component.html',
  styleUrls: ['./stripe-coupon-info.component.css']
})
export class StripeCouponInfoComponent  extends BaseComponent {
  columnDefs: Array<any> = [];
  gridData: any;

  constructor(
    private couponService: CouponService,
    private modalService: ModalService,
    public global: Global,
  ) {
    super(global);
   }

   setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Stripe Account Id', field: 'stripeAccountId'},
      { headerName: 'Stripe Coupon Id', field: 'stripeCouponId'},
      { headerName: 'Valid', field: 'valid'},
      { headerName: 'Name', field: 'stripCoupon.name'},
      {
        headerName: 'Action',
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Stripe Coupon Detail')
              break;
          }
        },
      }
  ];
  }

  ngOnInit() {
    this.setColumnDefs();
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.getAll();
  }

  getAll(){
    // this.couponService.getStripeCoupon().subscribe((res)=>{
    //   this.gridData = res
    //   this.gridApi.sizeColumnsToFit()
    // })
  }
}