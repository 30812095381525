import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StageService } from 'src/app/_services/stage.service';

@Component({
  selector: 'app-stage-group',
  templateUrl: './stage-group.component.html',
  styleUrls: ['./stage-group.component.css']
})
export class StageGroupComponent extends BaseComponent {
  columnDefs: Array<any> = [];
  @Output() onFocusEvent = new EventEmitter<any>();
  @Output() onUpdateEvent = new EventEmitter<any>();
  gridData: Array<any>


  constructor(
    private stageService: StageService,
    private modalService: ModalService,
    private toastr: ToastrService,
    public global: Global,
    private datePipe: DatePipe,
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'delete.stageGroup':
          this.delete(para.id);
          break;
      }
    });
   }
   
   setColumnDefs(){
    this.columnDefs = [
      { headerName: 'ID', field: 'id', minWidth: 150},
      { headerName: 'Name', field: 'name', minWidth: 150},
      { headerName: 'Display Name', field: 'displayName', minWidth: 150},
      { headerName: 'isRoot', field: 'isRoot', minWidth: 150},
      { headerName: 'rootId', field: 'rootId', minWidth: 150},
      { headerName: 'longitude', field: 'longitude', minWidth: 150},
      { headerName: 'latitude', field: 'latitude', minWidth: 150},
      { headerName: 'altitude', field: 'altitude', minWidth: 150},
      { headerName: 'createdAt', field: 'createdAt', sort: 'desc', minWidth: 150,
      valueFormatter: (params) =>{
        return this.datePipe.transform(params.value, 'yyyy-MM-dd, HH:mm  z') 
      }},

      
      {
        headerName: 'Action', minWidth: 150,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    ${params.data.isRoot ? `<i class="fas fa-fw fa-rotate text-success" id="sync" style="margin-right:3px; cursor: pointer;"></i>`: ''}
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Grpup Detail')
              break;
            case 'delete':
              this.showDelete(params)
              break;
            case 'sync':
              this.sync(params.data)
              break;
          }
        },
      }

  ];
  }

  sync(data){
    this.stageService.syncGroup(data.id).subscribe(res=>{
      this.toastr.success('Sync Successfully')
    })
  }

  ngOnInit() {
    this.setColumnDefs();

  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }


  setData(data){
    this.gridData = data
  }



  showDelete(params){
    const id = params.data.id;
    this.modalService.commonDelete(id, 'delete.stageGroup', 'sm');
  }

  delete(id){
    this.stageService.deleteStageGroup(id).subscribe(res=>{
      this.onUpdateEvent.emit()
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    })
  }

  onRowClicked(ev){
    this.onFocusEvent.emit(ev.data)
  }


}
