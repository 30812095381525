<div class="md-popover login-popover" style="margin:30px">
  <h3 style="text-align:center">Faq Edit</h3>
  <section class="my-popover-content">
    <form *ngIf="getdata" ngNativeValidate [formGroup]="faqUpdate" (ngSubmit)="onFaqUpdateFormSubmit()">
      <div class="form-group col-6">
        <label for="Descrption">Order:</label>
        <input type="number" class="form-control" formControlName="Order" [(ngModel)]="getdata.order" min="1" step="0.1" required>
      </div>

      <div class="form-group col-6">
        <label for="type">Type:</label>
        <select class="form-select" formControlName="type" [(ngModel)]="getdata.type"  required>
          <option *ngFor="let item of typeList" value="{{item}}">{{item}}</option>
        </select>
      </div>
     
      <div class="form-group col-12">
        <label for="Name">Question:</label>
        <input type="text" class="form-control" [(ngModel)]="getdata.question" required formControlName="Question">
      </div>
      <div class="form-group col-12">
        <label for="Name">Answer:</label>
        <textarea type="text" class="form-control" required formControlName="Answer" [(ngModel)]="getdata.answer"></textarea>
      </div>

      <div class="form-group col-12">
        <label for="LanguageCode">Language:</label>
        <select class="form-select" formControlName="LanguageCode" [(ngModel)]="getdata.languageCode" required>
          <option *ngFor="let item of languageList" value="{{item.languageCode}}">{{item.displayName}} ({{item.languageName}})</option>
        </select>
      </div>
      <div class="row">
        <div class="col-md-2">
          <button type="submit" class="btn btn-block btn-primary">Update</button>
        </div>
        <div class="col-md-2">
          <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
        </div>
      </div>
    </form>
  </section>
</div>

