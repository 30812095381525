import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { InfiniteResult, MitFieldInfo } from 'src/app/_models/body';
import { CustomerService } from 'src/app/_services/customer.service';
import { Global } from 'src/app/_services/global.service';
import { WhitelistService } from 'src/app/_services/whitelist.service';
import { InputFieldType } from 'src/app/common/constants';

@Component({
  selector: 'app-white-list-dialog',
  templateUrl: './white-list-dialog.component.html',
  styleUrls: ['./white-list-dialog.component.css']
})
export class WhiteListDialogComponent implements OnInit {
  @Input() assetSourceArray: Array<any>;
  formGroup: FormGroup
  dialogFields: Array<MitFieldInfo>
  whiteListModuleType: Array<string>;
  callback = ()=> this.onSubmit()
  currentOptions: Array<any>

  constructor(
    private customerService: CustomerService,
    private whitelistService: WhitelistService,
    private global: Global,
    private toastr: ToastrService,
    private nzModalRef: NzModalRef
  ) { 

  }

  setDialogFields(moduleType?){
    const DeveloperDialogFields : Array<MitFieldInfo> = [
      {
          key: 'userId',
          label: 'User ID',
          type: InputFieldType.Selector,
          size: 24,
          required: true,
          selectorLabel: 'email',
          selectorSearchEvent: (ev)=>this.getActiveCustomer(ev),
          options: this.currentOptions
      },
      {
        key: 'moduleType',
        label: 'Module Type',
        type: InputFieldType.Selector,
        size: 24,
        required: true,
        options: this.whiteListModuleType,
      },
      {
        key: 'moduleDataId',
        label: 'Developer ID',
        type: InputFieldType.Text,
        size: 24,
        requiredEvent: ()=>{
          return this.formGroup.value.moduleType !== 'All'
        }
      },
    ]

    const ModuleDialogFields : Array<MitFieldInfo> = [
      {
          key: 'userId',
          label: 'User ID',
          type: InputFieldType.Selector,
          size: 24,
          required: true,
          selectorLabel: 'email',
          selectorSearchEvent: (ev)=>this.getActiveCustomer(ev),
          options: this.currentOptions

      },
      {
        key: 'moduleType',
        label: 'Module Type',
        type: InputFieldType.Selector,
        size: 24,
        required: true,
        options: this.whiteListModuleType,
      },
      {
        key: 'moduleDataId',
        label: 'Module Data ID',
        type: InputFieldType.Selector,
        size: 24,
        requiredEvent: ()=>{
          return this.formGroup.value.moduleType !== 'All'
        },
        options: this.assetSourceArray
      },
    ]
    return moduleType === 'Developer' ? DeveloperDialogFields : ModuleDialogFields
  }



  ngOnInit() {
    this.whiteListModuleType = this.global.section.WhiteListModuleTypeSelections
    this.formGroup = new FormGroup({
      userId: new FormControl(null, Validators.required),
      moduleType:  new FormControl('All', Validators.required),
      moduleDataId:  new FormControl(),
    });
    this.formGroup.get('moduleType').valueChanges.subscribe(moduleType=>{
      this.formGroup.get('moduleDataId').setValidators(moduleType === 'All' ? null: Validators.required)
      this.formGroup.get('moduleDataId').setValue(null)
      this.dialogFields = this.setDialogFields(moduleType)
    })
    this.dialogFields = this.setDialogFields()
  }


  onSubmit(){
    return new Observable(sub=>{
      this.whitelistService.insertNew(this.formGroup.value).subscribe(data => {
        this.toastr.success(`Saved Successfully...!`, 'Success');
        this.nzModalRef.triggerOk()
        sub.next()
        sub.complete()
      });
    })

  }


  getActiveCustomer(value?){
    return new Observable(subscriber => {
      const prop = value ? {Email_Contains: value} : null
      this.customerService.getAll({PageSize: 100, IsActive:true, ...prop}).subscribe((res: InfiniteResult)=>{
        this.currentOptions = res.data
        subscriber.next(res.data)
        subscriber.complete()
      });
    })
  }
}

