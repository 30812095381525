import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MapTab } from 'src/app/common/constants';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StageService } from 'src/app/_services/stage.service';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent extends BaseComponent implements OnInit {
  @Output() onFocusEvent = new EventEmitter<any>();
  @Output() onRemoveEvent = new EventEmitter<any>();
  @Output() focusOnNavEvent = new EventEmitter<string>();

  readonly navList = ['Normal', 'VerticalCarousel', 'ReviewBoard', 'Streamer']
  activeNav: string = this.navList[0];
  stages:Array<any> = []

  constructor(
    private modalService: ModalService,
    public global: Global,
    private stageService: StageService,
    private ngbModal: NgbModal,
    private toastr: ToastrService
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'delete.stage':
          this.delete(para.id);
          break;
      }
    });
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  setStageData(source, resetGrid){
    this.stages = resetGrid ? source: [...this.stages, ...source];
  }

  changeStageType(nav: string){
    this.activeNav = nav
    this.stages = []
    this.focusOnNavEvent.emit(this.activeNav)
  }

  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},   
        { headerName: 'Latitude', field: 'latitude'},
        { headerName: 'Longitude', field: 'longitude'},
        { headerName: 'Icon Url', field: 'iconUrl',minWidth: 150,
          cellClass: 'img-cell',
          cellRenderer: params => {
              return ` <img height="100%" src="${params.value}" alt="without backgroundUrl"/>`;
          },
        },
        {
          headerName: 'Action',
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Geospatial Drop Object Detail')
                break;
              case 'delete':
                this.showDeleteDialog(params)
                break;
              default:
                break;
            }
          },
        },
    ];
  }

  showDeleteDialog(param){
    this.modalService.commonDelete(param.data.id, 'delete.stage')
  }

  delete(id){
    this.onRemoveEvent.emit({id: id, type: MapTab.Stage})
  }

  onRowClicked(ev){
    this.stageService.getStageById(ev.data.id).subscribe(res=>{
      this.onFocusEvent.emit(res)
    })
  }


}
