<section class="mit-table" >
    <!-- <h3>{{tableTitle}}</h3> -->
    <table class="table table-striped">
        <thead>
            <th scope="col" *ngFor="let title of shownTitle">{{title}}</th>
            <th scope="col" *ngIf="action">Action</th>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td *ngFor="let title of shownTitle">
                    {{ item[title] }}
                </td>
                <td *ngIf="action">
                    <button type="button" class="btn btn-block btn-primary" (click)="triggerEvent(item)">Detail</button>
                </td>
            </tr>
        </tbody>
    </table>
</section>
