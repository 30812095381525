import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Fallback } from 'src/app/common/constants';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DetailComponent implements OnInit {
  detailData: any;
  allowCopy: boolean
  listOfMapData: TreeNodeInterface[] 
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  fallback = Fallback

  constructor(
    private toastrService: ToastrService
  ) { 
    
  }

  copyValue(copyValue){
    navigator.clipboard.writeText(copyValue);
    this.toastrService.success(`The copied content:${copyValue}`, 'Copy')
  }


  ngOnInit() {
    this.listOfMapData = this.buildTableTree(this.detailData)
    this.listOfMapData.forEach(item => {
      this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
    });
  }

  updateTabelCollapse(expand){
    for(let data of Object.values(this.mapOfExpandedData)){
      data.forEach(item => {
        item.expand = expand
      })
    }
  }

  isURL(str){
    const reg = /^https?:\/\/.+/
    return reg.test(str);
  }


  buildTableTree(data, levelKey = '0'){
    const listOfMapData: TreeNodeInterface[] = []
    const buildLevel = (levelKey)=>{
      const _truck = levelKey.split('-')
      const lastLevel = Number(_truck[_truck.length -1]) +1
      let level = ''
      _truck.forEach((item, index)=>{
        if(index === _truck.length-1){
          level = level.concat(lastLevel.toString())
        } else {
          level = level.concat(item.toString()+ '-')
        }
      })
      return level
    }
    for(const prop in data){
      levelKey = buildLevel(levelKey)
      if(typeof data[prop] === 'object' && data[prop]!== null){
        listOfMapData.push({
          key: levelKey,
          value: null,
          label: prop,
          children: this.buildTableTree(data[prop], levelKey + '-0')
        })
        
      } else {
        listOfMapData.push({
          key: levelKey,
          value: data[prop],
          label: prop,
          children: null,
          isURL: this.isURL(data[prop]),
          copy: this.allowCopy
        })
      }
    }
    return listOfMapData
  }


  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: false });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: false, parent: node });
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }
}


interface TreeNodeInterface {
  key: string;
  label: string;
  value: string;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
  level?: number;
  isURL?: boolean
  copy?: boolean
}