import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ImageCarousel } from '../_models/imageCarousel';

@Injectable({ providedIn: 'root' })
export class ImageService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<ImageCarousel[]>(`${environment.apiEndpoint}api/ImageCarousel`);
  }

  getById(id: string) {
    return this.http.get<ImageCarousel>(`${environment.apiEndpoint}api/ImageCarousel/${id}`);
  }

  addNew(carousel: ImageCarousel) {
    return this.http.post<string>(`${environment.apiEndpoint}api/ImageCarousel`, carousel, this.httpOptions)
  }

  updateById(carousel: ImageCarousel, id: number) {
    return this.http.put<ImageCarousel>(`${environment.apiEndpoint}api/ImageCarousel/${id}`, carousel, this.httpOptions)
  }

  delete(id: string) {
    return this.http.delete<ImageCarousel>(`${environment.apiEndpoint}api/ImageCarousel/${id}`);
  }
}
