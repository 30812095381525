<section class="header-btn-wrapper">
  <button nz-button nzType="primary" (click)="showMessageItem()">
    <i class="fas fa-fw fa-plus"></i> Create Message
  </button>
</section>
<ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="gridData"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    [enableCellTextSelection]="true"
    (gridReady)="onGridReady($event)"
    >
</ag-grid-angular>

