import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const ResourceTemplate = (): DialogTemplate =>  {
    return {
        dialogTitle: 'Set Review Board Type',
        dialogFields: [
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 24,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 24,
            },
            {
                key: 'resourceUrl',
                label: 'Resource File',
                type: InputFieldType.Uploader,
                size: 24,
                required: true
            }
        ]
    }
}