<div class="col-md-6 offset-md-3 mt-5 login-wrapper">
  <div class="card">
    <h4 class="card-header">MMITA Admin Login</h4>
    <div class="card-body">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" name="username" formControlName="username" autocomplete="on" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" name="password" formControlName="password"  autocomplete="on" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
        </div>
        <button [disabled]="loading" class="btn btn-primary" type="submit">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Login
        </button>
      </form>
    </div>
  </div>
</div>            
