import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { ResourceFileService } from 'src/app/_services/resource-file.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourceTemplate } from './resource-file-item.constant';
import { Observable } from 'rxjs';
import { setNewOriginUrl } from 'src/app/_helpers/utility';

@Component({
  selector: 'app-resource-file',
  templateUrl: './resource-file.component.html',
  styleUrls: ['./resource-file.component.css'],
  providers: [DatePipe]

})
export class ResourceFileComponent extends BaseComponent implements OnInit {
  data: Array<any>;
  formGroup: FormGroup
  constructor(
    private service: ResourceFileService,
    public global: Global,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private modalService: ModalService
  ) { 
    super(global)
  }

  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Name', field: 'name'},
        { headerName: 'ResourceUrl', field: 'resourceUrl'},
        { headerName: 'Version', field: 'fileVersion'},
        { headerName: 'Description', field: 'description'},
        { headerName: 'Size', field: 'size'},
        { headerName: 'Created At', field: 'createdAt',
          valueFormatter: (params) =>{
            return this.datePipe.transform(params.value, 'short') 
          }
        },
        { headerName: 'Action',
          pinned: 'right',
          cellRenderer: params => {
            return ` <i class="fas fa-fw fa-trash grid-btn-icon text-danger"></i>`;
          },
          cellStyle: params => {
            return {cursor: 'pointer'};
          },
          minWidth: 50,
          onCellClicked: params => { this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))}
        },
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  getAll(){
    this.service.getResourceFile().subscribe(
      (data: Array<any>) => {
        this.data = data;
      });
  }

  showItem(){
    this.genFG()
    const dialogTemplate = ResourceTemplate()
    this.modalService.createNzModal(()=>this.onSubmit.call(this), this.formGroup, dialogTemplate)
  }

  delete(id){
    this.service.deleteResourceFile(id).subscribe(res=>{
      this.getAll();
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    })
  }

  genFG(){
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      resourceUrl: new FormControl(null, Validators.required),
      description: new FormControl()
    })
  }


  onSubmit(){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.getAll()
        this.toastr.success('Data Saved Successfully...!', 'Success');
        subscriber.next()
        subscriber.complete()
      }
      setNewOriginUrl(this.formGroup, 'resourceUrl')
       this.service.postResourceFile(this.formGroup.value).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          })
      })
  }
}
