import { Component, OnInit } from '@angular/core';
import { User } from '../../_models/models';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-topbar-navbar',
  templateUrl: './topbar-navbar.component.html',
  styleUrls: ['./topbar-navbar.component.css']
})
export class TopbarNavbarComponent implements OnInit {
  currentUser: User;
  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
  }
  logout() {
    this.authenticationService.logout();
  }
}
