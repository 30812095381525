import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fee-table',
  templateUrl: './fee-table.component.html',
  styleUrls: ['./fee-table.component.css']
})
export class FeeTableComponent implements OnInit {
  data
  constructor() { }

  ngOnInit() {
  }

}
