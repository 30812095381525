import { Component } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { MetricService } from 'src/app/_services/metric.service';
import { ModalService } from 'src/app/_services/modal.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';

@Component({
  selector: 'app-vc-posted',
  templateUrl: './vc-posted.component.html',
  styleUrls: ['./vc-posted.component.css']
})
export class VcPostedComponent extends BaseComponent {
  data: Array<any>
  constructor(
    private metricService: MetricService,
    public global: Global,
    private modalService: ModalService
  ) { 
    super(global);
  }

  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'User ID', field: 'userId'},
        { headerName: 'Email', field: 'email'},
        { headerName: 'Coupon Count', field: 'couponCount'},
        { headerName: 'Vertical Carousel Posted Count', field: 'verticalCarouselPostedCount'},
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 120,
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Detail')
                break;
            }
          },
        }
    ];
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.metricService.getVCPosted().subscribe((res: Array<any>)=>{
      this.data = res
    })
  }

  ngOnInit() {
    this.setColumnDefs();
  }



}
