import { Component, ElementRef, ViewChild } from '@angular/core';
import { LanguageService } from '../../_services/language.service'
import { ModalService } from '../../_services/modal.service';
import { LanguageReadDto } from '../../_models/languageReadDto';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageTemplate } from './language-item.constant';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent extends BaseComponent{
  languageReadDto: LanguageReadDto[];
  formGroup: FormGroup

  constructor(
    private languageService: LanguageService,
    private modalService: ModalService,
    public global: Global,
    private toastr: ToastrService
  ) {
    super(global);
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchLanguageAll();
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Order', field: 'order', sort: 'asc'},
      { headerName: 'Language Code', field: 'languageCode'},
      { headerName: 'Language Name', field: 'languageName' },
      { headerName: 'Display Name', field: 'displayName' },
      { headerName: 'Active', field: 'isActive' },
      { headerName: 'Key', field: 'mobileLanguageKey' },

      
      { headerName: 'Version', field: 'fileVersion' },
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Language Detail')
              break;
            case 'editDetail':
              this.showItem(params?.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))

              break;
          }
        },
      }
  ];
  }
  fetchLanguageAll() {
    this.languageService.apiLanguageGet().subscribe(
      data => {
        this.languageReadDto = data;
    });
  }


  delete(id) {
    this.languageService.apiLanguageIdDelete(id).subscribe(
      data => {
        this.fetchLanguageAll()
        this.toastr.success('Data Deleted Successfully...!', 'Success');
    });
  }
  showItem(data?) {
      this.genFG(data)
      const dialogTemplate = LanguageTemplate(!!data)
      this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }

  onSubmit(id){
    return new Observable(subscriber => {
      const androidFontAssetUrlVal = this.formGroup.get('androidFontAssetUrl')?.value?.[0]?.origin?.url
      const iosFontAssetUrlVal = this.formGroup.get('iosFontAssetUrl')?.value?.[0]?.origin?.url
      const onSuccess = ()=>{
        this.toastr.success(id ? 'Data Updated Successfully...!' : 'Data Created Successfully...!', 'Success');
        this.fetchLanguageAll()
        subscriber.next()
        subscriber.complete()
      }
      androidFontAssetUrlVal && this.formGroup.get('androidFontAssetUrl').setValue(androidFontAssetUrlVal)
      iosFontAssetUrlVal && this.formGroup.get('iosFontAssetUrl').setValue(iosFontAssetUrlVal)
      
      id ? this.languageService.apiLanguageIdPut(id, this.formGroup.value).subscribe({
        next: x => onSuccess(),
        error: x => subscriber.error(x)
      }):  this.languageService.apiLanguagePost(this.formGroup.value).subscribe({
        next: x => onSuccess(),
        error: x => subscriber.error(x)
      })
    })
  }


  genFG(orgData?){
    this.formGroup = new FormGroup({
      order: new FormControl(orgData?.order, Validators.required),
      languageCode: new FormControl(orgData?.languageCode, Validators.required),
      languageName: new FormControl(orgData?.languageName, Validators.required),
      displayName: new FormControl(orgData?.displayName, Validators.required),
      isActive: new FormControl(orgData?.isActive || false, Validators.required),
      mobileLanguageKey: new FormControl(orgData?.mobileLanguageKey),
      iosFontAssetUrl: new FormControl(orgData?.iosFontAssetUrl),
      androidFontAssetUrl: new FormControl(orgData?.androidFontAssetUrl),
    })
  }
}
