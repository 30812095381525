import { Component, Injector, OnInit, Input } from '@angular/core';
import { Global } from 'src/app/_services/global.service';

export class CommonDialogComponent{

  constructor(
    public global: Global,
  ) {
  }


  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });
  }
}
