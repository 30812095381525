import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchFieldType } from 'src/app/common/constants';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  @Input()formCtrl: FormControl
  @Input()field: any
  readonly SearchFieldType = SearchFieldType
  constructor() { }


  ngOnInit() {

  }



}
