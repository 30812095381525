  <section *ngIf="customerPageMode" class="header-btn-wrapper">
    <button nz-button nzType="primary" (click)="deleteBatchCustomer()" nzDanger>Delete Batch Users</button>
  </section> 
  <app-search-form [searchSection]="searchSection" (searchCriteria)="setSearchCriteria($event)"></app-search-form>
  <ag-grid-angular
  #agGrid
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [suppressRowClickSelection]="true"
  [enableCellTextSelection]="true"
  (gridReady)="onGridReady($event)"
  [gridOptions]="gridOptions"
  style="height: 80vh" 
  (sortChanged)="sortChanged($event)"
  (selectionChanged)="onSelectionChanged()"
>
</ag-grid-angular>

