import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Campaign/Query`, {
      params: {
        PageSize: '-1'
      }
    });
  }

  getCampaignItem(id){
    return this.http.get<any>(`${environment.apiEndpoint}api/Campaign/${id}`);
  }

  updateCampaign(id, data){
    return this.http.put(`${environment.apiEndpoint}api/Campaign/${id}`, data)
  }

  postCampaign(data){
    return this.http.post(`${environment.apiEndpoint}api/Campaign`, data)
  }

  releaseCampaign(id){
    return this.http.put(`${environment.apiEndpoint}api/Campaign/${id}/Release`, null)
  }

  takeDownCampaign(id){
    return this.http.put(`${environment.apiEndpoint}api/Campaign/${id}/TakeDown`, null)
  }

  deleteCampaign(id){
    return this.http.delete(`${environment.apiEndpoint}api/Campaign/${id}`)
  }

  getAllWave(campaignId?){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Campaign/Wave/Query`, 
    campaignId?  { params: {
        PageSize: '-1',
        CampaignId: campaignId
      }
    } : {params: {
      PageSize: '-1',
    }});
  }

  updateWave(campaignId, waveId, data){
    return this.http.put(`${environment.apiEndpoint}api/Campaign/${campaignId}/Wave/${waveId}`, data)
  }

  postWave(campaignId, data){
    return this.http.post(`${environment.apiEndpoint}api/Campaign/${campaignId}/Wave`, data)
  }

  deleteWave(campaignId, waveId){
    return this.http.delete(`${environment.apiEndpoint}api/Campaign/${campaignId}/Wave/${waveId}`)
  }

  getAllPortal(params){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Campaign/WavePortal/Query`,
     {
      params: params
    });
  }

  createWavePortal(data){
    return this.http.post(`${environment.apiEndpoint}api/Campaign/WavePortal`, data)
  }

  updateWavePortal(data, wavePortalId){
    return this.http.put(`${environment.apiEndpoint}api/Campaign/WavePortal/${wavePortalId}`, data)
  }

  deletePortal(wavePortalId){
    return this.http.delete(`${environment.apiEndpoint}api/Campaign/WavePortal/${wavePortalId}`)
  }

  getWaveUnlockUser(waveId, userId){
    return this.http.get(`${environment.apiEndpoint}api/Campaign/Wave/${waveId}/UnlockedUser/${userId}/Status`)
  }

  getWaveUnlockUserInfo(waveId){
    return this.http.get(`${environment.apiEndpoint}api/Campaign/Wave/${waveId}/UnlockedUsers`)
  }

  batchDelete(ids){
    return this.http.delete(`${environment.apiEndpoint}api/Campaign/WavePortals`,{ body: {
      ids: ids
    }})
  }
}
