import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation  } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SearchField } from 'src/app/_models/body';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchFormComponent implements OnInit {
  @Input() searchSection: Array<SearchField> = []
  formGroup: FormGroup = new FormGroup({})
  @Output() searchCriteria = new EventEmitter<any>()
  constructor() { }

  ngOnInit() {
    this.searchSection.forEach(item=>{
      this.formGroup.setControl(item.key,  new FormControl())
    })
  }


  onSubmit(){
    let criteria = {}
    for(let prop in this.formGroup.value){
      if(this.formGroup.value[prop] !== undefined && this.formGroup.value[prop] !== null && this.formGroup.value[prop] !== ''){
        criteria[prop] = this.formGroup.value[prop]
      }
    }

    this.searchCriteria.emit(criteria)
  }

  setFormCtrl(key,value){
    this.formGroup.setControl(key, new FormControl(value))
    this.onSubmit()
  }

}


