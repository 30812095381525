import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const MarkDownTemplate :DialogTemplate ={
        dialogTitle: 'Set Mark Down',
        dialogFields: [
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'order',
                label: 'Order',
                type: InputFieldType.Number,
                size: 12,
                required: true
            },
            {
                key: 'mdType',
                label: 'MD Type',
                type: InputFieldType.Selector,
                size: 12,
                options: ['None', 'GameSdk'],
                required: true
            },
            {
                key: 'uniqueKey',
                label: 'Unique Key',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'url',
                label: 'Markdown File',
                type: InputFieldType.Uploader,
                size: 12,
                required: true
            },
            {
                key: 'description',
                label: 'Description',
                type: InputFieldType.Text,
                size: 24,
                required: true
            }
        ]
}
