import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {finalize, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    count = 0;

    constructor(
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
      const currentUser = this.authenticationService.currentUserValue;
      const isLoggedIn = currentUser && currentUser.accessToken;
      const isApiUrl = request.url.startsWith(environment.apiEndpoint) || request.url.startsWith(environment.FILE_PATH) ;
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                Authorization: `Bearer ${currentUser.accessToken}`,
                Client: 'Web'
                }
            });
        }
        setTimeout(()=>{    // to handel ExpressionChangedAfterItHasBeenCheckedError 
            this.count++;
            if (this.count === 1) {
                this.spinner.show()
            }
        },0)

        return next.handle(request).pipe(
            finalize(() => {
              this.count--;
              if (this.count === 0) {
                    this.spinner.hide();
                }
            })
          );;
    }
}
