import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const AbilityTemplate = (editMode): DialogTemplate =>  {
    return {
        dialogTitle: editMode ? 'Edit Asset Ability' :'Add Asset Ability',
        dialogFields: [
            {
                key: 'order',
                label: 'Order',
                type: InputFieldType.Number,
                size: 24,
                required: true
            },
            {
                key: 'us',
                label: 'Language/en-US (Name)',
                type: InputFieldType.Text,
                size: 8,
                required: true
            },
            {
                key: 'cn',
                label: 'Language/zh-CN (Name)',
                type: InputFieldType.Text,
                size: 8,
                required: true
            },
            {
                key: 'hk',
                label: 'Language/zh-HK (Name)',
                type: InputFieldType.Text,
                size: 8,
                required: true
            },
            {
                key: 'desUS',
                label: 'Language/en-US (Description)',
                type: InputFieldType.Text,
                size: 8,
            },
            {
                key: 'desCN',
                label: 'Language/zh-CN (Description)',
                type: InputFieldType.Text,
                size: 8,
            },
            {
                key: 'desHK',
                label: 'Language/zh-HK (Description)',
                type: InputFieldType.Text,
                size: 8,
            },
            {
                key: 'url',
                label: 'Image',
                type: InputFieldType.Uploader,
                size: 24,
                showPreviewImage: true
            },
        ]
    }
 
}