import { DialogTemplate } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const LinkAccountTemplate: DialogTemplate = {
        dialogTitle: 'Link Account',
        dialogFields: [
            {
                key: 'applePayProductId',
                label: 'ApplePay Product ID',
                type: InputFieldType.Text,
                size: 24,
            },
            {
                key: 'googlePayProductId',
                label: 'GooglePay Product ID',
                type: InputFieldType.Text,
                size: 24,
            }
        ]
}
