import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mit-off-canvas',
  templateUrl: './mit-off-canvas.component.html',
  styleUrls: ['./mit-off-canvas.component.scss']
})
export class MitOffCanvasComponent implements OnInit {
  collapsed = false
  constructor() { }

  ngOnInit() {
  }

}
