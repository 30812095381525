    <section class="header-btn-wrapper">
      <button nz-button  nzType="primary" (click)="openItem()">
        <i class="fas fa-fw fa-plus"></i> Add Vertical Carousel
      </button>
    </section>
    <ag-grid-angular
      #agGrid
      domLayout='autoHeight'
      class="ag-theme-alpine"
      [rowData]="data"
      [columnDefs]="columnDefs"
      rowSelection="multiple"
      [defaultColDef]="defaultColDef"
      [pagination]="true"
      [suppressRowClickSelection]="true"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  
  
  