    <section class="header-btn-wrapper">
      <button nz-button nzType="primary"(click)="addLanguage()">
        <i class="fas fa-fw fa-plus"></i>Add Translation File
      </button>
  
      <div class="search-section">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
          <input type="text" nz-input [(ngModel)]="text"/>
        </nz-input-group>
        <ng-template #suffixButton>
          <button nz-button nzType="primary" nzSearch (click)="getTranslation(text)" [disabled]="!text">Translate</button>
        </ng-template>
      </div>

    </section>
    <ag-grid-angular
      #agGrid
      domLayout='autoHeight'
      class="ag-theme-alpine"
      [rowData]="languageReadDto"
      [columnDefs]="columnDefs"
      rowSelection="multiple"
      [defaultColDef]="defaultColDef"
      [pagination]="true"
      [suppressRowClickSelection]="true"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  
  
  