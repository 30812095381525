import { Component, Injector, OnInit, Input } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';
import { CommonDialogComponent } from '../common/common-dialog.component';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
})

export class DeleteComponent extends CommonDialogComponent implements OnInit{
  itemType: string;
  itemId: any;
  deleteMessage: string = 'Are you sure you want to delete this record?';
  prop: any
  constructor(
    global: Global,
  ) {
    super(global)
  }

  ngOnInit(){
    if(this.itemId && Array.isArray(this.itemId)){
      this.deleteMessage = `Are you sure you want to delete ${this.itemId.length>1 ? `${this.itemId.length} records`: `${this.itemId.length} record`}`;
    } else if(!this.itemId){
      this.deleteMessage = `Are you sure you want to delete all records`;
    }
  }

  

  delete(){
    this.global.appEvent.next({ msg: this.itemType, para: {
        id: this.itemId,
        ...this.prop
    }});
    this.modalClose();
  }

}
