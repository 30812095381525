<section class="header-btn-wrapper">
      <button type="button" nz-button (click)="showItem()" nzType="primary">
        <i class="fas fa-fw fa-plus"></i> Add New Asset Category
      </button>
    </section>
    <ag-grid-angular
      #agGrid
      domLayout='autoHeight'
      class="ag-theme-alpine"
      [rowData]="category"
      [columnDefs]="columnDefs"
      rowSelection="multiple"
      [defaultColDef]="defaultColDef"
      [pagination]="true"
      [suppressRowClickSelection]="true"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
      [gridOptions]="gridOptions"
    >
    </ag-grid-angular>