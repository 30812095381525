import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { CampaignService } from 'src/app/_services/campaign.service';
import { CategoryService } from 'src/app/_services/category.service';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { RewardService } from 'src/app/_services/reward.service';
import { StreamService } from 'src/app/_services/streamer.service';
import { VerticalCarouselService } from 'src/app/_services/vertical-carousel.service';

@Injectable({
  providedIn: 'root'
})
export class MapDataSourceService {

  constructor(
    private rewardService: RewardService,
    private assetService: MmitaAssetService,
    private vcService: VerticalCarouselService,
    private campaignService: CampaignService,
    private categoryService: CategoryService,
    private streamService: StreamService
  ) {
   }

  // to remove
  getSource(assetCategoryParam){
    return new Observable(subscriber => {
      forkJoin({
        allRewards: this.rewardService.getAll({HosterType: 'MapPlace'}),
        assetCategory: this.categoryService.getAssetCategory(assetCategoryParam),
        vcSourceList: this.vcService.getVerticalCarouselList(),
        portalSource: this.campaignService.getAllPortal({PageSize: '-1'}),
        streamingEventSource: this.streamService.getStreamingEvents(),
        streamerSubTypeAssetSource: this.assetService.getAll({PageSize: '-1', BusinessType: 'Streamer'}),
        campaignSource: this.campaignService.getAll()
      }).subscribe(res=>{
        subscriber.next(res)
        subscriber.complete()
      }, error=>{
        subscriber.error()
      })
    })

  }


  getSourceNewVersion(){
    return new Observable(subscriber => {
      forkJoin({
        allRewards: this.rewardService.getAll({HosterType: 'MapPlace'}),
        assetCategory: this.categoryService.getAssetCategory({IsStageTypeNormal: true}),
        vcSourceList: this.vcService.getVerticalCarouselList(),
        portalSource: this.campaignService.getAllPortal({PageSize: '-1'}),
        streamingEventSource: this.streamService.getStreamingEvents(),
        streamerSubTypeAssetSource: this.assetService.getAll({PageSize: '-1', BusinessType: 'Streamer'}),
        campaignSource: this.campaignService.getAll(),
        occlusionMeshAssetCategory: this.categoryService.getAssetCategory({AssetCategoryOption: 'OcclusionMesh'}),
      }).subscribe({
        next:res=>{
          const dataSource = {}
          for(let prop in res){
            dataSource[prop] = res[prop]?.data
          }
          subscriber.next(dataSource)
          subscriber.complete()
        }, 
        error: ()=>{
          subscriber.error()
        }

      })
    })

  }
}
