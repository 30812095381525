import { MitFieldInfo } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const RewardFields = (constantOptionDatas, assetList, preivewAssets, stripeCoupon, unshowField: Array<string>, rewardAssetIdsRequired): Array<MitFieldInfo>=>{
    return baseField(constantOptionDatas, assetList, preivewAssets, stripeCoupon, rewardAssetIdsRequired).filter(item=>{
        return unshowField.indexOf(item.key)< 0
    })
}

const baseField = (constantOptionDatas, assetList, preivewAssets, stripeCoupon, rewardAssetIdsRequired): Array<MitFieldInfo>=>{
    return [
        {
            key: 'mapRewardType',
            label: 'Map Reward Type',
            type: InputFieldType.Selector,
            options: constantOptionDatas.mapRewardType,
            size: 12,
            required: true,
        },
        {
            key: 'coolDownIntervalType',
            label: 'Cool Down Interval Type',
            type: InputFieldType.Selector,
            options: constantOptionDatas.coolDownIntervalType,
            size: 12,
            required: true
        },
        {
            key: 'hosterType',
            label: 'Hoster Type',
            type: InputFieldType.Selector,
            options: constantOptionDatas.hosterType,
            size: 12,
            required: true
        },
        {
            key: 'ratioOnMap',
            label: 'Ratio On Map',
            type: InputFieldType.Number,
            size: 12,
        },
        {
            key: 'description',
            label: 'Description',
            type: InputFieldType.Text,
            size: 24,
        },
        {
            key: 'rewardAssetIds',
            label: 'Reward Asset',
            type: InputFieldType.Selector,
            options: assetList,
            mode: 'multiple',
            size: 24,
            required: rewardAssetIdsRequired
        },
        {
            key: 'previewAssetId',
            label: 'Preview Asset Id',
            type: InputFieldType.Selector,
            options: preivewAssets,
            size: 12,
            required: true
        },

        {
            key: 'numberOfTokens',
            label: 'Number Of Tokens',
            type: InputFieldType.Number,
            size: 12,
            required: true
        },
        {
            key: 'mmitaCouponIds',
            label: 'Mmita Coupon Ids',
            type: InputFieldType.Selector,
            mode: 'multiple',
            size: 24,
            options: stripeCoupon,
            maxCount: 1,
            required: true
        },
        {
            key: 'isRefreshable',
            label: 'Refreshable',
            type: InputFieldType.Switch,
            size: 12,
        },
        {
            key: 'isRandomPoint',
            label: 'Random Point',
            type: InputFieldType.Switch,
            size: 12,
        },
        {
            key: 'isLimitMaxCollectedCount',
            label: 'Limit Max Collected Count',
            type: InputFieldType.Switch,
            size: 12,
        },
        {
            key: 'isRandomReward',
            label: 'Random Reward',
            type: InputFieldType.Switch,
            size: 12,
        },
        {
            key: 'totalCollectableCount',
            label: 'Total Collectable Count',
            type: InputFieldType.Number,
            size: 12,
            required: true
        },
        {
            key: 'alreadyCollectedCount',
            label: 'Already Collected Count',
            type: InputFieldType.Number,
            size: 12,
            required: true
        },
        {
            key: 'tokensRatio',
            label: 'Tokens Ratio',
            type: InputFieldType.Number,
            size: 12,
            required: true
        },
        {
            key: 'mmitaCouponRatio',
            label: 'Mmita Coupon Ratio',
            type: InputFieldType.Number,
            size: 12,
            required: true
        },
    ]
}