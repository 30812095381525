import { MitFieldInfo } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const WaveField = (assetSource, categorySource, assetOptionsSource): Array<MitFieldInfo> =>{
    return [
        {
            key: 'portalPreviewAssetId',
            label: 'Portal Preview Asset',
            type: InputFieldType.Selector,
            size: 12,
            options: assetSource,
            required: true
        },
        {
            key: 'coverProfileUrl',
            label: 'Cover Profile Url',
            type: InputFieldType.Uploader,
            size: 12,
            required: true
        },
        {
            key: 'assetCategoryId',
            label: 'Asset Category',
            type: InputFieldType.Selector,
            size: 24,
            options: categorySource,
            required: true
        },
        {
            key: 'assetIds',
            label: 'Assets',
            type: InputFieldType.Selector,
            mode: 'multiple',
            size: 24,
            options: assetOptionsSource,
            required: true
        },
        {
            key: 'startAt',
            label: 'Start At',
            type: InputFieldType.DateTimePicker,
            size: 12,
            required: true
        },
        {
            key: 'endAt',
            label: 'End At',
            type: InputFieldType.DateTimePicker,
            size: 12,
            required: true
        }
    ]
    
}