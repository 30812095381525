  <section class="header-btn-wrapper">
    <button type="button" (click)="AddImageCarousel()" class="btn btn-primary my-main-button lg-button"><i class="fas fa-fw fa-plus"></i> Add New Image</button>
  </section>
  <ag-grid-angular
  #agGrid
  domLayout='autoHeight'
  class="ag-theme-alpine"
  [rowHeight]="80"
  [rowData]="imageCarousel"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [suppressRowClickSelection]="true"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="onFirstDataRendered($event)"
>
</ag-grid-angular>

