import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { WaveField } from '../campaign-wave-item.constant';
import { CampaignService } from 'src/app/_services/campaign.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { setNewOriginUrl } from 'src/app/_helpers/utility';

@Component({
  selector: 'app-ant-campaign-wave-item',
  templateUrl: './ant-campaign-wave-item.component.html',
  styleUrls: ['./ant-campaign-wave-item.component.css']
})
export class AntCampaignWaveItemComponent implements OnInit {
  formGroup: FormGroup
  dialogFields
  readonly orgData
  readonly assetSource
  readonly category
  readonly campaignId
  callback
  constructor(
    private mmitaAssetService: MmitaAssetService,
    private campaignService: CampaignService,
    private toastr : ToastrService,
    private nzModalRef: NzModalRef
  ) { }

  ngOnInit(): void {
    this.genFG(this.orgData)
    this.callback = ()=>this.onSubmit.call(this)
  }

  genFG(orgData){
    this.formGroup = new FormGroup({
      coverProfileUrl: new FormControl(orgData?.coverProfileUrl, Validators.required),
      startAt: new FormControl(orgData?.startAt, Validators.required),
      endAt: new FormControl(orgData?.endAt, Validators.required),
      assetCategoryId: new FormControl(orgData?.assetCategoryId, Validators.required),
      assetIds: new FormControl(orgData?.assetIds || [], Validators.required),
      portalPreviewAssetId: new FormControl(orgData?.portalPreviewAssetId, Validators.required),
    })
    if(orgData?.isReleased){
      this.formGroup.get('startAt').disable()
      this.formGroup.get('endAt').disable()
      this.formGroup.get('assetCategoryId').disable()
      this.formGroup.get('assetIds').disable()
    }

    if(this.orgData){
      this.setAssetSource(this.orgData?.assetCategoryId).subscribe(assetOptionsSource=>{
        this.dialogFields = WaveField(this.assetSource ,this.category, assetOptionsSource)
      })
    } else {
      this.dialogFields = WaveField(this.assetSource ,this.category, [])
    }

    this.formGroup.get('assetCategoryId').valueChanges.subscribe(res=>{
      this.setAssetSource(res).subscribe(assetOptionsSource=>{
        this.dialogFields = WaveField(this.assetSource ,this.category, assetOptionsSource)
        this.formGroup.get('assetIds').patchValue([])
      })
    })
  }

  private setAssetSource(catId){
    return new Observable(subscriber => {
      this.mmitaAssetService.getAll({CategoryId: catId, PageSize: '-1'}).subscribe(source=>{
        subscriber.next(source.data)
        subscriber.complete()
      })
    })

  }

  onSubmit(){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.toastr.success('Data Saved Successfully...!', 'Success');
        this.nzModalRef.triggerOk()
        subscriber.next()
        subscriber.complete()
      }

      setNewOriginUrl(this.formGroup, 'coverProfileUrl')
      this.orgData ? this.campaignService.updateWave(this.campaignId,this.orgData.id, this.formGroup.value).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          }) :  this.campaignService.postWave(this.campaignId, this.formGroup.value).subscribe({
            next: x => saveSuccess(),
            error: x => subscriber.error(x)
          })
      })
    }


}
