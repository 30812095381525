import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AbilityDto } from '../_models/abilityDto';


@Injectable({ providedIn: 'root' })
export class AbilityService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<AbilityDto[]>(`${environment.apiEndpoint}api/AssetAbility`);
  }

  deleteAbility(id: string) {
    return this.http.delete<AbilityDto>(`${environment.apiEndpoint}api/AssetAbility/${id}`);
  }

  addAbility(data: AbilityDto){
    return this.http.post(`${environment.apiEndpoint}api/AssetAbility`, data)
  }

  updateAbility(id: string, data: AbilityDto){
    return this.http.put(`${environment.apiEndpoint}api/AssetAbility/${id}`, data)
  }

  getAbility(id: string){
    return this.http.get<AbilityDto>(`${environment.apiEndpoint}api/AssetAbility/${id}`);
  }


}
