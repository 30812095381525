    <section *ngIf="campaignId && rendered"  class="header-btn-wrapper">
        <h5 *ngIf="campaignInfo">
            Campaign Name: {{campaignInfo.name}}
        </h5>
        <button *ngIf="!campaignInfo.isReleased && data.length<1" type="button" (click)="showItem()" class="btn btn-primary">
            <i class="fas fa-fw fa-plus"></i> Add Campaign wave
        </button>
    </section>

    <ag-grid-angular
      #agGrid
      domLayout='autoHeight'
      class="ag-theme-alpine"
      [rowData]="data"
      [columnDefs]="columnDefs"
      rowSelection="multiple"
      [defaultColDef]="defaultColDef"
      [pagination]="true"
      [suppressRowClickSelection]="true"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
      [rowHeight]="80"
    >
    </ag-grid-angular>