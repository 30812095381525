import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from 'src/app/_services/global.service';
import * as AMapLoader from '@amap/amap-jsapi-loader';
import { ToastrService } from 'ngx-toastr';
import { MarkerList } from './map.data';
import { SearchResult } from './google-map.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root'
})
export class AmapService {
  map
  constructor(
    private global: Global,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { 
    window['_AMapSecurityConfig'] = {
      securityJsCode:'03dc077bcdc0f13e538143d86498d019',
    }
  }

  loadMap(lastPosition){
    return new Observable(subscriber => {
      AMapLoader.load({
        key: "9c6d461170fde037a97a677e71576f97", //申请好的Web端开发者 Key，调用 load 时必填
        version: "2.0", //指定要加载的 JS API 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Autocomplete','AMap.PlaceSearch'],  
      }).then((AMap) => {
        this.map = new AMap.Map('MapEl', {
          viewMode: '2D', // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D'
          center: lastPosition ? [lastPosition.lng, lastPosition.lat] : [114.175887, 22.33609],  
          zoom: localStorage.getItem('mapZoom') ? parseInt(localStorage.getItem('mapZoom')) : 14,
          doubleClickZoom: false,
          autoRefresh:false
          });
    
        this.map.on('click', this.mapClickListenHandler)
        subscriber.next(this.map)
        subscriber.complete()
        })
        .catch((e) => {
            console.error(e); //加载错误提示
        });
    })

  }

  searchByText(ev){
    this.spinner.show()
    return new Observable(subscriber => {
      const cpoint = ev.lat||ev.lng ? [ev.lng,ev.lat] : this.map.getCenter() //中心点坐标
      this.aMapPlaceSearch(subscriber, null, ev.name, cpoint, ev.radius ? ev.radius: '5000')
    })
  }


  mapClickListenHandler=(mapsMouseEvent)=>{
    const location = {
      latitude: mapsMouseEvent.lnglat.lat,
      longitude: mapsMouseEvent.lnglat.lng,
      altitude: 0
    }
    const infoWindow = new window['AMap'].InfoWindow({ 
      anchor: 'top-center',
      content: `lat:${mapsMouseEvent.lnglat.lat},lng:${mapsMouseEvent.lnglat.lng}`});
    infoWindow.open(this.map, mapsMouseEvent.lnglat);
    this.global.appEvent.next({msg: 'show-detail', para: location})

  }


  genAssetMarkerItem(item, showIconUrl){
    // const markerContent = 
    // `<div class="custom-content-marker" style="background-image: url(${item.iconUrl})">
    //   ${item.group ? item.group.displayName : ''}
    // </div>`; 
    const icon = new window['AMap'].Icon({
        image: item.iconUrl,
        size: new window['AMap'].Size(50, 50), 
        offset: new window['AMap'].Pixel(25, 25)
    });
    const marker = new window['AMap'].Marker({
      position: [item.longitude, item.latitude],
      id: item.id,
      icon: showIconUrl && item.iconUrl ? icon : null,
    });
    this.map.add(marker);
    marker.on('click',()=>{
      const infoWindow = new window['AMap'].InfoWindow({ 
        anchor: showIconUrl && item.iconUrl? 'bottom-center': 'top-center', 
        offset: new window['AMap'].Pixel(showIconUrl && item.iconUrl? 25 :0, 0)
      });
      this.global.appEvent.next({msg: 'trigger.infoWindow', para: { marker: marker, item: item, infoWindow: infoWindow, infoWindowLinkTo: [item.longitude, item.latitude]}})
    })
    return marker

  }

  clearMarkerItem(id){
    const marker = this.map.getAllOverlays('marker').find(item=> {
      return item._amap_id === id || item._opts.id === id
    })
    marker && this.map.remove(marker);
  }

  private createMarker(place: any, markerIndex: number) {
    if (!place.location) return;
    const marker = new window['AMap'].Marker({
      position: [place.location.lng, place.location.lat]
    })
    marker.on('click',()=>{
      const infoWindow = new window['AMap'].InfoWindow({ 
        anchor: 'top-center',
        content: `#${markerIndex + 1}: ` + (place.name || ""),
      });
      infoWindow.open(this.map, marker._position);
    })
    this.map.add(marker);
    return marker
  }

  private genMarkerListRecommendedList(result){
    const markerArray = []
    const recommendedList = new MarkerList(result.poiList.pois, true);
    result.poiList.pois.forEach((item, index)=>{
      const marker = this.createMarker(item,index);
      markerArray.push(marker)
    })
    const lastPosition = result.poiList.pois[result.poiList.pois.length-1]
    this.map.setCenter([lastPosition.location.lng, lastPosition.location.lat])
    return new SearchResult(markerArray, recommendedList)
  }

  aMapPlaceSearch(subscriber, placeSearchOption, placeName, center, radius){
    new window['AMap'].PlaceSearch(placeSearchOption).searchNearBy(placeName, center, radius, (status, result) =>{
      switch(status){
        case 'complete':
          //查询成功时，result 即对应匹配的 POI 信息
          const res = this.genMarkerListRecommendedList(result)
          const length = result.poiList.pois.length
          this.toastr.success(`return ${length} record${length>1 ?`s`:``}`,'Search succeeded')
          this.spinner.hide()
          subscriber.next(res)
          break
        case 'no_data':
          this.toastr.success('No Data')
          this.spinner.hide()
          subscriber.next(new SearchResult(null, null))
          break
        case 'error':
        default:
          this.toastr.error('search failed')
          this.spinner.hide()
          subscriber.next(new SearchResult(null, null))
          break              
      }
      subscriber.complete()
    })
  }

  getNearBy(currentFocusOnInfo){
    return new Observable(subscriber => {
      //构造地点查询类
      const placeSearchOption = { 
        pageSize: 20, // 单页显示结果条数
        pageIndex: 1, // 页码
        map: this.map, // 展现结果的地图实例
      }
    
      const cpoint = currentFocusOnInfo ? [currentFocusOnInfo.longitude, currentFocusOnInfo.latitude]: this.map.getCenter(); //中心点坐标
      this.spinner.show()
      this.aMapPlaceSearch(subscriber, placeSearchOption, '', cpoint, 500)
    })
  }

}
