import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PartnerService } from 'src/app/_services/partner.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { LinkAccountTemplate } from './link-account-item.constant';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [DatePipe]
})
export class ProductComponent extends BaseComponent implements OnInit{
  data
  initialPartnerId: string
  readonly TabList = ['Product', '3DModel']
  currentFocusOn: string = 'Product'
  formGroup: FormGroup
  constructor(
    private partnerService: PartnerService,
    public global: Global,
    private datePipe: DatePipe,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) { 
    super(global);
    this.initialPartnerId = this.route.snapshot.paramMap.get('partnerId');

  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.fetchAll();
  }

  ngOnInit(){
    this.setColumnDefs();
  }

  formatDateTime(params){
    return this.datePipe.transform(params.value, 'short')
  }


  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Id', field: 'id'},
      { headerName: 'Name', field: 'name'},
      { headerName: 'Created At', field: 'createdAt',valueFormatter: this.formatDateTime.bind(this)},
      {
        headerName: 'Avatar',
        cellClass: 'img-cell',
        cellRenderer: params => {
          return ` <img height="80" src="${params.data.thumbnailUrl ? params.data.thumbnailUrl : params.data.iconUrl}?m=${Math.random()}" alt="without thumbnail"/>`;
        },
      },
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return ` <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                  <i class="fas fa-fw fa-code-branch" id="navigate" style="margin-right:3px; cursor: pointer;"></i> 
                  ${ params.data.asset ? `<i class="fas fa-fw fa-link text-primary" id="linkAccount" style="margin-right:3px; cursor: pointer;"></i>`:``}`},
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Product Detail')
              break;
            case 'navigate':
              this.router.navigateByUrl(`/version/${this.currentFocusOn}/${params.data.id}`)
              break;
            case 'linkAccount':
              this.linkAccount(params.data?.asset)
              break
          }
        },
      },
  ];}


  fetchAll(){
    switch(this.currentFocusOn){
      case 'Product':
        this.partnerService.getProducts(this.initialPartnerId).subscribe((res: InfiniteResult)=>{
          this.data = res.data
        })
        break;
      case '3DModel':
        this.partnerService.getModels(this.initialPartnerId).subscribe((res: InfiniteResult)=>{
          this.data = res.data
        })
        break;
    }

  }

  changeTab(tab){
    this.currentFocusOn = tab
    this.fetchAll()
  }

  linkAccount(data){
    this.genFG(data)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, LinkAccountTemplate)    
  }

  onSubmit(id){
    return new Observable(subscriber => {
      const onSuccess = ()=>{
        this.fetchAll();
        this.toastrService.success(`Update Successfully...!`, 'Success');
        subscriber.next()
        subscriber.complete()
      }
      this.partnerService.updateAssetPayId(id, this.formGroup.value).subscribe(()=>{
          onSuccess()
      })
  }) 
  }

  genFG(data){
    const info = data?.gameInfo ? data?.gameInfo: data?.modelInfo
    this.formGroup = new FormGroup({
      applePayProductId: new FormControl(info?.applePayProductId),
      googlePayProductId: new FormControl(info?.googlePayProductId)
    })
  }
}
