import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PartnerService } from 'src/app/_services/partner.service';
import { StripeService } from 'src/app/_services/stripe.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StripeSettingTemplate } from './stripe-setting-item-constant';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-stripe-setting',
  templateUrl: './stripe-setting.component.html',
  styleUrls: ['./stripe-setting.component.scss']
})
export class StripeSettingComponent extends BaseComponent {
  columnDefs: Array<any> = [];
  gridData: any;
  partners: Array<any>
  formGroup: FormGroup


  constructor(
    private stripeService: StripeService,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    public global: Global,
    private partnerService: PartnerService
  ) {
    super(global);
   }

   setColumnDefs(){
    this.columnDefs = [
      { headerName: 'ID', field: 'id'},
      { headerName: 'Developer ID', field: 'developerId'},
      { headerName: 'API Key', field: 'apiKey'},
      { headerName: 'Active', field: 'active', cellClass: 'stringType'},

      { headerName: 'Created At', field: 'createdAt',
        valueFormatter: (params) =>{
          return this.datePipe.transform(params.value, 'short') 
        }},
      {
        headerName: 'Action',
        pinned: 'right',
        minWidth: 120,
        cellRenderer: params => {
          return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                    <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
        },
        onCellClicked: params => {
          switch(params.event.target.id){
            case 'moreDetail':
              this.modalService.showDetail(params.data, 'Stripe Setting Detail')
              break;
            case 'editDetail':
              this.showItem(params.data)
              break;
            case 'delete':
              this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
              break;
          }
        },
      }
  ];
  }

  ngOnInit() {
    this.setColumnDefs();
    this.getPartners()
  }
  getPartners(){
    this.partnerService.getDevelopers().subscribe((res: InfiniteResult)=>{
      let data = res.data
      if(data && data.length){
        data = data.map(item=>{
          return {des: `Email: ${item.email}, ID: ${item.id}`, id: item.id}
        })
      }
      this.partners = data
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.getAll();
    this.agGrid && this.agGrid.api.sizeColumnsToFit()

  }

  getAll(){
    this.stripeService.getAllStripeSetting().subscribe((res: InfiniteResult)=>{
      this.gridData = res.data
    })
  }

  showItem(orgData?){
    this.genFG(orgData)
    const dialogTemplate = StripeSettingTemplate(this.partners)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, orgData?.id), this.formGroup, dialogTemplate)
  }

  onSubmit(id){
    return new Observable(subscriber => {
      const api = id ? this.stripeService.updateSetting(this.formGroup.value, id) : this.stripeService.addSetting(this.formGroup.value)
      api.subscribe(()=>{
        this.toastr.success(id ? 'Update Successfully...!': 'Create Successfully...!', 'Success');
        this.getAll()
        subscriber.next()
        subscriber.complete()
      })
  }) 
  }

  delete(id){
    this.stripeService.deleteSetting(id).subscribe(res=>{
        this.getAll();
        this.toastr.success('Data Delete Successfully...!', 'Success');
    })
  }

  genFG(data){
    this.formGroup = new FormGroup({
      developerId: new FormControl(data?.developerId , Validators.required),
      apiKey: new FormControl(data?.apiKey, Validators.required),
      active: new FormControl(data?.active || false),
    })
  }
}