import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { CustomerReadDto } from '../_models/customerReadDto';
import { CustomerWriteDto } from '../_models/customerWriteDto';
import { InfiniteResult } from '../_models/body';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll(parmas) {
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/AppUser/Query`,{
      params: parmas
    });
  }

  getById(id: string) {
    return this.http.get<CustomerReadDto>(`${environment.apiEndpoint}api/AppUser/${id}`);
  }

  insertNew(user: CustomerWriteDto) {
    return this.http.post<CustomerReadDto>(`${environment.apiEndpoint}api/AppUser`, user, this.httpOptions)
  }

  updateById(info: any, id: number) {
    return this.http.put<CustomerReadDto>(`${environment.apiEndpoint}api/AppUser/${id}`, info, this.httpOptions)
  }

  delete(id: string) {
    return this.http.delete<CustomerReadDto>(`${environment.apiEndpoint}api/AppUser/${id}`,{
      headers: new HttpHeaders({'timeout': '60000'})
    });
  }

  setCoin(data){
    return this.http.put(`${environment.apiEndpoint}api/Customer/ExchangeCoins`, data)
  }
}
