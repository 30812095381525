import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GridAction } from 'src/app/common/constants';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { CouponService } from 'src/app/_services/coupon.service';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StripeService } from 'src/app/_services/stripe.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AntCouponItemComponent, Type } from './ant-coupon-item/ant-coupon-item.component';
import { MmitaTypeCouponItemComponent } from './mmita-type-coupon-item/mmita-type-coupon-item.component';
import { SendCouponComponent } from './send-coupon/send-coupon.component';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent extends BaseComponent {
  data: Array<any>
  stripeAccount: Array<any>
  updateFlag
  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    public global: Global,
    private ngbModal: NgbModal,
    private datePipe: DatePipe,
    private couponService: CouponService,
    private stripeService: StripeService,
    private nzModalService: NzModalService
  ) {
    super(global); 
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
          case 'update.coupon':
            // to handel update type:mmita promotion coupon id Updated
            this.updateFlag = true;
            break
      }
    });}
  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'ID', field: 'id'},
      { headerName: 'Type', field: 'type'},
      { headerName: 'Coupon Name', field: 'couponName'},
      { headerName: 'Merchant Name', field: 'merchantName'},
      { headerName: 'Expires At', field: 'expiresAt',  valueFormatter:(params)=>{
        return this.datePipe.transform(params.value, 'short')
      }},
      { headerName: 'Max Redemptions', field: 'maxRedemptions'},
      
      {
        headerName: 'Profile Url', field: 'profileUrl',
        cellClass: 'img-cell',minWidth: 130,
        cellRenderer: params => {
          return ` <img height="80" src="${params.value}?m=${Math.random()}" alt="without thumbnail"/>`;
        },
      },
    {
      headerName: 'Action',
      pinned: 'right',
      minWidth: 120,
      cellRenderer: params => {
        return `
                  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                  <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                  ${params.data.type === Type.Mmita ? `<i class="fas fa-fw fa-ticket" id="editCoupon" style="margin-right:3px; cursor: pointer;"></i>`: ''}
                  <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>
                  ${params.data.maxRedemptions > 0 ? `<i class="fas fa-fw fa-gift text-success" id="sendCoupon" style="margin-right:3px; cursor: pointer;"></i>`: ''}`;
      },
      onCellClicked: params => {
        switch(params.event.target.id){
          case 'moreDetail':
            this.modalService.showDetail(params.data, 'Coupon Detail')
            break;
          case 'editDetail':
            this.showItem(params?.data)
            break;
          case 'delete':
            this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
            break;
          case 'editCoupon':
            this.showMmitaCouponItem(params?.data)
            break;
          case 'sendCoupon':
            this.showSendCouponDialog(params?.data)
            break;
        }
      },
    }];
  }

  ngOnInit() {
    this.setColumnDefs();
    this.getStripeAccount()
  }

  getStripeAccount(){
    this.stripeService.getStripeAccount().subscribe(res=>{
      this.stripeAccount = res.map(item=>{
        return {...item, id:item.stripeAccountId, name: item.partnerName}
      })
    })
  }



  onGridReady(params) {
    this.gridApi = params.api;
    this.fetchUserAll();
    this.gridApi.sizeColumnsToFit();
  }


  fetchUserAll() {
    this.couponService.getAll().subscribe((data: InfiniteResult) => {
      this.data = data.data;
      this.updateFlag = false
    });
  }



  delete(id){
    this.couponService.delete(id).subscribe(res=>{
      this.fetchUserAll()
      this.toastr.success('Data Deleted Successfully...!', 'Success');
    } )
  }

  showItem(orgData?){
    this.nzModalService.create({
      nzContent: AntCouponItemComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: 'Set Coupon',
      nzOnOk: ()=>{
        this.toastr.success('Saved Successfully...!', 'Success');
        this.fetchUserAll()
      },
      nzComponentParams: {
        orgData: orgData,
        stripeAccount: this.stripeAccount
      }
    })
  }

  showMmitaCouponItem(orgData){
    this.nzModalService.create({
      nzContent: MmitaTypeCouponItemComponent,
      nzWidth: '700px',
      nzFooter: null,
      nzTitle: 'Set MMITA Coupon',
      nzOnOk: ()=>{
        this.toastr.success('Saved Successfully...!', 'Success');
        this.fetchUserAll()
      },
      nzOnCancel:()=>{
        this.updateFlag && this.fetchUserAll()
      },
      nzComponentParams: {
        orgData: orgData,
      }
    })
  }

  showSendCouponDialog(couponInfo){
    const modal = this.nzModalService.create({
      nzContent: SendCouponComponent,
      nzWidth: '1000px',
      nzFooter: null,
      nzTitle: 'Send Coupon',
      nzOnOk: ()=>{
        this.toastr.success('Sent Successfully...!', 'Success');
        this.fetchUserAll()
      },
      nzComponentParams: {
        couponInfo: couponInfo,
        sendCouponEvent: (userIds)=>this.sendCoupon.call(this, couponInfo.id, userIds, modal)
      }
    })
  }

  sendCoupon(id, userIds, modal){
    this.couponService.sendCoupon(id, userIds).subscribe({
      next:()=>{
        modal.triggerOk()
      }
    })
  }

}
