import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const Template = (editMode, TemplateType, LanguageCode): DialogTemplate =>  {
    return {
        dialogTitle: 'Set Template',        
        dialogFields: [
            {
                key: 'name',
                label: 'Name',
                type: InputFieldType.Text,
                size: 24,
                required: true
            },
            {
                key: 'type',
                label: 'Type',
                type: InputFieldType.Selector,
                size: 12,
                options: TemplateType,
                required: true
            },
            {
                key: 'languageCode',
                label: 'Language Code',
                type: InputFieldType.Selector,
                size: 12,
                options: LanguageCode,
                required: true
            },
            {
                key: 'url',
                label: 'File',
                type: InputFieldType.Uploader,
                size: 24,
                required: true
            },
        ]
    }
}