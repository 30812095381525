<ul class="nav nav-tabs">
    <li *ngFor="let tab of TabList" class="nav-item" [ngClass]="{'current-tab': currentFocusOn === tab}" (click)="changeTab(tab)">
      <a class="nav-link tab-link" >
        {{tab}}
      </a>
    </li>
</ul>
<ag-grid-angular
#agGrid
domLayout='autoHeight'
class="ag-theme-alpine"
[rowData]="data"
[columnDefs]="columnDefs"
rowSelection="multiple"
[defaultColDef]="defaultColDef"
[pagination]="true"
[suppressRowClickSelection]="true"
(gridReady)="onGridReady($event)"
[enableCellTextSelection]="true"
[rowHeight]="80"

>
</ag-grid-angular>