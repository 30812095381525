import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { MeshyService } from 'src/app/_services/meshy.service';
import { ModalService } from 'src/app/_services/modal.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';

@Component({
  selector: 'app-meshy',
  templateUrl: './meshy.component.html',
  styleUrls: ['./meshy.component.css']
})
export class MeshyComponent extends BaseComponent {
    columnDefs: Array<any> = [];
    gridData: any;
    constructor(
      private meshyService: MeshyService,
      private modalService: ModalService,
      private datePipe: DatePipe,
      public global: Global,
    ) {
      super(global);
     }
  
     setColumnDefs(){
      this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'iconUrl', field: 'iconUrl',
                cellClass: 'img-cell',
                minWidth: 100,
                cellRenderer: params => {
                    if (!params.data) return 'not loaded yet';
                return params.value||params?.data?.thumbnailUrl ? ` <img height="40" src="${params.value||params?.data?.thumbnailUrl}?m=${Math.random()}" alt="without thumbnail"/>`: `-`;
            },
        },
        { headerName: 'Glb Url', field: 'glbUrl'},
        { headerName: 'Type', field: 'type'},
        { headerName: 'Topology', field: 'topology'},
        { headerName: 'Art Style', field: 'artStyle' },
        { headerName: 'Created At', field: 'createdAt',sort: 'desc' , valueFormatter: (params) =>{
            return this.datePipe.transform(params.value, 'medium')
        }},
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 160,
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Message Detail')
                break;
            }
          },
        }
  
    ];
    }
  
    ngOnInit() {
      this.setColumnDefs();
    }
  
    onGridReady(params) {
      this.gridApi = params.api;
      this.getMessageList();
    }
  
    getMessageList(){
      this.meshyService.getMeshy().subscribe(res=>{
        this.gridData = res
      })
    }

}
