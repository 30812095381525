<div class="container pop-up-container">
    <section class="my-popover-header">
        <h3 style="text-align:center">
            Sketchfab Info
        </h3>
    </section>
    <section *ngIf="rendered" class="alert alert-primary">
        <h5>
            Current Info
        </h5>
        <div>
            <label>
                Access Token:
            </label>
            {{currentSketchfedInfo.accessToken}}
        </div>
        <div>
            <label for="createdAt">Created At:</label>
            {{currentSketchfedInfo.createdAt | date: 'dd/MM/yyyy, h:mm a z'}}
        </div>
        <div>
            <label for="expire">Expire Time:</label>
            {{currentSketchfedInfo.expireDate | date: 'dd/MM/yyyy, h:mm a z'}}
        </div>
    </section>
    <button type="button" (click)="formSubmit()" class="btn btn-block btn-primary">Refresh</button>

</div>