import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class VerticalCarouselService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getVerticalCarouselList(){
    return this.httpClient.get<InfiniteResult>(`${environment.apiEndpoint}api/VerticalCarousel/Query`,{
      params:{
        'PageSize': '-1'
      }
    })
  }

  delete(id){
    return this.httpClient.delete(`${environment.apiEndpoint}api/VerticalCarousel/${id}`)
  }

  update(id, body){
    return this.httpClient.put(`${environment.apiEndpoint}api/VerticalCarousel/${id}`,body)
  }

  add(body){
    return this.httpClient.post(`${environment.apiEndpoint}api/VerticalCarousel`,body)
  }

}
