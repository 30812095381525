import { Component } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { TagService } from 'src/app/_services/tag.service';
import { InfiniteGridComponent } from 'src/app/ui-library/component/infinite-grid/infinite-grid.component';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent  extends InfiniteGridComponent{

  constructor(
    public global: Global,
    private tagService: TagService
  ) {
    super(global);

   }

  ngOnInit(): void {
    this.setColumnDefs();
  }

  ngAfterViewInit(): void {
    this.setDatasource((prop)=>this.tagService.getAll(prop))
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'ID', field: 'id'},
      { headerName: 'ID', field: 'name'},
    ]
  }

}
