import { MarkerDto } from "src/app/_models/mapDto";

export class MarkerList {
    constructor(list: Array<any>, amapMode?){
        const markerList = list.map(item=>{
            return new MarkerDto(item, amapMode)
          });
        return markerList
    }
  }

