import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-mit-input-array',
  templateUrl: './mit-input-array.component.html',
  styleUrls: ['./mit-input-array.component.scss']
})
export class MitInputArrayComponent implements OnInit {
  @Input('formCtrl') formCtrl: FormControl
  get inputArray(){
    return this.formCtrl.value
  }
  constructor() { }

  ngOnInit(): void {
  }

  addField() {
    this.formCtrl.setValue([...this.formCtrl.value, ''])
  }

  removeField(i) {
    this.formCtrl.value.splice(i, 1)
  }

  trackById(index: number) {
    return index;
  }

}
