import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetricService {
  constructor(
    private httpClient: HttpClient
  ) { }

  getDailyLogin(){
    return this.httpClient.get(`${environment.apiEndpoint}api/Metric/DailyLogin`)
  }

  getVisitorRecord(){
    return this.httpClient.get(`${environment.apiEndpoint}api/Metric/VisitorRecord`)
  }

  getViewDate(appUserId){
    return this.httpClient.get(`${environment.apiEndpoint}api/Metric/VisitorRecord/${appUserId}/DateTimes`)
  }

  getVCPosted(){
    return this.httpClient.get(`${environment.apiEndpoint}api/Metric/VerticalCarouselPosted`)
  }
}
