import { ChangeDetectorRef, Component } from '@angular/core';
import { ImageService } from '../../_services/imageCarousel.service'
import { ImageCarousel } from '../../_models/imageCarousel';
import { ModalService } from '../../_services/modal.service';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
})
export class ImageCarouselComponent extends BaseComponent {
  imageCarousel: ImageCarousel[];
  constructor(
    private imageService: ImageService,
    private modalService: ModalService,
    public global: Global,
    private toastr: ToastrService
  ) {
    super(global);
    this.eventSubscription = this.global.appEvent.subscribe((event: {msg: string, para: any}) => {
      const {msg , para} = event;
      switch (msg) {
        case 'reload.image-carousel':
          this.fetchImageCarouselAll();
          break;
      }
    });

  }
  ngOnInit() {
    this.setColumnDefs();
  }

  
  onGridReady(params) {
    this.gridApi = params.api;
    this.fetchImageCarouselAll();
  }
  
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  setColumnDefs(){
    this.columnDefs = [
      { headerName: 'Order', field: 'order'},
      { headerName: 'Image', field: 'imgUrl',
      cellRenderer: params => {
        return ` <img height="100%" src="${params.value}" alt="without image"/>`;
      },
       },
      { headerName: 'Active', field: 'isActive'},
      { headerName: 'Language', field: 'languageCode' },

      { headerName: 'Edit', 
        cellRenderer: params => {
          return ` <i class="fas fa-fw fa-edit  text-primary"></i>`;
        },
        cellStyle: params => {
          return {cursor: 'pointer'};
        },
        onCellClicked: this.EditImageCarousel.bind(this)
      },
      { headerName: 'Delete', 
        cellRenderer: params => {
          return ` <i class="fas fa-fw fa-trash grid-btn-icon text-danger"></i>`;
        },
        cellStyle: params => {
          return {cursor: 'pointer'};
        },
        onCellClicked: this.DeleteImageCarousel.bind(this)
    },
  ];
  }

  fetchImageCarouselAll() {
    this.imageService.getAll()
      .subscribe(
        data => {
          this.imageCarousel = data;
        });
  }
  AddImageCarousel() {
    this.modalService.AddImageCarousel();
  }
  EditImageCarousel(params) {
    const id = params.data.id;
    this.modalService.UpdateImageCarousel(id);
  }
  DeleteImageCarousel(params) {
    const id = params.data.id;
    this.modalService.DeleteImageCarousel(id);
  }
}
