import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StageService } from 'src/app/_services/stage.service';
import { MapTab, StageNavList } from 'src/app/common/constants';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { PublicMapService } from '../../service/public-map.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-map-grid',
  templateUrl: './map-grid.component.html',
  styleUrls: ['./map-grid.component.scss']
})
export class MapGridComponent extends BaseComponent{
  @Input() currentTab: MapTab
  @Output() onOpenDetailPanel = new EventEmitter<any>();
  readonly MapTab = MapTab
  navList = StageNavList

  constructor(
    public global: Global,
    private modalService: ModalService,
    private stageService: StageService,
    private publicMapService: PublicMapService,
    private toastr: ToastrService
  ) {
    super(global);
  }

  get data(){
    return this.publicMapService.loadedPoints
  }

  get totalCount(){
    return this.publicMapService.totalCount
  }

  ngOnInit() {
    this.setColumnDefs();
  }
  ngOnChanges(changes: SimpleChanges){
    if(changes?.currentTab && !changes?.currentTab.isFirstChange()){
      this.setColumnDefs();
      setTimeout(()=>{
        this.gridApi.sizeColumnsToFit();
      }, 400)
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  buildColumn(){
    if(this.currentTab === MapTab.StageGroup){
      return [
        { headerName: 'ID', field: 'id', minWidth: 140,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        { headerName: 'Display Name', field: 'displayName', minWidth: 140},
        { headerName: 'Root', field: 'isRoot', minWidth: 100},
        { headerName: 'Latitude', field: 'latitude', minWidth: 140},
        { headerName: 'Longitude', field: 'longitude', minWidth: 140},
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 140,
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-arrow-right" id="navigate" style="margin-right:3px; cursor: pointer;"></i>
                      ${params.data.isRoot ? `<i class="fas fa-fw fa-rotate text-success" id="sync" style="margin-right:3px; cursor: pointer;"></i>`: ''}
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Point Detail')
                break;
              case 'navigate':
                  if(this.currentTab === MapTab.Stage){
                    this.stageService.getStageById(params.data.id).subscribe(res=>{
                      this.onOpenDetailPanel.emit(res)
                    })
                  } else {
                    this.onOpenDetailPanel.emit(params.data)
                  }
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params))
                break;
              case 'sync':
                this.sync(params.data)
                break;
            }
          },
        }
    ];
    } else {
      return [
        { headerName: 'ID', field: 'id', minWidth: 140,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        { headerName: 'iconUrl', field: 'iconUrl',
            cellClass: 'img-cell',
            minWidth: 100,
            cellRenderer: params => {
              if(!params.data) return 'not loaded yet';
              return params.value ? ` <img height="40" src="${params.value}?m=${Math.random()}" alt="without thumbnail"/>`: `-`;
        },
      },
        { headerName: 'Latitude', field: 'latitude', minWidth: 140},
        { headerName: 'Longitude', field: 'longitude', minWidth: 140},
        {
          headerName: 'Action',
          pinned: 'right',
          minWidth: 110,
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-arrow-right" id="navigate" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Point Detail')
                break;
              case 'navigate':
                  if(this.currentTab === MapTab.Stage){
                    this.stageService.getStageById(params.data.id).subscribe(res=>{
                      this.onOpenDetailPanel.emit(res)
                    })
                  } else {
                    this.onOpenDetailPanel.emit(params.data)
                  }
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params))
                break;
            }
          },
        }
    ];
    }
  }

  setColumnDefs(){
    this.columnDefs = this.buildColumn()
  }

  onRowClicked(ev){
    this.publicMapService.setCenter(ev?.data?.latitude , ev?.data?.longitude)
    this.publicMapService.triggerClickMarkerEvent(ev?.data?.id)
  }

  delete(params) {
    this.publicMapService.deleteItem(params?.data?.id, this.currentTab).subscribe(()=>{
      this.data.splice(params.rowIndex, 1)
      this.gridApi.setRowData(this.data)
    })
  }


  batchDelete(){
    const idArray = this.gridApi?.getSelectedRows().map(item=>{
      return item.id
    })
    this.publicMapService.batchDelete(idArray, this.currentTab).subscribe(res=>{})
  }

  
  sync(data){
    this.stageService.syncGroup(data.id).subscribe(res=>{
      this.toastr.success('Sync Successfully')
    })
  }

}