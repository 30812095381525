<div class="md-popover login-popover" style="margin:30px">
    <section class="my-popover-content">
      <div class="input-group mb-3">
        <h4>{{deleteMessage}}</h4>
      </div>
      <div class="row">
        <button type="submit" (click)="delete()" class="btn btn-primary" style="margin-right: 10px;">Delete</button>
        <button type="button" (click)="modalClose()" class="btn btn-danger">Close</button>
      </div>
    </section>
  </div>
  
  
  