import { Component, OnInit } from '@angular/core';
import { MetricService } from 'src/app/_services/metric.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

}
