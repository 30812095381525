import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MmitaAssetService } from '../../../_services/mmitaAsset.service';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/_services/category.service';
import { AbilityService } from 'src/app/_services/ability.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AssetDataBuilder } from '../asset.data.builder';
import { forkJoin, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { captureThumbnail } from 'src/app/_helpers/utility';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-asset-item',
  templateUrl: './asset-item.component.html',
  providers: [AssetDataBuilder],
  styleUrls: ['./asset-item.component.scss']
})

export class AssetItemComponent implements OnInit {
  assetFormGroup: FormGroup;
  categoryList: Array<any> = [];
  categoryForAsset: Array<any> = [];
  abilityList: Array<any> = [];
  
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'textField',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
  };


  id: string;
  isBatch: boolean = false;
  isVisionOSCategory = false
  isCopy: boolean = false;
  batchInfo: Array<any>;
  editMode: boolean = false;
  rendered = false;
  orgData: any;

  readonly AssetBusinessTypeSelections = this.global.section.AssetBusinessTypeSelections
  readonly AssetTypeSelections = this.global.section.AssetTypeSelections
  previewMediaSources = []
  constructor(
    private formbulider: FormBuilder,
    private assetService: MmitaAssetService,
    private global: Global,
    private toastr: ToastrService,
    private categoryService: CategoryService,
    private abilityService: AbilityService,
    private assetDataBuilder:AssetDataBuilder,
    private sanitizer: DomSanitizer,
    private commonService: CommonService
  ) {
  }
  
  ngOnInit() {
    this.createFG(!!this.batchInfo);
    forkJoin([
      this.getAssetInfo(this.id),
      this.getCategory(),
      this.getAbility()
    ]).subscribe({
      next: (result)=> {
        if(this.assetFormGroup.value.assetType){
          this.categoryForAsset = this.filterCategory(this.assetFormGroup.value.assetType);
        }
      },
      complete: () => this.rendered = true
    });
  }

  setViewModeField(){
    if(this.editMode){
      this.assetFormGroup.get('assetType').disable();
      this.assetFormGroup.get('categoryType').disable();
    }
  }


  getAssetInfo(id){
    return new Observable(subscriber => {
      if(id){
        this.assetService.getById(id).subscribe(res => {
          this.orgData = res;
          this.assetFormGroup.patchValue({
            assetType: this.orgData.assetType,
            categoryType: this.orgData.category ? this.orgData.category.id : this.orgData.category,
            name: this.orgData.name,
            description: this.orgData.description,
            file: null,
            thumbnail: null,
            androidFile:null,
            fileSource: null,
            androidFileSource: null,
            thumbnailSource:null,
            visionOSFile:null,
            visionOSFileSource:null,
            macOSFile:null,
            macOSFileSource:null,
            ability: this.mapAbilitySrouce(this.orgData.abilitiyIds),
            adUrlLinked: this.orgData.adUrlLinked,
            numberOfTokensUnlocked: this.orgData.numberOfTokensUnlocked,
            assetStatus: this.orgData.assetStatus,
            businessType: this.orgData.businessType,
            scaleInMap: this.orgData.scaleInMap
          })
          this.isVisionOSCategory = this.orgData.category.assetCategoryOptions.indexOf('VisionOS') >=0
          subscriber.next(true);
          subscriber.complete();
        });
      } else {
        subscriber.next(true);
        subscriber.complete();
      }

    })
  }

  private mapAbilitySrouce(abilitiyIds){
    if(abilitiyIds){
      const currentAbValue = abilitiyIds.map(item=> this.abilityList.find(abSource=> abSource.id === item))
      return currentAbValue.filter(item => item && item.id)
    }
    return null
  }

  createFG(batchEditFG: boolean){
    if(batchEditFG){
      this.assetFormGroup = this.formbulider.group({
        ability: [null, []],
      });
    } else {
      this.assetFormGroup = this.formbulider.group({
        assetType: ['', [Validators.required]],
        categoryType:  ['', [Validators.required]],
        name: ['', [Validators.required]],
        description: ['', [Validators.required]],
        file: [],
        thumbnail: [],
        androidFile: [],
        fileSource: [],
        androidFileSource: [],
        visionOSFile: [],
        visionOSFileSource: [],
        macOSFile: [],
        macOSFileSource: [],
        thumbnailSource: [],
        ability: [],
        adUrlLinked: [],
        numberOfTokensUnlocked: [0, [Validators.min(0)]],
        assetStatus: ['Inactive', [Validators.required]],
        businessType: [null, [Validators.required]],
        scaleInMap: [1, [Validators.min(0)]]
      });
    }
  }



  getAbility(){
    return new Observable(subscriber => {
      this.abilityService.getAll().subscribe(res=>{
        this.abilityList = res.map(item=>{
          item['textField'] = item.multilingualNames['en-US']
          return item
        })
        subscriber.next(true);
        subscriber.complete();
      })
    })

  }

  getCategory(){
    return new Observable(subscriber => {
      this.categoryService.getAssetCategory().subscribe(res=>{
        this.categoryList = res.data
        subscriber.next(true);
        subscriber.complete();
      })
    })
  }

  onFileChange(event, ctrlName){
    this.assetFormGroup.get(ctrlName).patchValue(this.isBatch ? event.target.files : event.target.files[0])
    if(ctrlName === 'fileSource'){
      this.previewMediaSources = Array.from(event.target.files).map((file: File)=>{
        return {src: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file)), name: file.name, type: file.type}
      })
    }
  }

  onAssetFormSubmit() {
    if (this.assetFormGroup.invalid){
      this.commonService.showFormInvalid(this.assetFormGroup)
      return;
    }
      const isSingle = !this.isBatch;
      const gifs = this.previewMediaSources.filter(item=> item.type.indexOf('gif')>=0)
      if(!this.editMode && this.assetFormGroup.value.assetType == 'Model3d' && this.isVisionOSCategory && !this.assetFormGroup.value.visionOSFileSource){
        this.toastr.warning('please upload VisionOS file.')
        return
      }
      this.assetDataBuilder.genAsset(this.assetFormGroup.value, isSingle, this.batchInfo, this.isCopy, this.orgData, captureThumbnail(gifs)).subscribe((res:any) =>{
        if(this.editMode){
          if(isSingle){
            this.assetService.updateAsset(res, this.id).subscribe(info => {
              saveSuccess()
            })
          } else {
            this.assetService.batchUpdateAbility(res).subscribe(info => {
              saveSuccess()
            })
          }
        } else {
          if(isSingle){
            this.assetService.addAsset(res).subscribe(info => {
              saveSuccess()
            })
          } else {
            this.assetService.batchaddAsset(res).subscribe(info => {
              saveSuccess()
            })
          }
        }
  
      }, error =>{
        this.toastr.warning('Please input corrent file type in the file field', 'Error', {
          timeOut: 3000
        });
      })


    const saveSuccess = () =>{
      this.modalClose();
      this.global.appEvent.next({ msg: 'reload.asset', para: null });
      this.toastr.success('Data Created Successfully...!', 'Success', {
        timeOut: 3000
      });
    }


  }

  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });
  }
  
  onChange(event) {
    const newVal = event.target.value;
    this.categoryForAsset = this.filterCategory(newVal);
    this.assetFormGroup.patchValue({
      categoryType: this.categoryForAsset.length ? this.categoryForAsset[0].id : null,
      file: null,
      fileSource: null,
      androidFile: null,
      androidFileSource: null,
      thumbnail: null,
      thumbnailSource: null,
      visionOSFile:null,
      visionOSFileSource:null,
      macOSFile:null,
      macOSFileSource:null,
    })
    this.changeCategory()
  }

  filterCategory(assetVal: string){
    return this.categoryList.filter(res=>{
      return res.assetType === assetVal && res.maxNumberOfUploadsForCustomer <= 0
    })
  }

  changeCategory(){
    const category = this.categoryList.find(item=>{
      return item.id === this.assetFormGroup.get('categoryType').value
    })
    this.isVisionOSCategory = category.assetCategoryOptions.indexOf('VisionOS') >=0
  }
}
