import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StreamService } from 'src/app/_services/streamer.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StreamerTemplate } from './streamer-item.constant';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-streamer',
  templateUrl: './streamer.component.html',
  styleUrls: ['./streamer.component.css']
})
export class StreamerComponent extends BaseComponent{
  data: Array<any>
  formGroup: FormGroup
  constructor(
    private streamService: StreamService,
    private toastr: ToastrService,
    public global: Global,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private router: Router
  ) {
      super(global)
  }


  setColumnDefs(){
    this.columnDefs = [
        { headerName: 'ID', field: 'id'},
        { headerName: 'Email', field: 'email'},
        { headerName: 'Status', field: 'status'},
        { headerName: 'Full Name', field: 'fullName'},
        { headerName: 'Created At', field: 'createdAt',  valueFormatter: (params) =>{
          return this.datePipe.transform(params.value, 'medium') }
        },
        {
          headerName: 'Action',
          minWidth: 130,
          pinned: 'right',
          cellRenderer: params => {
            return `  <i class="fas fa-fw fa-arrow-right" id="navigate" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-edit text-primary" id="edit" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'navigate':
                this.router.navigateByUrl(`/streaming/${params.data.id}`)
                break;
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Setting Detail')
                break;
              case 'edit':
                this.showItem(params?.data)
                break;
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
            }
          },
        },
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getAll();
  }

  ngOnInit() {
    this.setColumnDefs();
  }

  showItem(data?){
    this.genFG(data)
    const dialogTemplate = StreamerTemplate(data, this.global.section.StreamerStatusSelections)
    this.modalService.createNzModal(()=>this.onSubmit.call(this, data?.id), this.formGroup, dialogTemplate)
  }


  getAll() {
    this.streamService.getAll().subscribe((res:InfiniteResult)=>{
      this.data = res.data
    })
  }


  delete(id){
    this.streamService.delete(id).subscribe(res=>{
        this.getAll();
        this.toastr.success('Data Delete Successfully...!', 'Success');
      })
  }

  genFG(orgData){
    this.formGroup = new FormGroup({
      email: new FormControl(orgData?.email, Validators.required),
      firstName: new FormControl(orgData?.firstName, Validators.required),
      lastName: new FormControl(orgData?.lastName, Validators.required),
      password: new FormControl(orgData?.password),
      description: new FormControl(orgData?.description, Validators.required),
      profileUrl: new FormControl(orgData?.profileUrl, Validators.required),
      coverUrl: new FormControl(orgData?.cover?.url, Validators.required),        
    });
    if(orgData){
      this.formGroup.addControl('status', new FormControl(orgData?.status))
      this.formGroup.get('email').disable()
    } else {
      this.formGroup.get('password').setValidators([Validators.required])
    }
  }
  
  onSubmit(id){   
    return new Observable(subscriber => {
        const urlCtrlName = ['coverUrl', 'profileUrl']
          urlCtrlName.forEach(item=>{
          const value = this.formGroup.get(item).value
          value?.[0]?.origin?.url && this.formGroup.patchValue({[item]: value?.[0]?.origin?.url})
        })
        const onSuccess = (text)=>{
          this.getAll();
          this.toastr.success(`${text} Successfully...!`, 'Success');
          subscriber.next()
          subscriber.complete()
        }
        id ? this.streamService.udpate(this.formGroup.value, id).subscribe(()=>{
            onSuccess('Create')
          }) : this.streamService.create(this.formGroup.value).subscribe(()=>{
            onSuccess('Update')
        })
    })   

  }

}