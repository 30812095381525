import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReviewStatus } from 'src/app/common/constants';
import { PartnerService } from 'src/app/_services/partner.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-update-version',
  templateUrl: './update-version.component.html',
  styleUrls: ['./update-version.component.scss']
})
export class UpdateVersionComponent implements OnInit {
  input: string
  actionList = []
  id: string
  currentReviewStatus: ReviewStatus
  readonly ReviewStatus = ReviewStatus
  type: string
  constructor(
    private partnerService: PartnerService,
    private toastr: ToastrService,
    private nzModalRef: NzModalRef
  ) { }

  ngOnInit() {
    switch(this.currentReviewStatus){
      case ReviewStatus.SubmitedWaitingReview:
        this.actionList = ['LockReview']
        break;
      case ReviewStatus.UnderReviewing:
        this.actionList = ['AccepteReview', 'RejectReview']
        break;
      case ReviewStatus.Rejected:
      case ReviewStatus.AcceptedAndReleased:
        this.actionList = ['TakeDown']
        break
    }

  }

  updateStatus(actionName: string){
    if((actionName === 'RejectReview' ||  actionName === 'TakeDown') && !this.input){
      this.toastr.info('Please input the reject reason')
      return
    }
    let body;
    switch(actionName){
      case 'RejectReview':
        body = {unapproveReason: this.input}
        break;
      case 'TakeDown':
        body = {takeDownReason: this.input}
        break;
    }
    switch(this.type){
      case 'Product':
        this.partnerService.updateVersionStatus(this.id, actionName, body).subscribe(res=>{
          this.toastr.success('Update Successfully...!', 'Success');
          this.nzModalRef.triggerOk()
        } )
        break;
      case '3DModel':
        this.partnerService.updateModelVersionStatus(this.id, actionName, body).subscribe(res=>{
          this.toastr.success('Update Successfully...!', 'Success');
          this.nzModalRef.triggerOk()
        } )
        break;
    }

  }

}





