import { DialogTemplate } from "src/app/_models/body";
import { InputFieldType } from "src/app/common/constants";

export const CustomerTemplate = (): DialogTemplate =>  {
    return {
        dialogTitle: 'Edit User Information',
        dialogFields: [
            {
                key: 'firstName',
                label: 'FirstName',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'lastName',
                label: 'LastName',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'email',
                label: 'Email',
                type: InputFieldType.Text,
                size: 12,
                required: true
            },
            {
                key: 'gender',
                label: 'Gender',
                type: InputFieldType.Selector,
                notAllowClear: true,
                size: 12,
                options: ['Male', 'Female', 'Unspecific']
            },
            {
                key: 'isTester',
                label: 'Tester',
                type: InputFieldType.Switch,
                size: 12,
            },
            {
                key: 'languageCode',
                label: 'Language Code',
                type: InputFieldType.Text,
                size: 12,
            },
            {
                key: 'profileUrl',
                label: 'Profile Url',
                type: InputFieldType.Uploader,
                size: 24,
                showPreviewImage: true
            },
        ]
    }
 
}