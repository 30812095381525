import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { CategoryDto } from '../_models/categoryDto';
import { InfiniteResult } from '../_models/body';


@Injectable({ providedIn: 'root' })
export class CategoryService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAssetCategory(assetCategoryParam?){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Asset/Category/Query`,{
      params: {
        ...assetCategoryParam,
        'PageSize': '-1'
      }
    })
  }


  deleteCategory(id: string) {
    return this.http.delete<CategoryDto>(`${environment.apiEndpoint}api/Asset/Category/${id}`);
  }

  addCategory(categoryItem: CategoryDto){
    return this.http.post<CategoryDto>(`${environment.apiEndpoint}api/Asset/Category`, categoryItem, this.httpOptions)
  }

  updateCategory(id: string, categoryItem: CategoryDto){
    return this.http.put<CategoryDto>(`${environment.apiEndpoint}api/Asset/Category/${id}`, categoryItem, this.httpOptions)
  }

  getCategory(id: string){
    return this.http.get<CategoryDto>(`${environment.apiEndpoint}api/Asset/Category/${id}`);
  }


}
