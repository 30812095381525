<nav aria-label="breadcrumb" *ngIf="path && path.length">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" (click)="getReport()">View All</li>
    <li *ngFor="let pathitem of path; index as i" class="breadcrumb-item active" (click)="getPathInfo(i)">{{pathitem}}</li>
  </ol>
</nav>

<section class="card-body">
    <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="data"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true"

  >
  </ag-grid-angular>
  </section>
  
  