import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class FakeDataService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/FakeData/Query`,{
      params: {
        PageSize: '-1'
      }
    });
  }

  create(data){
    return this.http.post(`${environment.apiEndpoint}api/FakeData`,data)
  }

  update(data,id){
    return this.http.put(`${environment.apiEndpoint}api/FakeData/${id}`,data)
  }

  delete(id){
    return this.http.delete(`${environment.apiEndpoint}api/FakeData/${id}`)
  }
}
