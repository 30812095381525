/**
 * Admin.API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl } from "@angular/forms"
import { InputFieldType, SearchFieldType } from "../common/constants"



export interface InfiniteResult {
    continuationToken: string
    data: Array<any>
    totalCount: number,
    count: number
}

export interface SearchField {
    name: string
    key: string
    type?: SearchFieldType
    options?: Array<any>
}

export interface MitFieldInfo {
    key: string,
    label: string,
    type: InputFieldType,
    size: Number,
    required?: boolean | Function
    options?: Array<{id: string, name: string}|string|number> //  for selector
    mode?: 'default'|'multiple',
    offset?: number,
    showPreviewImage?: boolean,
    selectorLabel?: string // for selector
    selectorSearchEvent?: Function
    requiredEvent?: Function,
    notAllowClear?: boolean // for selctor
    accept?: string // for uploader
    maxCount?: number
}

export interface DialogTemplate {
    dialogTitle: string,
    dialogFields: Array<MitFieldInfo>
}

export interface MitField extends MitFieldInfo {
   formCtrl: FormControl
}
