<div class="md-popover login-popover" style="margin:30px">
    <h3 style="text-align:center">Update Localization Category</h3>
    <section class="my-popover-content">
        <form [formGroup]="formGroup" class="mb-4" ngNativeValidate (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col form-group">
                    <label for="categoryId">Category:</label>
                    <select class="form-select" formControlName="categoryId" (change)="updateForm()" required>
                        <option *ngFor="let category of editableCategoryData" value="{{category.id}}">{{category.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col form-group">
                    <label for="description">Description:</label>
                    <input class="form-control" type="text" formControlName="description"/>
                </div>

                <div class="col form-group">
                    <label for="name">New Name:</label>
                    <input class="form-control" type="text" formControlName="name"/>

                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                  <button type="submit" class="btn btn-block btn-primary">Save</button>
                </div>
                <div class="col-md-2">
                  <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
                </div>
              </div>
        </form>
    </section>
  </div>
  