import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private paramsBuilder(pageSize, continuationToken){
    let obj = { }
     obj['PageSize'] = pageSize ? pageSize.toString()  : '-1'
     if(continuationToken){
      obj['ContinuationToken'] =  continuationToken
     }
     return obj
  }


  getDevelopers(pageSize: number = -1, continuationToken?:string){
    const params = this.paramsBuilder(pageSize, continuationToken)
    return this.httpClient.get(`${environment.apiEndpoint}api/Partner/Developer/Query`,{params: params})
  }

  getProducts(initialPartnerId){
    if(initialPartnerId){
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/Products/Query`,{params: {
        PageSize: '-1',
        DeveloperId: initialPartnerId
      }})
    } else {
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/Products/Query`,{params: {
        PageSize: '-1',
      }})
    }

  }

  getProductsVersion(initialProductId){
    if(initialProductId){
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/ProductsVersion/Query`,{params: {
        PageSize: '-1',
        ProductId: initialProductId
      }})
    } else {
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/ProductsVersion/Query`,{params: {
        PageSize: '-1'
      }})
    }
  }

  updateDeveloper(id,body){
    return this.httpClient.put(`${environment.apiEndpoint}api/Partner/Developer/${id}`, body)
  }

  updateProduct(id,body){
    return this.httpClient.put(`${environment.apiEndpoint}api/Partner/Product/${id}`, body)
  }
  
  updateVersion(id,body){
    return this.httpClient.put(`${environment.apiEndpoint}api/Partner/ProductVersion/${id}`, body)
  }

// /api/Partner/ProductVersion/{id}/LockReview
// /api/Partner/ProductVersion/{id}/AccepteReview
// /api/Partner/ProductVersion/{id}/RejectReview
// /api/Partner/ProductVersion/{id}/TakeDown

  updateVersionStatus(id, actionName, body?){
    return this.httpClient.put(`${environment.apiEndpoint}api/Partner/ProductVersion/${id}/${actionName}`, 
      body ? body: null)
  }

  getContactUs(pageSize: number = 10, continuationToken?: string){
    return this.httpClient.get(`${environment.apiEndpoint}api/Partner/ContactUs/Query`,{params: {
      PageSize: '-1',
    }})
  }

  getMarkDown(pageSize: number = 10, continuationToken?: string){
    return this.httpClient.get(`${environment.apiEndpoint}api/Md/Query`,{params: {
      PageSize: '-1',
    }})
  }

  deleteMarkDown(id){
    return this.httpClient.delete(`${environment.apiEndpoint}api/Md/${id}`)
  }

  addMd(body){
    return this.httpClient.post(`${environment.apiEndpoint}api/Md/`, body)
  }

  updateMd(id, body){
    return this.httpClient.put(`${environment.apiEndpoint}api/Md/${id}`, body)
  }

  // 3D model

  getModels(initialPartnerId){
    if(initialPartnerId){
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/Models/Query`,{params: {
        PageSize: '-1',
        DeveloperId: initialPartnerId
      }})
    } else {
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/Models/Query`,{params: {
        PageSize: '-1',
      }})
    }

  }

  getModelsVersion(modelId){
    if(modelId){
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/ModelsVersion/Query`,{params: {
        PageSize: '-1',
        ModelId: modelId
      }})
    } else {
      return this.httpClient.get(`${environment.apiEndpoint}api/Partner/ModelsVersion/Query`,{params: {
        PageSize: '-1'
      }})
    }
  }

  updateModelVersionStatus(id, actionName, body?){
    return this.httpClient.put(`${environment.apiEndpoint}api/Partner/ModelVersion/${id}/${actionName}`, 
      body ? body: null)
  }

  updateAssetPayId(id, body){
    return this.httpClient.put(`${environment.apiEndpoint}api/Partner/Asset/${id}`, body)
  }

  getPartnerIncome(developerId?){
    if(developerId){
      return this.httpClient.get<InfiniteResult>(`${environment.apiEndpoint}api/Partner/Developer/Income/Query`,{
        params:{
          DeveloperId: developerId,
          PageSize: '-1'
        }
      })
    } else {
      return this.httpClient.get<InfiniteResult>(`${environment.apiEndpoint}api/Partner/Developer/Income/Query`,{
        params:{
          PageSize: '-1'
        }
      })
    }
  }

  sendEmailToReadyToExpire(){
    return this.httpClient.post(`${environment.apiEndpoint}api/Partner/Subscription/SendEmailToReadyToExpire`, null)
  }

  inactiveStageOfPartnerExpired (){
    return this.httpClient.post(`${environment.apiEndpoint}api/Partner/Subscription/InactiveStageOfPartnerExpired`, null)
  }
}
