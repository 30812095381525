import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CouponService } from 'src/app/_services/coupon.service';
import { Global } from 'src/app/_services/global.service';
import { cloneDeep } from 'lodash'
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'app-mmita-type-coupon-item',
  templateUrl: './mmita-type-coupon-item.component.html',
  styleUrls: ['./mmita-type-coupon-item.component.scss']
})
export class MmitaTypeCouponItemComponent implements OnInit {
    readonly orgData
    data
    promotionCodes
    orginalPromotionCodes
    constructor(
        private couponService: CouponService,
        private toastrService: ToastrService,
        private global: Global,
        private nzModalRef: NzModalRef,
        private modalService: ModalService
    ) { }

  ngOnInit(): void {
    this.data = cloneDeep(this.orgData)
    this.promotionCodes = this.data.promotionCodes
    this.orginalPromotionCodes = cloneDeep(this.promotionCodes)
  }

  upload(ev){
    if(ev instanceof Array && ev.length > 0){
      this.couponService.addPromotionCodesByExcel(this.orgData.id ,ev[0]?.file).subscribe({
        next: res=>{
          this.reloadData()
          this.toastrService.success('Upload file success', 'Success')
        },
        error: error => this.toastrService.error('post file faild')
      })
    }
  }
  trackById(index: number) {
    return index;
  }

  reloadData(){
    this.couponService.getCouponById(this.orgData.id).subscribe(res=>{
      this.data = res
      this.promotionCodes = this.data.promotionCodes
      this.orginalPromotionCodes = cloneDeep(this.promotionCodes)
      this.global.appEvent.next({ msg: 'update.coupon', para: null });
    })
  }
  addField() {
    this.promotionCodes.push({})
  }

  save(item){
    item.id ?
    this.couponService.updatePromotionCode(this.orgData.id, item.id, item.promotionCode).subscribe(()=>{
      this.reloadData()
      this.toastrService.success(`Code ${item.id} has been changed to ${item.promotionCode}`, 'Success')
    }) : 
    // to remove
    this.couponService.addPromotionCode(this.orgData?.id, item.promotionCode).subscribe(()=>{
      this.reloadData()
      this.toastrService.success(`Create Coupon ${item.promotionCode}`, 'Success')
    }) 

  }

  addFields(){
    const newCodes = this.promotionCodes.filter(item => !item.id)
    this.couponService.addPromotionCodes(this.orgData.id, newCodes).subscribe(()=>{
      this.nzModalRef.triggerOk()
    })
  }

  removeCode(id){
    this.couponService.deleteCouponCode(this.orgData.id, id).subscribe(()=>{
      this.reloadData()
      this.toastrService.success(`Remove Coupon Code`, 'Success')

    })
  }
    
  getUserInfo(id){
      this.couponService.getCouponCodeUserInfo(this.orgData.id, id).subscribe(res => { 
          this.modalService.showDetail(res, 'User Information Detail')
      })
  }
}
