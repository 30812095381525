import { Component, Injector, OnInit, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { FaqService } from '../../../_services/faq.service';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-faq-delete',
  templateUrl: './faq.delete.component.html',
})

export class FaqDeleteComponent implements OnInit {
  id: string;
  @Input() public itemId;

  constructor(
    private faqService: FaqService,
    private injector: Injector,
    private global: Global,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.id = this.itemId; // this.route.snapshot.params['id'];
  }
  faqDelete() {
    this.faqService.delete(this.id)
      .pipe(first())
      .subscribe(
        data => {
          this.modalClose();
          this.global.appEvent.next({ msg: 'reload.faq', para: null });
          this.toastr.success('Data Deleted Successfully...!', 'Success');
        });
  }
  modalClose() {;
    this.global.appEvent.next({ msg: 'modalService.close', para: null });

  }
}
