<ng-container *ngIf="collapsed; else showCanvas">
    <div class="collapsed-btn" (click)="collapsed = !collapsed">
        <i class="fas fa-fw icon">
        </i>
    </div>
</ng-container>
<ng-template #showCanvas>
    <div class="canvas-wrapper" >
        <div class="collapse-btn" (click)="collapsed = !collapsed">
            <i class="fas fa-fw icon"></i>
        </div>
        <ng-content *ngIf="!collapsed" select="[content]"></ng-content>
    </div>
</ng-template>
