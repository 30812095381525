import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MmitaAsset } from '../_models/mmitaAsset';
import { AssetReadDto } from '../_models/assetReadDto';
import { AssetWriteDto } from '../_models/assetWriteDto';
import { InfiniteResult } from '../_models/body';

@Injectable({ providedIn: 'root' })
export class MmitaAssetService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  formOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };

  constructor(
      private http: HttpClient
    ) {
  }

  getAll(params?) {
    let para = {}
    if(params){
      for(let prop in params){
        Object.assign(para, {[prop]: params[prop]});
      }
    }
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Asset/Query`,{
      params: para
    });
  }

  getById(id: string) {
    return this.http.get<AssetReadDto>(`${environment.apiEndpoint}api/Asset/${id}`);
  }

  addAsset(request: AssetWriteDto){
    return this.http.post(`${environment.apiEndpoint}api/Asset`, request)
  }

  batchaddAsset(request: Array<AssetWriteDto>){
    return this.http.post(`${environment.apiEndpoint}api/Assets`, request)
  }


  batchDelete(assetsIdList: any){
    return this.http.request<any>('delete',`${environment.apiEndpoint}api/Assets`, {
      body: assetsIdList,
      headers: this.formOptions,
      observe: 'body',
    })
  }

  updateAsset(request: AssetWriteDto, id: string){
    return this.http.put(`${environment.apiEndpoint}api/Asset/${id}`, request)
  }

  batchUpdateAbility(req){
    return this.http.put(`${environment.apiEndpoint}api/Assets/Abilities`, req)
  }

  delete(id: string) {
    return this.http.delete<MmitaAsset>(`${environment.apiEndpoint}api/Asset/${id}`);
  }

  getMapPlaceAsset(params){
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/Asset/Query?${params}`,{
      params: {
        'PageSize': '-1'
      }
    });
  }


  getSketchfabAccessToken(){
    return this.http.get(`${environment.apiEndpoint}api/Asset/Sketchfab/AccessToken`)
  }

  postSketchfabAccessToken(){
    return this.http.post(`${environment.apiEndpoint}api/Asset/Sketchfab/AccessToken`, null)

  }
  
}
