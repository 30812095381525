export class LocalizationDataBuilder {
    dataArray = []
    constructor(){

    }

    genData(data){
        this.dataArray = []
        if(!data && !data.length){
            return;
        }
        data.forEach(item => {
            this.dataArray.push({...item})
            if(item.updating){
                this.dataArray.push({status: 'Updating', ...item.updating})
            }
        })
        return this.dataArray;
    }
}

export class LocalizationItemBuilder{
    description;
    values = {
      "en-US": undefined,
      "zh-CN": undefined,
      "zh-HK": undefined
    };
    categoryId;
    constructor(data, updateMode){
        if(!data){
            return
        }
        this.description = data.description;
        this.values['en-US'] = data.us;
        this.values['zh-CN'] = data.cn;
        this.values['zh-HK'] = data.hk;
        this.categoryId = data.categoryId;
        if(!updateMode){
            this['key'] = data.key
        }
    }
}