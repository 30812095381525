import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FaqService } from '../../../_services/faq.service';
import { LanguageService } from '../../../_services/language.service';
import { LanguageReadDto } from '../../../_models/languageReadDto';
import { Global } from 'src/app/_services/global.service';

@Component({
  selector: 'app-faq-addnew',
  templateUrl: './faq.add.component.html',
  styleUrls: ['../faq.component.scss']
})

export class FaqAddComponent implements OnInit {
  faqAddNew: any;
  languageList: LanguageReadDto[];
  readonly typeList = [ 'MmitaWeb', 'Partner']

  constructor(
    private formbulider: FormBuilder,
    private faqService: FaqService,
    private languageService: LanguageService,
    private injector: Injector,
    private global: Global,
    private toastr: ToastrService
    ) {
    this.fetchLanguageAll();
  }

  ngOnInit() {
    this.faqAddNew = this.formbulider.group({
      Order: ['', [Validators.required]],
      Question: ['', [Validators.required]],
      Answer: ['', [Validators.required]],
      LanguageCode: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
  }

  fetchLanguageAll() {
    this.languageService.apiLanguageGet()
      .pipe(first())
      .subscribe(
        data => {
          this.languageList = data.filter(t => t.isActive).sort(t => t.order);
        });
  }
  onFaqFormSubmit() {
    const usernew = this.faqAddNew.value;
    this.faqService.addNew(usernew)
      .pipe(first())
      .subscribe(
        data => {
          this.modalClose();
          this.global.appEvent.next({ msg: 'reload.faq', para: null });
          this.toastr.success('Data Created Successfully...!', 'Success');
        });
  }
  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });

  }
}
