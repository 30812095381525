
<nz-table class="table table-striped"  #basicTable [nzData]="data">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Fee Type</th>
            <th scope="col">Fee Number Type</th>
            <th scope="col">Ratio</th>
            <th scope="col">Fee</th>

        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of basicTable.data; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>
                {{ item?.feeType }}
            </td>
            <td>{{ item?.feeNumberType }}</td>
            <td>{{ item?.ratio }}</td>
            <td>{{ item?.fee }} {{ item?.currency?.toUpperCase() }}</td>

        </tr>
    </tbody>
</nz-table>

