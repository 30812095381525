  <div class="menu-wrapper">
    <div class="side-bar-wrapper">
      <div class="user-info">
        Hi {{ currentUser.firstName }} {{ currentUser.lastName }}
      </div>
      <ng-container *ngFor="let item of menu">
        <li *ngIf="item.routerLink; else collapseMode" class="nav-li nav-tab" [routerLinkActive]="['active']" [routerLink]="[item.routerLink]" [routerLinkActiveOptions]="{ exact: true }">
              <i class="fas fa-fw" [ngClass]="item.icon"></i>
              <span>{{item.title}}</span>
        </li>
        <ng-template #collapseMode>
          <div  [ngClass]="item.isCollapsed ? 'collapsed': 'open'">
            <li class="nav-li nav-tab" (click)="item.isCollapsed = !item.isCollapsed">
              <i class="fas fa-fw" [ngClass]="item.icon"></i>
              <span>
                {{item.title}}
              </span>
            </li>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="item.isCollapsed" >
              <li class="nav-li" *ngFor="let child of item.children" [routerLink]="[child.routerLink]" [routerLinkActive]="['active']">
                  <i class="fas fa-fw" [ngClass]="child.icon"></i>
                  <span>{{child.title}}</span>
              </li>
            </div> 
          </div>
  
        </ng-template>
      </ng-container>

    </div>
    <div class="user-info logout" (click)="logout()">
      Log out
    </div>
  </div>
