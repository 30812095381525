    <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="report"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true"

  >
  </ag-grid-angular>
  
  