<ng-contianer [ngSwitch]="mode">
    <div class="drawer-header">
        <div class="point-type-header" [ngSwitch]="mode">
            <ng-contianer *ngSwitchCase="StageGroupMode.EditMode">
                Edit Mode
            </ng-contianer>
            <ng-contianer *ngSwitchDefault >
                {{mode === StageGroupMode.PreviewMode ? 'Preview Copy Group Information': 'Confirm To Save'}}
            </ng-contianer>
        </div>
        <ng-content select="[content]"></ng-content>
    </div>
    <ng-contianer *ngSwitchCase="StageGroupMode.EditMode">
        <nz-form-item nzRow>
            <nz-form-label [nzSpan]="24" nzLabelAlign="left" nzFor="id">ID</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input nz-input disabled [ngModel]="currentFocusOnInfo?.id"/>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nzRow>
            <nz-form-label [nzSpan]="24" nzLabelAlign="left" nzRequired nzFor="name">Group Name</nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Please input field" [nzValidateStatus]="name ? null: 'error'">
                <input nz-input required [(ngModel)]="name" />
            </nz-form-control>
        </nz-form-item>
        <button nz-button nzType="primary" (click)="submit(StageGroupMode.EditMode)" style="margin-right: 5px;">Update</button>
        <button nz-button nzType="primary" nzDanger (click)="delete(currentFocusOnInfo?.id)">Delete</button>
    </ng-contianer>
    <ng-contianer *ngSwitchDefault >
        <form  *ngIf="rendered && formGroup" nz-form [formGroup]="formGroup" [nzLayout]="'vertical'">
            <div nz-row nzGutter="16">
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="Group">Group</nz-form-label>
                        <nz-form-control nzErrorTip="Please input field">
                            <nz-select nzAllowClear formControlName="rootId">
                                <nz-option *ngFor="let item of rootGroupArray" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="scaleType">Scaling Rule</nz-form-label>
                        <nz-form-control nzErrorTip="Please input field">
                            <nz-select formControlName="scaleType">
                                <nz-option *ngFor="let item of scaleTypeList" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>    
                <div nz-col nzSpan="24">
                    <nz-form-item >
                        <nz-form-label nzRequired nzFor="scaleMultiple">Scale Multiple</nz-form-label>
                        <nz-form-control nzErrorTip="Please input field">
                            <nz-input-number formControlName="scaleMultiple" class="mit-number-field"></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="heading">Heading</nz-form-label>
                        <nz-form-control nzErrorTip="Please input field">
                            <nz-input-number formControlName="heading" class="mit-number-field"></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="longitude">Longitude</nz-form-label>
                        <nz-form-control nzErrorTip="Please input field">
                            <nz-input-number formControlName="longitude" class="mit-number-field"></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="latitude">Latitude</nz-form-label>
                        <nz-form-control nzErrorTip="Please input field">
                            <nz-input-number formControlName="latitude" class="mit-number-field"></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="altitude">Altitude</nz-form-label>
                        <nz-form-control nzErrorTip="Please input field">
                            <nz-input-number formControlName="altitude" class="mit-number-field"></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <button style="margin-right: 10px;" (click)="submit(StageGroupMode.PreviewMode)" nz-button nzType="default">Preview</button>
            <button *ngIf="mode === StageGroupMode.CopyMode" (click)="submit(StageGroupMode.CopyMode)" nz-button nzType="primary" >Save</button>
        </form>
    </ng-contianer>
</ng-contianer >