import { Component, Input, OnInit } from '@angular/core';
import { MapTab } from 'src/app/common/constants';
import { PublicMapService } from '../../service/public-map.service';
import { Global } from 'src/app/_services/global.service';

@Component({
  selector: 'app-marker-item',
  templateUrl: './marker-item.component.html',
  styleUrls: ['./marker-item.component.scss']
})
export class MarkerItemComponent implements OnInit {
  readonly MapTab = MapTab
  @Input() dataSource: any
  @Input() currentFocusOnInfo
  @Input() tabInfo: any

  constructor(
    private publicMapService: PublicMapService,
    private global: Global
  ) { }

  ngOnInit(): void {
  }

  delete(ev:{id: string, type: MapTab}) {
    this.publicMapService.deleteItem(ev.id, ev.type).subscribe(()=>{
      this.global.appEvent.next({msg: 'reload-map'})
    })
  }


  update(ev: {data: any, type: MapTab}){
    this.publicMapService.updateItem(ev).subscribe(()=>{
    })
  }

  add(ev: {data: any, type: MapTab}){
    this.publicMapService.addItem(ev).subscribe(()=>{
    })
  }

  backToGrid(){
    this.global.appEvent.next({msg: 'reload-map'})
  }



}
