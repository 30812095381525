import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GridAction } from 'src/app/common/constants';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { InfiniteResult } from 'src/app/_models/body';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { VerticalCarouselService } from 'src/app/_services/vertical-carousel.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VCTemplate } from './vertical-carousel-item.constant';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-vertical-carousel',
  templateUrl: './vertical-carousel.component.html',
  styleUrls: ['./vertical-carousel.component.scss'],
  providers: [DatePipe]

})
export class VerticalCarouselComponent extends BaseComponent implements OnInit {
  data: any;
  formGroup: FormGroup
  constructor(
    private verticalCarouselService: VerticalCarouselService,
    private toastr: ToastrService,
    public global: Global,
    private modalService: ModalService,
    private datePipe: DatePipe
  ) {
      super(global)
    }

    formatDateTime(params){
      return this.datePipe.transform(params.value, 'short')
    }
    


    setColumnDefs(){
      this.columnDefs = [
          { headerName: 'ID', field: 'id'},
          { headerName: 'Description', field: 'description'},
          { headerName: 'Speed', field: 'speed'},
          { headerName: 'Viusal Area', field: 'viusalArea'},
 
          { headerName: 'Created At', field: 'createdAt',  valueFormatter: this.formatDateTime.bind(this)},
          {
            headerName: 'Action',
            cellRenderer: params => {
              return `  <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                        <i class="fas fa-fw fa-edit text-primary" id="edit" style="margin-right:3px; cursor: pointer;"></i>
                        <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
            },
            onCellClicked: params => {
              switch(params.event.target.id){
                case 'moreDetail':
                  this.modalService.showDetail(params.data, 'Vertical Carousel Detail')
                  break;
                case 'edit':
                  this.openItem(params)
                  break;
                case 'delete':
                  this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                  break;
              }
            },
          },
      ];
    }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.getAll();
    }

    ngOnInit() {
      this.setColumnDefs();
    }


    getAll() {
      this.verticalCarouselService.getVerticalCarouselList()
        .subscribe((res: InfiniteResult) => {
            this.data = res.data;
        });
    }


  delete(id){
    this.verticalCarouselService.delete(id).subscribe(res=>{
        this.getAll();
        this.toastr.success('Data Delete Successfully...!', 'Success');
      })
  }


  openItem(params?){
    this.genFG(params?.data)
    const dialogTemplate = VCTemplate()
    this.modalService.createNzModal(()=>this.onSubmit.call(this, params?.data?.id), this.formGroup, dialogTemplate)
  }


  onSubmit(id){
    return new Observable(subscriber => {
      const saveSuccess = () => {
        this.getAll()
        this.toastr.success('Data Saved Successfully...!', 'Success');
        subscriber.next()
        subscriber.complete()
      }

      const fieldDetail = this.formGroup.get('images').value?.map(item=>{
        if(item?.medium){
          const res = item?.medium
          return {url: res.url, height:res.pixel ? res.pixel.height :0, width:res.pixel ? res.pixel.width :0, size: res.size}
        }
        return item
      })
      this.formGroup.get('images').patchValue(fieldDetail)
  
      id ? this.verticalCarouselService.update(id, this.formGroup.value).subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      })  : this.verticalCarouselService.add(this.formGroup.value).subscribe({
        next: x => saveSuccess(),
        error: x => subscriber.error(x)
      }) 
    })
  }

  genFG(orgData){
    this.formGroup = new FormGroup({
      description: new FormControl(orgData?.description, Validators.required),
      speed: new FormControl( orgData?.speed || 1, Validators.required),
      viusalArea: new FormControl(orgData?.viusalArea || 0.5, [Validators.required, Validators.min(0.1), Validators.max(0.9)]),
      images: new FormControl(orgData?.images, Validators.required)
    })
  }
}






