<table *ngIf="randered" class="table">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dailyInfo">
        <th scope="row">{{item.key}}</th>
        <td>{{item.value}}</td>
      </tr>
      </tbody>
  </table>