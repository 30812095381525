import { DialogTemplate, MitFieldInfo } from "src/app/_models/body"
import { InputFieldType } from "src/app/common/constants"

export const StreamerTemplate = (editMode, StreamerStatus): DialogTemplate => {
    return {
        dialogTitle: 'Set Streamer',
        dialogFields: editMode ? baseFieldArray(editMode).concat(StatusField(StreamerStatus)) : baseFieldArray(editMode)
    }
}

const baseFieldArray = (editMode): Array<MitFieldInfo>=>{
    return [
        {
            key: 'email',
            label: 'Email',
            type: InputFieldType.Text,
            size: 24,
            required: true
        },
        {
            key: 'firstName',
            label: 'FirstName',
            type: InputFieldType.Text,
            size: 12,
            required: true
        },
        {
            key: 'lastName',
            label: 'LastName',
            type: InputFieldType.Text,
            size: 12,
            required: true
        },
        {
            key: 'password',
            label: 'Password',
            type: InputFieldType.Text,
            size: 12,
            required: !editMode
        },
        {
            key: 'description',
            label: 'Description',
            type: InputFieldType.Text,
            size: 12,
            required: true
        },
        {
            key: 'profileUrl',
            label: 'Profile Url',
            type: InputFieldType.Uploader,
            size: 12,
            required: true
        },
        {
            key: 'coverUrl',
            label: 'Cover Url',
            type: InputFieldType.Uploader,
            size: 12,
            required: true
        },
    
    ]
}

const StatusField = (StreamerStatus): Array<MitFieldInfo>=> {
    return [{
        key: 'status',
        label: 'Status',
        type: InputFieldType.Selector,
        size: 12,
        options: StreamerStatus
    }]

}