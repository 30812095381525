<div *ngIf="rendered" class="container pop-up-container">
    <section class="my-popover-header">
      <h3 style="text-align:center">
        <span *ngIf="editMode; else createMode">
            Asset Edit
        </span>
        <ng-template #createMode>
            {{isBatch? 'Add Batch Assets' : 'Add New Asset'}}
        </ng-template>
        </h3>
    </section>
    <section class="my-popover-content">
      <form [formGroup]="assetFormGroup" class="mb-4" (ngSubmit)="onAssetFormSubmit()">
        <div *ngIf="batchInfo; else normalForm" class="form-group mb-3">
          <label for="assetType">Asset Ability:</label>
          <ng-multiselect-dropdown
            [settings]="dropdownSettings"
            [data]="abilityList"
            formControlName="ability"
          >
          </ng-multiselect-dropdown>
        </div>

        <ng-template #normalForm>
          <section class="row">
            <div class="form-group col">
              <label for="assetType">Asset Type:</label>
              <select class="form-select" formControlName="assetType" (change)="onChange($event)" [attr.disabled]="editMode ? editMode : null" required>
                <option *ngFor="let item of AssetTypeSelections" [value]="item">{{item}}</option>
              </select>
            </div>
            <div class="form-group col-6">
              <label for="categoryType">Category Name:</label>
              <select class="form-select" formControlName="categoryType" (change)="changeCategory($event)" [attr.disabled]="editMode ? editMode : null" required>
                <option *ngFor="let item of categoryForAsset" value="{{item.id}}">{{item?.multilingualNames['en-US']}}</option>
              </select>
            </div>

            <div class="form-group col-6">
              <label for="scaleInMap">Scale In Map:</label>
              <input type="number" class="form-control" formControlName="scaleInMap">
            </div>
          </section>
    
          <div *ngIf="assetFormGroup.value.assetType == 'Model3d'" class="form-group mb-3">
            <label for="assetType">Asset Ability:</label>
            <ng-multiselect-dropdown
              [settings]="dropdownSettings"
              [data]="abilityList"
              formControlName="ability"
            >
            </ng-multiselect-dropdown>
          </div>

          <section class="row" *ngIf="!isBatch">
            <div class="form-group col">
              <label for="Name">Name:</label>
              <input type="text" class="form-control" placeholder="Name" formControlName="name" required>
            </div>
            <div class="form-group col">
              <label for="Descrption">Description:</label>
              <input type="text" class="form-control" placeholder="Descrption" formControlName="description">
            </div>
    
          </section>
    
          <!-- new property -->
          <section class="row">
            <div class="form-group col">
              <label for="numberOfTokensUnlocked">Number Of Tokens Unlocked:</label>
              <input type="number" class="form-control" formControlName="numberOfTokensUnlocked" required>
            </div>
            <div class="form-group col">
              <label for="adUrlLinked">Ad Url Linked:</label>
              <input type="text" class="form-control" formControlName="adUrlLinked">
            </div>
          </section>
          
          <section class="row">
            <div class="form-group col">
              <label for="assetStatus">Asset Status:</label>
              <div class="radio-wrapper">
                <input type="radio"  formControlName="assetStatus" value="Active"> Active
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input type="radio" formControlName="assetStatus" value="Inactive"> Inactive
              </div>
            </div>

            <div class="form-group col">
              <label for="businessType">Business Type:</label>
              <select class="form-select" formControlName="businessType"  required>
                <option *ngFor="let item of AssetBusinessTypeSelections" [value]="item">{{item}}</option>
    
              </select>
            </div>
          </section>
    
    
          <div class="form-group mb-4">
            <label for="Url">IOS File:</label>
            <input formControlName="file"
                   id="file"
                   type="file"
                   class="form-control"
                   [attr.required]="editMode || isCopy ? null : true"
                   [multiple]="isBatch"
                   (change)="onFileChange($event,'fileSource')">
          </div>
          <ng-container *ngFor="let media of previewMediaSources">
            <img *ngIf="media.type.indexOf('image')>=0"  [src]="media.src" alt="" class="preview-wrapper" [id]="media.name">
          </ng-container>
          
          <div class="form-group mb-4" *ngIf="assetFormGroup.value.assetType == 'Model3d'">
            <label for="Android Url">Android File:</label>
            <input formControlName="androidFile"
                   id="androidFile"
                   type="file"
                   class="form-control"
                   [attr.required]="editMode || isCopy  ? null : true"
                   [multiple]="isBatch"
                   (change)="onFileChange($event,'androidFileSource')">
          </div>
          <!-- If the asset type is model3d, the vision os file is optional.  -->
          <div class="form-group mb-4" *ngIf="assetFormGroup.value.assetType == 'Model3d'">
            <label for="Android Url">Vision OS File:</label>
            <input formControlName="visionOSFile"
                   type="file"
                   class="form-control"
                   [attr.required]="editMode || isCopy  ? null : true"
                   [multiple]="isBatch"
                   (change)="onFileChange($event,'visionOSFileSource')">
          </div>

          <div class="form-group mb-4" *ngIf="assetFormGroup.value.assetType == 'Model3d'">
            <label for="Android Url">Mac OS File:</label>
            <input formControlName="macOSFile"
                   type="file"
                   class="form-control"
                   [attr.required]="editMode || isCopy  ? null : true"
                   [multiple]="isBatch"
                   (change)="onFileChange($event,'macOSFileSource')">
          </div>
    
          <div class="form-group mb-4" *ngIf="assetFormGroup.value.assetType == 'Video'||assetFormGroup.value.assetType == 'Model3d'">
            <label for="Thumbnail">Asset Thumbnail:</label>
            <input formControlName="thumbnail"
                   id="thumbnail"
                   type="file"
                   class="form-control"
                   accept="image/*"
                   [multiple]="isBatch"
                   [attr.required]="editMode || isCopy  ? null : true"
                   (change)="onFileChange($event,'thumbnailSource')">
          </div>
        </ng-template>

        <div class="row">
          <div class="col-md-2">
            <button type="submit" class="btn btn-block btn-primary">Save</button>
          </div>
          <div class="col-md-2">
            <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
          </div>
        </div>
      </form>
    </section>
  </div>
  