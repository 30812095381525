import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/_services/customer.service';

@Component({
  selector: 'app-add-coin',
  templateUrl: './add-coin.component.html',
  styleUrls: ['./add-coin.component.scss']
})
export class AddCoinComponent implements OnInit {
  id: number;
  callback
  formGroup = new FormGroup({
    exchangeCount: new FormControl(null, Validators.required),
  });
  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private nzModalRef: NzModalRef
  ) { }

  ngOnInit() {

  }
  onSubmit(){
    if(this.formGroup.invalid){
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return
    }
    this.customerService.setCoin({userId: this.id, exchangeCount: this.formGroup.value.exchangeCount }).subscribe(
      data => {
        this.modalClose();
        this.toastr.success('Exchange Coins Successfully...!', 'Success');
        this.callback.apply(this)
      });
  }

  modalClose(){
    this.nzModalRef.close()
  }   
}


