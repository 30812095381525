
// export function  handleApiError(error){
//   if( error && error.error ){
//     return  Array.isArray(error.error.details)?error.error.details.join(' ') : error.error.message ?  error.error.message: error.message
//   } else if (typeof error === 'string' || error instanceof String) {
//     return error;
//   }
// }

import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { UploadFileDto } from "../_models/uploadFileDto";

export function handleApiError(error){
  if(!error || !error.error){
    return;
  }
  const errorInfo = error.error
  if(errorInfo.details && Array.isArray(errorInfo.details)){
    return errorInfo.details.join(' ')
  } else if(errorInfo.message){
    return errorInfo.message
  }  else if(errorInfo.errors){
    return JSON.stringify(errorInfo.errors)
  } else if(error.message){
    return error.message
  } else {
    return 'Some unknown errors occurred'
  }
}

export function arrayFromParamsBuilder(formParams, paramName:string, requestObj:any) {
  // when Array.length === 0, don't append fromParams
  if (requestObj && Array.isArray(requestObj)) {  
    requestObj.forEach(item=>{
      formParams.append(paramName, <any>item) as any || formParams;
    })
  } else if(requestObj !== undefined && requestObj !== null) {
    formParams.append(paramName, <any>requestObj) as any || formParams;
  }
  return formParams
}


export function formParamsBuilder(formParams, paramName: string, requestObj:any) {
  if (requestObj !== undefined && requestObj !== null) {
    formParams = formParams.append(paramName, <any>requestObj) as any || formParams;
  }
  return formParams
}

export function buildFormData(params){
  const formData = new FormData();
  for(let prop in params){
    if(params[prop] !== undefined && params[prop] !== null) {
      formData.append(prop,params[prop])
    }
  }
  return formData
}

export function getLatLng(LatLngObj, isLat: boolean){
  return isLat ? LatLngObj.lat() : LatLngObj.lng()
}

export function genArrayDataInFG(links){
    const linkArray = []
    const linkStrArray =  links.map(item => { return item.li})
    linkStrArray && linkStrArray.forEach(item=>{
        if(item){
            linkArray.push(item)
        }
    })
    return linkArray
}

export function mapRandom(level: number = 3){
  // default 0.001
  return Number(((Math.round(Math.random()) * 2 - 1 )* Math.random()* 0.1**level).toFixed(14))
}

export function uploadImage(fileUpload, mediaSource){
  return new Observable(subscriber => {
    if(mediaSource){
      fileUpload.postFile([{file: mediaSource, name: mediaSource.name}]).subscribe(res=>{
        subscriber.next(res[0].origin);
        subscriber.complete();
      })
    } else {
      subscriber.next(null);
      subscriber.complete();
    }
    })
}

export function batchDeleteResult(res,toastr){
  let errorMsg = '';
  let successCount = 0
  for(let prop in res){
    if(res[prop]){
      successCount++
    } else {
      
      errorMsg += `【ID:${prop}】`
    }
  }

  successCount && toastr.success(`Successfully deleted ${successCount} users`, 'Success');
  errorMsg && toastr.error(`${errorMsg}, failed to delete`, 'Error')   
}

export function captureThumbnail(media: Array<any>) {
  return media.map(item =>{
        const img = document.getElementById(item.name) as any
        const canvas = document.createElement('canvas');
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth; 
        canvas.getContext('2d').drawImage(<CanvasImageSource>img, 0, 0);
        return dataURItoBlob(canvas.toDataURL('image/png'), `${item.name}`);
  })

}


export function captureGifThumbnail(array:Array<UploadFileDto>) {
  const gifThum:Array<UploadFileDto> = []
  array.forEach(fileData =>{
    if(fileData.file.type.indexOf('gif')>=0){
      const img = document.getElementById(fileData.guid) as any
      const canvas = document.createElement('canvas');
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth; 
      canvas.getContext('2d').drawImage(<CanvasImageSource>img, 0, 0);
      gifThum.push({file: dataURItoBlob(canvas.toDataURL('image/png'), `${fileData.guid}-thumbnail`), guid: guid()})
    }
  })
  return gifThum
}



function dataURItoBlob(dataURI, fileName) {
  const _dataURI = dataURI.split(',')
  const byteString = atob(_dataURI[1]);
  const fileType = mapImageType(_dataURI[0])
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: fileType });
  return new File([blob], fileName, { type: fileType });
}


function mapImageType(type){
  if(type.indexOf('png')>=0){
    return 'image/png'
  }
  return 'image/jpeg'
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
  });
}

export function setNewOriginUrl(fg: FormGroup, ctrlName: string){
  const newUploadedUrl = fg.get(ctrlName).value?.[0]?.origin?.url
  newUploadedUrl && fg.patchValue({[ctrlName]: newUploadedUrl})
}