import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PublicMapService } from '../../service/public-map.service';

@Component({
  selector: 'app-review-board',
  templateUrl: './review-board.component.html',
  styleUrls: ['./review-board.component.scss']
})
export class ReviewBoardComponent implements OnInit {
  searchResultArray: Array<any>
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private toastrService: ToastrService,
    private publicMapService: PublicMapService,
    private ref: ChangeDetectorRef
  ) { 
  }

  ngOnInit() {
  }

  searchByText(ev) {
    this.publicMapService.searchByText(ev).subscribe((res: Array<any>)=>{
      this.searchResultArray = res
      this.toastrService.success(`return ${res.length} records`)
      this.ref.detectChanges()
    });
  }

  selectItem(item){
    this.ngbActiveModal.close(item)
  }

}
