import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { WhitelistReadDto } from '../_models/whitelistReadDto';

@Injectable({ providedIn: 'root' })
export class WhitelistService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<WhitelistReadDto[]>(`${environment.apiEndpoint}api/WhiteLists`);
  }

  getById(id: string) {
    return this.http.get<WhitelistReadDto>(`${environment.apiEndpoint}api/WhiteLists/${id}`);
  }

  insertNew(data) {
    return this.http.post<WhitelistReadDto>(`${environment.apiEndpoint}api/WhiteLists`, data)
  }

  delete(id: string) {
    return this.http.delete<WhitelistReadDto>(`${environment.apiEndpoint}api/WhiteLists/${id}`);
  }

}
