<div class="md-popover login-popover" style="margin:30px">
    <h3 style="text-align:center">{{updateMode? 'Update': 'Add'}} Localization Item</h3>
    <section *ngIf="rendered" class="my-popover-content">
        <form [formGroup]="formGroup" class="mb-4" ngNativeValidate (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col form-group">
                    <label for="description">Description:</label>
                    <input class="form-control" type="text" formControlName="description"/>
                </div>
                <div class="col form-group">
                    <label for="categoryId">Category:</label>
                    <select class="form-select" formControlName="categoryId" required>
                        <option *ngFor="let category of categoryList" value="{{category.id}}">{{category.name}}</option>
                    </select>
                </div>
                <div *ngIf="!updateMode" class="col form-group">
                    <label for="key">Key:</label>
                    <input class="form-control" type="text" formControlName="key"/>

                </div>
            </div>

            <div class="row">
                <div class="col-4 form-group">
                    <label for="us">en-US:</label>
                    <input class="form-control" type="text" formControlName="us"/>
                </div>
                <div class="col-4 form-group">
                    <label for="cn">zh-CN:</label>
                    <input class="form-control" type="text" formControlName="cn"/>
                </div>
                <div class="col-4 form-group">
                    <label for="hk">zh-HK:</label>
                    <input class="form-control" type="text" formControlName="hk"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                  <button type="submit" class="btn btn-block btn-primary">Save</button>
                </div>
                <div class="col-md-2">
                  <button id="popoverTrigger" type="button" (click)="modalClose()" class="btn btn-block btn-danger">Close</button>
                </div>
              </div>
        </form>
    </section>
  </div>
  