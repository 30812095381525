import { Component, OnInit, Input, Injector } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ImageService } from '../../../_services/imageCarousel.service';
import { first } from 'rxjs/operators';
import { LanguageService } from '../../../_services/language.service';
import { LanguageReadDto } from '../../../_models/languageReadDto';
import { Global } from 'src/app/_services/global.service';
import { ToastrService } from 'ngx-toastr';
import { ImageCarousel } from 'src/app/_models/imageCarousel';
import { Observable } from 'rxjs';
import { FileUploadService } from 'src/app/_services/file-upload.service';

@Component({
  selector: 'app-image-carousel-update',
  templateUrl: './image-carousel.update.component.html',
})

export class ImageCarouselUpdateComponent implements OnInit {
  data = false;
  imageUpdate: any;
  getdata: any;
  id: string;
  languageList: LanguageReadDto[];
  @Input() public itemId;

  constructor(
    private formbulider: FormBuilder,
    private imageService: ImageService,
    private languageService: LanguageService,
    private global: Global,
    private toastr: ToastrService,
    private fileUpload: FileUploadService
  ) {
    this.fetchLanguageAll();
  }

  ngOnInit() {
    this.id = this.itemId; // this.route.snapshot.params['id'];
    this.imageUpdate = this.formbulider.group({
      Order: ['', [Validators.required]],
      ImgUrl: ['', [Validators.required]],
      IsActive: ['', [Validators.required]],
      file: ['', [Validators.required]],
      fileSource: [null, [Validators.required]],
      LanguageCode: ['', [Validators.required]],
    });

    this.imageService.getById(this.id)
        .pipe(first())
     .subscribe(x => {
       this.getdata = x;
     });
  }

  fetchLanguageAll() {
    this.languageService.apiLanguageGet()
      .pipe(first())
      .subscribe(
        data => {
          this.languageList = data.filter(t => t.isActive).sort(t => t.order);
        });
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.imageUpdate.patchValue({
        fileSource: file
      });
    }
  }

  onImageUpdateFormSubmit() {
    this.uploadImage().subscribe(imageInfo=> {
      const Imgnew = new ImageCarousel({
        imgUrl: imageInfo,
        isActive: this.imageUpdate.value.IsActive,
        order: this.imageUpdate.value.Order,
        languageCode: this.imageUpdate.value.LanguageCode,
      })
      this.imageService.updateById(Imgnew, this.getdata.id).subscribe(data => {
            this.modalClose();
            this.global.appEvent.next({ msg: 'reload.image-carousel', para: null });
            this.toastr.success('Data Created Successfully...!', 'Success');
          });
    })
  }

  uploadImage(){
    return new Observable(subscriber => {
      if(this.imageUpdate.value.fileSource === null || this.imageUpdate.value.fileSource === undefined || this.imageUpdate.value.fileSource === '' ){
        subscriber.next(null);
        subscriber.complete();
      }
      this.fileUpload.postFile([{file: this.imageUpdate.value.fileSource, name: this.imageUpdate.value.fileSource.name}]).subscribe(res=>{
        subscriber.next(res[0].origin);
        subscriber.complete();
      })
    })
  }
  modalClose() {
    this.global.appEvent.next({ msg: 'modalService.close', para: null });

  }
}
