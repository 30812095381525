import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/MmitaCoupon/Query`, {
      params: {
        PageSize: '-1'
      }
    });
  }

  getCouponById(id){
    return this.http.get(`${environment.apiEndpoint}api/MmitaCoupon/${id}`)
  }

  createCoupon(data){
    return this.http.post<any>(`${environment.apiEndpoint}api/MmitaCoupon`,data);
  }

  updateCoupon(id: string, data){
    return this.http.put<any>(`${environment.apiEndpoint}api/MmitaCoupon/${id}`,data);
  }

  delete(id: string){
    return this.http.delete<any>(`${environment.apiEndpoint}api/MmitaCoupon/${id}`);
  }

    updatePromotionCode(mmitaCouponId, promotionCodeId, promotionCode) {
        return this.http.put<any>(`${environment.apiEndpoint}api/MmitaCoupon/${mmitaCouponId}/PromotionCodes/${promotionCodeId}`,{
            promotionCode: promotionCode
        })
    }

  addPromotionCode(mmitaCouponId, promotioncode){
    return this.http.post<any>(`${environment.apiEndpoint}api/MmitaCoupon/${mmitaCouponId}/PromotionCode`, {promotionCode: promotioncode})
  }

  addPromotionCodes(mmitaCouponId, promotioncodes: Array<any>){
    return this.http.post<any>(`${environment.apiEndpoint}api/MmitaCoupon/${mmitaCouponId}/PromotionCodes`, promotioncodes)
  }

  addPromotionCodesByExcel(mmitaCouponId, excelFile){
    let header = new HttpHeaders()
    .set('Content-Disposition', 'form-data')
    const formParams = new FormData();
    formParams.set('ExcelFile', excelFile)
    return this.http.request<any>('post',`${environment.apiEndpoint}api/MmitaCoupon/${mmitaCouponId}/PromotionCodes/ByExcelFile`, {
      headers: header,
      body: formParams
    })
  }

  sendCoupon(mmitaCouponId, userIds: Array<string>){
    return this.http.post<any>(`${environment.apiEndpoint}api/MmitaCoupon/${mmitaCouponId}/Send`, userIds)
  }

  deleteCouponCode(mmitaCouponId, promotionCodeId){
      return this.http.delete<any>(`${environment.apiEndpoint}api/MmitaCoupon/${mmitaCouponId}/PromotionCodes/${promotionCodeId}`);
  }

  getCouponCodeUserInfo(mmitaCouponId, promotionCodeId){
    return this.http.get<any>(`${environment.apiEndpoint}api/MmitaCoupon/${mmitaCouponId}/PromotionCodes/${promotionCodeId}/UserInfo`);
  }
}
